import {
	createSlice,
	createAsyncThunk,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
	query,
	mutation,
} from "app/main/crm/tenant/graphql/tenant_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import CrmJwtService from 'app/services/crm/CrmJwtService';
import { format } from "date-fns";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

const token = CrmJwtService.getAccessToken();

export const tenantByRefNum = createAsyncThunk(
    "crmApps/crmTenant/tenantByRefNum",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.tenantByRefNum,
            variables: params,
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        if (response.data.data.tenantByRefNum) {
            var data = response.data.data.tenantByRefNum;

            return data === undefined ? null : data;
        }
    }
);

const tenantAdapter = createEntityAdapter({});

const tenantSlice = createSlice({
	name: "crmApps/crmTenant",
	initialState: tenantAdapter.getInitialState({
		searchText: "",
		showModal: false,
	}),
	reducers: {
		setTenantsSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[tenantByRefNum.fulfilled]: (state, action) => action.payload,
	},
});

export const { setTenantsSearchText } = tenantSlice.actions;

export default tenantSlice.reducer;
