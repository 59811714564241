import { combineReducers } from '@reduxjs/toolkit';
import crmlogin from './crmLoginSlice';
import crmuser from './crmUserSlice';

const authReducers = combineReducers({
  crmuser,
  crmlogin,
});

export default authReducers;
