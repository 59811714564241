export default function globalFunction() {
    const handlePasteNumericOnly = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        const numbersOnly = text.match(/^\d*\.?\d{0,2}$/g); // Extract only numbers
        const numberText = numbersOnly ? numbersOnly.join('') : ''; // Combine all extracted numbers
        document.execCommand('insertText', false, numberText);
    };

    const handlePreventCharacters = (e, excluded) => {
        if (excluded.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleFieldByRegex = (e, field, regex) => {
        const input = e.target.value;
        const isValid = (regex).test(input);
        if (isValid) {
            field.onChange(input);
        }
    };

    const handlePercentageByRegex = (e, field, regex) => {
        const input = e.target.value;
        const isValid = (regex).test(input);
        if (isValid && input <= 100) {
            field.onChange(input);
        }
    };

    return {
        handlePasteNumericOnly,
        handlePreventCharacters,
        handleFieldByRegex,
        handlePercentageByRegex // Exporting the function
    };
}
