const mutation = {
    approveBooking: `
        mutation approveBooking($data: JSONString!) {
            approveBooking (data: $data) {
                status,
                res,
                message
            }
        }
  `,
};

const query = {
    getTenantInvoice: `
        query getTenantInvoice($invoiceId: String!, $tenantId: String!) {
            getTenantInvoice (invoiceId: $invoiceId, tenantId: $tenantId){
                tenant {
                    id
                    roomPrice
                    roomNumber
                    start
                    end
                    timeOfBooking
                    mealCredit
                    bookCredit
                    totalPrice
                    house {
                        houseName
                    }
                    bedNumber
                    patron{
                        name
                        fullAddress
                        emergencyContact
                        email
                        departmentName
                    }
                }
                invoice {
                    id
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                }
            }
        }
    `
}

export { mutation, query };