import * as yup from 'yup';
import _ from '@lodash';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import CardContent from "@mui/material/CardContent";
import Box from '@mui/material/Box';

import { showMessage } from "app/store/fuse/messageSlice";
import { registerPatron } from "app/main/crm/store/crmSlice";


const defaultValues = {
    barcode: "",
    email: "",
};

function RegisterModal(props) {

    const dispatch = useDispatch();

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("Email is required"),
        barcode: yup.string().required("Barcode/ID No. is required"),
    });

    const { openDialog, handleDialogOpen, handleDialogClose } = props;

    const { control, formState, reset, getValues, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const [showErrors, setShowErrors] = useState("");

    async function handleSave() {

        const result = await trigger(["barcode", "email"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        dispatch(registerPatron(getValues('barcode'))).then((action) => {
            if (action.payload.status == 'success') {
                swal({
                    title: "Complete your Registration",
                    icon: "success",
                    text: action.payload["message"],
                    closeOnClickOutside: false,
                }).then((resp) => {
                    if (resp) {
                        handleDialogClose();
                    }
                });
            } else {
                setShowErrors(action.payload["message"]);
            }
        });

    }

    useEffect(() => {

        reset(defaultValues);
        setShowErrors("");

    }, [openDialog]);

    return (
        <>
            <Link className="font-normal" to="" onClick={() => handleDialogOpen()}>
                Register here
            </Link>

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogContent classes={{ root: 'p-16 pb-0 sm:pt-24  sm:p-48' }}>
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            size="large"
                            className='float-right'
                        >
                            <Icon>close</Icon>
                        </IconButton>

                        <form
                            name="registerForm"
                            noValidate
                            className="flex flex-col justify-center w-full mb-36"
                        >
                            <Typography variant="h6" className="mb-16 font-semibold text-18 sm:text-24 text-center">
                                Register
                            </Typography>

                            {showErrors && (<Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{
                                    bgcolor: '#FFECEC',
                                    m: 1,
                                    border: 2,
                                    width: '100%',
                                    height: '100%',
                                    margin: 'unset',
                                    borderColor: '#C11C20',
                                    borderRadius: 1,
                                    py: 2,
                                    marginBottom: "1.6rem"
                                }}>
                                    <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                        <Typography variant="h4 font-bold text-red-700 text-center" component="div">
                                            {showErrors}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Box>)}

                            <Controller
                                name="barcode"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        error={!!errors.barcode}
                                        required
                                        helperText={errors?.barcode?.message}
                                        label="Barcode / ID NO:"
                                        id="barcode"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        error={!!errors.email}
                                        required
                                        helperText={errors?.email?.message}
                                        label="Email"
                                        id="email"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className="w-full mx-auto mt-16 rounded-md h-48"
                                aria-label="LOG IN"
                                value="legacy"
                                onClick={handleSave}
                            >
                                Register
                            </Button>
                        </form>
                    </motion.div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default RegisterModal;
