const mutation = {
  create: `
        mutation createUser($data: JSONString!){
            createUser(data: $data) {
            res,
            message,
            status
            }
        }
    `,
};

const query = {
  checkEcommerceUsername: `
      query checkEcommerceUsername($keyword: String) {
          checkEcommerceUsername(keyword: $keyword) {
              id
              email
              username
          }
      }
  `,
  checkEcommerceEmail: `
    query checkEcommerceEmail($email: String, $userid: String, $ecommerce: Int, $crm: Int) {
        checkEcommerceEmail(email: $email, userid: $userid, ecommerce: $ecommerce, crm: $crm) {
              id
              email
              username
          }
      }
  `,
}

export { mutation, query };