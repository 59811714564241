import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { borderBottom } from '@mui/system';

const StyledAppBar = styled(AppBar)(({ theme }) => ({

    backgroundColor: "#FFFFFF !important",
    borderBottom: "3px solid #00008A",
    color: "#00008A"

}));


function BookingHeader(props) {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleGoToBooking = () => {
        navigate("/booking");
    };

    const handleGoToStatus = () => {
        navigate("/booking/status/check");
    };

    const handleGoToRegistration = () => {
        navigate("/booking/register");
    };

    return (
        <StyledAppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters className=" justify-between">
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <img src="assets/images/logos/cmi-logo-blue.png" />

                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                            <MenuItem onClick={handleGoToBooking}>
                                <Typography textAlign="center">Booking</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleGoToRegistration}>
                                <Typography textAlign="center">Booking Registration</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleGoToStatus}>
                                <Typography textAlign="center">Check Status</Typography>
                            </MenuItem>

                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <img width="50%" src="assets/images/logos/cmi-logo-blue.png" />
                    </Typography>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>

                        <Button
                            onClick={handleGoToBooking} className="mx-10 rounded-md text-15"
                            sx={{ my: 2, color: '#0252AE', display: 'block' }}
                        >
                            Booking
                        </Button>
                        <Button
                            onClick={handleGoToRegistration} className="mx-10 rounded-md text-15"
                            sx={{ my: 2, color: '#0252AE', display: 'block' }}
                        >
                            Booking Registration
                        </Button>
                        <Button
                            onClick={handleGoToStatus} className="mx-10 rounded-md text-15"
                            sx={{ my: 2, color: '#0252AE', display: 'block' }}
                        >
                            Booking Status
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </StyledAppBar>
    );

}

export default BookingHeader;