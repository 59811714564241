// * Updated By: Jherome
// * Summary: change email, website, and title content
// * Task: https://app.activecollab.com/187150/projects/5?modal=Task-43517-96
// * DateUpdated:  12/19/2022

// * Updated By: Jherome
// * Summary: remove the sentence below total, adjust padding, fix total alignment
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-43692-96
// * DateUpdated:  12/22/2022



import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import format from 'date-fns/format';

function PrintInformationInvoice({ printRefInvoice, form }) {

    return (
        <div className='hidden'>
            <DialogContent classes={{ root: 'py-10 px-50px pb-0 ' }} ref={printRefInvoice}>
                <div className='flex flex-row justify-center mx-20'>
                    <div className='flex flex-col mx-16'>
                        <div className='flex justify-center'>
                            <div className="logo">
                                <img width="200" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <Typography className=' text-14 font-semibold mt-2'>
                                College of Marshall Islands PO Box 1258 Majuro,
                            </Typography>
                        </div>
                        <div className='flex justify-center'>
                            <Typography className=' text-14 font-semibold mt-2'>
                                Republic of the Marshall Islands 96960
                            </Typography>
                        </div>
                        <div className='flex justify-center'>
                            <Typography className=' text-14 font-semibold mt-2'>
                                TELEPHONE #: +692 625 1213
                            </Typography>
                        </div>
                        <div className='flex justify-center'>
                            <Typography className=' text-14 font-semibold mt-2'>
                                EMAIL ADDRESS: acs@cmi.edu
                            </Typography>
                        </div>
                        <div className='flex justify-center'>
                            <Typography className=' text-14 font-semibold mt-2'>
                                WEBSITE: oss.cmi.edu
                            </Typography>
                        </div>
                        <div className='flex justify-center'>
                            <Typography className=' text-14 font-semibold mt-32'>
                               Commercial Services
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-bold mt-10'>
                            INVOICE #: {form.invoice?.invoiceNum}
                        </Typography>
                        <Typography className=' text-14 font-semibold'>
                            TIME OF BOOKING: {form.dateCreated ? format(new Date(form.dateCreated), 'MMMM dd, yyyy hh:mm a') : null}
                        </Typography>
                    </div>
                </div>

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-medium mt-10'>
                            BILL TO: {form.name}
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            EMAIL: {form.email}
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            ADDRESS: {form.address}
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            CONTACT: {form.contact}
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            DEPARTMENT: {form.department}
                        </Typography>
                    </div>
                </div>

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-bold mt-10'>
                            START: {form.startFormatted ? format(new Date(form.startFormatted), 'EEEE, MMMM dd, yyyy') : ""} {form.startFormatted ? format(new Date(form.startFormatted), 'hh:mm a') : ""} - {form.endFormatted ? format(new Date(form.endFormatted), 'hh:mm a') : ""}
                        </Typography>
                        <Typography className=' text-14 font-bold mt-3'>
                        DURATION: {form.durationFormat}
                        </Typography>
                    </div>
                </div>
                
               

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-medium mt-10'>
                            BOOKING DETAILS:
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            {form.itemCode} {form.itemType} {form.itemName}
                        </Typography>
                    </div>
                </div>

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-medium mt-10'>
                            PURPOSE:
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            {form.purpose}
                        </Typography>
                    </div>
                </div>

               

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-medium mt-10'>
                            UNIT PRICE:
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            SUBTOTAL:
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            DISCOUNT:
                        </Typography>
                    </div>
                    <div className='flex flex-col mx-16 text-right'>
                        <Typography className=' text-14 font-medium  mt-10'>
                            ${parseFloat(form.itemRate).toFixed(2)}
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            ${parseFloat(form.invoice?.subTotal).toFixed(2)}
                        </Typography>
                        <Typography className=' text-14 font-medium'>
                            ${parseFloat(form.invoice?.discount).toFixed(2)}
                        </Typography>
                    </div>
                </div>

                <div className='flex flex-row justify-between mx-20 mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        <Typography className=' text-14 font-bold mt-10'>
                            TOTAL:
                        </Typography>
                    </div>
                    <div className='flex flex-col mx-16 text-right'>
                        <Typography className=' text-14 font-bold mt-10'>
                            ${parseFloat(form.invoice?.grandTotal).toFixed(2)}
                        </Typography>
                    </div>
                </div>

                {/* <div className='flex flex-row justify-between mt-16 mb-16'>
                    <div className='flex flex-col mx-16 '>
                        Please make all checks/remittance payable to: "College of the Marshall Islands" and mail payment to the address above attention "FABS Office"
                        <br />
                        <br />
                        Thank you very much
                    </div>
                </div> */}

            </DialogContent>
        </div>
    );
}

export default PrintInformationInvoice;