import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import EcommerceHeader from 'app/main/ecommerce/header/EcommerceHeader';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import OrdersContent from 'app/main/ecommerce/orders/content/OrdersContent';

const Root = styled(FusePageSimple)(({ theme }) => ({
    "& .FusePageSimple-topBg": {
        background: "unset"
    },
    "& .FusePageSimple-contentWrapper": {
        overscrollBehavior:"auto"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

function Orders(props) {

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <EcommerceHeader />
                <Root
                    content={
                         <div className="min-w-xl max-w-1366px w-fit m-auto">
                            <OrdersContent />
                        </div>
                    }

                />
            <EcommerceFooter />
        </div>
    );
}

export default Orders;

