import { lazy } from "react";
import { authRoles } from 'app/auth';

const Credit = lazy(() => import("./credit/Credit"));
const Bookstore = lazy(() => import("./bookstore/Bookstore"));
const Meal = lazy(() => import("./meal/Meal"));
const Return = lazy(() => import("./return/Return"));
const ReturnList = lazy(() => import("./return-list/ReturnList"));
const OrderHistory = lazy(() => import("./order-history/OrderHistory"));
const Others = lazy(() => import("./others/Others"));
const Quotation = lazy(() => import("./quotation/Quotation"));
const PauseSale = lazy(() => import("./pause-sale/PauseSale"));
const NewJobOrder = lazy(() => import("./services/NewJobOrder"));
const RewardItems = lazy(() => import("./rewards/RewardItems"));
const RewardsClaimed = lazy(() => import("./rewards/RewardsClaimed"));
const JobOrderFullCalendar = lazy(() =>
    import("./services/JobOrderFullCalendar")
);
const JobOrderListView = lazy(() => import("./services/JobOrderListView"));

const EquipmentRental = lazy(() => import("./equipment/EquipmentRental"));
const EquipmentRentalCalendar = lazy(() => import("./equipment/EquipmentRentalCalendar"));
const EquipmentRentalListView = lazy(() => import("./equipment/EquipmentRentalListView"));
const EquipmentRentalPerformanceReport = lazy(() => import("./equipment/reports/performance/EquipmentRentalPerformanceReport"));
const EquipmentRentalCounterReport = lazy(() => import("./equipment/reports/counter/EquipmentRentalCounterReport"));
const StoreSettings = lazy(() => import("./settings/StoreSettings"));
const WaterDeliveryReport = lazy(() => import("./services/reports/Reports"));

// Bookstore Report
const WalkIn = lazy(() => import("./reports/walk-in/WalkIn"));
const BookstoreCharges = lazy(() => import("./reports/bookstore-charges/BookstoreCharges"));
const MealPlans = lazy(() => import("./reports/meal-plans/MealPlans"));
const MealPlansSummarized = lazy(() => import("./reports/meal-plans-summarized/MealPlansSummarized"));
const DepartmentCharges = lazy(() => import("./reports/department-charges/DepartmentCharges"));

const StoreAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: "apps/store/credit/:patronId/*",
            element: <Credit />,
            auth: ["str-crd"],
        },
        {
            path: "apps/store/meal/new",
            element: <Meal />,
        },
        {
            path: "apps/store/books/*",
            element: <Bookstore />,
            auth: ["str-bs"],
        },
        {
            path: "apps/store/return",
            element: <Return />,
            auth: ["str-ris"],
        },
        {
            path: "apps/store/order-history",
            element: <OrderHistory />,
            auth: ["str-ohs"],
        },
        {
            path: "apps/store/others",
            element: <Others />,
            auth: ["str-others"],
        },
        {
            path: "apps/store/quotations",
            element: <Quotation />,
            auth: ["str-quo"],
        },
        {
            path: "apps/store/pause-sale",
            element: <PauseSale />,
            auth: ["str-quo"],
        },
        {
            path: "apps/store/services/job-orders/:deliveryId/:deliveryDate/*",
            element: <NewJobOrder />,
            auth: ["str-wds", "str-wdss"],
        },
        {
            path: "apps/store/services/job-orders/calendar-view",
            element: <JobOrderFullCalendar />,
            auth: ["str-wds", "str-wdss"],
        },
        {
            path: "apps/store/services/job-orders/list-view",
            element: <JobOrderListView />,
            auth: ["str-wds", "str-wdss"],
        },
        {
            path: "apps/store/equipment-rental/:deliveryId/:deliveryDate/*",
            element: <EquipmentRental />,
            auth: ["str-er", "str-ers"],
        },
        {
            path: "apps/store/equipment-rental/calendar-view",
            element: <EquipmentRentalCalendar />,
            auth: ["str-er", "str-ers"],
        },
        {
            path: "apps/store/equipment-rental/list-view",
            element: <EquipmentRentalListView />,
            auth: ["str-er", "str-ers"],
        },
        {
            path: "apps/store/equipment-rental/report/performance",
            element: <EquipmentRentalPerformanceReport />,
            auth: ["str-er", "str-ers"],
        },
        {
            path: "apps/store/equipment-rental/report/counter",
            element: <EquipmentRentalCounterReport />,
            auth: ["str-er", "str-ers"],
        },
        {
            path: "apps/store/store-settings",
            element: <StoreSettings />,
            auth: ["str-set"],
        },
        {
            path: "apps/store/reward-items",
            element: <RewardItems />,
            auth: ["str-rris"],
        },
        {
            path: "apps/store/reports/walk-in",
            element: <WalkIn />,
            auth: ["str-bs"],
        },
        {
            path: "apps/store/reports/bookstore-charges",
            element: <BookstoreCharges />,
            auth: ["str-bs"],
        },
        {
            path: "apps/store/reports/meal-plans",
            element: <MealPlans />,
            auth: ["str-bs"],
        },
        {
            path: "apps/store/reports/meal-plans-summarized",
            element: <MealPlansSummarized />,
            auth: ["str-bs"],
        },
        {
            path: "apps/store/reports/department-charges",
            element: <DepartmentCharges />,
            auth: ["str-bs"],
        },
        {
            path: "apps/store/rewards-claimed",
            element: <RewardsClaimed />,
            auth: ["str-rris"],
        },
        {
            path: "apps/store/services/job-order-reports",
            element: <WaterDeliveryReport />,
            auth: ["str-wds", "str-wdss"],
        },
        {
            path: "apps/store/return-list",
            element: <ReturnList />,
            auth: ["str-ris"],
        },
    ],
};

export default StoreAppConfig;
