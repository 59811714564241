import { lazy } from "react";
import { authRoles } from 'app/auth';

const DriverManagement = lazy(() => import("./booking/driver-management/DriverManagement"));
const MotorpoolInventory = lazy(() => import("./motorpool-inventory/MotorpoolInventory"));
const MotorpoolVehicles = lazy(() => import("./motorpool-vehicles/MotorpoolVehicles"));
const MotorpoolBooking = lazy(() => import("./booking/MotorpoolBooking"));
const MotorpoolBookingListView = lazy(() => import("./booking/list-view/MotorpoolBookingListView"));
const MotorpoolBookingCalendarView = lazy(() => import("./booking/calendar/MotorpoolBookingCalendarView"));
const MotorpoolMaintenanceRecord = lazy(() => import("./maintenance-record/MotorpoolMaintenanceRecord"));
const MotorpoolMaintenanceMonitoring = lazy(() => import("./maintenance-monitoring/MotorpoolMaintenanceMonitoring"));
const MotorpoolFuelManagementRecord = lazy(() => import("./fuel-management-record/MotorpoolFuelManagementRecord"));
const MotorpoolVehicleReport = lazy(() => import("./vehicle-report/MotorpoolVehicleReport"));
const MotorpoolLocationTrace = lazy(() => import("./booking/location-trace/MotorpoolLocationTrace"));

const MotorPoolAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: "apps/motorpool/booking/driver-management",
            element: <DriverManagement />,
            auth: ["mot-drv-mgmt"],
        },
        {
            path: "apps/motorpool/motorpool-inventory",
            element: <MotorpoolInventory />,
            auth: ["mot-mot-inv"],
        },
        {
            path: "apps/motorpool/motorpool-vehicles",
            element: <MotorpoolVehicles />,
            auth: ["mot-mot-vcs"],
        },
        {
            path: "apps/motorpool/booking/:bookingId/*",
            element: <MotorpoolBooking />,
            auth: ["mot-new-booking"],
        },
        {
            path: "apps/motorpool/booking/list-view",
            element: <MotorpoolBookingListView />,
            auth: ["mot-booking-list"],
        },
        {
            path: "apps/motorpool/booking/calendar-view",
            element: <MotorpoolBookingCalendarView />,
            auth: ["mot-booking-list"],
        },
        {
            path: "apps/motorpool/maintenance-record",
            element: <MotorpoolMaintenanceRecord />,
            auth: ["mot-mtnc-rec"],
        },
        {
            path: "apps/motorpool/maintenance-monitoring",
            element: <MotorpoolMaintenanceMonitoring />,
            auth: ["mot-mtnc-mtr"],
        },
        {
            path: "apps/motorpool/fuel-management-record",
            element: <MotorpoolFuelManagementRecord />,
            auth: ["mot-fuel-rec"],
        },
        {
            path: "apps/motorpool/vehicle-report",
            element: <MotorpoolVehicleReport />,
            auth: ["mot-accs-rep"],
        },
        {
            path: "apps/motorpool/booking/location-trace",
            element: <MotorpoolLocationTrace />,
            auth: ["mot-accs-loc-trc"],
        },
    ],
};

export default MotorPoolAppConfig;
