import { combineReducers } from '@reduxjs/toolkit';

import bookings from './bookingSlice';
import bookingItems from './bookingItemsSlice';
import bookingItemsModal from './bookingItemsModalSlice';
import bookingItemTypes from './bookingItemtypesSlice';
import bookingDepartments from './bookingDepartmentsSlice';
import blacklist from './blacklistSlice';
import bookingEvents from './bookingEventsSlice';
import bookingInspectionModal from './bookingInspectionModalSlice';

const reducer = combineReducers({
    bookingItems,
    bookingItemTypes,
    bookingDepartments,
    bookingItemsModal,
    bookings,
    blacklist,
    bookingEvents,
    bookingInspectionModal
});

export default reducer;
