import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/order_graphql";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

const token = EcommerceJwtService.getAccessToken();

export const getOrderList = createAsyncThunk(
    "ecommerceApp/orders/getOrderList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.orderList,
            variables: params,
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.ecommerceOrdersListF) {
            const data = response.data.data.ecommerceOrdersListF;

            return data;
        } else {
            return null
        }
    }
);

export const deleteCartItem = createAsyncThunk(
    "ecommerceApp/orders/deleteCartItem",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteCartItem,
            variables: {
                id: id
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.deleteCartItem) {
            const data = response.data.data.deleteCartItem;

            return data;
        } else {
            return null
        }
    }
);

const ordersSlice = createSlice({
    name: "ecommerceApp/orders",
    initialState: null,
    reducers: {
        resetOrders: () => null,
        newOrders: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newOrders, resetOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
