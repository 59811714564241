import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import EcommerceHeaderStatic from 'app/main/ecommerce/header/EcommerceHeaderStatic';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import ActivateAccountContent from 'app/main/ecommerce/activate_account/content/ActivateAccountContent';


const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));

function ActivateAccount(props) {

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <EcommerceHeaderStatic title={"Activate Account"} />
            <Root
                content={
                    <div className="max-w-1366px m-auto">
                        <ActivateAccountContent />
                    </div>
                }
            />
            <EcommerceFooter />
        </div>
    );
}

export default ActivateAccount;

