import axios from "axios";
import { getSignedUrlPath } from "./store/serviceSlice";
import { useDispatch, useSelector } from "react-redux";

const presigned = async (data, loading) => {
    var formData = new FormData();
    formData.append("key", data.url.replace(data.server + "/", ""));
    // formData.append("key", data.url);
    formData.append("filename", "medisource");
    formData.append("forcedownload", data.download);
    return await new Promise(function (resolve, reject) {
        axios
            .post(data.api, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    if (typeof loading == "function") {
                        loading(percentCompleted);
                    }
                }
            })
            .then(function (result) {
                // const {records} = result.data;
                // resolve(records.temporaryUrl);
                resolve(result);
            })
            .catch(function (e) {
                reject(e);
            })
            .finally(function () {
                // So somthing here
            });
    });
};

const randomString = (length = 8) => {

    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

const arrayToStr = (array, separator = ", ") => {

    var str = "";

    var isArray = Array.isArray(array)

    if (!isArray) {
        return str;
    }

    var filter = [false, null, undefined, NaN, ''];

    var filteredArray = array.filter(function (v) {
        return (filter.indexOf(v) === -1);
    });

    return filteredArray.join(separator);
}

const getSignedUrl = async (path) => {
    const dispatch = useDispatch();
    try {
        const action = await dispatch(getSignedUrlPath(path));
        return action.payload;
    } catch (error) {
        console.error("Error while fetching signed URL:", error);
        throw error; // rethrow the error to be caught by the caller
    }
};

const isNotEmptyObject = (value) => {
    return value && typeof value === 'object' && Object.keys(value).length > 0;
};

const formatDate = (dateString) => {
    // Replace space with 'T' to make it ISO-8601 compliant
    const date = new Date(dateString.replace(' ', 'T'));
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });
};

function getTimeOnly(timestamp) {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Extract hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Determine AM/PM and adjust hours for 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format, and adjust 0 to 12

    // Format time as H:MM AM/PM
    return `${hours}:${minutes} ${ampm}`;
}

export {
    presigned,
    randomString,
    arrayToStr,
    getSignedUrl,
    isNotEmptyObject,
    formatDate,
    getTimeOnly
};

