import { combineReducers } from '@reduxjs/toolkit';
import tickets from './ticketsSlice';
import crmUsers from './crmUsersSlice';
import bookingEvents from './bookingEventsSlice';

const reducer = combineReducers({
  crmUsers,
  tickets,
  bookingEvents,
});

export default reducer;
