import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/home_page_graphql";
import format from 'date-fns/format';

export const getCategory = createAsyncThunk(
    "ecommerceApp/homePage/getCategory",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.homePageCategory
        });


        if (response.data.data.homePageCategory) {
            const data = response.data.data.homePageCategory;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getCategoryTitle = createAsyncThunk(
    "ecommerceApp/homePage/getCategoryTitle",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.homePageCategoryTitle
        });


        if (response.data.data.homePageCategory) {
            const data = response.data.data.homePageCategory;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getItems = createAsyncThunk(
    "ecommerceApp/homePage/getItems",
    async (catId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.homePageItems,
            variables: {
                catId: catId,
                dateNow: format(new Date(), 'yyyy-MM-dd')
            }
        });


        if (response.data.data.homePageItemsF) {
            const data = response.data.data.homePageItemsF;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getItemsPromos = createAsyncThunk(
    "ecommerceApp/homePage/getItemsPromos",
    async (catId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.itemPromos,
            variables: {
                dateNow: format(new Date(), 'yyyy-MM-dd')
            }
        });


        if (response.data.data.ecommerceSalesPromosItems) {
            var data = response.data.data.ecommerceSalesPromosItems;

            var sort = data[0]?.sort

            if (sort && sort != "random") {

                data = data.sort(function (a, b) {
                    if (sort == 'views') {
                        return b.views - a.views
                    } else {
                        return b.sales - a.sales
                    }
                });

            }

            var tempItems = [];

            if (data) {
                data.map((item) => {
                    if (item.itemDiscount.isActive) {
                        tempItems.push(item);
                    }
                })
            }

            return data === undefined ? null : tempItems;
        } else {
            return null
        }
    }
);

export const getGroups = createAsyncThunk(
    "ecommerceApp/homePage/getGroups",
    async (catId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.homePageGroups,
            variables: {
                catId: catId
            }
        });


        if (response.data.data.homePageGroups) {
            const data = response.data.data.homePageGroups;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const saveCategory = createAsyncThunk(
    "ecommerceApp/homePage/saveCategory",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveHomePageCategory,
            variables: {
                data: JSON.stringify(data)
            }
        });


        if (response.data.data.saveHomePageCategory) {
            const data = response.data.data.saveHomePageCategory;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const deleteHomePageItem = createAsyncThunk(
    "ecommerceApp/homePage/deleteHomePageItem",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteHomePageItem,
            variables: {
                id: id
            }
        });


        if (response.data.data.deleteHomePageItem) {
            const data = response.data.data.deleteHomePageItem;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getHomePageInventoryItems = createAsyncThunk(
    "ecommerceApp/homePage/getHomePageInventoryItems",
    async () => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.homePageInventoryItems
        });


        if (response.data.data.homePageInventoryItems) {
            const data = response.data.data.homePageInventoryItems;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const deleteHomePageCategory = createAsyncThunk(
    "ecommerceApp/homePage/deleteHomePageCategory",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteHomePageCategory,
            variables: {
                id: id
            }
        });


        if (response.data.data.deleteHomePageCategory) {
            const data = response.data.data.deleteHomePageCategory;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const saveGroups = createAsyncThunk(
    "ecommerceApp/homePage/saveGroups",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveHomePageGroups,
            variables: {
                data: JSON.stringify(data)
            }
        });


        if (response.data.data.saveHomePageGroups) {
            const data = response.data.data.saveHomePageGroups;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const saveItem = createAsyncThunk(
    "ecommerceApp/homePage/saveItem",
    async (data) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveHomePageItem,
            variables: {
                data: JSON.stringify(data)
            }
        });


        if (response.data.data.saveHomePageItem) {
            const data = response.data.data.saveHomePageItem;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const itemBasedOnBehaviors = createAsyncThunk(
    "ecommerceApp/homePage/itemBasedOnBehaviors",
    async (userId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.itemBasedOnBehaviors,
            variables: {
                dateNow: format(new Date(), 'yyyy-MM-dd')
            },
            publicUrl: 'ecommerce',
        });


        if (response.data.data.itemBasedOnBehaviors) {
            const data = response.data.data.itemBasedOnBehaviors;

            return data === undefined ? [] : data;
        } else {
            return []
        }
    }
);

const homeSlice = createSlice({
    name: "ecommerceApp/homePage",
    initialState: null,
    reducers: {
        resetHomePage: () => null,
        newHomePage: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newHomePage, resetHomePage } = homeSlice.actions;

export default homeSlice.reducer;
