const mutation = {
    create: `
        mutation createTenant($data: JSONString!) {
            createTenant (data: $data) {
                status,
                res {
                    referenceNum
                },
                message
            }
        }
    `,
    update: `
        mutation updateTenant($data: JSONString!) {
            updateTenant (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    delete: `
        mutation deleteTenant($id: String!) {
            deleteTenant (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    saveInspection: `
        mutation saveInspection($data: JSONString!) {
            saveInspection (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    resendTenant: `
        mutation resendTenant($data: JSONString!) {
            resendTenant (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    cancelTenant: `
        mutation cancelTenant($data: JSONString!) {
            cancelTenant (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    list: `
        query tenantsList($houseId: String, $year: String, $status: String, $semester: String) {
            tenantsList(houseId: $houseId, year: $year, status: $status, semester: $semester) {
                id
                tenantName
                tenantEmail
                tenantContact
                roomNumber
                roomAmenities
                totalPrice
                semester
                year
                start
                end
                preInspectionId
                dateCreated
                dateUpdated
            }
        }
    `,
    getTenantByID: `
        query tenantById($tenantId: String) {
            tenantById(tenantId: $tenantId) {
                id
                start
                end
                timeOfBooking
                referenceNum
                status
                userName
                userPosition
                semester
                year
                mealCredit
                bookCredit
                totalPrice
                preInspectionId
                houseId
                roomId
                roomNumber
                patronId
                roomPrice
                bedNumber
                house {
                    houseName
                }
                patron {
                    name
                    emergencyContact
                    departmentName
                    email
                    fullAddress
                }
                history {
                    id
                    details
                    username
                    dateFormatted
                } 
                approvedById
                approvedBy
                approvees
                approvee {
                    id
                    email
                    name
                    position
                    departments
                }
            }
        }
		
	`,
    getRoomsWithTenants: `
        query roomList($houseId: String, $amenities: String, $status: String) {
            roomList(houseId: $houseId, amenities: $amenities, status: $status) {
                id
                houseId
                roomNumber
                roomFloor
                roomStatus
                roomCapacity
                roomRate
                roomAmenities
                roomNotes
                roomColor
                roomPicId
                roomPicPath  
                tenants {
                    id
                    start
                    end
                    patron {
                        name
                        profilePicUrl
                    }
                }
                dateCreated
                dateUpdated
            }
        }
    `
}

export { mutation, query };