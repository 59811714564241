import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../../../../graphql/booking/blacklist_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import format from 'date-fns/format';


export const getBlacklist = createAsyncThunk('bookingApp/blacklist/getBlacklist', async () => {
  const response = await axios.post(CONFIG.API + "/api/", {
    query: query.list
  });
  const data = response.data.data.blacklistList;
  return data;
});

export const getCostumer = createAsyncThunk(
  "bookingApp/blacklist/getCostumer",
  async (params) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: query.getCostumer,
      variables: params
    });

    if (response.data.data.getCostumer) {
      const data = response.data.data.getCostumer;

      return data === undefined ? null : data;
    } else {
      return null;
    }
  }
);

export const checkBlacklist = createAsyncThunk(
  "bookingApp/blacklist/checkBlacklist",
  async (id) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.check,
      variables: {
        id: id
      }
    });

    if (response.data.data.checkBlacklist) {
      const data = response.data.data.checkBlacklist;

      return data === undefined ? null : data;
    } else {
      return null;
    }
  }
);

export const removeBlacklist = createAsyncThunk(
  'bookingApp/blacklist/removeBlacklist',
  async (blacklistIds, { dispatch, getState }) => {

    await blacklistIds.map(async (id) => {

      await axios.post(CONFIG.API + "/api/", {
        query: mutation.delete,
        variables: {
          id: id
        }
      });

    })

    return blacklistIds;
  }
);

export const saveBlacklist = createAsyncThunk(
  "bookingApp/blacklist/saveBlacklist",
  async (blacklistData, { dispatch, getState }) => {

    blacklistData.banExpiry = (blacklistData.banExpiry ? format(new Date(blacklistData.banExpiry), 'yyyy-MM-dd') : null);

    if (blacklistData.id) {
      const response = await axios.post(CONFIG.API + "/api/", {
        query: mutation.edit,
        variables: {
          data: JSON.stringify(blacklistData),
        },
      });

      if (response.data.data.editBlacklist) {
        dispatch(
          showMessage({
            message: "Blocklist has been successfully updated.",
            autoHideDuration: 5000, //ms
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      } else {
        dispatch(
          showMessage({
            message: "Something went wrong. Please try again later.",
            autoHideDuration: 5000, //ms
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            variant: "error",
          })
        );
      }

    } else {
      const response = await axios.post(CONFIG.API + "/api/", {
        query: mutation.create,
        variables: { data: JSON.stringify(blacklistData) },
      });

      if (response.data.data.createBlacklist) {
        dispatch(
          showMessage({
            message: "Blocklist has been successfully saved.",
            autoHideDuration: 5000, //ms
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            variant: "success",
          })
        );
      } else {
        dispatch(
          showMessage({
            message: "Something went wrong. Please try again later.",
            autoHideDuration: 5000, //ms
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            variant: "error",
          })
        );
      }
    }
  }
);

const blacklistAdapter = createEntityAdapter({});

export const { selectAll: selectBlacklist, selectById: selectBlacklistById } =
  blacklistAdapter.getSelectors((state) => state.bookingApp.blacklist);

const blacklistSlice = createSlice({
  name: 'bookingApp/blacklist',
  initialState: blacklistAdapter.getInitialState({
    searchText: ''
  }),
  reducers: {
    setBlacklistSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
    },
  },
  extraReducers: {
    [getBlacklist.fulfilled]: blacklistAdapter.setAll,
    [saveBlacklist.fulfilled]: (state, action) => action.payload,
    [removeBlacklist.fulfilled]: (state, action) =>
      blacklistAdapter.removeMany(state, action.payload),
  },
});

export const { setBlacklistSearchText } = blacklistSlice.actions;

export default blacklistSlice.reducer;
