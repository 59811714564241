import * as React from 'react';
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/booking/store";
import BookingApproveHeader from 'app/main/booking/header/BookingApproveHeader';
import InvoiceContent from 'app/main/booking/invoice/content/InvoiceContent';
import { useParams, useLocation } from 'react-router-dom';

import { getBookingInvoice, getMotorpoolBookingInvoice } from "app/main/booking/store/invoiceSlice";

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-header": {
        minHeight: 72,
        height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            height: 136,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
}));

function Invoice(props) {

    const routeParams = useParams();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const motorpool = queryParams.get('motorpool');

    const [form, setForm] = React.useState({
        approvedBy: []
    });

    React.useEffect(() => {

        console.log("routeParams", routeParams);

        dispatch((motorpool ? getMotorpoolBookingInvoice : getBookingInvoice)({
            invoiceId: routeParams.invoiceId,
            bookingId: routeParams.bookingId
        })).then(function (response) {
            setForm(response);
        });

    }, [routeParams]);

    return (
        <Root
            header={<BookingApproveHeader form={form} status={'download'} />}
            content={
                <>
                    <InvoiceContent status={props.status} form={form} setForm={setForm} />
                </>
            }
            innerScroll
        />
    );
}

export default withReducer("bookingApp", reducer)(Invoice);
