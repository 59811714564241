import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import CrmHeaderStatic from 'app/main/crm/header/CrmHeaderStatic';
import CrmFooter from 'app/main/crm/footer/CrmFooter';
import CrmBookingFeedbackContent from 'app/main/crm/feedback/content/CrmBookingFeedbackContent';


const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));

function CrmBookingFeedback(props) {

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <CrmHeaderStatic title={"Customer FeedBack"} />
            <Root
                content={
                    <div className="max-w-1366px m-auto">
                        <CrmBookingFeedbackContent />
                    </div>
                }
            />
            <CrmFooter />
        </div>
    );
}

export default CrmBookingFeedback;

