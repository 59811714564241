const mutation = {
    approveBooking: `
        mutation approveBooking($data: JSONString!) {
            approveBooking (data: $data) {
                status,
                res,
                message
            }
        }
  `,
};

const query = {
    getBookingInvoice: `
        query getBookingInvoice($invoiceId: String!, $bookingId: String!) {
            getBookingInvoice (invoiceId: $invoiceId, bookingId: $bookingId){
                booking {
                    id
                    start
                    end
                    name
                    email
                    address
                    contact
                    department
                    itemName
                    itemCode
                    itemType
                    itemRate
                    duration
                    purpose
                    dateCreated
                }
                invoice {
                    id
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                }
            }
        }
    `,
    getMotorpoolBookingInvoice: `
        query getMotorpoolBookingInvoice($invoiceId: String!, $bookingId: String!) {
            getMotorpoolBookingInvoice (invoiceId: $invoiceId, bookingId: $bookingId){
                booking {
                    id
                    start
                    end
                    name
                    email
                    address
                    contact
                    department
                    itemName
                    itemCode
                    itemType
                    itemRate
                    duration
                    purpose
                    dateCreated
                }
                invoice {
                    id
                    invoiceNum
                    subTotal
                    grandTotal
                    discount
                }
            }
        }
    `
}

export { mutation, query };