import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Suppliers = lazy(() => import('./suppliers/Suppliers'));
const Item = lazy(() => import('./item/Item'));
const Items = lazy(() => import('./items/Items'));
const Report = lazy(() => import('./report/Report'));
const Receivings = lazy(() => import('./receivings/Receivings'));
const Receiving = lazy(() => import('./receiving/Receiving'));
const Stocktakes = lazy(() => import('./stocktaking/stocktakes/Stocktakes'));
const Stocktake = lazy(() => import('./stocktaking/stocktake/Stocktake'));
const Writeoff = lazy(() => import('./stocktaking/writeoff/Writeoff'));
const Writeoffs = lazy(() => import('./stocktaking/writeoffs/Writeoffs'));
const StocktakeReport = lazy(() => import('./stocktaking/stocktake_report/StocktakeReport'));
const Settlement = lazy(() => import('./stocktaking/settlement/Settlement'));

const InventoryAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/inventory/suppliers',
            element: <Suppliers />,
            auth: ["inv-sup"],
        },
        {
            path: 'apps/inventory/item/:itemId/*',
            element: <Item />,
            auth: ["inv-ari"],
        },
        {
            path: 'apps/inventory/items',
            element: <Items />,
            auth: ["inv-mpr"],
        },
        {
            path: 'apps/inventory/report',
            element: <Report />,
            auth: ["inv-mpr"],
        },
        {
            path: 'apps/inventory/postings',
            element: <Receivings />,
            auth: ["inv-mpr"],
        },
        {
            path: 'apps/inventory/posting/:receivingId/*',
            element: <Receiving />,
            auth: ["inv-ari"],
        },
        {
            path: 'apps/inventory/stocktaking/stocktakes',
            element: <Stocktakes />,
            auth: ["inv-stk"],
        },
        {
            path: 'apps/inventory/stocktaking/stocktake/:stocktakeId/*',
            element: <Stocktake />,
            auth: ["inv-stk"],
        },
        {
            path: 'apps/inventory/stocktaking/stocktake-report',
            element: <StocktakeReport />,
            auth: ["inv-stk"],
        },
        {
            path: 'apps/inventory/stocktaking/settlement',
            element: <Settlement />,
            auth: ["inv-stk"],
        },
        {
            path: 'apps/inventory/stocktaking/writeoff/:writeoffId/*',
            element: <Writeoff />,
            auth: ["inv-stk"],
        },
        {
            path: 'apps/inventory/stocktaking/writeoffs',
            element: <Writeoffs />,
            auth: ["inv-stk"],
        },
    ],
};

export default InventoryAppConfig;
