/*
* DateUpdated: 12/07/22
* updatedBy: ico
* Summary: alignment 1 column
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-41592-96
*/

/*
* DateUpdated: 01/12/23
* updatedBy: ico
* Summary: Redesign
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-44385-96
*/
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';
import CardContent from "@mui/material/CardContent";
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import { Divider } from "@mui/material";
const TableUnstyled = styled('table')`
  

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

`;
function PrintInformation({ printRef, form }) {

    return (
        <>
            <div className='hidden'>
                <div ref={printRef}>
                    <div className="header-print-posting">
                        <div className='float-left m-10 p-10'>
                            <Typography className="text-18 font-semibold">
                                CMI OSS SYSTEM
                            </Typography>
                            <Typography className=" font-semibold text-16">Booking Reference Number: <span className="font-bold">{form.referenceNum}</span></Typography>

                        </div>
                        <div className='float-right m-10 p-10'>
                            <div className="logo">
                                <img width="110" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                            </div>
                        </div>
                    </div>

                    <Card className="flex flex-col shadow-0" elevation={0}>
                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                            <div className="container -mt-32">
                                <Stack direction="column" >
                                    <Typography className="mt-5">Time of Booking: <span className="">{form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}</span></Typography>
                                    <Typography className="mt-5">Start: <span className=""> {form.startFormatted ? format(new Date(form.startFormatted), 'EEEE, MMMM dd, yyyy') : ""} {form.startFormatted ? format(new Date(form.startFormatted), 'hh:mm a') : ""} - {form.endFormatted ? format(new Date(form.endFormatted), 'hh:mm a') : ""}</span></Typography>
                                    <Stack direction="row" className=" justify-between items-center mt-5 mb-5">
                                        <Typography>Duration: <span className="">{form.durationFormat}</span></Typography>
                                        {/* {(form.total != '0' && form.status.includes("Approved")) && (
                                            <>
                                                <Button variant="text" saze="small" className=" rounded-md capitalize py-0 px-5">
                                                    View Invoice |  {form.invoiceStatus}
                                                </Button>
                                            </>
                                        )} */}
                                    </Stack>
                                    <TableUnstyled>
                                        <tbody>
                                            <tr >
                                                <td width="50%">Name: <span className="font-bold">{form.name}</span></td>
                                                <td width="50%">Email: <span className="font-bold">{form.email}</span></td>
                                            </tr>
                                            <tr >
                                                <td width="50%">Department: <span className="font-bold">{form.department}</span></td>
                                                <td width="50%">Contact: <span className="font-bold">{form.contact}</span></td>
                                            </tr>
                                        </tbody>
                                    </TableUnstyled>

                                    <Typography className=" font-semibold uppercase mt-10 mb-10">Booking Details</Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <ImageList sx={{ width: 80, height: 80 }} cols={1} className="overflow-hidden rounded-md">
                                            <ImageListItem>
                                                <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                            </ImageListItem>
                                        </ImageList>
                                        <Stack >
                                            <Typography className=''>
                                                {form.itemCode} | {form.itemName}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Divider className=" mt-16" />
                                    <Typography className=" font-semibold uppercase mt-10 mb-10">purpose</Typography>
                                    <Typography className=" leading-4">{form.purpose}</Typography>

                                    <Typography className=" font-semibold uppercase mt-20 ">approved by:</Typography>
                                    {form.approvee && (
                                        <>
                                            {
                                                form.approvee.map(function (approvee) {
                                                    return (
                                                        approvee.dateApproval ? <>
                                                            <Typography className=" capitalize mt-10">Position: <b>{approvee.position}</b></Typography>
                                                            <TableUnstyled className="mb-10" key={approvee.id}>
                                                                <tbody>
                                                                    <tr >
                                                                        <td width="50%">Name: <span className="font-bold">{approvee.name}</span></td>
                                                                        <td width="50%">Date of Approval: <span className="font-bold">{approvee.dateApproval}</span></td>
                                                                    </tr>
                                                                    <tr >
                                                                        <td width="50%">Department: <span className="font-bold">{approvee.department}</span></td>
                                                                        <td width="50%">Status: <span className="font-bold"> {approvee.status}</span></td>
                                                                    </tr>
                                                                </tbody>
                                                            </TableUnstyled>
                                                        </> : null
                                                    )
                                                })
                                            }
                                        </>
                                    )}
                                </Stack>
                                <Box className="mb-10 mt-10" sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                                    <Box className={'py-10 ' +
                                        (form.status == "Rejected" ? "bg-red-600 hover:bg-red-700" : "") +
                                        (form.status == "Cancelled" ? "bg-pink-600 hover:bg-pink-700" : "") +
                                        (form.status.includes("Approved") ? "bg-blue-600 hover:bg-blue-700" : "") +
                                        (form.status == "Approved - For Pre-Inspection" ? " bg-purple-600 hover:bg-purple-700" : "") +
                                        (form.status == "Expired" ? "bg-gray-600 hover:bg-gray-700" : "") +
                                        (form.status == "Pending" ? "bg-yellow-600 hover:bg-yellow-700" : "") +
                                        (form.status.includes("In-Use") ? "bg-orange-600 hover:bg-orange-700" : "") +
                                        (form.status == "Done" ? "bg-green-600 hover:bg-green-700" : "")
                                    } sx={{
                                        m: 1,
                                        width: '100%',
                                        height: '100%',
                                        margin: 'unset',
                                        borderRadius: 1,
                                        py: 2
                                    }}>
                                        <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                            <Typography variant="h4 font-bold text-white text-center" component="div">
                                                {form.status}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Box>
                            </div>
                        </DialogContent>
                    </Card>

                </div>
            </div>
        </>
    );
}

export default PrintInformation;