import _ from "@lodash";
import * as yup from "yup";
import * as React from 'react';
import { usePrompt } from "app/services/leaveRoute";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeepCompareEffect } from "@fuse/hooks";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";
import FuseLoading from "@fuse/core/FuseLoading";
import FusePageSimple from "@fuse/core/FusePageSimple";

import { getUser } from "app/main/apps/e-commerce/store/userManagementSlice";

import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import EcommerceHeader from 'app/main/ecommerce/header/EcommerceHeader';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import MyAccountContent from "./content/MyAccountContent";

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageSimple-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));

function MyAccount(props) {

    const dispatch = useDispatch();

    const token = EcommerceJwtService.getDecodedAccessToken();

    const editSchema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("Email is required"),
        lastName: yup.string().required("Last name is required"),
        firstName: yup.string().required("First name is required"),
        username: yup.string()
            .required("Username is required"),
        phone: yup
            .string()
            .required('Phone Number is required')
            .test("len", "Invalid Phone No.", (val) => {
                if (val) {
                    const val_length_without_dashes = val.replace(/-|_/g, "").length;
                    return (val_length_without_dashes === 3 || val_length_without_dashes === 10 || val_length_without_dashes === 13);
                } else {
                    return true;
                }
            }),
        mobilephone: yup
        .string()
        .required('Mobile Phone Number is required')
        .test("len", "Invalid Mobile Phone No.", (val) => {
            if (val) {
                const val_length_without_dashes = val.replace(/-|_/g, "").length;
                return (val_length_without_dashes === 3 || val_length_without_dashes === 10 || val_length_without_dashes === 13);
            } else {
                return true;
            }
        })
    });

    const [loading, setLoading] = useState(false);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(editSchema),
    });

    const { formState, reset } = methods;

    const { dirtyFields } = formState;

    usePrompt(Object.keys(dirtyFields).length);

    useDeepCompareEffect(() => {
        function updateUserState() {
            /**
             * Get User data
             */
            setLoading(true);
            dispatch(getUser({ usersId: token.id })).then((action) => {
                reset(action.payload);
                setLoading(false);
            });
        }

        updateUserState();
    }, [dispatch, token]);

    if (loading) {
        return <FuseLoading />;
    }

    return (
        <FormProvider {...methods}>
            <EcommerceHeader />
            <Root
                content={
                    <div className="max-w-1366px m-auto">
                            <MyAccountContent />
                    </div>
                }
            />
            <EcommerceFooter />
        </FormProvider>
    );
}

export default MyAccount;
