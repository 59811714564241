import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Houses = lazy(() => import('./houses/Houses'));
const House = lazy(() => import('./house/House'));
const Rooms = lazy(() => import('./rooms/Rooms'));
const Tenant = lazy(() => import('./tenants/tenant/Tenant'));
const TenantsListView = lazy(() => import('./tenants/list-view/TenantsListView'));
const TenantsListViewPending = lazy(() => import('./tenants/pending/TenantsListViewPending'));
const TenantsCardView = lazy(() => import('./tenants/card-view/TenantsCardView'));
const Announcement = lazy(() => import('./announcement/Announcement'));
const VisitRequest = lazy(() => import('./visit-request/VisitRequest'));
const CheckoutRequest = lazy(() => import('./checkout-request/CheckoutRequest'));
const PostInspection = lazy(() => import('./post-inspection/PostInspection'));
const CrmInbox = lazy(() => import('./crm-inbox/CrmInbox'));
const Reports = lazy(() => import('./reports/Reports'));

const ResidenceHallAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/residence-hall/houses',
            element: <Houses />,
            auth: ["rh-houses"],
        },
        {
            path: 'apps/residence-hall/house/:houseId/*',
            element: <House />,
            auth: ["rh-houses"],
        },
        {
            path: 'apps/residence-hall/house/rooms/:houseId/*',
            element: <Rooms />,
            auth: ["rh-rooms"],
        },
        {
            path: 'apps/residence-hall/tenant/:tenantId/*',
            element: <Tenant />,
            auth: ["rh-tenant"],
        },
        {
            path: 'apps/residence-hall/tenant-list-view',
            element: <TenantsListView />,
            auth: ["rh-tenant"],
        },
        {
            path: 'apps/residence-hall/pending-tenant-list-view',
            element: <TenantsListViewPending />,
            auth: ["rh-tenant"],
        },
        {
            path: 'apps/residence-hall/tenant-card-view',
            element: <TenantsCardView />,
            auth: ["rh-tenant"],
        },
        {
            path: 'apps/residence-hall/announcement',
            element: <Announcement />,
            auth: ["rh-announcement"],
        },
        {
            path: 'apps/residence-hall/visit-request',
            element: <VisitRequest />,
            auth: ["rh-request"],
        },
        {
            path: 'apps/residence-hall/checkout-request',
            element: <CheckoutRequest />,
            auth: ["rh-request"],
        },
        {
            path: 'apps/residence-hall/post-inspection',
            element: <PostInspection />,
            auth: ["rh-inspection"],
        },
        {
            path: 'apps/residence-hall/crm-inbox',
            element: <CrmInbox />,
            auth: ["rh-crm-inbox"],
        },
        {
            path: 'apps/residence-hall/reports',
            element: <Reports />,
            auth: ["rh-reports"],
        },
    ],
};

export default ResidenceHallAppConfig;
