import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/cart_graphql";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import { format } from "date-fns";

const token = EcommerceJwtService.getAccessToken();

export const getCartCount = createAsyncThunk(
    "ecommerceApp/cart/getCartCount",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.cartCount,
            variables: {
                customerId: id
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.ecommerceCartList) {
            const data = response.data.data.ecommerceCartList;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getCartList = createAsyncThunk(
    "ecommerceApp/cart/getCartList",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.cartList,
            variables: {
                customerId: id,
                dateNow: format(new Date(), 'yyyy-MM-dd')
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.ecommerceCartList) {
            const data = response.data.data.ecommerceCartList;

            return data;
        } else {
            return null
        }
    }
);

export const deleteCartItem = createAsyncThunk(
    "ecommerceApp/cart/deleteCartItem",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteCartItem,
            variables: {
                id: id
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.deleteCartItem) {
            const data = response.data.data.deleteCartItem;

            return data;
        } else {
            return null
        }
    }
);

export const saveCartList = createAsyncThunk(
    "ecommerceApp/cart/saveCartList",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveCartList,
            variables: { data: JSON.stringify(data) },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });

        return response.data.data.saveCartList;
    }
);

const cartSlice = createSlice({
    name: "ecommerceApp/cart",
    initialState: null,
    reducers: {
        resetCart: () => null,
        newCart: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newCart, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
