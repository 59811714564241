const mutation = {
    saveCrmUsers: `
      mutation saveCrmUsers($data: JSONString!) {
          saveCrmUsers (data: $data) {
            status,
            res,
            message
          }
      }
  `,
};

const query = {
    getCrmUsersById: `
        query getCrmUsersById($crmUsersId: String!) {
        getCrmUsersById(crmUsersId: $crmUsersId) {
            id
            patronId
            patronType
            departmentName
            departmentId
            status
            idNum
            name
            firstName
            lastName
            middleName
            gender
            birthdate
            emergencyContact
            email
            phone
            ssn
            address1
            lat
            lng
            address2
            country
            city
            state
            zipCode
            notes
            field1
            field2
            field3
            field4
            field5
            profilePicId
            profilePicPath
            fullAddress
            usersId
            dateCreated
            dateUpdated
            images {
                id,
                crmUsersId,
                uploadId,
                fileName,
                fileType,
                fileId,
                module,
                path,
                url,
                dateCreated,
                dateUpdated
            }
        }
    }
    `,
}

export { mutation, query };