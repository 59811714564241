import * as React from 'react';

import { experimentalStyled as styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { Controller, useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import FuseLoading from '@fuse/core/FuseLoading';
import clsx from 'clsx';
import FormHelperText from '@mui/material/FormHelperText';
import Icon from '@mui/material/Icon';

import { getItemsPaginate } from "app/main/apps/booking/store/bookingItemsSlice";
import { getTypes } from "app/main/apps/booking/store/bookingItemtypesSlice";


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const TabsStyled = styled(Tabs)(({ theme }) => ({
    minHeight:'36px',
    '& .MuiTabs-scrollButtons': {
        height:"36px !important",
        color:"black"
     },
    '& button.MuiButtonBase-root': { 
        minHeight:"36px",
        width:"20rem" ,
        fontWeight:"400",
        marginRight:"2rem",
        marginLeft:"2rem",
        color:theme.palette.primary.main,
        borderRadius:"6px",
        paddingTop: 3,
        paddingBottom: 3,
        border:`1px solid ${theme.palette.primary.main}`},

    '& .MuiTabs-indicator': { 
        display:"none"},
    '& button.MuiButtonBase-root.Mui-selected': { 
        background: `${theme.palette.primary.main}!important`,
        borderRadius:"6px",
        color: theme.palette.primary.contrastText,
    },

}));
const Item = styled('div')(({ theme }) => ({
    '& .productImageFeaturedStar': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: orange[400],
        opacity: 0,
    },

    '& .productImageUpload': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
    },

    '& .productImageItem': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:hover': {
            '& .productImageFeaturedStar': {
                opacity: 0.8,
            },
        },
        '&.featured': {
            pointerEvents: 'none',
            boxShadow: theme.shadows[3],
            '& .productImageFeaturedStar': {
                opacity: 1,
            },
            '&:hover .productImageFeaturedStar': {
                opacity: 1,
            },
        },
    },
}));


function Tab2(props) {

    const { activeTab } = props;

    if (activeTab != 1) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();

    const { formState, control, setValue } = methods;
    const { errors } = formState;

    const [loadingOptions, setLoadingOptions] = useState(false);

    const [valueItem, setValueitem] = useState(0);

    const handleChange = (event, newValue) => {
        setValueitem(newValue);
    };

    const [bookingTypeOptions, setBookingTypeOptions] = useState([]);

    const [bookingListOptions, setBookingListOptions] = useState([]);

    useEffect(() => {
        dispatch(getTypes()).then((action) => {
            if (action.payload) {
                setBookingTypeOptions(action.payload);

                getBookingItems(action.payload[0].name);

            }
        });

    }, [dispatch])

    function getBookingItems(type) {

        setLoadingOptions(true);

        dispatch(getItemsPaginate({
            keyword: "",
            type: type,
            limit: 99999,
            page: 1,
            order: "-date_created",
            exclude: 'true'
        })).then((action) => {
            if (action.payload) {
                setLoadingOptions(false);
                setBookingListOptions(action.payload.objects)
            }
        });
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired
    };

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            "aria-controls": `scrollable-force-tabpanel-${index}`
        };
    }

    function setItem(items){
        setValue('itemName', items.name);
        setValue('itemRate', items.cmiRate);
        setValue('itemCode', items.code);
        setValue('itemType', items.type);
        setValue('itemPhotoUrl', items.photoUrl);
        setValue('inspectionRequired', items.inspectionRequired);
    }

    return (
        <Item>
            {/* <FormHelperText error={!!errors.itemId}>{errors?.itemId?.message}</FormHelperText> */}
            
            <div className="flex sm:justify-start flex-wrap m-auto mt-52 w-11/12 ">
                <AppBar
                    position="static"
                    className=' mb-52'
                    style={{ boxShadow:"unset", background: "none", height:'36px'  }}
                >
                    <TabsStyled
                        value={valueItem}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={true}
                        indicatorColor="secondary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                        wrapped="true"
                    >

                        {bookingTypeOptions.map((value, key) => (
                            <Tab
                                onClick={() => { getBookingItems(value.name, key) }}
                                key={key}
                                label={value.name}
                                {...a11yProps(0)}
                                color="primary"
                                style={{ fontSize: 14, }}
                            />
                        ))}

                    </TabsStyled>
                </AppBar>

                {loadingOptions ? (
                    <FuseLoading />
                ) :
                    <>
                        <Controller
                            name="itemId"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) =>
                                bookingListOptions.map((items, key) => (
                                    <div className='flex flex-col mx-20 mb-24 max-w-160' key={key}>
                                        <div className='flex'>
                                            <div
                                                onClick={() => {onChange((items.id)); setItem(items)}}
                                                onKeyDown={() => {onChange((items.id)); setItem(items)}}
                                                role="button"
                                                tabIndex={0}
                                                className={clsx(
                                                    'productImageItem flex items-center justify-center relative rounded-md overflow-hidden cursor-pointer outline-none shadow hover:shadow-lg',
                                                    (items.id === value) && 'featured'
                                                )}
                                                key={(items.id)}
                                            >

                                                <Icon className="productImageFeaturedStar absolute left-0 mt-5 ml-5 z-99">star</Icon>
                                                 <ImageList sx={{ width: 130, height: 130 }} cols={1} className="overflow-hidden rounded-md">
                                                    <ImageListItem>
                                                        <img className="max-w-none w-auto h-full" src={items.photoUrl ? items.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={items.name} loading="lazy"/>
                                                     </ImageListItem>
                                                </ImageList>
                                              
                                            </div>
                                        </div>
                                       <Typography className='text-12 font-semibold max-w-128'>
                                            {items.code}
                                        </Typography>
                                        <Typography className='text-13 font-bold w-136 line-clamp-2'>
                                            {items.name}
                                        </Typography>
                                    </div>
                                ))
                            }
                        />
                        
                    </>
                }
            </div>
        </Item>
    );
}

export default Tab2;

