import {
	createSlice,
	createAsyncThunk,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
	query,
	mutation,
} from "app/main/apps/residence-hall/graphql/announement_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

export const getAnnouncement = createAsyncThunk(
	"residenceHallApp/announcement/getAnnouncement",
	async (params) => {
		const response = await axios.post(CONFIG.API + "/api/", {
			query: query.list,
            variables: params
		});
		var data = response.data.data.announcementList;

		return data;
	}
);

export const getAnnouncementById = createAsyncThunk(
    "residenceHallApp/announcement/getAnnouncementById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getAnnouncementByID,
            variables: {
                announcementId: params.announcementId,
            },
        });

        if (response.data.data.announcementById) {
            var data = response.data.data.announcementById;

            data.houses = (data.houses ? JSON.parse(data.houses) : []);

            return data === undefined ? null : data;
        }
    }
);

export const removeAnnouncement = createAsyncThunk(
	"residenceHallApp/announcement/removeAnnouncement",
	async (announcementIds, { dispatch, getState }) => {

		await announcementIds.map(async (id) => {
			await axios.post(CONFIG.API + "/api/", {
				query: mutation.delete,
				variables: {
					id: id,
				},
			});
		});

		dispatch(
			showMessage({
				message: "Announcement(s) has been successfully deleted.",
				autoHideDuration: 5000, //ms
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
				variant: "success",
			})
		);

		return announcementIds;
	}
);

const uploadImages = (uploadData, announcementData, image) => {

    return new Promise(async (resolve, reject) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutationUpload.create,
            variables: {
                data: JSON.stringify(uploadData),
            },
        });

        if (response.data.data.createUpload.status == "success") {
            var upload = response.data.data.createUpload.upload;

            if (announcementData.announcementPicId == image.image.id) {
                announcementData.announcementPicPath = upload.path;
            }

            announcementData.images[image.index].upload_id = upload.id;
            announcementData.images[image.index].file_name = upload.fileName;
            announcementData.images[image.index].file_id = upload.fileId;
            announcementData.images[image.index].file_type = upload.fileType;
            announcementData.images[image.index].path = upload.path;
            announcementData.images[image.index].module = upload.module;
            announcementData.images[image.index].url = "";

            resolve(announcementData);

        }

    });

}

const saveImages = (announcementData) => {

    return new Promise(async (resolve, reject) => {

        if (announcementData.images.length) {

            var newImages = [];

            announcementData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (announcementData.announcementPicId == image.fileId) {
                        announcementData.announcementPicPath = image.path;
                    }
                }

            });

            if (newImages.length) {

                var tempCount = 0;

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "residence-hall/announcement/announcement-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'residence-hall-announcement';
                            uploadData['file_id'] = image.image.id;

                            await uploadImages(uploadData, announcementData, image).then(async (announcementData) => {

                                if (announcementData) {
                                    tempCount++;
                                    if (newImages.length == tempCount) {
                                        resolve(announcementData);
                                    }
                                }

                            });
                        }


                    });

                });

            } else {
                resolve(announcementData)
            }


        } else {

            resolve(announcementData);

        }

    });

}

export const saveAnnouncement = createAsyncThunk(
    "residenceHallApp/announcement/saveAnnouncement",
    async (announcementData, { dispatch, getState }) => {

        announcementData.houses = (announcementData.houses ? JSON.stringify(announcementData.houses) : '');

        return await saveImages(announcementData).then(async (announcementData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.save,
                variables: {
                    data: JSON.stringify(announcementData),
                    id: announcementData?.id,
                },
            });

            dispatch(
                showMessage({
                    message: response.data.data.saveAnnouncement.message,
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.saveAnnouncement.status
                })
            );
            return response.data.data.saveAnnouncement;

        });

    });

const announcementAdapter = createEntityAdapter({});

export const {
	selectAll: selectAnnouncement,
	selectById: selectAnnouncementById,
} = announcementAdapter.getSelectors((state) => state.residenceHallApp.announcement);

const announcementSlice = createSlice({
	name: "residenceHallApp/announcement",
	initialState: announcementAdapter.getInitialState({
		searchText: "",
		showModal: false,
	}),
	reducers: {
		setAnnouncementSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[getAnnouncement.fulfilled]: announcementAdapter.setAll,
		[removeAnnouncement.fulfilled]: (state, action) =>
			announcementAdapter.removeMany(state, action.payload),
		[saveAnnouncement.fulfilled]: (state, action) => action.payload,
	},
});

export const { setAnnouncementSearchText } = announcementSlice.actions;

export default announcementSlice.reducer;
