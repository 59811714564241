// * Updated By: ICO
// * Summary: All Price Alignment by porudcts and move the % OFF top left of the Images
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-40108-96
// * DateUpdated:  11/14/2022

// * Updated By: ICO
// * Summary:  ublic Pages - Ecommerce Design Concerns
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44126-96
// * DateUpdated:  01/17/2023

import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getItemsPromos } from "app/main/ecommerce/store/homeSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import Typography from "@mui/material/Typography";
import * as React from 'react';
import Carousel from 'better-react-carousel';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FuseCountdownProduct from "@fuse/core/FuseCountdown/FuseCountdownProduct";


const StyledBtn = styled.div`
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 20px;
    color: black;
    opacity: 0.6;
    cursor: pointer;
    top: 50%;
    z-index: 10;
    transition: all 0.25s;
    transform: ${({ type }) =>
        `translateY(-50%) ${type === 'left' ? 'rotate(180deg)' : ''}`};
    left: ${({ type }) => (type === 'left' ? '-20px' : 'initial')};
    right: ${({ type }) => (type === 'right' ? '-20px' : 'initial')};

    &:hover {
        background: gray;
        color: #fff;
        opacity: 0.5;
    }
`;

const CustomDot = styled.span`
  display: inline-block;
  height: ${({ isActive }) => (isActive ? '8px' : '5px')};
  width: ${({ isActive }) => (isActive ? '8px' : '5px')};
  background: ${({ isActive }) => (isActive ? '#1890ff' : '#1890ff78')};
  transition: all 0.2s;
`

function ItemContent({ category }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);
        dispatch(getItemsPromos()).then((action) => {
            console.log("action.payload", action.payload);
            setItems(action.payload)
            setLoading(false);
        });
    }, [dispatch]);


    function handleClickItem(item) {
        navigate(`/e-commerce/product/${item.itemId}`);
    }


    if (loading) {

        return <div className="mt-25vh"><FuseLoading /></div>

    }

    const LeftBtn = <StyledBtn type="left">➜</StyledBtn>
    const RightBtn = <StyledBtn type="right">➜</StyledBtn>

    return (
        <>

            <section className="body-font">
                <div className="container mx-auto px-10">

                    {(items.length == 0) && (

                        <motion.div
                            className="w-full text-center m-auto"
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                        >
                            <Typography color="textSecondary" variant="h5">
                                There are no items!
                            </Typography>
                        </motion.div>

                    )}

                    {(items.length > 5) ? (
                        <Carousel
                            mobileBreakpoint={600}
                            responsiveLayout={[{
                                breakpoint: 1279,
                                cols: 2,
                                rows: 1,
                            }]}
                            cols={5}
                            rows={1}
                            gap={3}
                            loop={true}
                            autoplay={3000}
                            showDots
                            arrowLeft={LeftBtn}
                            arrowRight={RightBtn}
                            dot={CustomDot}
                        >

                            {
                                items.map((item) => {
                                    return (
                                        <Carousel.Item key={item.id}>
                                            {
                                                ProductTile(item, handleClickItem)
                                            }
                                        </Carousel.Item>
                                    );
                                })
                            }
                        </Carousel>
                    ) :
                        (
                            <div className="flex flex-wrap -m-4 -m-">
                                {
                                    items.map((item) => {
                                        return (
                                            <div className="xl:w-1/5 lg:w-1/4 md:w-1/2 sm:w-1/2 max-mobile:w-full" key={item.id}>{ProductTile(item, handleClickItem)} </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    }

                </div>
            </section>
        </>
    )

}

function ProductTile(item, handleClickItem) {
    return (
        <div className=" p-16 max-600px:p-8" key={item.id} onClick={() => { handleClickItem(item) }}>
            <div className="bg-white border-1 border-1-gray-50 border-opacity-5 p-0 cursor-pointer relative ">
                {/* <div style={{ width: "100%", height: "218px" }} cols={1} className="overflow-hidden my-10 m-auto mt-0 border-b-1 relative">
                    <img
                        src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                        alt={item.itemName}
                        className="h-full w-full object-cover object-center"
                    />
                    <div className="text-10 flex flex-row justify-between text-white font-medium bg-red px-8 py-5 max-w-fit absolute left-0 top-0 w-full">
                        <span className="w-fit truncate mr-3">{item.discount}% OFF </span> <FuseCountdownProduct itemDiscount={item.itemDiscount} dateOnly={true} />
                    </div>
                </div> */}
                 <div className='productImageItem flex flex-shrink-0 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden ' style={{ width: "218px", height: "218px" }} >
                    <img className="max-w-none w-full h-full object-contain"  src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                        alt={item.itemName} />
                </div>
                <div className="text-10 flex flex-row justify-between text-white font-medium bg-red px-8 py-5 max-w-fit absolute left-0 top-0 w-full">
                    <span className="w-fit truncate mr-3">{item.discount}% OFF </span> <FuseCountdownProduct itemDiscount={item.itemDiscount} dateOnly={true} />
                </div>

                <div className=" h-76 relative border-t-1 p-8">
                    <Typography className="font-medium leading-4 line-clamp-2">{item.itemName}</Typography>
                    <div className="font-semibold absolute bottom-5 my-8 flex">
                        <Typography className=" text-lg leading-tight font-medium " color="primary">
                            ${item.discountedPrice}
                        </Typography>

                        <Typography className=" mx-8 text-lg leading-tight line-through font-medium text-red-200">
                            ${item.price}
                        </Typography>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemContent;