import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from "app/config";
import { query, mutation } from "app/graphql/booking/booking_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const getEventsBooking = createAsyncThunk('bookingApp/booking/getEventsBooking', async (filter) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: filter.mode ? query.getEventsBookingCalendar : query.getEventsBooking,
        variables: {
            fromDate: filter.from,
            toDate: filter.to,
        }
    });

    var tempEvents = [];

    response.data.data.getEventsBooking.map(function (value, key) {
        var approvee = [];

        var approvedById = value['approvedById'] ? JSON.parse(value['approvedById']) : [];
        if (value['approvee'] && value['approvee'].length) {
            value['approvee'].map(function (val, k) {

                var ifExist = approvedById.filter(function (v) {
                    return v.id == val.id;
                });

                if (ifExist.length) {
                    var status = "Approved";

                    if (value['status'] == 'Expired') {
                        status = "Expired";
                    }

                    if (value['status'] == 'Rejected') {
                        status = "Rejected";
                    }

                    approvee.push({
                        ...val,
                        status: status,
                        dateApproval: ifExist[0].date
                    })
                } else {
                    approvee.push({
                        ...val,
                        status: value['status'],
                    })
                }
            });
        }

        var tempEventsVal = {
            ...value,
            approvee: approvee
        };

        tempEvents.push(tempEventsVal);
    });

    return tempEvents;

});

export const getEventsBookingPaginate = createAsyncThunk('bookingApp/booking/getEventsBookingPaginate', async (filter) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: filter.mode ? query.getEventsBookingCalendar : query.getEventsBookingPaginate,
        variables: filter
    });

    var tempEvents = [];

    response.data.data.getEventsBookingPaginate.objects.map(function (value, key) {

        var approvee = [];

        var approvedById = value['approvedById'] ? JSON.parse(value['approvedById']) : [];
        if (value['approvee'] && value['approvee'].length) {
            value['approvee'].map(function (val, k) {

                var ifExist = approvedById.filter(function (v) {
                    return v.id == val.id;
                });

                if (ifExist.length) {
                    var status = "Approved";

                    if (value['status'] == 'Expired') {
                        status = "Expired";
                    }

                    if (value['status'] == 'Rejected') {
                        status = "Rejected";
                    }

                    if (value['status'] == 'Cancelled') {
                        status = "Cancelled";
                    }

                    approvee.push({
                        ...val,
                        status: status,
                        dateApproval: ifExist[0].date
                    })
                } else {
                    approvee.push({
                        ...val,
                        status: value['status'],
                    })
                }
            });
        }

        var tempEventsVal = {
            ...value,
            approvee: approvee
        };

        tempEvents.push(tempEventsVal);
    });

    return {
        data: tempEvents,
        totalItems: response.data.data.getEventsBookingPaginate.totalItems
    };

});

export const cancelBooking = createAsyncThunk(
    'bookingApp/booking/cancelBooking',
    async (itemData) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.cancelBooking,
            variables: {
                data: JSON.stringify(itemData),
            },
        });

        return response.data.data.cancelBooking;

    });

export const removeEvents = createAsyncThunk(
    'bookingApp/booking/remove-event',
    async (itemsIds, { dispatch }) => {

        await itemsIds.map(async (id) => {

            await axios.post(CONFIG.API + "/api/", {
                query: mutation.delete,
                variables: {
                    id: id
                }
            });

        })

        return itemsIds;

    }
);

const eventsBookingAdapter = createEntityAdapter({});

export const {
    selectAll: selectBookingEvents,
    selectIds: selectBookingEventIds,
    selectById: selectBookingEventById,
} = eventsBookingAdapter.getSelectors((state) => state.bookingApp.bookingEvents);

const bookingEventsSlice = createSlice({
    name: 'bookingApp/booking',
    initialState: eventsBookingAdapter.getInitialState({
        eventDialog: {
            type: 'new',
            props: {
                open: false,
            },
            data: null,
        },
        searchText: '',
        typeText: '',
        departmentText: '',
    }),
    reducers: {
        setItemsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
        setItemsTypeText: {
            reducer: (state, action) => {
                state.typeText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
        setDepartmentText: {
            reducer: (state, action) => {
                state.departmentText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
    },
    extraReducers: {
        [getEventsBooking.fulfilled]: eventsBookingAdapter.setAll,
        [removeEvents.fulfilled]: eventsBookingAdapter.removeOne
    },
});

export const { setItemsSearchText, setItemsTypeText, setDepartmentText } = bookingEventsSlice.actions;

export default bookingEventsSlice.reducer;
