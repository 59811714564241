import { createSlice } from '@reduxjs/toolkit';
import { query } from 'app/main/crm/graphql/status_graphql';
import axios from "axios";
import CONFIG from "app/config";

export const getBookingStatus =
    (refNum) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBookingRefNum,
            variables: { refNum: refNum },
            publicUrl: 'crm',
        });

        return response.data.data.bookingByRefNum;
    };

export const getMotorpoolBookingStatus =
    (refNum) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMotorpoolBookingRefNum,
            variables: { refNum: refNum },
            publicUrl: 'crm',
        });

        return response.data.data.motorpoolBookingByRefNum;
    };

const initialState = {
    success: false,
    errors: [],
};

const registerSlice = createSlice({
    name: 'crmApps/crmBooking/register',
    initialState,
    reducers: {
        registerSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        registerError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
});

export const { registerSuccess, registerError } = registerSlice.actions;

export default registerSlice.reducer;