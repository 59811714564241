import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../../../../graphql/user_graphql";

export const getUsers = createAsyncThunk('crmApp/users/getUsers', async (filter) => {
  const response = await axios.post(CONFIG.API + "/api/", {
    query: query.listPaginate,
    variables: filter
  });
  const data = response.data.data.usersListPaginate;
  return data;
});

export const getUsersNoPhoto = createAsyncThunk('crmApp/users/getUsersNoPhoto', async (filter) => {
  const response = await axios.post(CONFIG.API + "/api/", {
    query: query.listPaginateNoPhoto,
    variables: filter
  });
  const data = response.data.data.usersListPaginate;
  return data;
});

export const getUsersNoPhotoIdEmail = createAsyncThunk('crmApp/users/getUsersNoPhotoIdEmail', async (filter) => {
  const response = await axios.post(CONFIG.API + "/api/", {
    query: query.listPaginateNoPhotoIdEmail,
    variables: filter
  });
  const data = response.data.data.usersListPaginate;
  return data;
});

export const removeUsers = createAsyncThunk(
  'crmApp/users/removeUsers',
  async (usersIds, { dispatch, getState }) => {
    
    await usersIds.map(async (id)  =>  {

      await axios.post(CONFIG.API + "/api/", {
        query: mutation.delete,
        variables: {
          id: parseInt(id)
        }
      });

    })

    return usersIds;
  }
);

export const saveResetPassword = createAsyncThunk(
    "crmApp/user/saveResetPassword",
    async (userData, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.resetpassword,
            variables: {
                data: JSON.stringify(userData),
                id: parseInt(userData.id),
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.resetPassword["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.resetPassword["status"],
            })
        );
    }
);

const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors((state) => state.crmApp.users);

const usersManagementSlice = createSlice({
  name: 'crmApp/users',
  initialState: usersAdapter.getInitialState({
    searchText: '',
    typeText: ''
  }),
  reducers: {
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setUsersTypeText: {
      reducer: (state, action) => {
        state.typeText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
    [removeUsers.fulfilled]: (state, action) =>
      usersAdapter.removeMany(state, action.payload),
  },
});

export const { setUsersSearchText, setUsersTypeText } = usersManagementSlice.actions;

export default usersManagementSlice.reducer;
