import { authRoles } from 'app/auth';
import Approve from './approve/Approve';
import TenantStatus from './status/TenantStatus';
import Invoice from './invoice/Invioce';

const TenantConfig = {
	settings: {
		theme: {
			main: "default",
			navbar: "default",
			toolbar: "default",
			footer: "default"
		},
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: 'tenant/accept/:level/:referenceNum/:approveeId/:tenantId/*',
			element: <Approve status={'accept'} />,
		},
		{
			path: 'tenant/reject/:level/:referenceNum/:approveeId/:tenantId/*',
			element: <Approve status={'reject'} />,
		},
		{
			path: 'tenant/status/:referenceNum/*',
			element: <TenantStatus />,
		},
		{
			path: 'tenant/invoice/:invoiceId/:tenantId/*',
			element: <Invoice />,
		},
	],
};

export default TenantConfig;
