import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader(props) {
  const user = useSelector(({ auth }) => auth.user);

  return (
    <StyledAppBar
      position="static"
      color="primary"
      className="user relative flex flex-col items-center justify-center mb-10 z-0 shadow-0">
      <div className="bg-[url('img/bg-menu.png')] bg-auto md:bg-cover pt-24 pb-24 px-40 ">
        <div className="flex items-center -ml-28 ">
              <img
                className="avatar w-72 h-72 box-content rounded-full"
                alt="user photo"
                src='assets/images/logos/cmi-system-logo.png'
              
              />
            <Typography className="username text-16 whitespace-nowrap font-semibold mb-4 ml-16" color="inherit">
              {/* {user.data.displayName} */} CMI OSS SYSTEM
            </Typography>
        </div>
      </div>

      {/* <div className="flex items-center justify-center absolute bottom-0 -mb-44">
        <img
          className="avatar w-72 h-72 box-content rounded-full"
          alt="user photo"
          src='assets/images/logos/cmi-system-logo.png'
          // {
          //   user.data.photoURL && user.data.photoURL !== ''
          //     ? user.data.photoURL
          //     : 'assets/images/avatars/profile.jpg'
          // }
        />
      </div> */}
    </StyledAppBar>
  );
}

export default UserNavbarHeader;
