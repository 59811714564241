import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from "app/config";
import { query, mutation } from "app/main/crm/graphql/tickets_graphql";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";

import CrmJwtService from 'app/services/crm/CrmJwtService';

const token = CrmJwtService.getAccessToken();
const dToken = CrmJwtService.getDecodedAccessToken();

export const getTicketsAdmin = createAsyncThunk('crmApp/tickets/getTicketsAdmin',
    async (filter) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTicketsAdmin,
            variables: filter,
        });
        const data = await response.data.data.getTicketsAdmin;

        return data;
    }
);

export const getTicketById = createAsyncThunk('crmApp/tickets/getTicketById',
    async (ticketId, { getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTicketById,
            variables: {
                id: ticketId
            },
        });
        var data = response.data.data.getTicketById;

        data.topicsAssignees = data.topicsAssigneesRaw ? JSON.parse(data.topicsAssigneesRaw) : [];

        return data;
    }
);

const saveFiles = async (postData) => {
    return await new Promise(async (resolve, reject) => {
        if (postData.attachments.length) {
            var newImages = [];

            postData.attachments.map(async (image, index) => {
                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image,
                    });
                }
            });

            if (newImages.length) {
                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name:
                            "crm/tickets-" +
                            randomString() +
                            "-" +
                            (image.image.file ? image.image.file.name : image.image.fileName),
                    }).then(async (result) => {

                        if (result.data.status === "success") {
                            var uploadData = result.data.uploadData;
                            uploadData["module"] = "crm";
                            uploadData["file_id"] = image.image.id;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                }
                            }, {
                                headers: {
                                    'common': {
                                        'Authorization': `Bearer ${token}`
                                    }

                                }
                            });

                            if (response.data.data.createUpload.status == "success") {
                                var upload = response.data.data.createUpload.upload;

                                postData.attachments[image.index].upload_id = upload.id;
                                postData.attachments[image.index].file_name = upload.fileName;
                                postData.attachments[image.index].file_id = upload.fileId;
                                postData.attachments[image.index].file_type = upload.fileType;
                                postData.attachments[image.index].file_size = upload.fileSize;
                                postData.attachments[image.index].path = upload.path;
                                postData.attachments[image.index].module = upload.module;
                                postData.attachments[image.index].url = "";

                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(postData);
                        }
                    });
                });
            } else {
                resolve(postData);
            }
        } else {
            resolve(postData);
        }
    });
};

export const updateCrmTickets = createAsyncThunk(
    "crmApp/crmTickets/updateCrmTickets",
    async (postData, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.updateCrmTickets,
            variables: {
                data: JSON.stringify(postData)
            },
        });

        return response.data.data.updateCrmTickets;
    }
);


export const saveCrmDiscussion = createAsyncThunk(
    "crmApp/crmTickets/saveCrmDiscussion",
    async (postData, { dispatch, getState }) => {

        return await saveFiles(postData).then(async (postData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.saveCrmDiscussion,
                variables: {
                    data: JSON.stringify(postData)
                },
            });

            if (response.data.data.saveCrmDiscussion) {
                dispatch(
                    showMessage({
                        message: "Message sent.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });
    }
);

const ticketsAdapter = createEntityAdapter({});

export const { selectAll: selectTickets, selectById: selectTicketById } = ticketsAdapter.getSelectors(
    (state) => state.crmApp.tickets
);

const ticketsSlice = createSlice({
    name: 'crmApp/tickets',
    initialState: ticketsAdapter.getInitialState({
        searchText: '',
        topicText: '',
        assigneeText: '',
        departmentText: '',
        assigneeMeText: false,
        routeParams: {},
        selectedTicketIds: [],
    }),
    reducers: {
        setTicketsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        setTicketsTopicsText: {
            reducer: (state, action) => {
                state.topicText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        setTicketsAssigneeText: {
            reducer: (state, action) => {
                state.assigneeText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        setTicketsDepartmentText: {
            reducer: (state, action) => {
                state.departmentText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        setTicketsAssigneeMeText: {
            reducer: (state, action) => {
                state.assigneeMeText = action.payload;
            },
            prepare: (event) => ({ payload: event || false }),
        },
    },
    extraReducers: {
        [getTicketsAdmin.fulfilled]: (state, action) => {
            ticketsAdapter.setAll(state, action.payload);
        },
    },
});

export const {
    setTicketsSearchText,
    setTicketsTopicsText,
    setTicketsAssigneeText,
    setTicketsDepartmentText,
    setTicketsAssigneeMeText,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
