import _ from "@lodash";
import { usePrompt } from "app/services/leaveRoute";
import { showMessage } from "app/store/fuse/messageSlice";
import { motion } from "framer-motion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import reducer from "../store";

import FuseLoading from "@fuse/core/FuseLoading";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { useDeepCompareEffect } from "@fuse/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withReducer from "app/store/withReducer";
import { FormProvider, useForm } from "react-hook-form";

import { getCrmUsersById, saveCrmUsers } from "../store/crmUsersSlice";

import CrmUsersProfileContent from "./content/CrmUsersProfileContent";
import CrmUsersProfileSidebar from "./content/CrmUsersProfileSidebar";

import CrmFooter from 'app/main/crm/footer/CrmFooter';
import CrmHeader from 'app/main/crm/header/CrmHeader';

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    "& .FusePageCarded-header": {
        background: "unset",
        height: "unset",
        minHeight: "unset",
        maxHeight: "unset"
    },
    "& .FusePageSimple-header": {
        minHeight: 72,
        // height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            // height: 136,
        },
    },
    "& .FusePageCarded-contentWrapper": {
        marginLeft:"0",
        [theme.breakpoints.up("md")]: {
            marginLeft:"2rem",
        }, 
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
    "& .FusePageSimple-header": {
        background: "unset"
    },
    "& .FusePageSimple-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    },
    "& .FusePageCarded-contentCard": {
       borderRadius:"20px"
    }
}));

const defaultValues = {
    crmUsersId: "",
    patronType: "",
    status: "",
    idNum: "",
    name: "",
    firstName: "",
    lastName: "",
    middleName: "",
    departmentName: "",
    departmentId: "",
    gender: "",
    birthdate: null,
    emergencyContact: "",
    email: "",
    phone: "",
    ssn: "",
    address1: "",
    lat: "",
    lng: "",
    address2: "",
    country: "United States",
    city: "Majuro",
    state: "Marshall Islands",
    zipCode: "96960",
    notes: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    profilePicId: "",
    profilePicPath: "",
    images: [],
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().email("You must enter a valid email").required("Email is required"),
    firstName: yup.string().required("First Name is required").nullable(),
    lastName: yup.string().required("Last Name is required").nullable(),
    // address1: yup.string().required("Address1 is required"),
    // phone: yup
    //     .string()
    //     .required('Phone Number is required')
    //     .test("len", "Invalid Phone No.", (val) => {
    //         if (val) {
    //             const val_length_without_dashes = val.replace(/-|_/g, "").length;
    //             return (val_length_without_dashes === 3 || val_length_without_dashes === 10 || val_length_without_dashes === 13);
    //         } else {
    //             return true;
    //         }
    //     })
});

function CrmUsersProfile(props) {

    const dispatch = useDispatch();

    const routeParams = useParams();

    const [tabValue, setTabValue] = useState(0);

    const [loading, setLoading] = useState(false);

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const { reset, formState, getValues, trigger, watch } = methods;

    const { isValid, dirtyFields, errors } = formState;

    usePrompt(Object.keys(dirtyFields).length);

    useDeepCompareEffect(() => {

        setLoading(true);

        function updateCrmUsersState() {

            /**
             * Get CrmUsers data
             */
            dispatch(getCrmUsersById(routeParams)).then((action) => {
                console.log("action.payload", action.payload);
                reset(action.payload);

                setLoading(false);
            });
        }

        updateCrmUsersState();
    }, [dispatch, routeParams]);

    /**
     * Tab Change
     */
    function handleTabChange(event, value) {
        setTabValue(value);
    }

    async function handleSave() {

        const result = await trigger(["lastName", "firstName", "email"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            dispatch(saveCrmUsers(getValues())).then(function(){
                reset(getValues());
            });
        }
    }

    return (
        <FormProvider {...methods}>
            <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
                <CrmHeader />
                <Root
                    sx={{
                        minHeight:"unset",
                        '& div.flex.w-full': { maxWidth:"960px", margin: "35px auto ",height:"fit-content"},
                        '& .FusePageCarded-sidebar': { width: "300px",},
                        
                        
                        }}
                    header={<div className="hidden max-1199px:block max-1199px:mb-20 w-full"><CrmUsersProfileSidebar crmUsers={getValues()} /> </div>}
                    contentToolbar={
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            classes={{ root: 'w-full h-64' }}
                        >
                            <Tab className="h-64" label="Primary Details" />
                            <Tab className="h-64" label="Location" />
                            <Tab className="h-64" label="Notes" />
                        </Tabs>
                    }
                    content={
                        <>
                            {(!loading) ?
                                <>

                                    <CrmUsersProfileContent tabValue={tabValue} />
                                    <motion.div
                                        className="flex justify-center pt-16 pr-24 pb-16"
                                        initial={{ opacity: 0, x: 20 }}
                                        animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                    >
                                        <Button
                                            className="whitespace-nowrap mx-4 rounded-md"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                        >
                                            Update
                                        </Button>
                                    </motion.div>
                                </> : <FuseLoading />}
                        </>}
                    leftSidebarContent={<CrmUsersProfileSidebar crmUsers={getValues()} />}
                    // sidebarInner
                    // innerScroll
                />
                <CrmFooter />
            </div>
        </FormProvider>
    );
}

export default withReducer("crmApps", reducer)(CrmUsersProfile);
