import * as React from 'react';

import { useDispatch } from 'react-redux';
import { useFormContext, Controller } from "react-hook-form";

import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import format from 'date-fns/format';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));
function Tab4(props) {

    const { activeTab, tempData } = props;

    if (activeTab != 3) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();

    const { control, formState, setValue, trigger, watch } = methods;
    const { errors } = formState;

    const form = watch();

    return (
        <div className="w-full">
            <div className="p-9 pb-0 w-4/5 min-w-320 m-auto mb-48 mt-32 ">
                <CardStyled elevation={0} className="flex flex-col shadow-0 px-10 py-5">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }}>
                                <div className='flex flex-col'>
                                    
                                    <Typography className=' text-blue-900 text-14 font-semibold'>
                                        Terms and Condition
                                    </Typography>
                                    <Typography color="text.primary" className="mt-10">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae placerat risus, et feugiat leo. Cras id est non sapien suscipit imperdiet. Suspendisse potenti. Nulla vulputate odio ut aliquet convallis. Phasellus diam nisl, lacinia ut cursus nec, rutrum at lacus. Fusce suscipit faucibus urna, ac mollis lacus sollicitudin quis. Aliquam iaculis ipsum ac lorem congue egestas. Nullam sapien felis, vehicula at mollis vel, varius quis leo. Sed at lacus turpis.
                                    </Typography>
                                    <Typography color="text.primary" className="mt-10">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae placerat risus, et feugiat leo. Cras id est non sapien suscipit imperdiet. Suspendisse potenti. Nulla vulputate odio ut aliquet convallis. Phasellus diam nisl, lacinia ut cursus nec, rutrum at lacus. Fusce suscipit faucibus urna, ac mollis lacus sollicitudin quis. Aliquam iaculis ipsum ac lorem congue egestas. Nullam sapien felis, vehicula at mollis vel, varius quis leo. Sed at lacus turpis.
                                    </Typography>
                                    <Typography color="text.primary" className="mt-10">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae placerat risus, et feugiat leo. Cras id est non sapien suscipit imperdiet. Suspendisse potenti. Nulla vulputate odio ut aliquet convallis. Phasellus diam nisl, lacinia ut cursus nec, rutrum at lacus. Fusce suscipit faucibus urna, ac mollis lacus sollicitudin quis. Aliquam iaculis ipsum ac lorem congue egestas. Nullam sapien felis, vehicula at mollis vel, varius quis leo. Sed at lacus turpis.
                                    </Typography>
                                </div>
                            </DialogContent>
                            <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }}>
                                <FormControl
                                    className="flex mx-16 ml-0"
                                    variant="outlined">
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{
                                                "& .MuiTypography-root": {
                                                    whiteSpace: "nowrap", fontWeight: 600,color:"#0d47a1"
                                                }
                                            }}
                                            control={
                                                <Controller
                                                    name="terms"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value}
                                                            onChange={(e) => field.onChange(e.target.checked)}
                                                        />
                                                    )}
                                                />
                                            }
                                            label="I have read and accepted the Terms and Condition"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </DialogContent>
                        </Grid>
                    </Box>
                </CardStyled>
            </div>
        </div>
    );
}

export default Tab4;
