import Icon from '@mui/material/Icon';
import { styled, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { selectMainThemeLight, selectMainTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import CrmJwtService from 'app/services/crm/CrmJwtService';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Root = styled('div')(({ theme }) => ({
    '& .theme-color': {
        color: theme.palette.primary.main
    }
}));

const viewNamesObj = {
    dayGridMonth: {
        title: 'Month',
        icon: 'view_module',
    },
    timeGridWeek: {
        title: 'Week',
        icon: 'view_week',
    },
    timeGridDay: {
        title: 'Day',
        icon: 'view_agenda',
    },
};

function BookingCalendarHeader(props) {
    const { calendarRef, currentDate, itemTypes, selectedType, setSelectedType, setCalendarView } = props;

    const navigate = useNavigate();

    const calendarApi = () => calendarRef.current?.getApi();

    const mainThemeLight = useSelector(selectMainThemeLight);
    const mainTheme = useSelector(selectMainTheme);

    const user = CrmJwtService.getDecodedAccessToken();

    const newBooking = () => {
        if (user.hasOwnProperty('id') && user.id !== '') {
            navigate('/crm/booking');
        } else {
            navigate('/crmlogin?newBooking=true');
        }
    }

    return (
        <ThemeProvider theme={mainThemeLight}>
            <Root
                className={clsx(
                    'relative',
                    format(new Date(currentDate?.start || null), 'MMM')
                )}
            >
                <div className="flex flex-1 flex-col justify-between z-10 container">

                    <div className="flex flex-col items-center justify-between sm:flex-row">
                        <div className="flex items-center my-16 sm:mb-0">
                            <motion.div
                                className="flex"
                                initial={{ opacity: 0, x: 20 }}
                                animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                            >
                                <Button
                                    className="truncate rounded-md"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Icon className="hidden sm:flex">add</Icon>}
                                    onClick={newBooking}
                                >
                                    New Booking
                                </Button>
                            </motion.div>
                            <ThemeProvider theme={mainTheme}>
                                <Paper
                                    component={motion.div}
                                    initial={{ y: -20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                                    className="flex items-center w-full max-w-216 px-8 py-4 rounded-md shadow-0 border-1 h-40px relative"
                                >
                                    <InputLabel className='w-80 absolute'>Type: </InputLabel>
                                    <FormControl
                                        className="h-28"
                                        fullWidth
                                        sx={{
                                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        }}>
                                        <Select
                                            id="demo-simple-select"
                                            className="h-28"
                                            value={selectedType}
                                            onChange={(ev) => {
                                                setSelectedType(ev.target.value);
                                            }}
                                            sx={{
                                                "& div.MuiSelect-select": {
                                                    paddingLeft: "40px"
                                                },

                                            }}
                                        >
                                            <MenuItem value="all" >
                                                <em> All </em>
                                            </MenuItem>
                                            {itemTypes.map((type) => (
                                                <MenuItem value={type.name} key={type.id}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Paper>
                            </ThemeProvider>
                        </div>
                        <div className="flex items-center">
                            <Tooltip title="Today">
                                <div>
                                    <motion.div
                                        initial={{ scale: 0 }}
                                        animate={{ scale: 1, transition: { delay: 0.3 } }}
                                    >
                                        <IconButton
                                            aria-label="today"
                                            onClick={() => calendarApi().today()}
                                            size="large"
                                        >
                                            <Icon>today</Icon>
                                        </IconButton>
                                    </motion.div>
                                </div>
                            </Tooltip>
                            {Object.entries(viewNamesObj).map(([name, view]) => (
                                <Tooltip title={view.title} key={name}>
                                    <div>
                                        <motion.div
                                            initial={{ scale: 0 }}
                                            animate={{ scale: 1, transition: { delay: 0.3 } }}
                                        >
                                            <IconButton
                                                aria-label={name}
                                                onClick={() => {
                                                    setCalendarView(name);
                                                    calendarApi().changeView(name);
                                                    console.log("DDDDDDDDDD", name);
                                                }}
                                                disabled={currentDate?.view.type === name}
                                                size="large"
                                            >
                                                <Icon>{view.icon}</Icon>
                                            </IconButton>
                                        </motion.div>
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                    </div>

                    <motion.div
                        className="flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.3 } }}
                    >
                        <Tooltip title="Previous">
                            <IconButton className='theme-color' aria-label="Previous" onClick={() => calendarApi().prev()} size="large">
                                <Icon>{mainThemeLight.direction === 'ltr' ? 'chevron_left' : 'chevron_right'}</Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="h6">{currentDate?.view.title}</Typography>
                        <Tooltip title="Next">
                            <IconButton className='theme-color' aria-label="Next" onClick={() => calendarApi().next()} size="large">
                                <Icon>{mainThemeLight.direction === 'ltr' ? 'chevron_right' : 'chevron_left'}</Icon>
                            </IconButton>
                        </Tooltip>
                    </motion.div>
                </div>
            </Root>
        </ThemeProvider>
    );
}

export default BookingCalendarHeader;
