import { useFormContext, Controller } from "react-hook-form";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from "@mui/material/CardContent";
import format from 'date-fns/format';
import FormControl from "@mui/material/FormControl";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { acceptTenant, getTenantRefId } from "app/main/tenant/store/approveSlice";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Icon from "@mui/material/Icon";
import moment from 'moment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));
const TableUnstyledNoBorder = styled('table')` 
  td,
  th {
    border: none;
    text-align: left;
    padding: 8px;
  }

`;
const TableUnstyled = styled('table')`
  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

`;

function ApproveContent(props) {

    const { form, pageStatus, setForm } = props;
    const dispatch = useDispatch();
    const routeParams = useParams();

    const [reason, setReason] = React.useState('');

    const handleClick = () => {

        form.approvalDate = moment().format('LLL');

        dispatch(acceptTenant(form)).then(function (response) {
            dispatch(
                showMessage({
                    message: response.message,
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.status,
                })
            );
            dispatch(getTenantRefId({
                refNum: routeParams.referenceNum,
                tenantId: routeParams.tenantId
            })).then(function (response) {
                response.level = routeParams.level;
                response.approveeId = routeParams.approveeId;
                response.tenantId = routeParams.tenantId;
                response.approvedBy = (response.approvedBy ? JSON.parse(response.approvedBy) : []);
                response.status = response.status;
                response.pageStatus = props.status;
                setForm(response);
            });
            // window.close();
        });

    }

    const handleChangeReason = (event) => {
        setReason(event.target.value);
        setForm({
            ...form,
            reason: event.target.value
        })
    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>
            <div className="w-full">
                <div className=" px-10 w-fit  m-auto mb-16">
                    <Box sx={{ flexGrow: 1 }}>
                        <div className="text-right">
                            <Button
                                color="inherit" className="rounded-0 py"
                                onClick={handelClickPrint}
                            >
                                <Icon color="inherit" className="text-5xl">print</Icon>
                            </Button>
                        </div>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <DialogContent classes={{ root: 'py-0 px-10 pb-0 ' }} ref={printRef}>

                                <img className="m-auto" src="assets/images/logos/cmi-logo-blue.png" loading="lazy" />
                                <hr></hr>
                                <div className=" flex flex-col justify-center mx-16 mt-10">
                                    <Typography component="div" className=' text-2xl font-semibold text-center'>
                                        {
                                            status == 'accept' && <>
                                                Approve Residence Application for {form?.patron?.name}
                                            </>
                                        }
                                        {
                                            status == 'reject' && <>
                                                Reject Residence Application for {form?.patron?.name}
                                            </>
                                        }
                                        {
                                            status == 'download' && <>
                                                Download Invoice
                                            </>
                                        }
                                    </Typography>
                                </div>
                                <div className=" flex justify-between my-16">
                                    <Typography className="text-15">
                                        <b>{form?.patron?.name} has booked {form?.roomNumber} Bed {form?.bedNumber} on {form?.timeOfBooking && format(new Date(form?.timeOfBooking), 'MMMM dd, yyyy hh:mm a')} and waiting for Approval </b>
                                    </Typography>
                                </div>
                                <Typography className=" text-14">Start: <span className="font-bold">{form?.start && format(new Date(form?.start), 'MMMM dd, yyyy')} to {form?.end && format(new Date(form?.end), 'MMMM dd, yyyy')}</span></Typography>
                                <Typography className=" text-14">Duration: <span className="font-bold">1 Semester</span></Typography>
                                <Typography className=" text-14">Time of Transaction: <span className="font-bold">{form?.timeOfBooking && format(new Date(form?.timeOfBooking), 'MMMM dd, yyyy hh:mm a')}</span></Typography>

                                <TableUnstyled className='w-full mt-16'>
                                    <tbody>
                                        <tr>
                                            <td width="50%">Name: <span className="font-bold">{form?.patron?.name}</span></td>
                                            <td width="50%">Email: <span className="font-bold">{form?.patron?.email}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="50%">Department: <span className="font-bold">{form?.patron?.departmentName}</span></td>
                                            <td width="50%">Contact: <span className="font-bold">{form?.patron?.emergencyContact}</span></td>
                                        </tr>
                                    </tbody>
                                </TableUnstyled>

                                <Typography className='text-14 font-bold mt-10'>
                                    Breakdown
                                </Typography>
                                <Table className='w-full' stickyHeader aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    Services
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    Description
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    Quantity
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    Rate
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography className=" text-15 ">
                                                    Total
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    {form?.roomNumber}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    Full Payment
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    1
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    {form?.roomPrice}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className="p-10">
                                                <Typography variant="h7">
                                                    {form?.roomPrice}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    {
                                        form?.mealCredit == 'None' ? null :
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            Meal Credit
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">

                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            1
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            {form.mealCredit}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            {form.mealCredit}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                    }
                                    {
                                        form?.bookCredit == 'None' ? null :
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            Book Credit
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">

                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            1
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            {form.bookCredit}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className="p-10">
                                                        <Typography variant="h7">
                                                            {form.bookCredit}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                    }
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={4} className="p-10">
                                                <Typography className="font-bold text-15">
                                                    Total
                                                </Typography>
                                            </TableCell>

                                            <TableCell className="p-10">
                                                <Typography className=" text-15 font-bold">
                                                    {form?.totalPrice}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>


                                <div className='flex flex-col sm:flex-row justify-between mt-16 mb-16'>
                                    <div className='flex flex-col'>
                                        <Typography className=' text-15 font-bold mt-10'>
                                            Processed by
                                        </Typography>
                                        <Typography className=' text-14 font-bold '>
                                            <span className=" text-14 font-normal">Name:</span> {form?.userName}
                                        </Typography>
                                        <Typography className=' text-14 font-bold'>
                                            <span className=" text-14 font-normal">House:</span> {form?.house?.houseName}
                                        </Typography>
                                        <Typography className=' text-14 font-bold'>
                                            <span className=" text-14 font-normal">Position:</span> {form?.userPosition}
                                        </Typography>
                                    </div>
                                </div>

                                {
                                    (form.status != 'Rejected' && status == 'reject' && !form.approvedBy.includes(form.level)) && <div className='flex flex-col mb-24'>
                                        <Typography className='text-15 font-bold'>
                                            Reason
                                        </Typography>
                                        <FormControl className="flex mx-16 mt-4 mb-9 ml-0" variant="outlined">
                                            <TextField
                                                id="reason"
                                                type="text"
                                                value={reason}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                onChange={handleChangeReason}
                                                sx={{
                                                    "& .MuiOutlinedInput-root.MuiInputBase-root ": { padding: "8px 8px" }
                                                }}
                                            />

                                        </FormControl>
                                    </div>
                                }
                                {
                                    form.status == 'Rejected' && <div className='flex flex-col mb-24'>
                                        <Typography className='text-15 font-bold'>
                                            Reason
                                        </Typography>
                                        <FormControl className="flex mx-16 mt-4 mb-9 ml-0" variant="outlined">
                                            {reason || form.reason}

                                        </FormControl>
                                    </div>
                                }

                                {
                                    (form.approvees && form.approvees.length > 0) &&
                                    <div className='flex flex-col mb-8'>
                                        <Typography className=' text-15 font-bold'>
                                            Approved by:
                                        </Typography>

                                        {
                                            form.approvees.map(function (approvee) {
                                                return (
                                                    <div className='flex flex-row justify-between' key={approvee.id}>
                                                        <div className='flex flex-col mx-3 '>
                                                            <Typography className=' text-14 font-bold'>
                                                                <span className=" text-14 font-normal">Name: </span> {approvee.name}
                                                            </Typography>
                                                            <Typography className=' text-14 font-bold'>
                                                                <span className=" text-14 font-normal">Department: </span> {approvee.department ? (JSON.parse(approvee.department)?.map(val => val))?.join(", ") : ''}
                                                            </Typography>
                                                            <Typography className=' text-14 font-bold'>
                                                                <span className=" text-14 font-normal">Position: </span> {approvee.position}
                                                            </Typography>
                                                            <Typography className=' text-14 font-bold'>
                                                                <span className=" text-14 font-normal">Date of Approval: </span> {approvee.date}
                                                            </Typography>
                                                            <Typography className=' text-14 font-bold'>
                                                                <span className=" text-14 font-normal">Status: </span> Approved
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }

                            </DialogContent>
                        </Grid>
                    </Box>
                    {
                        (form.status != 'Rejected' && pageStatus == 'accept' && !form.approvedBy.includes(form.level)) &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <CardContent className='p-0 pb-0 text-center' sx={{ pb: '0px!important' }}>
                                <Button
                                    className="mx-4 mt-16 mb-16 ml-1 mr-1 rounded"
                                    variant="contained"
                                    onClick={handleClick}
                                >
                                    Accept Booking
                                </Button>
                            </CardContent>
                        </Box>
                    }

                    {
                        (form.status != 'Rejected' && pageStatus == 'reject' && !form.approvedBy.includes(form.level)) &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <CardContent className='p-0 pb-0  text-center' sx={{ pb: '0px!important' }}>
                                <Button
                                    className="mx-4 mt-16 mb-16 bg-red-700 ml-1 mr-1 rounded"
                                    variant="contained"
                                    onClick={handleClick}
                                >

                                    Reject
                                </Button>
                            </CardContent>
                        </Box>
                    }
                    {
                        form.status == 'Rejected' &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <Box sx={{
                                bgcolor: '#ffe5e5',
                                m: 1,
                                width: '100%',
                                height: '100%',
                                margin: 'unset',
                                borderRadius: 1,
                                py: 2
                            }}>
                                <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                    <Typography variant="h4 font-bold text-red-700 text-center" component="div">
                                        This booking is rejected and email has been sent to the requestor.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Box>
                    }

                    {
                        (form?.status == 'Approved' || form?.approvedBy?.includes(form.level)) &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <Box sx={{
                                bgcolor: '#F1FFF7',
                                m: 1,
                                width: '100%',
                                height: '100%',
                                margin: 'unset',
                                borderRadius: 1,
                                py: 2
                            }}>
                                <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                    <Typography variant="h4 font-bold text-green-700 text-center" component="div">
                                        {form.status}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Box>
                    }

                </div>
            </div>
        </>
    );

}

export default ApproveContent;