
// * Updated By: ICO
// * Summary:  CRM Design Concerns
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44455-96
// * DateUpdated:  01/16/2023

import {
	createSlice,
	createAsyncThunk,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
	query,
	mutation,
} from "../../../../graphql/crm/crm_topics_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const getTopics = createAsyncThunk(
	"crmApp/topics/getTopics",
	async () => {
		const response = await axios.post(CONFIG.API + "/api/", {
			query: query.getTopics,
		});
		const data = response.data.data.getTopics;
		return data;
	}
);

export const removeTopics = createAsyncThunk(
	"crmApp/topics/removeTopics",
	async (topicsId, { dispatch, getState }) => {

		await topicsId.map(async (id) => {
			await axios.post(CONFIG.API + "/api/", {
				query: mutation.deleteCrmTopics,
				variables: {
					id: id,
				},
			});
		});

		dispatch(
			showMessage({
				message: "Item(s) has been successfully deleted.",
				autoHideDuration: 5000, //ms
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
				variant: "success",
			})
		);

		return topicsId;
	}
);

export const saveTopics = createAsyncThunk(
	"crmApp/topics/saveTopics",
	async (postdata, { dispatch, getState }) => {
		postdata.assignees = postdata.assignees ? JSON.stringify(postdata.assignees) : '';
		if (postdata.id) {
			const response = await axios.post(CONFIG.API + "/api/", {
				query: mutation.updateCrmTopics,
				variables: {
					data: JSON.stringify(postdata),
				},
			});


			if (response.data.data.updateCrmTopics) {
				dispatch(
					showMessage({
						message: "CRM Topics has been successfully updated.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "success",
					})
				);
			} else {
				dispatch(
					showMessage({
						message: "Something went wrong. Please try again later.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "error",
					})
				);
			}
		} else {
			const response = await axios.post(CONFIG.API + "/api/", {
				query: mutation.createCrmTopics,
				variables: { data: JSON.stringify(postdata) },
			});

			if (response.data.data.createCrmTopics) {
				dispatch(
					showMessage({
						message: "CRM Topics has been successfully saved.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "success",
					})
				);
			} else {
				dispatch(
					showMessage({
						message: "Something went wrong. Please try again later.",
						autoHideDuration: 5000, //ms
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "right",
						},
						variant: "error",
					})
				);
			}
		}
	}
);

const topicsAdapter = createEntityAdapter({});

export const {
	selectAll: selectTopics,
	selectById: selectTopicsById,
} = topicsAdapter.getSelectors((state) => state.crmApp.topics);

const topicsSlice = createSlice({
	name: "crmApp/topics",
	initialState: topicsAdapter.getInitialState({
		searchText: "",
		showModal: false,
	}),
	reducers: {
		setTopicsSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[getTopics.fulfilled]: topicsAdapter.setAll,
		[removeTopics.fulfilled]: (state, action) => topicsAdapter.removeMany(state, action.payload),
		[saveTopics.fulfilled]: (state, action) => action.payload
	},
});

export const { setTopicsSearchText } = topicsSlice.actions;

export default topicsSlice.reducer;
