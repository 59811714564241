// * Updated By: ICO
// * Summary:  CRM Booking list Hndi nag sscroll down and up pag natapat ung cursor sa table
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-52526-96
// * DateUpdated:  05/29/2023

import * as React from "react";
import { styled } from '@mui/material/styles';
import reducer from "app/main/apps/crm/store";

import withReducer from "app/store/withReducer";
import FusePageSimple from "@fuse/core/FusePageSimple";

import CrmHeader from 'app/main/crm/header/CrmHeader';
import CrmFooter from 'app/main/crm/footer/CrmFooter';
import CrmBookingTabs from 'app/main/crm/booking/CrmBookingTabs';
import CrmBookingListContent from 'app/main/crm/booking-list/content/CrmBookingListContent';

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .FusePageSimple-contentWrapper": {
        maxWidth: "1366px",
        margin: "10px auto",
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));
function CrmBookingList(props) {

    return (
        <div className="flex flex-col flex-auto min-w-0 relative z-10 h-full">
            <CrmHeader />
            <Root
                contentToolbar={
                    <div className="px-12 w-full">
                        <CrmBookingTabs menu='list' />
                    </div>
                }
                content={
                    <div className="max-w-1366px m-auto">
                        <div className="px-16 pt-16 pb-0 sm:p-24 sm:pb-0 m-auto mb-56 mt-10 md:mt-9 bg-white shadow-md rounded-lg relative min-h-90p w-full max-w-screen-sf">
                            <CrmBookingListContent />
                        </div>
                    </div>

                }
            />
            <CrmFooter />
        </div>
    );
}

export default withReducer("crmApps", reducer)(CrmBookingList);
