const mutation = {
    createUpdateDriver: `
        mutation createUpdateDriver($data: JSONString) {
           createUpdateDriver(data: $data) {
                status,
                res,
                message
            }
        }
    `,
    removeDriver: `
        mutation removeDriver($id: String) {
           removeDriver(id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    motorpoolDrivers: `
        query motorpoolDrivers($keyword: String){
            motorpoolDrivers(keyword: $keyword){
                id
                name
                licenseNumber
                licenseExpiration
                backLicenseIdUrl
                backLicenseIdPath
                frontLicenseIdUrl
                frontLicenseIdPath
                isPatron
                lastDrive
                driveList
                dateCreated
            }
        }
    `,
    getMotorpoolDriverByLicenseNumber: `
        query getMotorpoolDriverByLicenseNumber($licenseNumber: String){
            getMotorpoolDriverByLicenseNumber(licenseNumber: $licenseNumber){
                id
                name
                licenseNumber
                licenseExpiration
                backLicenseIdPath
                frontLicenseIdPath
                dateCreated
            }
        }
    `,
};

export { mutation, query };