const mutation = {
    create: `
        mutation CreateUpload($data: JSONString) {
            createUpload(data: $data) {
                status,
                message,
                upload {
                id,
                fileId,
                fileName,
                fileSize,
                fileType,
                path,
                module,
                source          
                }
            }
        }
    `,
    removeUploadById: `
        mutation removeUploadById($id: String) {
           removeUploadById(id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getUploadsPerModule: `
        query getUploadsPerModule($module: String!) {
            getUploadsPerModule(module: $module) {
                id,
                fileId,
                fileName,
                fileSize,
                fileType,
                path,
                uploaderUsername,
                dateUpdated,
                preSignedUrl,
                module,
                source   
            }
        }
    `,
    getUploadsByFileId: `
        query getUploadsByFileId($fileId: String!) {
            getUploadsByFileId(fileId: $fileId) {
                id
                fileName
                fileType
                fileId
                module
                path
                url
                dateCreated
                dateUpdated
            }
        }
    `,
    getSignedUrl: `
        query getSignedUrl($path: String){
            getSignedUrl(path: $path)
        }
    `,
};

export { mutation, query };