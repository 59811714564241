import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { query } from "../../../../graphql/settings/booking_item_types_graphql";
import CONFIG from 'app/config';

export const getTypes = createAsyncThunk('bookingApp/bookingItemTypes/getTypes', async () => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.bookingItemTypesListWithoutVehicle
    });
    const data = response.data.data.bookingItemTypesListWithoutVehicle;
    return data;
});

const typesAdapter = createEntityAdapter({});

export const { selectAll: selectTypes, selectById: selectTypeById } =
    typesAdapter.getSelectors((state) => state.bookingApp.bookingItemTypes);

const bookingItemTypesSlice = createSlice({
    name: 'bookingApp/bookingItemTypes',
    initialState: typesAdapter.getInitialState({}),
    reducers: {},
    extraReducers: {
        [getTypes.fulfilled]: typesAdapter.setAll,
    },
});

export default bookingItemTypesSlice.reducer;
