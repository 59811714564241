import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { query } from "../../../../graphql/settings/booking_departments";
import CONFIG from 'app/config';

export const getDepartments = createAsyncThunk('bookingApp/bookingDepartments/getDepartments', async (params) => {
  const response = await axios.post(CONFIG.API + "/api/", {
    query: query.list,
    variables: params
  });
  const data = response.data.data.bookingDepartmentsList;
  return data;
});

const departmentsAdapter = createEntityAdapter({});

export const { selectAll: selectDepartments, selectById: selectDepartmentById } =
  departmentsAdapter.getSelectors((state) => state.bookingApp.bookingDepartments);

const bookingDepartmentsSlice = createSlice({
  name: 'bookingApp/bookingDepartments',
  initialState: departmentsAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getDepartments.fulfilled]: departmentsAdapter.setAll,
  },
});

export default bookingDepartmentsSlice.reducer;
