import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import authRoleExamplesConfigs from 'app/main/auth/authRoleExamplesConfigs';
import CallbackConfig from 'app/main/callback/CallbackConfig';
import DocumentationConfig from 'app/main/documentation/DocumentationConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import BookingConfig from 'app/main/booking/BookingConfig';
import EcommerceConfig from 'app/main/ecommerce/EcommerceConfig';
import EcommerceUserConfig from 'app/main/ecommerce/EcommerceUserConfig';
import UserInterfaceConfig from 'app/main/user-interface/UserInterfaceConfig';
import CrmUsersConfig from 'app/main/crm/CrmUsersConfig';
import CrmConfig from 'app/main/crm/CrmConfig';
import TenantConfig from 'app/main/tenant/TenantConfig';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import BusPublicAppConfig from 'app/main/apps/bus-management/BusPublicAppConfig';

const routeConfigs = [
  ...appsConfigs,
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  UserInterfaceConfig,
  DocumentationConfig,
  LogoutConfig,
  LoginConfig,
  BookingConfig,
  TenantConfig,
  EcommerceConfig,
  EcommerceUserConfig,
  CrmConfig,
  CrmUsersConfig,
  CallbackConfig,
  BusPublicAppConfig,
];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: '/',
    element: <Navigate to="e-commerce/home" />,
  },
  {
    path: '/e-commerce/home',
    element: <Navigate to="e-commerce/home" />,
  },
  {
    path: '/crmlogin',
    element: <Navigate to="crmlogin" />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="pages/errors/error-404" />,
  },
];

export default routes;
