import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getWishlist } from "app/main/ecommerce/store/wishlistSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import Button from '@mui/material/Button';
import WishlistTable from 'app/main/ecommerce/wishlist/content/WishlistTable';
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import Alert from '@mui/material/Alert';

function WishlistContent({ cartClick, setCarClick }) {

    const token = EcommerceJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});

    const [deleteClick, setDeleteClick] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getWishlist(token.id)).then((action) => {
            setItems(action.payload);
            setLoading(false);
        });
        EcommerceJwtService.setJwtLoginReload(false);   
    }, [dispatch, deleteClick]);

    if (loading) {

        return <div className="mt-25vh"><FuseLoading /></div>

    }

    return (
        <div className="px-24">
            <div role="presentation" className="py-20 font-bold">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important', fontWeight:'bold' }} color="inherit" href="/" >
                        <Icon sx={{ mr: 0.5 }} fontSize="inherit" >keyboard_backspace</Icon> Go Back shopping
                    </Link>
                    
                </Breadcrumbs>
            </div>
                <div className="w-full bg-white border-1 border-1-gray-50 border-opacity-5 px-24 py-12 relative ...">
                    <Typography className="font-semibold text-18">Wishlist ({items.length} Items)</Typography>
                    
                    <WishlistTable items={items} deleteClick={deleteClick} setDeleteClick={setDeleteClick} cartClick={cartClick} setCarClick={setCarClick} />                    

                </div>
        </div>
    )

}

export default WishlistContent;