const mutation = {
    createNotifications: `
      mutation createNotifications($data: JSONString!) {
          createNotifications (data: $data) {
                status,
                res,
                message
          }
      }
    `,
    updateNotifications: `
        mutation updateNotifications($data: JSONString!) {
            updateNotifications (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    notificationList: `
        query notificationList($userId: String!) {
            notificationList (userId: $userId) {
                id
                userId
                userName
                customerId
                customerName
                message
                link
                module
                status
                time
                dateCreated
                dateUpdated
            }
        }
    `,
}

export { mutation, query };