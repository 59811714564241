import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/wishlist_graphql";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

const token = EcommerceJwtService.getAccessToken();

export const getWishlist = createAsyncThunk(
    "ecommerceApp/cart/getWishlist",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.wishlistList,
            variables: {
                customerId: id
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.ecommerceWishlistList) {
            const data = response.data.data.ecommerceWishlistList;

            return data;
        } else {
            return null
        }
    }
);

export const getWishlistCount = createAsyncThunk(
    "ecommerceApp/cart/getWishlistCount",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.wishlistCount,
            variables: {
                customerId: id
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.ecommerceWishlistList) {
            const data = response.data.data.ecommerceWishlistList;

            return data;
        } else {
            return null
        }
    }
);

export const deleteCartItem = createAsyncThunk(
    "ecommerceApp/cart/deleteCartItem",
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.deleteCartItem,
            variables: {
                id: id
            },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });


        if (response.data.data.deleteCartItem) {
            const data = response.data.data.deleteCartItem;

            return data;
        } else {
            return null
        }
    }
);

const wishlist = createSlice({
    name: "ecommerceApp/cart",
    initialState: null,
    reducers: {
        resetWishlist: () => null,
        newWishlist: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newWishlist, resetWishlist } = wishlist.actions;

export default wishlist.reducer;
