import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/shop_graphql";

export const getItems = createAsyncThunk(
    "ecommerceApp/shopPage/getItems",
    async (itemId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.itemById,
            variables: {
                itemId: itemId
            }
        });


        if (response.data.data.itemById) {
            const data = response.data.data.itemById;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getItemsPaginate = createAsyncThunk(
    'ecommerceApp/shopPage/getItemsPaginate',
    async (filter) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: (filter.noPhoto ? query.listPaginateNoPhoto : query.listPaginate),
            variables: {
                ...filter,
                fromPage: 'ecommerce'
            },
            publicUrl: 'ecommerce',
        });
        const data = response.data.data.itemsListPaginate;
        return data;
    }
);

export const saveItemSearch = createAsyncThunk(
    'ecommerceApp/shopPage/saveItemSearch',
    async (formData) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveItemSearch,
            variables: { data: JSON.stringify(formData) },
        });
        const data = response.data.data;
        return data;
    }
);

export const getItemCategoryList = createAsyncThunk(
    "ecommerceApp/shopPage/getItemCategoryList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getItemCategoryList
        });


        if (response.data.data.itemCategoryList) {
            const data = response.data.data.itemCategoryList;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

const productSlice = createSlice({
    name: "ecommerceApp/shopPage",
    initialState: null,
    reducers: {
        resetProductPage: () => null,
        newProductPage: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newProductPage, resetProductPage } = productSlice.actions;

export default productSlice.reducer;
