import Icon from '@mui/material/Icon';
import { styled, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';

const Root = styled('div')(({ theme }) => ({
    '& .theme-color': {
        color: theme.palette.primary.main
    }
}));

function Tab3Header(props) {
    const { calendarRef, currentDate, setCurrentDateChange } = props;

    const calendarApi = () => calendarRef.current?.getApi();

    const mainThemeDark = useSelector(selectMainThemeDark);

    return (
        <ThemeProvider theme={mainThemeDark}>
            <Root
                className={clsx(
                    'flex relative',
                    format(new Date(currentDate?.start || null), 'MMM')
                )}
            >
                <div className="flex flex-1 flex-col p-12 justify-between z-10 container">
                    <motion.div
                        className="flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.3 } }}
                    >
                        <Tooltip title="Previous">
                            <IconButton className='theme-color' aria-label="Previous" onClick={() => {
                                calendarApi().prev()
                                setCurrentDateChange(true);
                            }} size="large">
                                <Icon>{mainThemeDark.direction === 'ltr' ? 'chevron_left' : 'chevron_right'}</Icon>
                            </IconButton>
                        </Tooltip>
                        <Typography variant="h6">{currentDate?.view.title}</Typography>
                        <Tooltip title="Next">
                            <IconButton className='theme-color' aria-label="Next" onClick={() => {
                                calendarApi().next()
                                setCurrentDateChange(true);
                            }} size="large">
                                <Icon>{mainThemeDark.direction === 'ltr' ? 'chevron_right' : 'chevron_left'}</Icon>
                            </IconButton>
                        </Tooltip>
                    </motion.div>
                </div>
            </Root>
        </ThemeProvider>
    );
}

export default Tab3Header;
