/*
* DateUpdated: 01/05/22
* updatedBy: ico
* Summary: tabs Name
* Task:  https://app.activecollab.com/187150/projects/96?modal=Task-44364-96
*/


// * Updated By: ICO
// * Summary: Screen Resolution [1280x1024] Store Tabs alaignment
// * Task:https://app.activecollab.com/187150/projects/96?modal=Task-47122-96
// * DateUpdated:  02/03/2023

import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import UserService from "app/services/users";

function CrmBookingTabs(props) {

    const { menu } = props;

    return (

        <div className="flex p-0 container mt-52 overflow-auto">
            <Button
                className="mx-4 mt-16 mb-16 ml-5 mr-5 w-1/4 min-w-fit rounded"
                variant={menu === "booking" ? "contained" : "outlined"}
                color="primary"
                component={Link}
                to="/crm/booking"
                ng
            >
                Booking
            </Button>
            <Button
                className="mx-4 mt-16 mb-16 ml-5 mr-5 w-1/4 min-w-fit rounded"
                variant={menu === "status" ? "contained" : "outlined"}
                color="primary"
                component={Link}
                to="/crm/booking/status/check"
                ng
            >
                Booking Status
            </Button>
            <Button
                className="mx-4 mt-16 mb-16 ml-5 mr-5 w-1/4 min-w-fit rounded"
                variant={menu === "list" ? "contained" : "outlined"}
                color="primary"
                component={Link}
                to="/crm/booking/list"
                ng
            >
                Booking List
            </Button>
        </div>

    )
}

export default CrmBookingTabs;