import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitLogin } from "app/auth/store/loginSlice";
import * as yup from "yup";
import _ from "@lodash";
import Typography from '@mui/material/Typography';
import JwtService from "app/services/jwtService";
import { useNavigate } from 'react-router-dom';
import ForgotPasswordModal from '../modal/ForgotPasswordModal';


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().required("You must enter a email"),
    password: yup
        .string()
        .required("Please enter your password.")
        .min(4, "Password is too short - should be 4 chars minimum."),
});

const defaultValues = {
    email: "",
    password: "",
};

function JWTLoginTab(props) {
    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);

    const [openDialogForgotPassword, setOpenDialogForgotPassword] = useState(false);

    function handleDialogForgotPasswordOpen() {
        setOpenDialogForgotPassword(true);
    }

    function handleDialogForgotPasswordClose() {
        setOpenDialogForgotPassword(false);
    }

    const {
        control,
        setValue,
        formState,
        handleSubmit,
        reset,
        trigger,
        setError,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        login.errors.forEach((error) => {
            setError('password', {
                type: "manual",
                message: error.message,
            });
        });
    }, [login.errors, setError]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = JwtService.getDecodedAccessToken();
        if (token && token.id) {
            setTimeout(() => {
                if (token.vendor.vendorId) {
                    navigate("/apps/meal-vendors/meal/new");
                } else {
                    navigate("/apps/dashboards/project");
                }
            });
        }
    }, [login.success]);

    function onSubmit(model) {
        dispatch(submitLogin(model));
    }

    return (
        <div className="w-full">
            <form
                className="flex flex-col justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
            >
                <label className="mt-14 text-15">Email</label>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-16"
                            type="text"
                            error={!!errors.email}
                            helperText={errors?.email?.message}


                            variant="outlined"
                        />
                    )}
                />
                <label className="mt-5 text-15">Password</label>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-16"

                            type="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            variant="outlined"
                            InputProps={{
                                className: "pr-2",
                                type: showPassword ? "text" : "password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            size="large"
                                        >
                                            <Icon className="text-20" color="action">
                                                {showPassword ? "visibility" : "visibility_off"}
                                            </Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    )}
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="w-full mx-auto mt-16 rounded-md h-48"
                    aria-label="LOG IN"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    value="legacy"
                >
                    Login
                </Button>
                <Typography className="mt-24 text-14 font-500">
                    <ForgotPasswordModal
                        openDialogForgotPassword={openDialogForgotPassword}
                        handleDialogForgotPasswordOpen={handleDialogForgotPasswordOpen}
                        handleDialogForgotPasswordClose={handleDialogForgotPasswordClose}
                    />
                    <br/><br/>
                    Don't have an account?
                    <br/>
                    Contact your administrator
                </Typography>
            </form>
        </div>
    );
}

export default JWTLoginTab;
