// * Updated By: Jherome
// * Summary: print form format, igaya sa ibang print form- ARA
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-43517-96
// * DateUpdated:  12/20/2022

// * Updated By: ico
// * Summary: fix price and remvie sentence
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-43692-96
// * DateUpdated:  12/27/2022

import { useFormContext, Controller } from "react-hook-form";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from "@mui/material/CardContent";
import format from 'date-fns/format';
import FormControl from "@mui/material/FormControl";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));

function InvoiceContent(props) {

    const { form, status, setForm } = props;
    const dispatch = useDispatch();
    const routeParams = useParams();

    console.log(form, "form");

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>
            <div className="w-full">
                <div className="p-9 pb-0 w-fit max-w-lg min-w-800 m-auto mb-48">

                    <CardStyled className="flex flex-col shadow-0 mt-10" elevation={0}>
                        <Box sx={{ flexGrow: 1 }}>
                            <div className="text-right">
                                <Button
                                    color="inherit" className="rounded-0 py"
                                    onClick={handelClickPrint}
                                >
                                    <Icon color="inherit">print</Icon>
                                </Button>
                            </div>
                            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }} ref={printRef}>
                                    <div className='flex flex-row justify-center'>
                                        <div className='flex flex-col mx-16'>
                                            <div className='flex justify-center'>
                                                <div className="logo">
                                                    <img width="200" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                                                </div>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    College of Marshall Islands PO Box 1258 Majuro,
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    Republic of the Marshall Islands 96960
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    TELEPHONE #: +692 625 1213
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    EMAIL ADDRESS: acs@cmi.edu
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    WEBSITE: oss.cmi.edu
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-32'>
                                                    Commercial Services
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-bold mt-10'>
                                                INVOICE #: {form?.invoice?.invoiceNum}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                TIME OF BOOKING: {form?.tenant?.timeOfBooking ? format(new Date(form?.tenant?.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : null}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                BILL TO: {form?.tenant?.patron?.name}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                EMAIL: {form?.tenant?.patron?.email}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                ADDRESS: {form?.tenant?.patron?.fullAddress}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                CONTACT: {form?.tenant?.patron?.emergencyContact}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                DEPARTMENT: {form?.tenant?.patron?.departmentName}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-bold mt-10'>
                                                START: {form?.tenant?.start ? format(new Date(form?.tenant?.start), 'EEEE, MMMM dd, yyyy') : ""} {form?.tenant?.start ? format(new Date(form?.tenant?.start), 'hh:mm a') : ""} - {form?.tenant?.end ? format(new Date(form?.tenant?.end), 'hh:mm a') : ""}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='flex flex-col sm:flex-row justify-between mb-16 w-full'>
                                        <div className='flex flex-col mx-16 w-full'>
                                            <Typography className='text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Breakdown</span>
                                            </Typography>
                                            <Table className='w-full' stickyHeader aria-labelledby="tableTitle">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Services
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Description
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Quantity
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Rate
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Total
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                {form?.tenant?.roomNumber}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Full Payment
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                1
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                {form?.tenant?.roomPrice}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                {form?.tenant?.roomPrice}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                                {
                                                    form?.tenant?.mealCredit == 'None' ? null :
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        Meal Credit
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">

                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        1
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        {form?.tenant?.mealCredit}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        {form?.tenant?.mealCredit}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                }
                                                {
                                                    form?.tenant?.bookCredit == 'None' ? null :
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        Book Credit
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">

                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        1
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        {form?.tenant?.bookCredit}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell className="p-10">
                                                                    <Typography variant="h7">
                                                                        {form?.tenant?.bookCredit}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                }
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                Total
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">

                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">

                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">

                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className="p-10">
                                                            <Typography variant="h7">
                                                                {form?.tenant?.totalPrice}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                DURATION:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                1 Semester
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                UNIT PRICE:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                SUBTOTAL:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                DISCOUNT:
                                            </Typography>
                                        </div>
                                        <div className='flex flex-col mx-16 text-right'>
                                            <Typography className=' text-14 font-medium  mt-10'>
                                                ${parseFloat(form?.invoice?.subTotal).toFixed(2)}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                ${parseFloat(form?.invoice?.subTotal).toFixed(2)}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                ${parseFloat(form?.invoice?.discount).toFixed(2)}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-bold mt-10'>
                                                TOTAL:
                                            </Typography>
                                        </div>
                                        <div className='flex flex-col mx-16 text-right'>
                                            <Typography className=' text-14 font-bold mt-10 '>
                                                ${parseFloat(form?.invoice?.grandTotal).toFixed(2)}
                                            </Typography>
                                        </div>
                                    </div>

                                </DialogContent>
                            </Grid>
                        </Box>

                    </CardStyled>

                </div>
            </div>
        </>
    );

}

export default InvoiceContent;