import * as yup from 'yup';
import _ from '@lodash';
import { useNavigate, Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import { showMessage } from "app/store/fuse/messageSlice";
import { checkEcommerceEmail } from "app/main/ecommerce/store/registerSlice";
import { forgotPassword } from "app/main/ecommerce/store/loginSlice";


const defaultValues = {
    email: "",
};

function ForgotPasswordModal(props) {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [showBackButton, setShowBackButton] = useState(false);

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("Email is required")
            .test({
                message: () => 'Email not found.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkEcommerceEmail({ email: id, ecommerce: 1, crm: 0 }));
                    }
                },
            })
    });

    const { openDialogForgotPassword, handleDialogForgotPasswordOpen, handleDialogForgotPasswordClose } = props;

    const { control, formState, trigger, reset, getValues, watch } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const emailTemp = watch('email');

    async function handleSave() {

        const result = await trigger(["email"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        var postdata = getValues();

        postdata.crm = 0;

        dispatch(forgotPassword(postdata)).then(function (action) {
            if(action.payload.status != 'error'){
                setShowBackButton(true);
            }else{
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });

    }

    function backToHomePage() {
        handleDialogForgotPasswordClose();
    }

    useEffect(() => {
        setShowBackButton(false);

        reset(defaultValues);

    }, [openDialogForgotPassword]);

    return (
        <>
            <Link className="font-normal" to="" onClick={() => handleDialogForgotPasswordOpen()}>
                Forgot Password?
            </Link>

            <Dialog
                open={openDialogForgotPassword}
                onClose={handleDialogForgotPasswordClose}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogContent classes={{ root: 'p-16 pb-0 sm:pt-24  sm:p-48' }}>
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => handleDialogForgotPasswordClose()}
                            size="large"
                            className='float-right'
                        >
                            <Icon>close</Icon>
                        </IconButton>

                        <form
                            name="forgotForm"
                            noValidate
                            className="flex flex-col justify-center w-full mb-36"
                        >
                            {showBackButton && (
                                <>
                                    <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                        Reset Password
                                    </Typography>
                                    <Typography variant="h6" className="text-center mt-16">
                                        <Icon className="text-green font-semibold text-5xl">mark_email_read</Icon>
                                    </Typography>
                                    <Typography variant="h8" className="font-medium text-14 sm:text-14 text-center">
                                        A Verification email has been sent to this email address {emailTemp}. Please verify it.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="w-full mx-auto mt-32 rounded-md h-48"
                                        aria-label="LOG IN"
                                        value="legacy"
                                        onClick={() => backToHomePage()}
                                    >
                                        Back to Login
                                    </Button>
                                </>
                            )}

                            {!showBackButton && (
                                <>
                                    <Typography variant="h6" className=" font-semibold text-18 sm:text-24 text-center">
                                        Forgot your password?
                                    </Typography>
                                    <Typography className="mt-16 mb-36 text-center">
                                        Enter your email and recover your account.
                                    </Typography>

                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mt-16"
                                                error={!!errors.email}
                                                required
                                                helperText={errors?.email?.message}
                                                label="Email"
                                                id="email"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />

                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className="w-full mx-auto mt-44 rounded-md h-48"
                                        aria-label="LOG IN"
                                        value="legacy"
                                        onClick={handleSave}
                                    >
                                        Reset password
                                    </Button>
                                </>
                            )}
                        </form>
                    </motion.div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ForgotPasswordModal;
