const mutation = {
    create: `
        mutation createBlacklist($data: JSONString!) {
            createBlacklist (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    edit: `
        mutation editBlacklist($data: JSONString!) {
            editBlacklist (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    delete: `
        mutation deleteBlacklist($id: String!) {
            deleteBlacklist (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    check: `
        mutation checkBlacklist($id: String!) {
            checkBlacklist (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getCostumer: `
        query getCostumer($keyword: String, $limit: Int) {
            getCostumer(keyword: $keyword, limit: $limit) {
                id
                patronNameWId
                patronId
                phone
                name
                email
                departmentName
            }
        }
        
    `,
    list: `
    query blacklistList {
        blacklistList {
          id,
          patronId
          name,
          email,
          phone,
          department,
          reason,
          banExpiry,
          dateCreated,
          dateUpdated
        }
      }
  `
}

export { mutation, query };