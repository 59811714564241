const mutation = {
    create: `
      mutation createPatronFromBooking($data: JSONString!) {
          createPatronFromBooking (data: $data) {
            status,
            res,
            message
          }
      }
  `,
};

const query = {
    getBookingRefNum: `
        query bookingByRefNum($refNum: String!) {
            bookingByRefNum (refNum: $refNum){    
                id
                patronId
                publicId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemPhotoUrl
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                dateCreated
                dateUpdated
                approvedById
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
            }
        }
    `,
    getMotorpoolBookingRefNum: `
        query motorpoolBookingByRefNum($refNum: String!) {
            motorpoolBookingByRefNum (refNum: $refNum){    
                id
                patronId
                publicId
                itemId
                referenceNum
                itemName
                itemCode
                itemRate
                itemPhotoUrl
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                dateCreated
                dateUpdated
                approvedById
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
            }
        }
    `
}

export { mutation, query };