import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Checkpoint = lazy(() => import('./checkpoint/Checkpoint'));
const RouteSchedule = lazy(() => import('./route-schedule/RouteSchedule'));
const RouteForm = lazy(() => import('./route-schedule/form/RouteForm'));
const ShiftingManagement = lazy(() => import('./patrollers/shifting-management/ShiftingManagement'));
const PatrollerCalendar = lazy(() => import('./patrollers/patroller-calendar/PatrollerCalendar'));
const PatrollerManagement = lazy(() => import('./patrollers/patroller-calendar/management/PatrollerManagement'));
const PatrolLiveMonitoring = lazy(() => import('./patrollers/live-monitoring/PatrolLiveMonitoring'));
const IncidentReport = lazy(() => import('./incident-report/IncidentReport'));
const PatrolMonitoring = lazy(() => import('./patrollers/patrol-monitoring/PatrolMonitoring'));
const PatrolManagement = lazy(() => import('./patrollers/patrol-monitoring/management/PatrolManagement'));

const PatrolAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/patrol/checkpoint',
            element: <Checkpoint />,
            auth: ["patrol-checkpoint"],
        },
        {
            path: 'apps/patrol/route-schedule',
            element: <RouteSchedule />,
            auth: ["patrol-route"],
        },
        {
            path: 'apps/patrol/route/schedule/:routeId/*',
            element: <RouteForm />,
            auth: ["patrol-route"],
        },
        {
            path: 'apps/patrol/patrollers/shifting-management',
            element: <ShiftingManagement />,
            auth: ["patrol-shifting"],
        },
        {
            path: 'apps/patrol/patrollers/patroller-calendar',
            element: <PatrollerCalendar />,
            auth: ["patrol-calendar"],
        },
        {
            path: 'apps/patrol/patrollers/patroller-management',
            element: <PatrollerManagement />,
            auth: ["patrol-calendar"],
        },
        {
            path: 'apps/patrol/patrollers/live-monitoring',
            element: <PatrolLiveMonitoring />,
            auth: ["patrol-live"],
        },
        {
            path: 'apps/patrol/incident-report',
            element: <IncidentReport />,
            auth: ["patrol-incident-report"],
        },
        {
            path: 'apps/patrol/patrollers/patrol-calendar',
            element: <PatrolMonitoring />,
            auth: ["patrol-monitoring"],
        },
        {
            path: 'apps/patrol/patrollers/patrol-management',
            element: <PatrolManagement />,
            auth: ["patrol-monitoring"],
        },
    ],
};

export default PatrolAppConfig;
