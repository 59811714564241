const mutation = {
    create: `
    mutation createBooking($data: JSONString!) {
        createBooking (data: $data) {
            status,
            res{
                referenceNum
                dateCreated
            },
            message
        }
    }
    `,
    update: `
        mutation updateBooking($data: JSONString!) {
            updateBooking (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    bookingList: `
    query bookingList(
        $id: String!, 
    ) {
        bookingList(
            id: $id
        ) {
            id
            patronId
            publicId
            itemId
            referenceNum
            itemName
            itemCode
            itemRate
            itemPhotoUrl
            name
            contact
            address
            email
            department
            purpose
            timeOfBooking
            startFormatted
            endFormatted
            start
            end
            status
            title
            duration
            total
            backgroundColor
            inspectionRequired
            textColor
            display
            editable
            statusBanned
            dateCreated
            dateUpdated  
            type
        }
      }
  `,
  searchBookingPatron: `
    query searchBookingPatron($empId: String!, $email: String!) {
        searchBookingPatron(email: $email, empId: $empId) {
            departmentName
            id
            patronNameWId
            name
            fullAddress
            lat
            lng
            emergencyContact
            email
            departmentName
            blacklist {
                id
            }
            booking {
                status
            }
        }
    } 
  `
}

export { mutation, query };