import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import EcommerceHeader from 'app/main/ecommerce/header/EcommerceHeader';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import WishlistContent from 'app/main/ecommerce/wishlist/content/WishlistContent';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { useState } from "react";

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .ps__rail-y": {
        display: "none !important"
    },
    "& .FusePageSimple-contentWrapper": {
        overscrollBehavior: 'auto'
    }
}));

function Wishlist(props) {

    const [cartClick, setCarClick] = useState(false);

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <EcommerceHeader cartClick={cartClick} setCarClick={setCarClick}/>
                <Root
                    content={
                        <div className="max-w-1366px min-w-lg m-auto">
                            <WishlistContent cartClick={cartClick} setCarClick={setCarClick}/>
                        </div>
                    }

                />
            <div className="pt-48">
                <EcommerceFooter />
            </div>
        </div>
    );
}

export default Wishlist;

