const mutation = {
    createCrmTopics: `
        mutation createCrmTopics($data: JSONString!) {
            createCrmTopics (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    updateCrmTopics: `
        mutation updateCrmTopics($data: JSONString!) {
            updateCrmTopics (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    deleteCrmTopics: `
        mutation deleteCrmTopics($id: String!) {
            deleteCrmTopics (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getTopics: `
    query getTopics {
        getTopics {
            id
            title
            assignee
            assignees
            assigneeId
            subtopics{
                id
                topicsId
                subtopics
                isDel
                dateCreated
                dateUpdated
            }
            dateCreated
            dateUpdated
        }
    }
    `,
    getTopicsById: `
    query getTopicsById($topicsId: String) {
            getTopicsById(topicsId: $topicsId) {
                id
                title
                assignee
                assignees
                assigneeId
                subtopics{
                    id
                    topicsId
                    subtopics
                    isDel
                    dateCreated
                    dateUpdated
                }
                dateCreated
                dateUpdated
            }
        }
    `,
}

export { mutation, query };