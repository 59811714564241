const mutation = {
    create: `
        mutation createItemCategory($data: JSONString!) {
            createItemCategory (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    edit: `
        mutation editItemCategory($data: JSONString!) {
            editItemCategory (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    delete: `
        mutation deleteItemCategory($id: String!) {
            deleteItemCategory (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    saveItemSearch: `
        mutation saveEcommerceItemSearches($data: JSONString!) {
            saveEcommerceItemSearches (data: $data) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getItemCategoryList: `
    query itemCategoryList {
        itemCategoryList {
          id,
          name,
          dateCreated,
          dateUpdated
        }
      }
  `,
    listPaginate: `
    query itemsListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int, 
        $category: String!, 
        $type: String!, 
        $order: String!, 
        $status: String,
        $catArr: String,
        $minSrp: Float,
        $maxSrp: Float,
        $fromPage: String
    ){
        itemsListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            category: $category, 
            type: $type, 
            order: $order, 
            status: $status,
            catArr: $catArr,
            minSrp: $minSrp,
            maxSrp: $maxSrp,
            fromPage: $fromPage
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {            
                id,
                code,
                status,
                type,
                barcode,
                category,
                department,
                name,
                description,
                cost,
                srp,
                supplier,
                initialSupply,
                featuredImageId,
                featuredImagePath,
                photoUrl,
                dateCreated,
                dateUpdated,
                stock,
                dateReceive
            }
        }
    }
  `,
  listPaginateNoPhoto: `
    query itemsListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int, 
        $fromDate: String!, 
        $toDate: String!, 
        $category: String!, 
        $type: String!, 
        $order: String!, 
        $status: String, 
        $catArr: String
    ){
        itemsListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            fromDate: $fromDate, 
            toDate: $toDate, 
            category: $category, 
            type: $type, 
            order: $order, 
            status: $status, 
            catArr: $catArr
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {            
                id,
                code,
                status,
                type,
                barcode,
                category,
                department,
                name,
                description,
                cost,
                srp,
                supplier,
                dateCreated,
                dateUpdated,
                stock,
                dateReceive
            }
        }
    }
  `,
}

export { mutation, query };