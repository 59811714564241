import * as yup from "yup";

import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';

import { motion } from "framer-motion";

import FuseLoading from "@fuse/core/FuseLoading";
import FusePageCarded from "@fuse/core/FusePageCarded";

import { showMessage } from "app/store/fuse/messageSlice";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import BookingHeader from 'app/main/booking/header/BookingHeader';
import RegisterContent from 'app/main/booking/register/content/RegisterContent';

import { submitRegister, checkPatron } from "app/main/booking/store/registerSlice";
import { usePrompt } from "app/services/leaveRoute";

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    "& .FusePageCarded-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));


const defaultValues = {
    patronId: "",
    patronType: "",
    status: "Enable",
    idNum: "",
    firstName: "",
    lastName: "",
    middleName: "",
    departmentName: "",
    departmentId: "",
    gender: "",
    birthdate: null,
    emergencyContact: "",
    email: "",
    phone: "",
    address1: "",
    lat: "",
    lng: "",
    address2: "",
    country: "United States",
    city: "Majuro",
    state: "Marshall Islands",
    zipCode: "96960",
    notes: "",
    createdFromBooking: "true",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    profilePicId: "",
    profilePicPath: "",
    images: [],
};

function Register(props) {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("Email is required")
            .test({
                message: () => 'Email is already exists.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkPatron({ keyword: id }));
                    }
                },
            }),
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        idNum: yup
            .string()
            .required("Barcode/ID No. is required")
            .test({
                message: () => 'Barcode/ID No. is already exists.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkPatron({ keyword: id }));
                    }
                },
            }),
        departmentName: yup.string().required("Department is required"),
        phone: yup
            .string()
            .required('Phone Number is required')
            .test("len", "Invalid Phone No.", (val) => {
                if (val) {
                    const val_length_without_dashes = val.replace(/-|_/g, "").length;
                    return (val_length_without_dashes === 3 || val_length_without_dashes === 10 || val_length_without_dashes === 13);
                } else {
                    return true;
                }
            })
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const { reset, watch, control, onChange, formState, getValues, trigger } = methods;
    const { isValid, dirtyFields, errors } = formState;
    const form = watch();

    usePrompt(Object.keys(dirtyFields).length);

    const handleSavePatron = async () => {

        await trigger(["email", "patronId", "name", "idNum", "phone", "departmentName"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        console.log("here");
        dispatch(submitRegister(getValues())).then((response) => {

            if (response.status == 'success') {
                dispatch(
                    showMessage({
                        message: response.message,
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
                reset(defaultValues);
                setTimeout(() => {
                    navigate("/booking");
                }, 1000);
            } else {
                dispatch(
                    showMessage({
                        message: response.message,
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        });

    }

    const handleCancelPatron = () => {
        reset(defaultValues);
    }

    return (
        <>
            <FormProvider {...methods}>
                {/* <BookingHeader /> */}
                <Root
                    header={<BookingHeader />}
                    content={
                        <div className="p-16 sm:p-24 max-w-md m-auto mb-56 mt-24 md:mt-88  bg-white shadow-md rounded-lg">
                            <motion.div
                                initial={{ x: -20 }}
                                animate={{ x: 0, transition: { delay: 0.3 } }}
                            >
                                <Typography variant="caption" className="text-3xl mb-5 font-bold">
                                    Registration
                                </Typography>
                            </motion.div>

                            <RegisterContent />

                            <div className="p-16 w-1/2 min-w-1200 m-auto">
                                <motion.div
                                    className="flex justify-center"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                >
                                    <Button
                                        className="whitespace-nowrap mx-4 rounded-md"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSavePatron}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="whitespace-nowrap mx-4 rounded-md"
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleCancelPatron}
                                    >
                                        Cancel
                                    </Button>
                                </motion.div>
                            </div>
                        </div>
                    }

                />


            </FormProvider>
        </>
    );
}

export default Register;

