// * Updated By: ICO
// * Summary: Change Expire date to Expiry Date label
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-40150-96
// * DateUpdated:  11/15/2022

import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import CheckoutTable from 'app/main/ecommerce/checkout/content/CheckoutTable';
import TextField from '@mui/material/TextField';

import { saveEcommerceOrders } from 'app/main/ecommerce/store/checkoutSlice';
import { showMessage } from "app/store/fuse/messageSlice";
import { IMaskInput } from "react-imask";

import { logoutUser } from "../../store/loginSlice";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import { PayPalButton } from "react-paypal-button-v2";
import CONFIG from "app/config";

const TextMaskCardNumber = forwardRef(function TextMaskCardNumber(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="0000-0000-0000-0000"
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const TextMaskExpiry = forwardRef(function TextMaskExpiry(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="00/00"
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    number: yup
        .string()
        .required("Credit Card is required")
        .test("len", "Credit Card is invalid", (val) => {
            if (val) {
                return val.length >= 19
            }
        }),
    name: yup
        .string()
        .required("Name is required"),
    expiry: yup
        .string()
        .required("Card Expiry date is required")
        .test("len", "Card is expired.", (val) => {
            if (val) {
                var year = val.split("/")?.[1]

                if (year != "__") {
                    var yearnow = new Date().getFullYear().toString().substr(-2);

                    return parseInt(yearnow) <= parseInt(year);
                }

                return true;
            }
        }),
    code: yup
        .string()
        .required("Security Code is required")
        .test("len", "Security Code is invalid.", (val) => {
            if (val) {
                return val.length >= 4
            }
        }),

});

function CheckoutContent() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            number: '',
            name: '',
            expiry: '',
            code: '',
            paypalTransactionId: '',
            paypalTransactionAmount: 0,
        },
        resolver: yupResolver(schema),
    });

    const { reset, watch, control, onChange, formState, getValues, setValue, trigger } = methods;
    const { isValid, dirtyFields, errors } = formState;

    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [user, setUser] = useState('');
    const [orderClick, setOrderClick] = useState(false);

    useEffect(() => {

        if (localStorage.getItem('ecommerce_checkout_data') == 'false') {
            navigate(`/e-commerce/cart`);
        }

        const checkoutData = JSON.parse(localStorage.getItem('ecommerce_checkout_data'));

        setItems(checkoutData.items);
        setTotal(checkoutData.total);
        setUser(checkoutData.user);
        EcommerceJwtService.setJwtLoginReload(false);     

    }, [dispatch]);

    async function handleClickOrder() {

        // await trigger(['number', 'name', 'expiry', 'code'])

        // if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
        //     return dispatch(
        //         showMessage({
        //             message: "Please fill in the required fields.",
        //             autoHideDuration: 5000, //ms
        //             anchorOrigin: {
        //                 vertical: "bottom",
        //                 horizontal: "right",
        //             },
        //             variant: "error",
        //         })
        //     );
        // }

        var card = getValues();

        setOrderClick(true);

        const checkoutData = {
            items: items,
            total: total,
            customer: user.data.displayName,
            customerId: user.id,
            shippingAddress: user.address,
            cardNumber: card.number,
            cardName: card.name,
            cardExpiry: card.expiry,
            cardSecCode: card.code,
            paypalTransactionId: card.paypalTransactionId,
            paypalTransactionAmount: card.paypalTransactionAmount
        }

        dispatch(saveEcommerceOrders(checkoutData)).then((action) => {
            if (action.payload.status == 'success') {
                swal({
                    title: " Thank you!",
                    text: "Your order is confirmed.",
                    icon: "success",
                    closeOnClickOutside: false,
                }).then((resp) => {
                    if (resp) {
                        localStorage.setItem('ecommerce_checkout_data', 'false');
                        navigate(`/e-commerce/orders`);
                    }
                });
            }

            dispatch(
                showMessage({
                    message: action.payload["message"],
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: action.payload["status"],
                })
            );

            setOrderClick(false);

            if (action.payload.res == 'not_found') {
                dispatch(logoutUser());                
            }
        });


    }

    return (
        <div className="px-24 max-400px:px-10 pb-48">
            <div role="presentation" className="py-20 font-medium">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important', fontWeight: 'bold' }} color="inherit" href="/e-commerce/cart" >
                        <Icon sx={{ mr: 0.5 }} fontSize="inherit" >keyboard_backspace</Icon> Go Back to Cart
                    </Link>

                </Breadcrumbs>
            </div>
            <div className="flex flex-wrap -m-2">
                <div className="w-full bg-white border-1 border-1-gray-50 border-opacity-5 mb-16 px-24 max-400px:px-10 py-12 max-400px:py-8 relative">
                    <Typography className="font-semibold text-18">Payment Details</Typography>
                    <div className=" flex flex-row flex-wrap max-600px:flex-col">
                        <div className="sm:w-full md:w-3/5 sm:pr-0 md:pr-40">
                            <Typography className="font-bold text-15">Your Order</Typography>
                            <CheckoutTable items={items} total={total} />
                        </div>
                        <Stack className="sm:w-full md:w-2/5 md:mt-0 sm:mt-16 ">
                            <Typography className="font-bold text-15">Payment Method (Note: Paypal transaction fee will added to your total value.)</Typography>
                            <PayPalButton
                                amount={(Math.round(((total ? total : 0)+((((total ? total : 0)*0.035) + 0.30)) + ((((total ? total : 0)*0.035) + 0.30) *0.035 +  + 0.30)) * 100) / 100)}
                                onSuccess={(details, pdata) => {
                                    console.log("details", details);
                                    console.log("pdata", pdata);
                                    setValue('paypalTransactionId', details?.id);
                                    setValue('paypalTransactionAmount', (Math.round(((total ? total : 0)+((((total ? total : 0)*0.035) + 0.30)) + ((((total ? total : 0)*0.035) + 0.30) *0.035 +  + 0.30)) * 100) / 100));
                                    setTimeout(function() {
                                        handleClickOrder();
                                    }, 500);
                                }}
                                options={{
                                    clientId: CONFIG.paypalClientID
                                }}
                            />
                            {/* <Typography className="">CREDIT CARD</Typography>
                            <Controller
                                name="number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-10"
                                        size="medium"
                                        type="text"
                                        placeholder="Card Number"
                                        variant="outlined"
                                        error={!!errors.number}
                                        required
                                        helperText={errors?.number?.message}
                                        InputProps={{
                                            inputComponent: TextMaskCardNumber,
                                        }}
                                    />
                                )}
                            />
                            <Typography className="">CARDHOLDER NAME</Typography>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-10"
                                        size="medium"
                                        type="text"
                                        placeholder="Card Name"
                                        variant="outlined"
                                        error={!!errors.name}
                                        required
                                        helperText={errors?.name?.message}

                                    />
                                )}
                            />
                            <Stack spacing={2} direction="row" className=" md:justify-between">
                                <div>
                                    <Typography className=" uppercase">Expiry Date</Typography>
                                    <Controller
                                        name="expiry"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mb-10"
                                                size="medium"
                                                type="text"
                                                placeholder="MM/YY"
                                                variant="outlined"
                                                error={!!errors.expiry}
                                                required
                                                helperText={errors?.expiry?.message}
                                                InputProps={{
                                                    inputComponent: TextMaskExpiry,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div>
                                    <Typography className="">SECURITY CODE</Typography>
                                    <Controller
                                        name="code"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="mb-10"
                                                size="medium"
                                                type="text"
                                                placeholder=""
                                                variant="outlined"
                                                error={!!errors.code}
                                                required
                                                inputProps={{ maxLength: 4 }}
                                                helperText={errors?.code?.message}
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        color: "rgb(0 184 212 / var(--tw-border-opacity))",
                                                        marginLeft: "0px"
                                                    }
                                                }}

                                            />
                                        )}
                                    />
                                </div> 
                            </Stack>
                            <div className=" text-right my-16">
                                <Button className=" rounded-md" variant="contained" color="primary" size="large" onClick={handleClickOrder} disabled={orderClick}>
                                    PLACE ORDER NOW
                                </Button>
                            </div> */}
                        </Stack>
                    </div>


                </div>
            </div>
        </div>
    )

}

export default CheckoutContent;