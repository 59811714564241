// * Updated By: ICO
// * Summary:  Responsive issues
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-52526-96
// * DateUpdated:  05/29/2023

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import { Typography } from '@mui/material';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { deleteCartItem } from '../../store/cartSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import Checkbox from '@mui/material/Checkbox';
import globalFunction from 'app/services/globalFunction';

const Root = styled('div')`
  td,
  th {
    border: unset;
    padding-top:10px;
    padding-bottom:10px;
  }

  th {
    background-color: #FFFF;
  }
`;

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];

export default function StickyHeadTable({ cartList, setCartList, deleteClick, setDeleteClick, selected, handleSelectAllClick, handleCheck, cartClick, setCarClick }) {

    const dispatch = useDispatch();

    function handleClickDelete(item) {

        swal({
            title: "Do you want to delete this item?",
            text: item.name + ' - ' + item.quantity,
            icon: "warning",
            closeOnClickOutside: false,
            buttons: {
                confirm: {
                    text: "Yes",
                    className: "bg-blue-500",
                    value: true,
                    visible: true,
                    closeModal: true
                },
                cancel: {
                    text: "No",
                    className: "bg-red-500 text-white border-0 hover:bg-red-300",
                    value: false,
                    visible: true,
                    closeModal: true
                }
            }
        }).then((resp) => {
            if (resp) {
                dispatch(deleteCartItem(item.id)).then((action) => {
                    dispatch(
                        showMessage({
                            message: "Item has been successfully deleted from the cart.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "success",
                        })
                    );
                    setDeleteClick(!deleteClick);
                    setCarClick(!cartClick);
                });
            }
        });
    }

    return (
        <Root>
            <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "unset", border: "unset", boxShadow: "unset" }}>
                <TableContainer sx={{ marginBottom: { md: 18, sm: 18, xs: 20 } }} className="max-h-screen-50 min-h-xs overscroll-auto  overflow-auto">
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>
                                <TableCell className='pb-0 truncate'>
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < cartList.length}
                                        checked={cartList.length !== 0 && selected.length === cartList.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableCell>
                                <TableCell className='pb-0 truncate'>
                                    Product
                                </TableCell>
                                <TableCell className='pb-0 truncate' align='right'>
                                    Unit Price
                                </TableCell>
                                <TableCell className='pb-0 truncate' align='center'>
                                    Quantity
                                </TableCell>
                                <TableCell className='pb-0 truncate' align='right'>
                                    Total Price
                                </TableCell>
                                <TableCell className='pb-0 truncate' align='right'>
                                    Actions
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                cartList.length ? cartList.map((item, index) => {
                                    const isSelected = selected.indexOf(item.id) !== -1;
                                    return (
                                        <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                                            <TableCell className='font-bold max-w-sm'>
                                                <Checkbox
                                                    checked={isSelected}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleCheck(event, item.id)}
                                                />
                                            </TableCell>
                                            <TableCell className='font-bold max-w-sm'>
                                                <Stack direction="row" spacing={1} alignItems="center" className='-ml-5'>
                                                    <div style={{ width: 80, height: 80, minWidth: 80 }} cols={1} className="overflow-hidden mr-10 border-1 rounded-md">

                                                        <img
                                                            className="max-w-none w-full h-full object-contain"
                                                            src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                                            loading="lazy"
                                                        />
                                                    </div>
                                                    <Typography className="font-bold min-w-128">
                                                        {item.name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align='right' className='font-bold'>${item.price}</TableCell>
                                            <TableCell align='right' className='font-bold'>
                                                <div className='rounded-md flex justify-center items-center h-auto align-middle border-1 m-auto border-grey-400 w-fit'>
                                                    <Button
                                                        contained="text"
                                                        className='text-indigo-900 h-40 p-0 min-w-36 rounded-none font-medium text-20 text-center'
                                                        onClick={() => {
                                                            item.quantity = (item.quantity ? item.quantity : 0)
                                                            if (item.quantity > 1) {
                                                                var tempCartList = [];

                                                                cartList.map(function (value, i) {
                                                                    if (index === i) {
                                                                        value.quantity = parseInt(value.quantity) - 1;
                                                                        value.total = parseInt(value.quantity) * parseFloat(item.price);
                                                                    }

                                                                    tempCartList.push(value);
                                                                });

                                                                setCartList(tempCartList);
                                                            }
                                                        }}
                                                    >
                                                        -
                                                    </Button>
                                                    <TextField
                                                        className="mb-0 text-17 font-bold appearance-none"
                                                        type="number"
                                                        placeholder='QTY'
                                                        id="item_quantity"
                                                        variant="outlined"
                                                        onKeyDown={event => globalFunction().handlePreventCharacters(event, ['e', 'E', '-', '+'])}
                                                        onPaste={globalFunction().handlePasteNumericOnly}
                                                        onChange={(ev) => {
                                                            var tempCartList = [];

                                                            cartList.map(function (value, i) {
                                                                if (index === i) {
                                                                    var qaun = parseInt(ev.target.value);
                                                                    if (qaun > 0) {
                                                                        value.quantity = ev.target.value;
                                                                    }

                                                                    if (qaun > item.stock) {
                                                                        value.quantity = item.stock;
                                                                    }

                                                                    if (!qaun) {
                                                                        value.quantity = ev.target.value;
                                                                    }

                                                                    value.total = parseInt((value.quantity ? value.quantity : 0)) * parseFloat(item.price);
                                                                }

                                                                tempCartList.push(value);
                                                            });

                                                            setCartList(tempCartList);
                                                        }}
                                                        value={item.quantity}
                                                        sx={{
                                                            '& #item_quantity': {
                                                                fontSize: '1.5rem',
                                                                fontWeight: '500',
                                                                textAlign: 'center',
                                                                width: '5rem',
                                                                padding: '0px'

                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                border: "unset !important"
                                                            }
                                                        }}
                                                    />
                                                    <Button
                                                        contained="text"
                                                        className='text-indigo-900 h-40 p-0 min-w-36 rounded-none font-medium text-20 text-center '
                                                        onClick={() => {
                                                            item.quantity = (item.quantity ? item.quantity : 0)
                                                            if (item.quantity < item.stock) {
                                                                var tempCartList = [];

                                                                cartList.map(function (value, i) {
                                                                    if (index === i) {
                                                                        value.quantity = parseInt((value.quantity ? value.quantity : 0)) + 1;
                                                                        value.total = parseInt((value.quantity ? value.quantity : 0)) * parseFloat(item.price);
                                                                    }

                                                                    tempCartList.push(value);
                                                                });

                                                                setCartList(tempCartList);
                                                            }
                                                        }}
                                                    >
                                                        +
                                                    </Button>
                                                </div>

                                            </TableCell>
                                            <TableCell align='right' className='font-bold'>${parseFloat(item.total).toFixed(2)} </TableCell>
                                            <TableCell align='right' className='font-bold'>
                                                <IconButton
                                                    size="large"
                                                    onClick={() => {
                                                        handleClickDelete(item);
                                                    }}
                                                >
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) : null
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </Root>
    );
}