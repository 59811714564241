import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/checkout_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

const token = EcommerceJwtService.getAccessToken();

export const saveEcommerceOrders = createAsyncThunk(
    "ecommerceApp/productPage/saveEcommerceOrders",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveEcommerceOrders,
            variables: { data: JSON.stringify(data) },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                } 
                
            }
        });

        return response.data.data.saveEcommerceOrders;
    }
);

const checkoutSlice = createSlice({
    name: "ecommerceApp/checkout",
    initialState: null,
    reducers: {
        resetCheckout: () => null,
        newCheckout: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newCheckout, resetCheckout } = checkoutSlice.actions;

export default checkoutSlice.reducer;
