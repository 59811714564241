import * as yup from 'yup';
import _ from '@lodash';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import PasswordStrengthBar from "react-password-strength-bar";
import InputMask from 'react-input-mask';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from '@mui/material/FormHelperText';
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { showMessage } from "app/store/fuse/messageSlice";
import { createEcommerceUsers, checkEcommerceUsername } from "app/main/ecommerce/store/registerSlice";
import { format } from 'date-fns';


const defaultValues = {
    firstName: "",
    lastName: "",
    username: "",
    contact: "",
    isActive: 0,
    status: 'Pending',
    ecommerce: 1,
    email: "",
    password: "",
    role: "ecommerce",
    group: [],
    roleitems: [],
    roles: [],
    gender: "",
    phone: "",
    mobilephone: "",
    address1: "",
    address2: "",
    country: "United States",
    countrySelect: {
        name: "United States"
    },
    stateSelect: {
        name: "Marshall Islands"
    },
    city: "Majuro",
    state: "Marshall Islands",
    zipcode: "96960",
    hiredate: null,
    birthdate: null,
    terminationdate: null,
    featuredImageId: "",
    images: []
};

function RegisterModal(props) {

    const dispatch = useDispatch();

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        email: yup
            .string()
            .email("You must enter a valid email")
            .required("Email is required")
            .test({
                message: () => 'Email is already exists.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkEcommerceUsername({ keyword: id }));
                    }
                },
            }),
        password: yup
            .string()
            .required("Password is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        passwordConfirm: yup
            .string()
            .required("Password (Confirm) is required")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
        lastName: yup.string().required("Last name is required"),
        firstName: yup.string().required("First name is required"),
        username: yup.string()
            .required("Username is required")
            .test({
                message: () => 'Username is already exists.',
                test: async (id) => {
                    if (id) {
                        return dispatch(checkEcommerceUsername({ keyword: id }));
                    }
                },
            }),
        phone: yup
            .string()
            .required('Phone Number is required')
            .test("len", "Invalid Phone No.", (val) => {
                if (val) {
                    const val_length_without_dashes = val.replace(/-|_/g, "").length;
                    return (val_length_without_dashes === 3 || val_length_without_dashes === 10 || val_length_without_dashes === 13);
                } else {
                    return true;
                }
            }),
        mobilephone: yup
            .string()
            .required('Mobile Phone Number is required')
            .test("len", "Invalid Mobile Phone No.", (val) => {
                if (val) {
                    const val_length_without_dashes = val.replace(/-|_/g, "").length;
                    return (val_length_without_dashes === 3 || val_length_without_dashes === 10 || val_length_without_dashes === 13);
                } else {
                    return true;
                }
            })
    });

    const { openDialogRegister, handleDialogRegisterOpen, handleDialogRegisterClose } = props;

    const { control, formState, handleSubmit, reset, setValue, watch, getValues, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    const password = watch("password");

    const [showPassword, setShowPassword] = useState(false);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    async function generateUsername() {
        var rug = require("random-username-generator");
        rug.setSeperator("");

        setValue("username", rug.generate());

        await trigger(["username"]);
    }


    async function handleSave() {

        const result = await trigger(["username", "email", "password", "passwordConfirm", "firstName", "lastName", "phone", "mobilephone"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        var data = getValues();

        data.birthdate = data.birthdate ? format(new Date(data.birthdate), 'yyyy-MM-dd') : null;

        dispatch(createEcommerceUsers(data)).then(function () {
            reset(defaultValues);
        });

    }

    useEffect(() => {

        reset(defaultValues);

    }, [openDialogRegister]);

    return (
        <>
            <Link className="font-normal" to="" onClick={() => handleDialogRegisterOpen()}>
                Sign up
            </Link>

            <Dialog
                open={openDialogRegister}
                onClose={handleDialogRegisterClose}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogContent classes={{ root: 'p-16 pb-0 sm:pt-24  sm:p-48' }}>
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => handleDialogRegisterClose()}
                            size="large"
                            className='float-right'
                        >
                            <Icon>close</Icon>
                        </IconButton>

                        <form
                            name="registerForm"
                            noValidate
                            className="flex flex-col justify-center w-full mb-36 max-600px:mb-20"
                        >
                            <Typography variant="h6" className=" font-semibold text-18 sm:text-24 text-center">
                                Create an account
                            </Typography>
                            <Typography className="mt-16 mb-36 text-center">
                                Let's get you all set up so you can verify your personal account and begin setting up your profile.
                            </Typography>

                            <Controller
                                name="username"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextField
                                            {...field}
                                            error={!!errors.username}
                                            required
                                            helperText={errors?.username?.message}
                                            id="username"
                                            variant="outlined"
                                            label="Username"
                                            fullWidth
                                        />
                                        <motion.div
                                            className="flex mx-16 mr-0"
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                        >
                                            <Button
                                                className="whitespace-nowrap mx-4 max-h-52 rounded-md"
                                                variant="contained"
                                                onClick={generateUsername}
                                            >
                                                <Icon className="">settings_backup_restore</Icon>
                                            </Button>
                                        </motion.div>
                                    </div>


                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        error={!!errors.email}
                                        required
                                        helperText={errors?.email?.message}
                                        label="Email"
                                        id="email"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        label="Password"
                                        type="password"
                                        name="password"
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputProps={{
                                            className: "pr-2",
                                            type: showPassword ? "text" : "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        size="large"
                                                    >
                                                        <Icon className="text-20" color="action">
                                                            {showPassword ? "visibility" : "visibility_off"}
                                                        </Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                name="passwordConfirm"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        label="Password (Confirm)"
                                        type="password"
                                        error={!!errors.passwordConfirm}
                                        helperText={errors?.passwordConfirm?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputProps={{
                                            className: "pr-2",
                                            type: showPasswordConfirm ? "text" : "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                        size="large"
                                                    >
                                                        <Icon className="text-20" color="action">
                                                            {showPasswordConfirm ? "visibility" : "visibility_off"}
                                                        </Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                            {password && (
                                <PasswordStrengthBar
                                    password={password}
                                    shortScoreWord="Password is short"
                                    scoreWords={[
                                        "Password is weak",
                                        "Password is weak",
                                        "Password is okay",
                                        "Password is good",
                                        "Password is strong",
                                    ]}
                                />
                            )}

                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        error={!!errors.firstName}
                                        required
                                        helperText={errors?.firstName?.message}
                                        label="First Name"
                                        id="firstName"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mt-16"
                                        error={!!errors.lastName}
                                        required
                                        helperText={errors?.lastName?.message}
                                        label="Last Name"
                                        id="lastName"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="gender"
                                control={control}
                                render={({ field }) => (
                                    <div className="mt-16">
                                        <FormLabel id="gender">Gender</FormLabel>
                                        <RadioGroup {...field} aria-label="gender" name="gender1" row color={"red"}>
                                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                            <FormControlLabel
                                                value="Female"
                                                control={<Radio />}
                                                label="Female"
                                            />
                                        </RadioGroup>
                                        {/* <FormHelperText className='mt-0 mb-10' error={!!errors.gender}>{errors?.gender?.message}</FormHelperText> */}
                                    </div>
                                )}
                            />

                            <Controller
                                name="birthdate"
                                control={control}
                                render={({ field }) => (
                                    <div className="mt-16">
                                        <FormLabel id="birthdate">Birthdate</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(field) => (
                                                    <TextField {...field} className="w-full"
                                                        variant='outlined' />
                                                )}
                                                onChangeRaw={() => {
                                                    setFieldTouched(field.name, true, true);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                )}
                            />

                            <Controller
                                control={control}
                                name="phone"
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        mask="(999) 999-9999"
                                        maskPlaceholder="(000) 000-0000"
                                    >
                                        {() =>
                                            <TextField
                                                {...field}
                                                className="mt-16"
                                                label="Phone"
                                                id="phone"
                                                variant="outlined"
                                                required
                                                error={!!errors.phone}
                                                helperText={errors?.phone?.message}
                                                fullWidth
                                            />
                                        }
                                    </InputMask>
                                )}
                            />

                            <Controller
                                control={control}
                                name="mobilephone"
                                render={({ field }) => (
                                    <InputMask
                                        {...field}
                                        mask="(999) 999-9999"
                                        maskPlaceholder="(000) 000-0000"
                                    >
                                        {() =>
                                            <TextField
                                                {...field}
                                                className="mt-16"
                                                label="Mobile Phone"
                                                id="mobilephone"
                                                variant="outlined"
                                                required
                                                error={!!errors.mobilephone}
                                                helperText={errors?.mobilephone?.message}
                                                fullWidth
                                            />
                                        }
                                    </InputMask>
                                )}
                            />

                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className="w-full mx-auto mt-16 rounded-md h-48"
                                aria-label="LOG IN"
                                value="legacy"
                                onClick={handleSave}
                            >
                                Register
                            </Button>
                        </form>

                        <div className="flex flex-col sm:flex-row items-center justify-center max-600px:items-start max-600px:mb-20 ">
                            <span className="font-normal mr-16">Are you already a member?</span>

                            <Link className="font-normal" to="" onClick={() => handleDialogRegisterClose()}>
                                Log in
                            </Link>
                        </div>
                    </motion.div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default RegisterModal;
