import AcademyAppConfig from './academy/AcademyAppConfig';
import CalendarAppConfig from './calendar/CalendarAppConfig';
import ChatAppConfig from './chat/ChatAppConfig';
import ContactsAppConfig from './contacts/ContactsAppConfig';
import AnalyticsDashboardAppConfig from './dashboards/analytics/AnalyticsDashboardAppConfig';
import ProjectDashboardAppConfig from './dashboards/project/ProjectDashboardAppConfig';
import ECommerceAppConfig from './e-commerce/ECommerceAppConfig';
import CrmAppConfig from './crm/CrmAppConfig';
import FileManagerAppConfig from './file-manager/FileManagerAppConfig';
import MailAppConfig from './mail/MailAppConfig';
import NotesAppConfig from './notes/NotesAppConfig';
import ScrumboardAppConfig from './scrumboard/ScrumboardAppConfig';
import TodoAppConfig from './todo/TodoAppConfig';
import SettingsAppConfig from './settings/SettingsAppConfig';
import InventoryAppConfig from './inventory/InventoryAppConfig';
import PatronAppConfig from './patron/PatronAppConfig';
import UserAccountAppConfig from './user-account/UserAccountAppConfig';
import StoreAppConfig from './store/StoreAppConfig';
import BookingAppConfig from './booking/BookingAppConfig';
import VendorAppConfig from './meal-vendors/VendorAppConfig';
import CardsAppConfig from './cards/CardsAppConfig';
import ResidenceHallAppConfig from './residence-hall/ResidenceHallAppConfig';
import MotorPoolAppConfig from './motorpool/MotorPoolAppConfig';
import PatrolAppConfig from './patrol/PatrolAppConfig';
import BusManagementAppConfig from './bus-management/BusManagementAppConfig';
import BusCardsAppConfig from './bus-management/bm-bus-tickets/BusCardsAppConfig';

const appsConfigs = [
    AnalyticsDashboardAppConfig,
    ProjectDashboardAppConfig,
    MailAppConfig,
    TodoAppConfig,
    FileManagerAppConfig,
    ContactsAppConfig,
    CalendarAppConfig,
    ChatAppConfig,
    ECommerceAppConfig,
    CrmAppConfig,
    ScrumboardAppConfig,
    AcademyAppConfig,
    NotesAppConfig,
    SettingsAppConfig,
    InventoryAppConfig,
    UserAccountAppConfig,
    PatronAppConfig,
    StoreAppConfig,
    BookingAppConfig,
    VendorAppConfig,
    CardsAppConfig,
    ResidenceHallAppConfig,
    MotorPoolAppConfig,
    PatrolAppConfig,
    BusManagementAppConfig,
    BusCardsAppConfig
];

export default appsConfigs;
