import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Divider } from "@mui/material";
const TableUnstyled = styled('table')`
  

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

`;
function PrintStatusContent({ printRef, form }) {

    return (
        <>
            <div className='hidden'>
                <div ref={printRef}>
                    <div className="header-print-posting">
                        <div className='float-left m-10 p-10 '>
                            <Typography className="text-18 font-semibold">
                                CMI OSS SYSTEM
                            </Typography>
                            <Typography className=" font-semibold text-16">Booking Reference Number:  <span className="font-bold"> {form.referenceNum}</span></Typography>
                        </div>
                        <div className='float-right m-10 p-10 '>
                            <div className="logo">
                                <img width="110" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className='max-w-lg m-auto mx-24 -mt-3'>
                       
                        <Stack direction="column" >
                            <Typography className="mt-0">Time of Booking: {form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}</Typography>
                            <Typography className="mt-5">Start: {form.start ? format(new Date(form.start), 'EEEE, MMMM dd, yyyy') : ""} {form.start ? format(new Date(form.start), 'hh:mm a') : ""} - {form.end ? format(new Date(form.end), 'hh:mm a') : ""}</Typography>
                            <Stack direction="row" className=" justify-between items-center mt-7 mb-5">
                                <Typography>Duration: {form.duration}</Typography>
                                
                            </Stack>
                            <TableUnstyled>
                                <tbody>
                                    <tr >
                                        <td width="50%">Name: <span className="font-bold"> {form.name}</span></td>
                                        <td width="50%">Email: <span className="font-bold">{form.email}</span></td>
                                    </tr>
                                    <tr >
                                        <td width="50%">Department: <span className="font-bold">{form.department}</span></td>
                                        <td width="50%">Contact: <span className="font-bold">{form.contact}</span></td>
                                    </tr>
                                </tbody>
                            </TableUnstyled>

                            <Typography className=" font-semibold uppercase mt-10 mb-10">Booking Details</Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <div style={{ width: 90, height: 90 }} className="overflow-hidden rounded-md">
                                    
                                    <img className="max-w-none w-full h-full object-contain"  src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                    
                                </div>
                                <Stack >
                                    <Typography className=''>
                                    {form.itemCode} |  {form.itemName}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider className=" mt-16" />
                            <Typography className=" font-semibold uppercase mt-10 mb-10">purpose</Typography>
                            <Typography className=" leading-4"> {form.purpose}</Typography>
                            <Typography className=" font-semibold uppercase mt-20 ">approved by:</Typography>
                            
                        {form.approvee && (
                            <>
                                {
                                    form.approvee.map(function (approvee) {
                                        return (
                                            approvee.dateApproval ? <>
                                                <Typography className=" capitalize mt-10">Position: <b>{approvee.position}</b></Typography>
                                                <TableUnstyled className="mb-10" key={approvee.id}>
                                                    <tbody>
                                                        <tr >
                                                            <td width="50%">Name: <span className="font-bold">{approvee.name}</span></td>
                                                            <td width="50%">Date of Approval: <span className="font-bold">{approvee.dateApproval}</span></td>
                                                        </tr>
                                                        <tr >
                                                            <td width="50%">Department: <span className="font-bold">{approvee.department}</span></td>
                                                            <td width="50%">Status: <span className="font-bold"> {approvee.status}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </TableUnstyled>
                                            </> : null
                                        )
                                    })
                                }
                            </>
                        )}
                        </Stack>
                        {/* <PrintStatusContent printRef={printRef} form={form} /> */}
                        
                        <div className=" flex flex-col justify-center ">
                          
                            <div className='text-15 text-center font-semibold'>
                                <Typography  component="div"
                                className={"my-2 rounded-md font-bold tracking-wide text-14 p-1 py-10 text-white " +
                                (form.status == "Rejected" ? "bg-red-600 hover:bg-red-700" : "") +
                                (form.status == "Cancelled" ? "bg-pink-600 hover:bg-pink-700" : "") +
                                (form.status.includes("Approved") ?  "bg-blue-600 hover:bg-blue-700 " : "") +
                                (form.status  == "Approved - For Pre-Inspection" ? "bg-purple-600 hover:bg-purple-700" : "") +
                                (form.status == "Expired" ?"bg-gray-600 hover:bg-gray-700" : "") +
                                (form.status == "Pending" ? "bg-yellow-600 hover:bg-yellow-700" : "") +
                                (form.status.includes("In-Use") ? "bg-orange-600 hover:bg-orange-700" : "") +
                                (form.status == "Done" ? "bg-green-600 hover:bg-green-700" : "") +
                                (form.status == "For Return" ? "bg-black" : "")
                            }>
                                    Your request for booking is {form.status}
                                </Typography>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default PrintStatusContent;