import {
	createSlice,
	createAsyncThunk,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
	query,
	mutation,
} from "app/main/apps/residence-hall/graphql/rooms_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

export const getRooms = createAsyncThunk(
	"residenceHallApp/room-management/getRooms",
	async (params) => {
		const response = await axios.post(CONFIG.API + "/api/", {
			query: query.list,
            variables: params
		});
		var data = response.data.data.roomList;

		return data;
	}
);

export const getRoomById = createAsyncThunk(
    "residenceHallApp/room-management/getRoomById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getRoomByID,
            variables: {
                roomId: params.roomId,
            },
        });

        if (response.data.data.roomById) {
            var data = response.data.data.roomById;

            data.roomAmenities = (data.roomAmenities ? JSON.parse(data.roomAmenities) : []);

            return data === undefined ? null : data;
        }
    }
);

export const removeRooms = createAsyncThunk(
	"residenceHallApp/room-management/removeRooms",
	async (roomIds, { dispatch, getState }) => {

		await roomIds.map(async (id) => {
			await axios.post(CONFIG.API + "/api/", {
				query: mutation.delete,
				variables: {
					id: id,
				},
			});
		});

		dispatch(
			showMessage({
				message: "Room(s) has been successfully deleted.",
				autoHideDuration: 5000, //ms
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
				variant: "success",
			})
		);

		return roomIds;
	}
);

const uploadImages = (uploadData, roomData, image) => {

    return new Promise(async (resolve, reject) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutationUpload.create,
            variables: {
                data: JSON.stringify(uploadData),
            },
        });

        if (response.data.data.createUpload.status == "success") {
            var upload = response.data.data.createUpload.upload;

            if (roomData.roomPicId == image.image.id) {
                roomData.roomPicPath = upload.path;
            }

            roomData.images[image.index].upload_id = upload.id;
            roomData.images[image.index].file_name = upload.fileName;
            roomData.images[image.index].file_id = upload.fileId;
            roomData.images[image.index].file_type = upload.fileType;
            roomData.images[image.index].path = upload.path;
            roomData.images[image.index].module = upload.module;
            roomData.images[image.index].url = "";

            resolve(roomData);

        }

    });

}

const saveImages = (roomData) => {

    return new Promise(async (resolve, reject) => {

        if (roomData.images.length) {

            var newImages = [];

            roomData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (roomData.roomPicId == image.fileId) {
                        roomData.roomPicPath = image.path;
                    }
                }

            });

            if (newImages.length) {

                var tempCount = 0;

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "residence-hall/room/room-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'residence-hall-room';
                            uploadData['file_id'] = image.image.id;

                            await uploadImages(uploadData, roomData, image).then(async (roomData) => {

                                if (roomData) {
                                    tempCount++;
                                    if (newImages.length == tempCount) {
                                        resolve(roomData);
                                    }
                                }

                            });
                        }


                    });

                });

            } else {
                resolve(roomData)
            }


        } else {

            resolve(roomData);

        }

    });

}

export const saveRoom = createAsyncThunk(
    "residenceHallApp/room-management/saveRoom",
    async (roomData, { dispatch, getState }) => {

        roomData.roomAmenities = (roomData.roomAmenities ? JSON.stringify(roomData.roomAmenities) : '');

        return await saveImages(roomData).then(async (roomData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.save,
                variables: {
                    data: JSON.stringify(roomData),
                    id: roomData?.id,
                },
            });

            dispatch(
                showMessage({
                    message: response.data.data.saveRoom.message,
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.saveRoom.status
                })
            );
            
            return response.data.data.saveRoom;

        });

    });

const roomAdapter = createEntityAdapter({});

export const {
	selectAll: selectRooms,
	selectById: selectRoomsById,
} = roomAdapter.getSelectors((state) => state.residenceHallApp.rooms);

const roomSlice = createSlice({
	name: "residenceHallApp/room-management",
	initialState: roomAdapter.getInitialState({
		searchText: "",
		showModal: false,
	}),
	reducers: {
		setRoomsSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[getRooms.fulfilled]: roomAdapter.setAll,
		[removeRooms.fulfilled]: (state, action) =>
			roomAdapter.removeMany(state, action.payload),
		[saveRoom.fulfilled]: (state, action) => action.payload,
	},
});

export const { setRoomsSearchText } = roomSlice.actions;

export default roomSlice.reducer;
