import { combineReducers } from '@reduxjs/toolkit';
import users from './usersManagementSlice';
import user from './userManagementSlice';
import topics from './topicsSlice';
import tickets from './ticketsSlice';
import report from './reportSlice';
import replyButtons from './replyButtonSlice';

const reducer = combineReducers({
    topics,
    user,
    tickets,
    report,
    users,
    replyButtons
});

export default reducer;
