import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Types = lazy(() => import('./types/Types'));
const Card = lazy(() => import('./card/Card'));
const CardTransactions = lazy(() => import('./transaction/CardTransactions'));
const CardLists = lazy(() => import('./list/CardLists'));

const BusCardsAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/bus-tickets/types',
            element: <Types />,
            auth: ["bus-tks-type"],
        },
        {
            path: 'apps/bus-tickets/:cardId',
            element: <Card />,
            auth: ["bus-tks-new"],
        },
        {
            path: 'apps/bus-tickets/lists',
            element: <CardLists />,
            auth: ["bus-tks-mg"],
        },
        {
            path: 'apps/bus-tickets/transactions',
            element: <CardTransactions />,
            auth: ["bus-tks-tran"],
        },
    ],
};

export default BusCardsAppConfig;
