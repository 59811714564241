const mutation = {
    save: `
        mutation saveHouses($data: JSONString!) {
            saveHouses (data: $data) {
                status
                res
                message
            }
        }
    `,
    delete: `
        mutation deleteHouse($id: String!) {
            deleteHouse (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query houseList {
            houseList {
                id
                houseName
                houseMother
                houseAdmins
                houseDetails
                houseAddress
                houseContact
                houseRoomNumber
                houseStatus
                housePicId
                housePicPath
                dateCreated
                dateUpdated
            }
        }
    `,
    listItems: `
    query itemCategoryList($typeId: String, $type: String) {
        itemCategoryList(typeId: $typeId, type: $type) {
            id,
            name,
            typeId,
            type,
            dateCreated,
            dateUpdated
        }
    }
    `
}

export { mutation, query };