const mutation = {
    createNotifications: `
      mutation createNotifications($data: JSONString!) {
          createNotifications (data: $data) {
            status,
            res,
            message
          }
      }
  `,
    createCrmTickets: `
        mutation createCrmTickets($data: JSONString!) {
            createCrmTickets (data: $data) {
                status,
                ticketId,
                ticketNum,
                message
            }
        }
    `,
    saveCrmDiscussion: `
        mutation saveCrmDiscussion($data: JSONString!) {
            saveCrmDiscussion (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    updateCrmTickets: `
        mutation updateCrmTickets($data: JSONString!) {
            updateCrmTickets (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    getTopics: `
        query getTopics {
            getTopics {
                id
                title
                assignee
                assignees
                assigneeId
                subtopics{
                    id
                    topicsId
                    subtopics
                    isDel
                    dateCreated
                    dateUpdated
                }
                dateCreated
                dateUpdated
            }
        }
    `,
    getTickets: `
        query getTickets($status: String!, $userId: String) {
            getTickets (status: $status, userId: $userId){
                id
                ticketNum
                topics
                userId
                userName
                topicsId
                topicsAssignee
                subject
                message
                status
                statusView
                statusViewDate
                status
                dateCreated
                dateUpdated
            }
        }
    `,
    getTicketsAdmin: `
        query getTicketsAdmin($keyword: String!,$topic: String!,$assignee: String!,$assigneeTome: Boolean!,$status: String!,$type: String!,$userId: String,$department: String) {
            getTicketsAdmin (keyword: $keyword,topic: $topic,assignee: $assignee,assigneeTome: $assigneeTome,status: $status,type: $type,userId: $userId, department: $department){
                id
                ticketNum
                topics
                userId
                userName
                topicsId
                topicsAssignee
                topicsAssignees
                subject
                message
                status
                statusView
                statusViewDate
                status
                dateCreated
                dateUpdated
            }
        }
    `,
    getTicketById: `
        query getTicketById($id: String!) {
            getTicketById(id: $id) {
                id
                userId
                topicsId
                ticketNum
                subject
                status
                message
                topics
                topicsAssignee
                topicsAssigneeId
                topicsAssigneesRaw
                attachments {
                    id
                    fileId
                    fileName
                    fileType
                    fileSize
                    path
                    url
                    dateCreated
                    dateUpdated
                }
                discussions {
                    id
                    userId
                    userName
                    userPhoto
                    message
                    attachments {
                        id
                        fileId
                        fileName
                        fileType
                        fileSize
                        path
                        url  
                        dateCreated
                        dateUpdated      
                    }
                    dateCreated
                    dateUpdated
                }
                dateCreated
                dateUpdated
            }
        }
    `,
    getHouses: `        
        query houseList {
            houseList {
                id
                houseName
                houseMother
                houseAdmins
                houseDetails
                houseAddress
                houseContact
                houseRoomNumber
                houseStatus
                housePicId
                housePicPath
                dateCreated
                dateUpdated
            }
        }
    `,
    getRooms: `        
        query roomList($houseId: String, $amenities: String, $status: String) {
            roomList(houseId: $houseId, amenities: $amenities, status: $status) {
                id
                houseId
                roomNumber
                roomFloor
                roomStatus
                roomCapacity
                roomRate
                roomAmenities
                roomNotes
                roomPicId
                roomPicPath   
                roomCapacityStatus         
                dateCreated
                dateUpdated
            }
        }
    `
        
}

export { mutation, query };