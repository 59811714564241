import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Home = lazy(() => import('./home/Home'));
const Orders = lazy(() => import('./orders/Orders'));
const EcommerceConfig = lazy(() => import('./config/EcommerceConfig'));
const UserManage = lazy(() => import('./user-management/manage-account/Manage'));
const Users = lazy(() => import('./user-management/user/Users'));
const Sales = lazy(() => import('./sales-promos/Sales'));
const CloudSearch = lazy(() => import('./cloud-search/CloudSearch'));
const OrderReviews = lazy(() => import('./reviews/OrderReviews'));
const Reports = lazy(() => import('./reports/Reports'));

const ECommerceAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/e-commerce/home-page',
            element: <Home />,
            auth: ["eco-hp"],
        },
        {
            path: 'apps/e-commerce/orders',
            element: <Orders />,
            auth: ["eco-or"],
        },
        {
            path: 'apps/e-commerce/configuration',
            element: <EcommerceConfig />,
        },
        {
            path: 'apps/e-commerce/manage',
            element: <UserManage />,
            auth: ["eco-um"],
        },
        {
            path: 'apps/e-commerce/user/:usersId/*',
            element: <Users />,
            auth: ["eco-um"],
        },
        {
            path: 'apps/e-commerce/sales-n-promos',
            element: <Sales />,
            auth: ["eco-sp"],
        },
        {
            path: 'apps/e-commerce/cloud-search',
            element: <CloudSearch />,
            auth: ["eco-cs"],
        },
        {
            path: 'apps/e-commerce/reviews',
            element: <OrderReviews />,
            auth: ["eco-rw"],
        },
        {
            path: 'apps/e-commerce/reports',
            element: <Reports />,
            auth: ["eco-rw"],
        }
    ],
};

export default ECommerceAppConfig;
