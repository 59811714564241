/*****
* Module: Product View
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-39758-96
* DateUpdated: 11/15/2022
* Updated By: JF
* Update Summary: update checkout function
*/
import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import { getItems } from "app/main/ecommerce/store/productSlice";
import EcommerceHeader from 'app/main/ecommerce/header/EcommerceHeader';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import ProductContent from "./content/ProductContent";
import { useEffect, useState } from "react";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import { saveEcommerceCart, viewItem } from 'app/main/ecommerce/store/productSlice';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showMessage } from "app/store/fuse/messageSlice";
import { logoutUser } from "../store/loginSlice";

const Root = styled(FusePageSimple)(({ theme }) => ({
    "& .FusePageSimple-topBg": {
        background: "unset"
    },

    minHeight: "unset"
    ,
    "& .FusePageSimple-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

function Product(props) {

    const token = EcommerceJwtService.getDecodedAccessToken();
    const [item, setItem] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routeParams = useParams();

    const { itemId } = routeParams;

    useEffect(function () {
        dispatch(viewItem(itemId));
    }, [dispatch])

    async function check() {
        console.log("Check Reload", new Date(), item);
        // setLoading(true);
        // dispatch(getItems(itemId)).then((action) => {
        //     if (action.payload) {
        //         console.log(action.payload);
        //         setItem(action.payload);
        //     }
        //     // setLoading(false);
        // });
    }

    useEffect(() => {
        check()
    }, []);

    setInterval(check, 60000);

    const [cartClick, setCarClick] = useState(false);

    function handleClickCart(item, quantity, status) {

        if (item.stock == 0 && status != 'wishlist') {
            return dispatch(
                showMessage({
                    message: "Item is out of stock",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: 'error',
                })
            );
        }

        if (quantity == 0 && status != 'wishlist') {
            return dispatch(
                showMessage({
                    message: "Quantity must not be equal to 0.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: 'error',
                })
            );
        }

        var orderStatus = status == 'checkout' ? 'cart' : status;
        var price = ((item.itemDiscount.discount && item.itemDiscount.isActive) ? item.itemDiscount.price : item.srp);
        var total = (parseFloat(price) * parseInt(quantity));

        var data = {
            orderStatus: orderStatus,
            customer: token?.data?.displayName,
            customerId: token?.id,
            shippingAddress: token?.address1,
            itemId: item.id,
            price: price,
            name: item.name,
            description: item.description,
            quantity: quantity,
            discount: item.itemDiscount.isActive ? item.itemDiscount.discount : 0,
            origPrice: item.srp,
            total: total,
        };

        if (status == 'checkout') {
            var checkoutData = {
                user: {
                    ...token
                },
                items: [data],
                total: total
            }

            localStorage.setItem('ecommerce_checkout_data', JSON.stringify(checkoutData));
            navigate(`/e-commerce/checkout`);
        } else {
            dispatch(saveEcommerceCart(data)).then(function (action) {
                setCarClick(!cartClick);

                dispatch(
                    showMessage({
                        message: action.payload["message"],
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: action.payload["status"],
                    })
                );

                if (action.payload.res == 'not_found') {
                    dispatch(logoutUser());
                }

            });;
        }
    }

    function handleClickCartMultiple(items) {
        items.map((item) => {
            if (item.isCheck) {

                var orderStatus = 'cart';
                var price = ((item.itemDiscount.discount && item.itemDiscount.isActive) ? item.itemDiscount.price : item.srp);
                var total = (parseFloat(price) * parseInt(1));

                var data = {
                    orderStatus: orderStatus,
                    customer: token?.data?.displayName,
                    customerId: token?.id,
                    shippingAddress: token?.address1,
                    itemId: item.id,
                    price: price,
                    name: item.name,
                    description: item.description,
                    quantity: 1,
                    discount: item.itemDiscount.isActive ? item.itemDiscount.discount : 0,
                    origPrice: item.srp,
                    total: total,
                };

                dispatch(saveEcommerceCart(data)).then(function (action) {
                    setCarClick(!cartClick);

                    if (action.payload.res == 'not_found') {
                        dispatch(logoutUser());
                    }
                });;

                dispatch(
                    showMessage({
                        message: "Item(s) has been successfully added to cart",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            }
        })
    }

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <EcommerceHeader cartClick={cartClick} setCarClick={setCarClick} />
            <Root
                content={
                    <div className="max-w-1366px m-auto">
                        <ProductContent handleClickCart={handleClickCart} handleClickCartMultiple={handleClickCartMultiple} item={item} setItem={setItem} />
                    </div>
                }

            />
            <EcommerceFooter />
        </div>
    );
}

export default Product;

