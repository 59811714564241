import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getCategory } from "app/main/ecommerce/store/homeSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import OrdersTable from 'app/main/ecommerce/orders/content/OrdersTable';
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import { getOrderList } from "app/main/ecommerce/store/ordersSlice";


export default function OrdersContent() {
    const [status, setStatus] = React.useState(0);
    const token = EcommerceJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);

    const statuses = {
        0: 'All',
        1: 'To Ship',
        2: 'Shipping',
        3: 'Completed',
        4: 'Cancelled',
    }

    useEffect(() => {
        setLoading(true);
        dispatch(getOrderList({
            status: statuses[status],
            customerId: token.id,
            limit: 10,
            page: page 
        })).then((action) => {
            

            setOrders(action.payload.objects)
            setPages(action.payload.pages)

            setLoading(false);
        });
    }, [dispatch, page, status]);

    const handleChange = (event, newValue) => {
        setStatus(newValue);
    };

    return (
        <div className="px-24 pb-48">

            <div role="presentation" className="py-20 font-medium">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important' }} color="inherit" href="/e-commerce/home" >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    </Link>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary" >
                        My Orders
                    </Typography>
                </Breadcrumbs>
            </div>
            <div className="flex flex-wrap -m-2">
                <div className="w-full bg-white border-1 border-1-gray-50 border-opacity-5 mb-16 px-24 py-12 relative ...">
                    <Typography className="font-semibold text-18">My Orders</Typography>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Tabs value={status} onChange={handleChange} >
                            <Tab label="All" />
                            <Tab label="To Ship" />
                            <Tab label="To Receive" />
                            <Tab label="Completed" />
                            <Tab label="Cancelled" />
                        </Tabs>
                    </Box>
                    <OrdersTable orders={orders} pages={pages} setPages={setPages} page={page} setPage={setPage} status={status} loading={loading}/>
                </div>
            </div>
        </div>
    );

}

