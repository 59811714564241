import * as React from 'react';
import _ from "@lodash";
import reducer from "app/main/apps/crm/store";
import withReducer from "app/store/withReducer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";

import CrmHeader from 'app/main/crm/header/CrmHeader';
import CrmFooter from 'app/main/crm/footer/CrmFooter';
import StatusContent from './content/StatusContent';
import CrmMotorpoolBookingTabs from '../booking/CrmMotorpoolBookingTabs';

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .FusePageSimple-contentWrapper": {
        maxWidth: "1366px",
        margin: "10px auto",
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));
function CrmMotorpoolBookingStatus(props) {

    return (
        <div className="flex flex-col flex-auto min-w-0 relative z-10 h-full">
            <CrmHeader />
            <Root
                contentToolbar={
                    <div className="px-12 w-full">
                        <CrmMotorpoolBookingTabs menu='status' />
                    </div>
                }
                content={
                    <div className="max-w-1366px m-auto">

                        <StatusContent />

                    </div>

                }
            />
            <CrmFooter />
        </div>
    );
}

export default withReducer("crmApps", reducer)(CrmMotorpoolBookingStatus);
