import { lazy } from 'react';
import { authRoles } from 'app/auth';
import { Navigate } from 'react-router-dom';

const CrmTopics = lazy(() => import('./topics/CrmTopics'));
const CrmDashboard = lazy(() => import('./dashboard/CrmDashboard'));
const CrmReplyButtons = lazy(() => import('./reply_buttons/CrmReplyButtons'));
const CrmReport = lazy(() => import('./report/CrmReport'));

const CrmAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: '/apps/crm/dashboards/:folderHandle',
            element: <CrmDashboard />,
            children: [{ path: ':ticketId', element: <CrmDashboard /> }],
            auth: ["crm-dash"],
        },
        {
            path: 'apps/crm/dashboards',
            element: <Navigate to="/apps/crm/dashboards/all-tickets" />,
            auth: ["crm-dash"],
        },
        {
            path: 'apps/crm/topics',
            element: <CrmTopics />,
            auth: ["crm-tpc"],
        },
        {
            path: 'apps/crm/report/:reportType',
            element: <CrmReport />,
            auth: ["crm-rpt"],
        },
        {
            path: 'apps/crm/reply/buttons',
            element: <CrmReplyButtons />,
            auth: ["crm-dash"],
        },
    ],
};

export default CrmAppConfig;
