import { authRoles } from 'app/auth';
import Cart from './cart/Cart';
import Orders from './orders/Orders';
import Wishlist from './wishlist/Wishlist';
import Checkout from './checkout/Checkout';
import MyAccount from './my_account/MyAccount';

const EcommerceConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.ecommerce,
    routes: [
        {
            path: 'e-commerce/cart',
            element: <Cart />,
        },
        {
            path: 'e-commerce/orders',
            element: <Orders />,
        },
        {
            path: 'e-commerce/wishlist',
            element: <Wishlist />,
        },
        {
            path: 'e-commerce/checkout',
            element: <Checkout />,
        },
        {
            path: 'e-commerce/my-account',
            element: <MyAccount />,
        },
    ],
};

export default EcommerceConfig;
