import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: 'inherit!important',
  textDecoration: 'none!important',
  height: 40,
  width: '100%',
  borderRadius: 6,
  paddingLeft: 12,
  paddingRight: 12,
  marginBottom: 4,
  '&.active': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, .05)!important'
        : 'rgba(255, 255, 255, .1)!important',
    pointerEvents: 'none',
    '& .list-item-icon': {
      color: 'inherit',
    },
  },
  '& .list-item-icon': {
    fontSize: 16,
    width: 16,
    height: 16,
    marginRight: 16,
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function CrmUsersProfileSidebar({ crmUsers }) {


  return (
    <div className="p-0 lg:pl-24 lg:ltr:pr-4 lg:rtl:pl-4">
      <Paper
        component={motion.div}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
        className="rounded-0 shadow-none lg:rounded-16 lg:shadow bg-white"
      >
        <div className="rounded-0 flex items-center">
          <StyledAppBar
            position="static"
            color="primary"
            className="user bg-[url('img/bg-menu.png')] relative flex flex-col items-center justify-center pt-24 pb-84 mb-32 z-0 shadow-0 rounded-tl-2xl rounded-tr-2xl"
          >
            <div className="flex items-center justify-center absolute bottom-0 -mb-44">
              {/* <Avatar
                className="avatar w-72 h-72 p-8 box-content"
                alt="user photo"
                src={'assets/images/avatars/profile.jpg'}
              /> */}
              <Avatar className="w-72 h-72 p-8 box-content text-5xl bg-grey-400">{crmUsers.name[0]}</Avatar>
            </div>
          </StyledAppBar>
        </div>

        <div className="p-24 text-center">
          <Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="black">
            {crmUsers.name}
          </Typography>
          <Typography
            className="email text-13 opacity-50 whitespace-nowrap font-medium"
            color="black"
          >
            {crmUsers.email}
          </Typography>
          <Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="black">
            {crmUsers.departmentName}
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default CrmUsersProfileSidebar;
