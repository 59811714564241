
// * Updated By: ICO
// * Summary: Customize Google Map Marker Pin Design
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-38708-96
// * DateUpdated:  11/11/2022

import Tooltip from '@mui/material/Tooltip';
import GoogleMap from 'google-map-react';
import CONFIG from "../../config";

function Marker({ text }) {
    return (
        <Tooltip title={text} placement="top">
            <img src="assets/images/customPin_v1.png" width="40" height="40" style={{
                marginTop: "-5.8rem",
                marginLeft: "-2.3rem"
            }} />
        </Tooltip>
    );
}

function Maps({ location, zoomLevel = 17, handleClickMaps, mapType }) {

    console.log("location", location);

    const mapOptions = maps => {
        return {
            mapTypeId: maps.MapTypeId.HYBRID,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_CENTER,
            },
            zoomControl: true,
            zoomControlOptions: {
                position: maps.ControlPosition.LEFT_CENTER,
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: maps.ControlPosition.LEFT_TOP,
            },
            fullscreenControl: false,
        };
    };

    var centerLocation = [7.1026295, 171.3759513];

    if (location.data?.length) {
        centerLocation = [location.data[0].lat, location.data[0].lng];   
    } else {
        centerLocation = [location.lat, location.lng];
    }

    if (!location.multi) {
        location.lat = location.lat ? location.lat : 7.10262568638697;
        location.lng = location.lng ? location.lng : 171.3782997355861;

        centerLocation = [location.lat, location.lng];
    }

    return (
        <div className="w-full h-320">
            <GoogleMap
                bootstrapURLKeys={{
                    key: CONFIG.MAPS,
                    mapTypeId: 'satellite'
                }}
                zoomControl={false}
                scaleControl={true}
                defaultZoom={zoomLevel}
                center={centerLocation}
                onClick={handleClickMaps}
                options={mapOptions}
            >
                {
                    location.multi ?
                        location?.data?.map((loc, index) => {
                            return <Marker key={index} text={loc.address ? loc.address : ''} lat={loc.lat} lng={loc.lng} />
                        })
                        : <Marker text={location.address ? location.address : ''} lat={location.lat} lng={location.lng} />
                }
            </GoogleMap>
        </div>
    );
}

export default Maps;