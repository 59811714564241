import { combineReducers } from '@reduxjs/toolkit';
import houses from './houseManagementSlice';
import house from './houseSlice';
import rooms from './roomManagementSlice';
import tenant from './tenantSlice';
import tenantsListView from './tenantsListViewSlice';
import announcement from './announcementSlice';
import visit from './visitRequestSlice';
import postInspection from './postInspectionSlice';
import crmInbox from './crmInboxSlice';

const reducer = combineReducers({
  houses,
  house,
  rooms,
  tenant,
  tenantsListView,
  announcement,
  visit,
  postInspection,
  crmInbox
});

export default reducer;
