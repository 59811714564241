import { motion } from 'framer-motion';
import { styled, lighten } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import LoginTab from 'app/main/crm/login/content/LoginTab';
import { useEffect } from 'react';
import CrmJwtService from 'app/services/crm/CrmJwtService';
import { useNavigate } from 'react-router-dom';

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.primary.white,
  color: theme.palette.primary.contrastText,

  '& .Login-leftSection': {},

  '& .Login-rightSection': {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
      theme.palette.primary.dark,
      0.1
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function CrmSsoLogin() {
  useEffect(function() {
    CrmJwtService.setJwtLoginReload(false);
    window.location.replace('https://id.quicklaunch.io/samlsso?tenantDomain=cmiprod.edu&spEntityID=https://cmi.medisource.com')
  }, []);
  return (
    <Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 lg-1180:p-24">
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 lg-1180:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className="Login-leftSection flex flex-col w-full max-w-md items-center justify-center shadow-0 pt-28"
          square
        >
          <CardContent className="flex flex-col pt-28 items-center justify-center w-full py-5 max-w-320" sx={{ p: 0, '&:last-child': { pb: '6rem' } }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <div className="flex items-center mb-10">
                {/* <img className="logo-icon" src="assets/images/logos/cmi-logo-college.png" alt="logo" /> */}
                <img className=" w-200" src="assets/images/logos/CMI_CRM_LOGO_v1.svg" alt="logo" />
              </div>
            </motion.div>
            <LoginTab />

          </CardContent>
        </Card>

        <div className="Login-rightSection hidden lg-1180:flex flex-1 " >
          <div className="hidden max-w-none w-full h-full bg-contain bg-no-repeat lg-1180:flex flex-1 p-64 bg-[url('img/CRM-LOGIN.png')]  max-1245px:p-52">
            <div className="max-w-fit">
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
              >
                <Typography variant="h2" color="inherit" className="font-semibold leading-tight -mt-12">
                  Welcome to
                </Typography>
                <Typography variant="h5" color="inherit" className="font-semibold leading-tight text-orange-300">
                    CMI Customer Relationship Management
                </Typography>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.3 } }}
              >
                {/* <Typography variant="subtitle1" color="inherit" className="text-20 mt-24">
                  Powerful and professional admin template for <br></br> Web Applications, CRM, CMS,<br></br>  Admin
                  Panels and more.
                </Typography> */}
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </Root>
  );
}

export default CrmSsoLogin;
