const mutation = {
    approvedVisit: `
        mutation approvedVisit($data: JSONString!) {
            approvedVisit (data: $data) {
                status
                res
                message
            }
        }
    `,
    delete: `
        mutation deleteVisit($id: String!) {
            deleteVisit (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query getTicketsAdmin($residenceHall: Boolean, $status: String!) {
            getTicketsAdmin (residenceHall: $residenceHall, status: $status){
                id
                ticketNum
                topics
                userId
                userName
                topicsId
                topicsAssignee
                topicsAssignees
                subject
                message
                status
                statusView
                statusViewDate
                status
                tenant {
                    year
                    semester
                }
                room {
                    roomFloor
                }
                houseId
                roomId
                dateCreated
                dateUpdated
            }
        }
    `,
}

export { mutation, query };