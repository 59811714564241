// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./print.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./tables.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./prism.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Component classes registered by plugins.
 *
 */

.container{

    width: 100%
}

@media (min-width: 299px){

    .container{

        max-width: 299px
    }
}

@media (min-width: 400px){

    .container{

        max-width: 400px
    }
}

@media (min-width: 600px){

    .container{

        max-width: 600px
    }
}

@media (min-width: 640px){

    .container{

        max-width: 640px
    }
}

@media (min-width: 899px){

    .container{

        max-width: 899px
    }
}

@media (min-width: 960px){

    .container{

        max-width: 960px
    }
}

@media (min-width: 1024px){

    .container{

        max-width: 1024px
    }
}

@media (min-width: 1180px){

    .container{

        max-width: 1180px
    }
}

@media (min-width: 1280px){

    .container{

        max-width: 1280px
    }
}

@media (min-width: 1366px){

    .container{

        max-width: 1366px
    }
}

@media (min-width: 1468px){

    .container{

        max-width: 1468px
    }
}

@media (min-width: 1515px){

    .container{

        max-width: 1515px
    }
}

@media (min-width: 1568px){

    .container{

        max-width: 1568px
    }
}

@media (min-width: 1780px){

    .container{

        max-width: 1780px
    }
}

@media (min-width: 1920px){

    .container{

        max-width: 1920px
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/app-components.css","<no source>"],"names":[],"mappings":"AAAA;;;EAGE;;AAQF;;ICXA;ADWoB;;AAApB;;ICXA;;QAAA;IDqBI;AAVgB;;AAApB;;ICXA;;QAAA;ID6BI;AAlBgB;;AAApB;;ICXA;;QAAA;IDqCI;AA1BgB;;AAApB;;ICXA;;QAAA;ID6CI;AAlCgB;;AAApB;;ICXA;;QAAA;IDqDI;AA1CgB;;AAApB;;ICXA;;QAAA;ID6DI;AAlDgB;;AAApB;;ICXA;;QAAA;IDqEI;AA1DgB;;AAApB;;ICXA;;QAAA;ID6EI;AAlEgB;;AAApB;;ICXA;;QAAA;IDqFI;AA1EgB;;AAApB;;ICXA;;QAAA;ID6FI;AAlFgB;;AAApB;;ICXA;;QAAA;IDqGI;AA1FgB;;AAApB;;ICXA;;QAAA;ID6GI;AAlGgB;;AAApB;;ICXA;;QAAA;IDqHI;AA1GgB;;AAApB;;ICXA;;QAAA;ID6HI;AAlHgB;;AAApB;;ICXA;;QAAA;IDqII;AA1HgB","sourcesContent":["/**\n * Component classes registered by plugins.\n *\n */\n\n@import 'print.css';\n\n@import 'tables.css';\n\n@import 'prism.css';\n\n@tailwind components;\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
