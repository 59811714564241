import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../grahpql/driver_management_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import { format } from "date-fns";

export const createUpdateDriver = createAsyncThunk(
    "motorpoolApp/booking/driver/createUpdateDriver",
    async (params, { dispatch, getState }) => {

        const variables = {
            query: mutation.createUpdateDriver,
            variables: { data: JSON.stringify(params) },
        };

        if (params.isCrm) {
            variables.publicUrl = 'crm';
        }

        const response = await axios.post(`${CONFIG.API}/api/`, variables);

        const data = response.data.data.createUpdateDriver

        if (data.status == 'success') {
            return dispatch(
                showMessage({
                    message: data.message,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            )
        } else {
            return dispatch(
                showMessage({
                    message: "Something went wrong please try again later.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            )
        }
    }
);

export const motorpoolDrivers = createAsyncThunk('motorpoolApp/booking/driver/motorpoolDrivers', async (type) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.motorpoolDrivers,
        variables: {
            type: type,
        }
    });

    if (response.data.data.motorpoolDrivers) {
        let data = response.data.data.motorpoolDrivers;

        data = data.map(item => ({
            ...item,
            licenseExpirationDate: !!item['licenseExpiration'] ? format(new Date(item['licenseExpiration']), 'MM/dd/yyyy ') : '',
            dateCreatedFormatted: !!item['dateCreated'] ? format(new Date(item['dateCreated']), 'MM/dd/yyyy ') : '',
        }));

        return data === undefined ? null : data;
    }
});

export const getMotorpoolDriverByLicenseNumber = createAsyncThunk('motorpoolApp/booking/driver/getMotorpoolDriverByLicenseNumber', async (licenseNumber) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getMotorpoolDriverByLicenseNumber,
        variables: {
            licenseNumber: licenseNumber,
        }
    });

    if (response.data.data.getMotorpoolDriverByLicenseNumber) {
        const data = response.data.data.getMotorpoolDriverByLicenseNumber;

        return data === undefined ? null : data;
    }
});

export const removeDriver = createAsyncThunk(
    'motorpoolApp/booking/driver/removeDriver',
    async (depIds, { dispatch, getState }) => {
        try {
            await Promise.all(depIds.map(async (id) => {
                await axios.post(CONFIG.API + "/api/", {
                    query: mutation.removeDriver,
                    variables: {
                        id: id
                    }
                });
            }));
            dispatch(
                showMessage({
                    message: "Driver(s) have been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );

            return depIds;
        } catch (error) {
            dispatch(
                showMessage({
                    message: "Failed to delete driver(s). Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            throw error;
        }
    }
);

const driverManagementSlice = createSlice({
    name: "motorpoolApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [motorpoolDrivers.fulfilled]: (state, action) => action.payload,
        [removeDriver.fulfilled]: (state, action) => action.payload,
    },
});

export default driverManagementSlice.reducer;
