const mutation = {
    create: `
      mutation createPatrons($data: JSONString!) {
          createPatrons (data: $data) {
          status,
          res,
          message
          }
      }
  `,
    edit: `
      mutation editPatrons($data: JSONString!) {
          editPatrons (data: $data) {
          status,
          res,
          message
          }
      }
  `,
    resetAllCredits: `
        mutation resetAllCredits($data: JSONString!) {
            resetAllCredits(data: $data) {
                status,
                res,
                message
            }
        }
    `,
    resetCreditsById: `
        mutation resetCreditsById($id: String!) {
            resetCreditsById (id: $id) {
                status,
                res,
                message
            }
        }
    `,
    delete: `
      mutation deletePatrons($id: String!) {
          deletePatrons (id: $id) {
              status,
              res,
              message            
          }
      }
  `,
    verifyEmail: `
        mutation verifyEmail($id: String!, $email: String!) {
            verifyEmail (id: $id, email: $email) {
                status,
                res,
                message            
            }
        }
    `,
    updatePatronsStatus: `
    mutation updatePatronsStatus($id: String!, $status: String!) {
        updatePatronsStatus (id: $id, status: $status) {
            status,
            res,
            message            
        }
    }
`,
    createCreditsPatron: `
      mutation createCreditsPatron($data: JSONString!) {
          createCreditsPatron (data: $data) {
            status,
            res,
            message
          }
      }
  `,
    fetchMyPoints: `
        mutation fetchMyPoints($id: String!) {
            fetchMyPoints (id: $id) {
                status
                res{
                    loyaltyCard{
                        cardType
                        scanType
                        cardNumber
                        expiry
                        expiryDur
                    }
                    busCard{
                        cardType
                        scanType
                        cardNumber
                        expiry
                        expiryDur
                    }
                    totalCardCredit
                    totalBookCredit
                    totalMealCredit
                    totalLoyaltyCard
                    loyaltyInvoice{
                        id
                        change
                        paid
                        change
                        customer
                        discount
                        invoiceNum
                        invoiceStatus
                        invoiceType
                        subTotal
                        due
                        grandTotal
                        cards {
                            id
                            cardType
                            invoiceId
                            price
                            quantity
                            discount
                            subTotal
                        }
                        patron{
                            id
                            name
                            departmentName
                            gender
                            profilePicUrl
                            patronType
                            patronId
                            idNum
                            gender
                            phone
                            email
                            birthdate
                            ssn
                            fullAddress
                        }
                    }
                    busInvoice{
                        id
                        change
                        paid
                        change
                        customer
                        discount
                        invoiceNum
                        invoiceStatus
                        invoiceType
                        subTotal
                        due
                        grandTotal
                        cards {
                            id
                            cardType
                            invoiceId
                            price
                            quantity
                            discount
                            subTotal
                        }
                        patron{
                            id
                            name
                            departmentName
                            gender
                            profilePicUrl
                            patronType
                            patronId
                            idNum
                            gender
                            phone
                            email
                            birthdate
                            ssn
                            fullAddress
                        }
                    }
                }
                message            
            }
        }
  `,
};

const query = {
    checkPatron: `
        query checkPatron(
            $keyword: String
        ) {
            checkPatron(
                keyword: $keyword
            ) {
                id
                idNum
                email
            }
        }
    `,
    list: `
        query patronsList {
        patronsList {
            id
            patronId
            patronType
            patronTypeId
            departmentName
            departmentId
            status
            idNum
            name
            gender
            birthdate
            emergencyContact
            email
            emailVerified
            phone
            ssn
            address1
            address2
            country
            city
            state
            zipCode
            notes
            field1
            field2
            field3
            field4
            field5
            profilePicId
            profilePicPath
            fullAddress
            totalCardCredits
            totalMealCredits
            totalBookCredits
            totalCredits
            totalPrevCredits
            dateCreated
            dateUpdated
            images {
            id,
            patronId,
            uploadId,
            fileName,
            fileType,
            fileId,
            module,
            path,
            url,
            dateCreated,
            dateUpdated
            }
        }
        }
    `,
    listPaginate: `
      query patronsListPaginate(
          $limit: Int, 
          $keyword: String,  
          $page: Int, 
          $type: String!, 
          $importbool: Boolean, 
          $order: String!
      ){
          patronsListPaginate(
              limit: $limit, 
              keyword: $keyword,  
              page: $page, 
              type: $type, 
              importbool: $importbool, 
              order: $order
          ){
              page
              pages
              hasNext
              hasPrev
              totalItems
              itemsPerPage
              objects {        
                id
                patronId
                patronType
                accountType
                patronTypeId
                departmentName
                departmentId
                status
                idNum
                name
                gender
                birthdate
                emergencyContact
                email
                notifiyingEmail
                emailVerified
                phone
                ssn
                address1
                address2
                country
                city
                state
                zipCode
                notes
                field1
                field2
                field3
                field4
                field5
                profilePicId
                profilePicPath
                fullAddress
                totalCardCredits
                totalMealCredits
                totalBookCredits
                totalCredits
                totalPrevCredits
                dateCreated
                dateUpdated
                profilePicUrl
                mealCredits {
                    vendorId
                    vendorName
                    balance
                    prevBalance
                }
            }
          }
      }
    `,
    getPatronByID: `
        query patronById($patronId: String!) {
        patronById(patronId: $patronId) {
            id
            patronId
            patronType
            accountType
            patronTypeId
            departmentName
            departmentId
            status
            idNum
            name
            firstName
            lastName
            middleName
            gender
            birthdate
            emergencyContact
            email
            notifiyingEmail
            emailVerified
            phone
            ssn
            address1
            lat
            lng
            address2
            country
            city
            state
            zipCode
            notes
            field1
            field2
            field3
            field4
            field5
            profilePicId
            profilePicPath
            fullAddress
            dateCreated
            dateUpdated
            images {
                id,
                fileName,
                fileType,
                fileId,
                module,
                path,
                url,
                dateCreated,
                dateUpdated
            }
            fileId
            department
            division
            program
            title
            vaccinationDate
            idCreatedDate
            idIssuedDate
            idExpirationDate
            imageLink
            signatureLink
        }
    }
    `,
    getPatronType: `    
        query patronTypesList($order: String) {
            patronTypesList(order: $order) {
                id,
                name
            }
        }
    `,
    getCostumer: `
        query getCostumer($keyword: String, $limit: Int, $type: String) {
            getCostumer(keyword: $keyword, limit: $limit, type: $type) {
                id
                patronNameWId
                name
                fullAddress
                lat
                lng
                emergencyContact
                email
                departmentName
                departmentId
                blacklist {
                    id
                }
                booking {
                    status
                }
                tenantStatus
            }
        }
        
    `,
    checkPatronExists: `
        query checkPatronExists($value: String, $model: String, $id: String) {
            checkPatronExists (value: $value, model: $model, id: $id) {
                id
                idNum
                email
                patronId
            }
        }
    `,
    storeAllTransactions: `
      query storeAllTransactions(
          $limit: Int, 
          $patronId: String,  
          $page: Int, 
      ){
          storeAllTransactions(
              limit: $limit, 
              patronId: $patronId,  
              page: $page, 
          ){
              page
              pages
              hasNext
              hasPrev
              totalItems
              itemsPerPage
              objects {  
                invoiceNum
                dateFormatted
                items {
                    id
                    name
                }
                patron {
                    email
                }
                grandTotal
                dateFormatted
                paymentType
                change
                paid
                customer
                address
                discount
                invoiceStatus
                contact
                invoiceType
                subTotal
                due
                paidAt
                paidCash
                paidBook
                paidCard
                costumerNote
                globalNote
                globalHeader
                dateCreated
              }
            }
        }
    `,
}

export { mutation, query };