const mutation = {
    create: `
        mutation createBookingDepartments($data: JSONString!) {
            createBookingDepartments (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    edit: `
        mutation editBookingDepartments($data: JSONString!) {
            editBookingDepartments (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    delete: `
        mutation deleteBookingDepartments($id: String!) {
            deleteBookingDepartments (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
    query bookingDepartmentsList($order: String) {
        bookingDepartmentsList(order: $order) {
          id,
          name,
          head,
          position,
          dateCreated,
          dateUpdated
        }
      }
  `
}

export { mutation, query };