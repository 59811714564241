
/* DateUpdated: 04/03/23
* updatedBy: ico
* Summary: Task #1208:Email verification Design Concerns
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49908-96
*/
import * as yup from 'yup';
import _ from '@lodash';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from "@mui/material/FormControlLabel";

import { showMessage } from "app/store/fuse/messageSlice";
import { bookingFeedback, saveBookingFeedback } from "app/main/crm/store/bookingSlice";

const CardStyled = styled(Card)(() => ({
}));
const RadioGroupStyle = styled(RadioGroup)(({ theme }) => ({
    '& .muiltr-1uwxtuf-MuiButtonBase-root-MuiRadio-root.Mui-checked': {
        color: `${theme.palette.primary.dark} !important`

    }
}));

const defaultValues = {
    isSatisfied: "",
    notes: ""
};

function CrmBookingFeedbackContent() {

    const dispatch = useDispatch();

    const routeParams = useParams();

    const navigate = useNavigate();

    const [showBackButton, setShowBackButton] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(false);

    const { feedBackId, bookingId } = routeParams;

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        isSatisfied: yup
            .string()
            .required()
            .oneOf(['0', '1'], 'Selecting the gender field is required'),
    });

    const { control, formState, reset, getValues, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { errors, dirtyFields, isValid } = formState;


    async function handleSave(data) {
        var data = getValues();

        const result = await trigger(["isSatisfied"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        } else {
            dispatch(saveBookingFeedback(data)).then((action) => {
                if (action.payload.status != 'error') {
                    setShowBackButton(true);
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                }
            });
        }
    }

    useEffect(() => {
        setShowCancelButton(false);
        dispatch(bookingFeedback(feedBackId)).then((action) => {
            if(action.payload){
                reset(action.payload);
                setShowCancelButton(action.payload.isSatisfied == null ? false : true);
            }
        });
    }, [dispatch]);

    function backToHomePage() {
        navigate("/crmlogin");
    }

    return (
        <div className="w-full">
            <div className="p-9 pb-0 w-1/3 min-w-320 m-auto mb-48 mt-32 ">
                <CardStyled elevation={0} className="flex flex-col shadow-0 px-10 py-5">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <DialogContent classes={{ root: 'py-25 px-25 pb-0 ' }}>
                                <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>

                                    <form
                                        name="forgotPassForm"
                                        noValidate
                                        className="flex flex-col justify-center w-full mb-36"
                                    >
                                        {showBackButton && (
                                            <>
                                                <Typography variant="h6" className="text-center">
                                                    <Icon className="text-green text-6xl">check_circle_outline</Icon>
                                                </Typography>
                                                <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                                    Thankyou!
                                                </Typography>

                                                <Typography variant="h8" className="font-medium text-14 sm:text-14 text-center">
                                                    Thank you for your feedback. We really appreciate your time. Your feedback has been submitted.
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-full mx-auto mt-32 rounded-md h-48"
                                                    aria-label="LOG IN"
                                                    value="legacy"
                                                    onClick={() => backToHomePage()}
                                                >
                                                    Back to Homepage
                                                </Button>
                                            </>
                                        )}

                                        {showCancelButton && (
                                            <>
                                                <Typography variant="h6" className="text-center mt-16">
                                                    <Icon className="text-orange text-6xl">warning</Icon>
                                                </Typography>
                                                <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                                    Customer FeedBack
                                                </Typography>
                                                <Typography className="w-full font-medium text-14 sm:text-14 text-center">
                                                    You've already submmited your feedback. Thankyou!
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-full mx-auto mt-32 rounded-md h-48"
                                                    aria-label="LOG IN"
                                                    value="legacy"
                                                    onClick={() => backToHomePage()}
                                                >
                                                    Back to Homepage
                                                </Button>
                                            </>
                                        )}

                                        {!showBackButton && !showCancelButton && (
                                            <>
                                                <Typography variant="h6" className="font-semibold text-18 sm:text-24 text-center">
                                                    Customer Feedback
                                                </Typography>
                                                <Typography variant="h6" className="text-12 text-center">
                                                    Thank you for taking time to provide feedback. We appreciate hearing from you and will review your comments carefully.
                                                </Typography>

                                                <Typography variant="h6" className="text-12 text-center mt-16   ">
                                                    <span className="text-red-500">*</span> How would you rate our Booking Services?
                                                </Typography>

                                                <Controller
                                                    render={({ field }) => (
                                                        <div>
                                                            <RadioGroupStyle {...field} aria-label="isSatisfied" name="isSatisfied" row className="justify-center"
                                                                required>
                                                                <FormControlLabel
                                                                    value="1"
                                                                    control={<Radio />}
                                                                    label="Good, I'm satisfied"
                                                                />
                                                                <FormControlLabel
                                                                    value="0"
                                                                    control={<Radio />}
                                                                    label="Bad, I'm unsatisfied"
                                                                />
                                                            </RadioGroupStyle>
                                                        </div>
                                                    )}
                                                    name="isSatisfied"
                                                    control={control}
                                                />

                                                <Typography variant="h6" className="text-12 text-center mt-16   ">
                                                    Do you have any suggestions to improve our product and service?
                                                </Typography>

                                                <Controller
                                                    name="notes"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            id="notes"
                                                            type="text"
                                                            multiline
                                                            rows={5}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-full mx-auto mt-32 rounded-md h-48"
                                                    onClick={() => { handleSave() }}
                                                >
                                                    Submit
                                                </Button>
                                            </>
                                        )}
                                    </form>
                                </motion.div>
                            </DialogContent>
                        </Grid>
                    </Box>
                </CardStyled>
            </div>
        </div>
    );
}

export default CrmBookingFeedbackContent;
