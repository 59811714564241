// * Updated By: Jherome
// * Summary:  change New Stockstakes to "New Stocktakes", Stockstake Report to " Stocktake Report", Change Stockstake Settlement to "Stocktake Settlement"
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44147-96
// * DateUpdated:  01/03/2023

import { authRoles } from "app/auth";
import i18next from "i18next";
import DocumentationNavigation from "../main/documentation/DocumentationNavigation";

import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
    {
        id: "dashboards",
        title: "Dashboard",
        show: true,
        translate: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: "apps/dashboards/project",
    },
    {
        id: 'bus-management',
        title: 'Bus Management',
        show: true,
        translate: 'BUS_MANAGEMENT',
        type: 'collapse',
        icon: 'directions_bus',
        auth: ["bus-mng-fleet", "bus-mng-tlms", "bus-mng-routes", "bus-mng-mtnc-mtrg", "bus-mng-mtnc-rec", "bus-mng-mtnc-fuel", "bus-mng-inct-rep"],
        children: [
            {
                id: "bus-management-fleet",
                title: "Bus Fleet",
                show: true,
                type: "item",
                url: "apps/bus-management/bus-fleet",
                auth: ["bus-mng-fleet"],
            },
            {
                id: "bus-management-routes",
                title: "Routes",
                show: true,
                type: "item",
                url: "apps/bus-management/routes",
                auth: ["bus-mng-routes"],
            },
            {
                id: "bus-management-terminals",
                title: "Terminals",
                show: true,
                type: "item",
                url: "apps/bus-management/terminals",
                auth: ["bus-mng-tlms"],
            },
            {
                id: 'bus-management-maintenance',
                title: 'Bus Maintenance',
                show: true,
                translate: 'BUS_MAINTENANCE',
                type: 'collapse',
                auth: ["bus-mng-mtnc-mtrg", "bus-mng-mtnc-rec", "bus-mng-mtnc-fuel"],
                children: [
                    {
                        id: "bus-management-maintenence-monitoring",
                        title: "Monitoring",
                        show: true,
                        type: "item",
                        url: "apps/bus-management/maintenance-monitoring",
                        auth: ["bus-mng-mtnc-mtrg"],
                    },
                    {
                        id: "bus-management-maintenence-record",
                        title: "Maintenance Record",
                        show: true,
                        type: "item",
                        url: "apps/bus-management/maintenance-record",
                        auth: ["bus-mng-mtnc-rec"],
                    },
                    {
                        id: "bus-management-maintenence-fuel-management-record",
                        title: "Fuel Record",
                        show: true,
                        type: "item",
                        url: "apps/bus-management/fuel-management-record",
                        auth: ["bus-mng-mtnc-fuel"],
                    },
                ]
            },
            {
                id: 'bus-management-monitoring',
                title: 'Bus Monitoring',
                show: true,
                type: 'collapse',
                auth: ['bus-mng-live', 'bus-mng-trips'],
                children: [
                    {
                        id: "bus-management-monitoring-live",
                        title: "Live Monitoring",
                        show: true,
                        type: "item",
                        url: "apps/bus-management/bus-monitoring/live",
                        auth: ['bus-mng-live'],
                    },
                    {
                        id: "bus-management-monitoring-trips",
                        title: "Bus Trips",
                        show: true,
                        type: "item",
                        url: "apps/bus-management/bus-monitoring/trips",
                        auth: ['bus-mng-trips'],
                    },
                ]
            },
            {
                id: "bus-management-monitoring-tickets",
                title: "Bus Tickets",
                show: true,
                type: "collapse",
                auth: ["bus-tks-new", "bus-tks-type", "bus-tks-mg", "bus-tks-tran"],
                children: [
                    {
                        id: "bus-tickets-new",
                        title: "New Card",
                        show: true,
                        type: "item",
                        url: "apps/bus-tickets/new",
                        auth: ["bus-tks-new"],
                    },
                    {
                        id: "bus-tickets-types",
                        title: "Card Types",
                        show: true,
                        type: "item",
                        url: "apps/bus-tickets/types",
                        auth: ["bus-tks-type"],
                    },
                    {
                        id: "bus-tickets-mg",
                        title: "Card Lists",
                        show: true,
                        type: "item",
                        url: "apps/bus-tickets/lists?cardType=bus",
                        auth: ["bus-tks-mg"],
                    },
                    {
                        id: "bus-tickets-tran",
                        title: "Card Transactions",
                        show: false,
                        type: "item",
                        url: "apps/bus-tickets/transactions?cardType=bus",
                        auth: ["bus-tks-tran"],
                    },
                    {
                        id: "bus-tickets-pricing",
                        title: "Pricing",
                        show: true,
                        type: "item",
                        url: "apps/bus-tickets/pricing",
                        auth: ["bus-tks-pricing"],
                    },
                ],
            },
            {
                id: 'bus-management-report',
                title: 'Bus Report',
                show: true,
                type: 'item',
                url: "apps/bus-management/bus-report",
                auth: ["bus-mng-rep"],
            },
            {
                id: 'bus-management-incident',
                title: 'Incident Report',
                show: true,
                type: 'item',
                url: "apps/bus-management/incident-report",
                auth: ["bus-mng-inct-rep"],
            },
        ],
    },
    {
        id: "motorpool",
        title: "Motorpool",
        show: true,
        translate: "Motorpool",
        type: "collapse",
        icon: "airport_shuttle",
        auth: ["mot-drv-mgmt", "mot-new-booking", "mot-booking-list", "mot-mot-inv", "mot-mot-vcs", "mot-mtnc-mtr", "mot-mtnc-rec", "mot-fuel-rec", "mot-accs-rep", "mot-accs-loc-trc"],
        children: [
            {
                id: "motorpool-booking",
                title: "Booking",
                show: true,
                type: "collapse",
                auth: ["mot-drv-mgmt", "mot-new-booking", "mot-booking-list", "mot-accs-loc-trc"],
                children: [
                    {
                        id: "motorpool-driver-management",
                        title: "Driver Management",
                        show: true,
                        type: "item",
                        url: "apps/motorpool/booking/driver-management",
                        auth: ["mot-drv-mgmt"],
                    },
                    {
                        id: "motorpool-booking-new",
                        title: "New Booking",
                        show: true,
                        type: "item",
                        url: "apps/motorpool/booking/new",
                        auth: ["mot-new-booking"],
                    },
                    {
                        id: "motorpool-booking-list",
                        title: "Booking List",
                        show: true,
                        type: "item",
                        url: "apps/motorpool/booking/list-view",
                        auth: ["mot-booking-list"],
                    },
                    {
                        id: "motorpool-booking-location-tracker",
                        title: "Booking Location Trace",
                        show: true,
                        type: "item",
                        url: "apps/motorpool/booking/location-trace",
                        auth: ["mot-accs-loc-trc"],
                    },
                ],
            },
            {
                id: "motorpool-inventory",
                title: "Motorpool Inventory",
                show: true,
                type: "item",
                url: "apps/motorpool/motorpool-inventory",
                auth: ["mot-mot-inv"],
            },
            {
                id: "motorpool-vehicles",
                title: "Motorpool Vehicles",
                show: true,
                type: "item",
                url: "apps/motorpool/motorpool-vehicles",
                auth: ["mot-mot-vcs"],
            },
            {
                id: "motorpool-maintenance-monitoring",
                title: "Maintenance Monitoring",
                show: true,
                type: "item",
                url: "apps/motorpool/maintenance-monitoring",
                auth: ["mot-mtnc-mtr"],
            },
            {
                id: "motorpool-maintenance-record",
                title: "Maintenance Record",
                show: true,
                type: "item",
                url: "apps/motorpool/maintenance-record",
                auth: ["mot-mtnc-rec"],
            },
            {
                id: "motorpool-fuel-management-record",
                title: "Fuel Management Record",
                show: true,
                type: "item",
                url: "apps/motorpool/fuel-management-record",
                auth: ["mot-fuel-rec"],
            },
            {
                id: "motorpool-vehicle-report",
                title: "Vehicle Report",
                show: true,
                type: "item",
                url: "apps/motorpool/vehicle-report",
                auth: ["mot-accs-rep"],
            },
        ],
    },
    {
        id: "inventory",
        title: "Inventory",
        show: true,
        translate: "INVENTORY",
        type: "collapse",
        icon: "fact_check",
        auth: ["inv-mpr", "inv-ari", "inv-sup", "inv-stk"],
        children: [
            {
                id: "inventory-add-items",
                title: "Add Items",
                show: true,
                type: "item",
                url: "apps/inventory/item/new",
                auth: ["inv-ari"],
            },
            {
                id: "inventory-list",
                title: "Inventory List",
                show: true,
                type: "item",
                url: "apps/inventory/items",
                auth: ["inv-mpr"],
            },
            {
                id: "inventory-report",
                title: "Inventory Report",
                show: true,
                type: "item",
                url: "apps/inventory/report",
                auth: ["inv-mpr"],
            },
            {
                id: "inventory-add-postings",
                title: "New Postings",
                show: true,
                type: "item",
                url: "apps/inventory/posting/new",
                auth: ["inv-ari"],
            },
            {
                id: "inventory-postings",
                title: "Postings",
                show: true,
                type: "item",
                url: "apps/inventory/postings",
                auth: ["inv-mpr"],
            },
            {
                id: "inventory-supplier",
                title: "Suppliers",
                show: true,
                type: "item",
                url: "apps/inventory/suppliers",
                auth: ["inv-sup"],
            },
            {
                id: "inventory-report-generator",
                title: "Report Generator",
                show: false,
                type: "item",
                url: "apps/dashboards/inventory-report-generator",
                // auth: ["inv-mpr", "inv-ari", "inv-sup"],
            },
            {
                id: "inventory-stocktaking",
                title: "Stocktaking",
                show: true,
                type: "collapse",
                auth: ["inv-stk"],
                children: [
                    {
                        id: "inventory-stocktaking-stocktakes",
                        title: "Stocktakes",
                        show: true,
                        type: "item",
                        url: "apps/inventory/stocktaking/stocktakes",
                    },
                    {
                        id: "inventory-stocktaking-add-stocktakes",
                        title: "New Stocktakes",
                        show: true,
                        type: "item",
                        url: "apps/inventory/stocktaking/stocktake/new",
                    },
                    {
                        id: "inventory-stocktaking-stocktakes-report",
                        title: "Stocktake Report",
                        show: true,
                        type: "item",
                        url: "apps/inventory/stocktaking/stocktake-report",
                    },
                    {
                        id: "inventory-stocktaking-stocktakes-settlement",
                        title: "Stocktake Settlement",
                        show: true,
                        type: "item",
                        url: "apps/inventory/stocktaking/settlement",
                    },
                    {
                        id: "inventory-stocktaking-add-writeoff",
                        title: "New Writeoff",
                        show: true,
                        type: "item",
                        url: "apps/inventory/stocktaking/writeoff/new",
                    },
                    {
                        id: "inventory-stocktaking-list-writeoff",
                        title: "List of Writeoffs",
                        show: true,
                        type: "item",
                        url: "apps/inventory/stocktaking/writeoffs",
                    },
                ],
            },
        ],
    },
    {
        id: 'ecommerce',
        title: 'E-Commerce',
        show: true,
        translate: 'E-Commerce',
        type: 'collapse',
        icon: 'shopping_cart',
        auth: ["eco-hp", "eco-or", "eco-sp", "eco-cs", "eco-um"],
        children: [
            {
                id: "ecommerce-home-page",
                title: "Home Page",
                show: true,
                type: "item",
                url: "apps/e-commerce/home-page",
                auth: ["eco-hp"],
            },
            {
                id: "ecommerce-orders",
                title: "Orders",
                show: true,
                type: "item",
                url: "apps/e-commerce/orders",
                auth: ["eco-or"],
            },
            {
                id: "ecommerce-reviews",
                title: "Orders Reviews",
                show: true,
                type: "item",
                url: "apps/e-commerce/reviews",
                auth: ["eco-rw"],
            },
            {
                id: "ecommerce-sales-n-promos",
                title: "Sales and Promos",
                show: true,
                type: "item",
                url: "apps/e-commerce/sales-n-promos",
                auth: ["eco-sp"],
            },
            {
                id: "ecommerce-cloud-search",
                title: "Cloud Search",
                show: true,
                type: "item",
                url: "apps/e-commerce/cloud-search",
                auth: ["eco-cs"],
            },
            {
                id: "ecommerce-configuration",
                title: "E-Commerce Configuration",
                show: false,
                type: "item",
                url: "apps/e-commerce/configuration",
            },
            {
                id: "ecommerce-reports",
                title: "Reports",
                show: true,
                type: "item",
                url: "apps/e-commerce/reports",
            },
            {
                id: "ecommerce-user-account-manage",
                title: "User Management",
                show: true,
                type: "item",
                url: "apps/e-commerce/manage",
                auth: ["eco-um"],
            },
        ],
    },
    {
        id: 'crm',
        title: 'CRM',
        show: true,
        translate: 'CRM',
        type: 'collapse',
        icon: 'confirmation_number',
        auth: ["crm-dash", "crm-tpc", "crm-rpt"],
        children: [
            {
                id: "crm-dashboard",
                title: "CRM Dashboard",
                show: true,
                type: "item",
                url: "apps/crm/dashboards/Open/all",
                auth: ["crm-dash"],
            },
            {
                id: "crm-topics",
                title: "CRM Topics",
                show: true,
                type: "item",
                url: "apps/crm/topics",
                auth: ["crm-tpc"],
            },
            {
                id: "crm-reports",
                title: "Reports",
                show: true,
                type: "item",
                url: "apps/crm/report/individual",
                auth: ["crm-rpt"],
            },
            {
                id: "crm-buttons",
                title: "Canned Response",
                show: true,
                type: "item",
                url: "apps/crm/reply/buttons",
                auth: ["crm-dash"],
            },
        ],
    },
    {
        id: "store",
        title: "Store",
        show: true,
        translate: "STORE",
        type: "collapse",
        icon: "shopping_bag",
        auth: ["str-mm", "str-bs", "str-others", "str-others", "str-er", "str-ers", "str-wds", "str-wdss", "str-crd", "str-quo", "str-ris", "str-set", "str-rris"],
        children: [
            {
                id: "store-bookstore",
                title: "Bookstore",
                show: true,
                type: "item",
                url: "apps/store/books",
                auth: ["str-bs"],
            },
            {
                id: "store-return-item",
                title: "Return Item/Refund",
                show: true,
                type: "item",
                url: "apps/store/return",
                auth: ["str-ris"],
            },
            {
                id: "store-order-history",
                title: "Order History",
                show: true,
                type: "item",
                url: "apps/store/order-history",
                auth: ["str-ohs"],
            },
            {
                id: "store-others",
                title: "Others",
                show: true,
                type: "item",
                url: "apps/store/others",
                auth: ["str-others"],
            },
            {
                id: "store-equipment-rental",
                title: "Equipment Rentals",
                show: true,
                url: "apps/store/equipment-rental/list-view",
                type: "item",
                auth: ["str-er", "str-ers"],
            },
            {
                id: "store-water-delivery-service",
                title: "Water Delivery Service",
                show: true,
                url: "apps/store/services/job-orders/list-view",
                type: "item",
                auth: ["str-wds", "str-wdss"],
            },
            {
                id: "store-credits",
                title: "Credits",
                show: true,
                type: "item",
                url: "apps/store/credit/new",
                auth: ["str-crd"],
            },
            {
                id: "store-quotation",
                title: "Quotations",
                show: true,
                type: "item",
                url: "apps/store/quotations",
                auth: ["str-quo"],
            },
            {
                id: "store-pause-sale",
                title: "Pause Sale",
                show: true,
                type: "item",
                url: "apps/store/pause-sale",
                auth: ["str-quo"],
            },
            {
                id: "store-promo-discount",
                title: "Promo & Discounts",
                show: false,
                type: "item",
                url: "apps/dashboards/store-promo-discount",
            },
            {
                id: "store-report-generator",
                title: "Report Generator  ",
                show: false,
                type: "item",
                url: "apps/dashboards/store-report-generator",
            },
            {
                id: "store-rewards",
                title: "Rewards",
                show: true,
                type: "item",
                url: "apps/store/reward-items",
                auth: ["str-rris"],
            },
            {
                id: "store-reports",
                title: "Bookstore Report",
                show: true,
                type: "item",
                url: "apps/store/reports/meal-plans",
                auth: ["str-bs"],
            },
            {
                id: "store-settings",
                title: "Store Settings",
                show: true,
                type: "item",
                url: "apps/store/store-settings",
                auth: ["str-set"],
            },
        ],
    },
    {
        id: "meal-vendor",
        title: "Food Vendors",
        show: true,
        isVendor: true,
        translate: "FOOD_VENDORS",
        type: "collapse",
        icon: "set_meal",
        auth: ["ven-has", "ven-lis", "ven-sal"],
        children: [
            {
                id: "meal-vendor-list",
                title: "Vendor Lists",
                show: true,
                isVendor: true,
                type: "item",
                auth: ["ven-lis"],
                url: "apps/meal-vendors/list",
            },
            {
                id: "meal-vendor-sales",
                title: "Vendor Sales",
                show: true,
                isVendor: true,
                type: "item",
                auth: ["ven-sal"],
                url: "apps/meal-vendors/sales",
            },
            {
                id: "meal-vendor-order-history",
                title: "Vendor Order History",
                show: true,
                isVendor: true,
                type: "item",
                auth: ["ven-sal"],
                url: "apps/meal-vendors/order-history",
            },
            {
                id: "meal-vendor-inventory",
                title: "Vendor Inventory",
                show: true,
                isVendor: true,
                type: "item",
                auth: ["ven-inv"],
                url: "apps/meal-vendors/inventory",
            },
            {
                id: "meal-vendor-name",
                title: "Vendor Name",
                show: true,
                isVendor: true,
                type: "item",
                auth: ["ven-has"],
                url: "apps/meal-vendors/meal/new",
            },
        ],
    },
    {
        id: "patrol",
        title: "Patrol Management",
        show: true,
        translate: "PATROL_MANAGEMENT",
        type: "collapse",
        icon: "two_wheeler",
        auth: ["patrol-incident-report", "rh-houses", "rh-rooms", "rh-tenant", "patrol-monitoring", "patrol-live", "patrol-calendar", "patrol-shifting", "patrol-checkpoint", "patrol-route"],
        children: [
            {
                id: "patrol-incident-report",
                title: "Incident Reports",
                show: true,
                type: "item",
                url: "apps/patrol/incident-report",
                auth: ["patrol-incident-report"],
            },
            {
                id: "patrol-patrollers",
                title: "Patrollers Management",
                show: true,
                type: "collapse",
                auth: ["patrol-monitoring", "patrol-live", "patrol-calendar", "patrol-shifting"],
                children: [
                    {
                        id: "patrol-patron-monitoring",
                        title: "Patrol Monitoring",
                        show: true,
                        type: "item",
                        url: "apps/patrol/patrollers/patrol-calendar",
                        auth: ["patrol-monitoring"],
                    },
                    {
                        id: "patrol-live-monitoring",
                        title: "Live Monitoring",
                        show: true,
                        type: "item",
                        url: "apps/patrol/patrollers/live-monitoring",
                        auth: ["patrol-live"],
                    },
                    {
                        id: "patrol-patroller-calendar",
                        title: "Patroller Calendar",
                        show: true,
                        type: "item",
                        url: "apps/patrol/patrollers/patroller-calendar",
                        auth: ["patrol-calendar"],
                    },
                    {
                        id: "patrol-shifting-management",
                        title: "Shifting Management",
                        show: true,
                        type: "item",
                        url: "apps/patrol/patrollers/shifting-management",
                        auth: ["patrol-shifting"],
                    },
                ]
            },
            {
                id: "patrol-checkpoint-management",
                title: "Checkpoint Management",
                show: true,
                type: "item",
                url: "apps/patrol/checkpoint",
                auth: ["patrol-checkpoint"],
            },
            {
                id: "patrol-route-schedule-management",
                title: "Route Schedule Management",
                show: true,
                type: "item",
                url: "apps/patrol/route-schedule",
                auth: ["patrol-route"],
            },
        ],
    },
    {
        id: "residence",
        title: "Residence Hall",
        show: true,
        translate: "RESIDENCE_HALL",
        type: "collapse",
        icon: "home",
        auth: ["rh-announcement", "rh-houses", "rh-rooms", "rh-tenant"],
        children: [
            {
                id: "house-announcement",
                title: "Announcements",
                show: true,
                type: "item",
                url: "apps/residence-hall/announcement",
                auth: ["rh-announcement"],
            },
            {
                id: "house-management",
                title: "Hall Management",
                show: true,
                type: "item",
                url: "apps/residence-hall/houses",
                auth: ["rh-houses"],
            },
            {
                id: "room-management",
                title: "Room Management",
                show: true,
                type: "item",
                url: "apps/residence-hall/house/rooms/all",
                auth: ["rh-rooms"],
            },
            {
                id: "residence-hall-tenants",
                title: "Residence Hall Residents",
                show: true,
                type: "collapse",
                auth: ["rh-tenant", "rh-inspection", "rh-request", "rh-crm-inbox"],
                children: [
                    {
                        id: "residence-hall-tenants-list-view",
                        title: "List View",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/tenant-list-view",
                        auth: ["rh-tenant"],
                    },
                    {
                        id: "residence-hall-tenants-card-view",
                        title: "Card View",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/tenant-card-view",
                        auth: ["rh-tenant"],
                    },
                    {
                        id: "residence-hall-tenants-pending",
                        title: "Pending Incoming Residents",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/pending-tenant-list-view",
                        auth: ["rh-tenant"],
                    },
                    {
                        id: "residence-hall-tenants-post-inspection",
                        title: "Post Inspection",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/post-inspection",
                        auth: ["rh-inspection"],
                    },
                    {
                        id: "residence-hall-visit-request",
                        title: "Visit Requests",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/visit-request",
                        auth: ["rh-request"],
                    },
                    {
                        id: "residence-hall-checkout-request",
                        title: "Checkout Requests",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/checkout-request",
                        auth: ["rh-request"],
                    },
                    {
                        id: "residence-hall-tenants-crm-inbox",
                        title: "CRM Inbox",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/crm-inbox",
                        auth: ["rh-crm-inbox"],
                    },
                    {
                        id: "residence-hall-tenants-new",
                        title: "New Resident",
                        show: true,
                        type: "item",
                        url: "apps/residence-hall/tenant/new",
                        auth: ["rh-tenant"],
                    },
                ]
            },
            {
                id: "rh-reports",
                title: "Reports",
                show: true,
                type: "item",
                url: "apps/residence-hall/reports",
                auth: ["rh-reports"],
            },
        ],
    },
    {
        id: "booking",
        title: "Booking",
        show: true,
        translate: "BOOKING",
        type: "collapse",
        icon: "date_range",
        auth: ["book-nb", "book-bl", "book-bi", "book-blk"],
        children: [
            {
                id: "booking-new",
                title: "New Booking",
                show: true,
                type: "item",
                url: "apps/booking/new",
                auth: ["book-nb"],
            },
            {
                id: "booking-list",
                title: "Booking List",
                show: true,
                type: "item",
                url: "apps/booking/list-view",
                auth: ["book-bl"],
            },
            {
                id: "booking-items",
                title: "Booking Items",
                show: true,
                type: "item",
                url: "apps/booking/items",
                auth: ["book-bi"],
            },
            {
                id: "booking-blacklist",
                title: "Blocklist",
                show: true,
                type: "item",
                url: "apps/booking/blacklist",
                auth: ["book-blk"],
            },
            {
                id: "booking-reports",
                title: "Reports",
                show: true,
                type: "item",
                url: "apps/booking/reports/performance",
            },
        ],
    },
    {
        id: "patrons",
        title: "Patrons",
        show: true,
        translate: "PATRONS",
        type: "collapse",
        icon: "supervised_user_circle",
        auth: ["pat-ap", "pat-mp"],
        children: [
            {
                id: "patrons-add-patrons",
                title: "Add Patron",
                show: true,
                type: "item",
                url: "apps/patron/new",
                auth: ["pat-ap"],
            },
            {
                id: "patrons-records",
                title: "Patron Records",
                show: true,
                type: "item",
                url: "apps/patrons",
                auth: ["pat-mp"],
            },
            {
                id: "patrons-reports",
                title: "Patron Reports",
                show: true,
                type: "collapse",
                auth: ["pat-mp"],
                children: [
                    {
                        id: "patrons-reports-transactions",
                        title: "Transactions Report",
                        show: true,
                        type: "item",
                        url: "apps/patrons-reports",
                        auth: ["pat-mp"],
                    },
                    {
                        id: "patrons-reports-service",
                        title: "Services Report",
                        show: true,
                        type: "item",
                        url: "apps/patrons-services",
                        auth: ["pat-mp"],
                    },
                ]
            },
            {
                id: "patrons-import-patrons",
                title: "Import Patron",
                show: true,
                type: "item",
                url: "apps/patrons-import",
                auth: ["pat-mp"],
            },
        ],
    },
    {
        id: "cards",
        title: "Cards",
        show: true,
        translate: "CARDS",
        type: "collapse",
        icon: "card_giftcard",
        auth: ["card-type", "card-new", "card-mg", "card-tran"],
        children: [
            {
                id: "cards-new",
                title: "New Card",
                show: true,
                type: "item",
                url: "apps/cards/new",
                auth: ["card-new"],
            },
            {
                id: "cards-types",
                title: "Card Types",
                show: true,
                type: "item",
                url: "apps/cards/types",
                auth: ["card-type"],
            },
            {
                id: "cards-mg",
                title: "Card Lists",
                show: true,
                type: "item",
                url: "apps/cards/lists?cardType=loyalty",
                auth: ["card-mg"],
            },
            {
                id: "cards-tran",
                title: "Card Transactions",
                show: false,
                type: "item",
                url: "apps/cards/transactions?cardType=loyalty",
                auth: ["card-tran"],
            },
        ],
    },
    {
        id: "usr",
        title: "User Accounts",
        show: true,
        translate: "USER_ACCOUNTS",
        type: "collapse",
        icon: "supervisor_account",
        auth: ["usr-mu", "usr-au"],
        children: [
            {
                id: "user-account-add",
                title: "Add User",
                show: true,
                type: "item",
                auth: ["usr-au"],
                url: "apps/user-account/users/new",
            },
            {
                id: "user-account-manage",
                title: "Manage Accounts",
                show: true,
                type: "item",
                auth: ["usr-mu"],
                url: "apps/user-account/manage",
            },
        ],
    },
    {
        id: "settings",
        title: "Settings",
        show: true,
        translate: "SETTINGS",
        type: "collapse",
        icon: "settings",
        auth: ["set-inv-cat", "set-inv-type", "set-inv-dept", "set-inv-crse", "set-inv-pub", "set-pat-type", "set-book-type", "set-book-dept", "set-book-conf", "set-store-cred", "set-store-note", "set-store-head", "set-vendors-note", "set-vendors-head", "set-aud-logs", "set-mot-inv-cat", "set-mot-inv-type", "set-mot-vcs-type", "set-mot-vcs-mec"],
        children: [
            {
                id: "settings-bus-management",
                title: "Bus Management",
                show: true,
                type: "collapse",
                auth: ["set-bus-mec", "set-bus-trc"],
                children: [
                    {
                        id: "settings-bus-management-mechanic",
                        title: "Manage Bus Personnel",
                        show: true,
                        type: "item",
                        url: "apps/settings/bus-management/mechanic",
                        auth: ["set-bus-mec"],
                    },
                    {
                        id: "settings-bus-management-tracker",
                        title: "Manage Bus Tracker",
                        show: true,
                        type: "item",
                        url: "apps/settings/bus-management/tracker",
                        auth: ["set-bus-trc"],
                    },
                ]
            },
            {
                id: "settings-motorpool",
                title: "Motorpool",
                show: true,
                type: "collapse",
                auth: ["set-mot-inv-cat", "set-mot-inv-type", "set-mot-vcs-type", "set-mot-trcd"],
                children: [
                    {
                        id: "settings-motorpool-inventory-category",
                        title: "Manage Inventory Category",
                        show: true,
                        type: "item",
                        url: "apps/settings/motorpool/inventory-category",
                        auth: ["set-mot-inv-cat"],
                    },
                    {
                        id: "settings-motorpool-inventory-type",
                        title: "Manage Inventory Type",
                        show: true,
                        type: "item",
                        url: "apps/settings/motorpool/inventory-type",
                        auth: ["set-mot-inv-type"],
                    },
                    {
                        id: "settings-motorpool-vehicles-type",
                        title: "Manage Vehicles Type",
                        show: true,
                        type: "item",
                        url: "apps/settings/motorpool/vehicles-type",
                        auth: ["set-mot-vcs-type"],
                    },
                    {
                        id: "settings-motorpool-vehicles-tracker",
                        title: "Manage Vehicles Tracker",
                        show: true,
                        type: "item",
                        url: "apps/settings/motorpool/vehicles-tracker",
                        auth: ["set-mot-trcd"],
                    },
                    {
                        id: "settings-motorpool-vehicles-mechanic",
                        title: "Manage Vehicles Personnel",
                        show: true,
                        type: "item",
                        url: "apps/settings/motorpool/vehicles-mechanic",
                        auth: ["set-mot-vcs-mec"],
                    },
                ],
            },
            {
                id: "settings-inventory",
                title: "Inventory",
                show: true,
                type: "collapse",
                auth: ["set-inv-cat", "set-inv-type", "set-inv-dept"],
                children: [
                    {
                        id: "settings-inventory-manage-item-category",
                        title: "Manage Item Category",
                        show: true,
                        type: "item",
                        url: "apps/settings/inventory/item-category",
                        auth: ["set-inv-cat"],
                    },
                    {
                        id: "settings-inventory-manage-item-types",
                        title: "Manage Item Types",
                        show: true,
                        type: "item",
                        url: "apps/settings/inventory/item-type",
                        auth: ["set-inv-type"],
                    },
                    {
                        id: "settings-inventory-manage-item-department",
                        title: "Manage Item Department",
                        show: true,
                        type: "item",
                        url: "apps/settings/inventory/item-department",
                        auth: ["set-inv-dept"],
                    },
                    {
                        id: "settings-inventory-manage-item-course",
                        title: "Manage Item Course",
                        show: true,
                        type: "item",
                        url: "apps/settings/inventory/item-course",
                        auth: ["set-inv-crse"],
                    },
                    {
                        id: "settings-inventory-manage-item-publisher",
                        title: "Manage Item Publisher",
                        show: true,
                        type: "item",
                        url: "apps/settings/inventory/item-publisher",
                        auth: ["set-inv-pub"],
                    },
                ],
            },
            {
                id: "settings-patron",
                title: "Patron",
                show: true,
                type: "collapse",
                auth: ["set-pat-type"],
                children: [
                    {
                        id: "settings-patron-manage-type",
                        title: "Manage Type",
                        show: true,
                        type: "item",
                        url: "apps/settings/patron/type",
                        auth: ["set-pat-type"],
                    },
                ],
            },
            {
                id: "settings-booking",
                title: "Booking",
                show: true,
                type: "collapse",
                auth: ["set-book-type", "set-book-dept", "set-book-conf"],
                children: [
                    {
                        id: "settings-booking-manage-item-type",
                        title: "Manage Item Type",
                        show: true,
                        type: "item",
                        url: "apps/settings/booking/booking-item-type",
                        auth: ["set-book-type"],
                    },
                    {
                        id: "settings-booking-departments",
                        title: "Departments",
                        show: true,
                        type: "item",
                        url: "apps/settings/booking/departments",
                        auth: ["set-book-dept"],
                    },
                    {
                        id: "settings-booking-configuration",
                        title: "Configuration",
                        show: true,
                        type: "item",
                        url: "apps/settings/booking/configuration",
                        auth: ["set-book-conf"],
                    },
                    {
                        id: "settings-booking-alerts",
                        title: "Alerts",
                        show: true,
                        type: "item",
                        url: "apps/settings/booking/alerts",
                        auth: ["set-book-conf"],
                    },
                ],
            },
            {
                id: "settings-store",
                title: "Store",
                show: true,
                type: "collapse",
                auth: ["set-store-cred", "set-store-note", "set-store-head"],
                children: [
                    {
                        id: "settings-store-credit-settings",
                        title: "Credit Settings",
                        show: false,
                        type: "item",
                        url: "apps/dashboards/settings-store-credit-settings",
                        auth: ["set-store-cred"],
                    },
                    {
                        id: "settings-store-global-note",
                        title: "Global Store Note",
                        show: true,
                        type: "item",
                        url: "apps/settings/store/global-note",
                        auth: ["set-store-note"],
                    },
                    {
                        id: "settings-store-global-header",
                        title: "Global Store Header",
                        show: true,
                        type: "item",
                        url: "apps/settings/store/global-header",
                        auth: ["set-store-head"],
                    },
                ],
            },
            {
                id: "settings-vendors",
                title: "Food Vendors",
                show: true,
                type: "collapse",
                auth: ["set-vendors-cred", "set-vendors-note", "set-vendors-head"],
                children: [
                    {
                        id: "settings-vendors-global-note",
                        title: "Global Vendors Note",
                        show: true,
                        type: "item",
                        url: "apps/settings/vendors/global-note",
                        auth: ["set-vendors-note"],
                    },
                    {
                        id: "settings-vendors-global-header",
                        title: "Global Vendors Header",
                        show: true,
                        type: "item",
                        url: "apps/settings/vendors/global-header",
                        auth: ["set-vendors-head"],
                    },
                ],
            },
            {
                id: "settings-bus",
                title: "Bus Ticket",
                show: true,
                type: "collapse",
                auth: ["set-bus-cat"],
                children: [
                    {
                        id: "settings-bus-category",
                        title: "Manage Category",
                        show: true,
                        type: "item",
                        url: "apps/settings/bus/category",
                        auth: ["set-bus-cat"],
                    }
                ],
            },
            {
                id: "settings-residence-hall",
                title: "Residence Hall",
                show: true,
                type: "collapse",
                auth: ["set-rh-amn", "set-rh-config", "set-rh-visit", "set-rh-checkout"],
                children: [
                    {
                        id: "settings-residence-hall-amenities",
                        title: "Amenities",
                        show: true,
                        type: "item",
                        url: "apps/settings/residence-hall/amenities",
                        auth: ["set-rh-amn"],
                    },
                    {
                        id: "settings-residence-hall-configuration",
                        title: "Configuration",
                        show: true,
                        type: "item",
                        url: "apps/settings/residence-hall/configuration",
                        auth: ["set-rh-config"],
                    },
                    {
                        id: "settings-residence-hall-visit-agreement",
                        title: "Visit Agreement",
                        show: true,
                        type: "item",
                        url: "apps/settings/residence-hall/agreement/visit",
                        auth: ["set-rh-visit"],
                    },
                    {
                        id: "settings-residence-hall-checkout-agreement",
                        title: "Checkout Agreement",
                        show: true,
                        type: "item",
                        url: "apps/settings/residence-hall/agreement/checkout",
                        auth: ["set-rh-checkout"],
                    },
                ],
            },
            {
                id: "settings-patrol",
                title: "Patrol",
                show: true,
                type: "collapse",
                auth: ["set-ptrl-chkgrp"],
                children: [
                    {
                        id: "settings-patrol-checkpoint-group",
                        title: "Checkpoint Group",
                        show: true,
                        type: "item",
                        url: "apps/settings/patrol/checkpoint-group",
                        auth: ["set-ptrl-chkgrp"],
                    },
                ],
            },
            {
                id: "settings-notification-settings",
                title: "Notification Settings",
                show: false,
                type: "item",
                url: "apps/dashboards/settings-notification-settings",
            },
            {
                id: "settings-audit-logs",
                title: "Audit Logs",
                show: true,
                type: "item",
                url: "apps/settings/audit-logs",
                auth: ["set-aud-logs"],
            },
            {
                id: "settings-about-cmi-oss",
                title: "About CMI OSS",
                show: false,
                type: "item",
                url: "apps/dashboards/settings-about-cmi-oss",
            },
        ],
    },
];

export default navigationConfig;
