import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../../../../graphql/settings/booking_departments";
import { mutation as mutationUpload } from "../../../../graphql/upload_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "../../../../services/upload";
import randomString from "../../../../services/randomString";

export const getDepartments = createAsyncThunk('settingsApp/booking/departments/getDepartments', async (params) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.list,
        variables: params
    });
    const data = response.data.data.bookingDepartmentsList;
    return data;
});

export const removeDepartments = createAsyncThunk(
    'settingsApp/booking/departments/removeDepartments',
    async (depIds, { dispatch, getState }) => {

        await depIds.map(async (id) => {

            await axios.post(CONFIG.API + "/api/", {
                query: mutation.delete,
                variables: {
                    id: id
                }
            });

        })

        dispatch(
            showMessage({
                message: "Department(s) has been successfully deleted.",
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: "success",
            })
        );

        return depIds;
    }
);

export const saveFileImportDepartments = createAsyncThunk(
    "settingsApp/booking/departments/saveFileImportDepartments",
    async (filesData, { dispatch, getState }) => {

        console.log("filesData", filesData);

        await uploadSvc({
            file: filesData.file,
            destination_file_name: "booking/files/csv-" + randomString() + "-" + filesData.file.name
        }).then(async (result) => {

            if (result.status === 200 && result.data.status === "success") {

                var uploadData = result.data.uploadData
                uploadData['module'] = 'booking-file-import-dpms';

                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutationUpload.create,
                    variables: {
                        data: JSON.stringify(uploadData),
                    },
                });

                if (response.data.data.createUpload.status == "success") {
                    const response = await axios.post(CONFIG.API + "/external/booking/import/booking/departments");
                    if (response.data.status == 'error') {

                        dispatch(
                            showMessage({
                                message: "Something went wrong please check your import file.",
                                autoHideDuration: 5000, //ms
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                variant: "error",
                            })
                        );
                    } else {

                        dispatch(
                            showMessage({
                                message: "File has been succesfully imported.",
                                autoHideDuration: 5000, //ms
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                                variant: "success",
                            })
                        );

                    }

                    return response;
                }

            }

        });

    }
);

export const saveDepartments = createAsyncThunk(
    "settingsApp/booking/departments/saveDepartments",
    async (params, { dispatch, getState }) => {
        if (params.id) {
            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.edit,
                variables: {
                    data: JSON.stringify(params),
                },
            });

            if (response.data.data.editBookingDepartments) {
                dispatch(
                    showMessage({
                        message: "Department has been successfully updated.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        } else {
            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.create,
                variables: { data: JSON.stringify(params) },
            });

            if (response.data.data.createBookingDepartments) {
                dispatch(
                    showMessage({
                        message: "Department has been successfully saved.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        }
    }
);

const departmentsAdapter = createEntityAdapter({});

export const { selectAll: selectDepartments, selectById: selectDepartmentsById } =
    departmentsAdapter.getSelectors((state) => state.settingsApp.bookingDepartments);

const bookingDepartmentsSlice = createSlice({
    name: 'settingsApp/booking/departments',
    initialState: departmentsAdapter.getInitialState({
        searchText: '',
        showModal: false
    }),
    reducers: {
        setDepartmentsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: (!!event ? event.target.value : '') || '' }),
        },
    },
    extraReducers: {
        [getDepartments.fulfilled]: departmentsAdapter.setAll,
        [removeDepartments.fulfilled]: (state, action) =>
            departmentsAdapter.removeMany(state, action.payload),
        [saveFileImportDepartments.fulfilled]: (state, action) => action.payload,
        [saveDepartments.fulfilled]: (state, action) => action.payload,
    },
});

export const { setDepartmentsSearchText } = bookingDepartmentsSlice.actions;

export default bookingDepartmentsSlice.reducer;
