import { authCrmRoles } from 'app/crm';
import CrmLogin from 'app/main/crm/login/CrmLogin';
import CrmSsoLogin from 'app/main/crm/login/CrmSsoLogin';
import CrmRegisterPassword from 'app/main/crm/login/CrmRegisterPassword';
import CrmVerifyEmail from 'app/main/crm/verify/CrmVerifyEmail';
import ForgotPassword from 'app/main/crm/forgot-password/ForgotPassword';
import CrmBookingFeedback from 'app/main/crm/feedback/CrmBookingFeedback';
import BookingCalendar from 'app/main/crm/booking-calendar/BookingCalendar';
import MotorpoolBookingCalendar from './motorpool/booking-calendar/MotorpoolBookingCalendar';

const CrmConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authCrmRoles.onlyGuest,
    routes: [
        {
            path: 'crm',
            element: <BookingCalendar />,
        },
        {
            path: 'crm/motorpool',
            element: <MotorpoolBookingCalendar />,
        },
        {
            path: 'crmlogin',
            element: <CrmLogin />,
        },
        {
            path: 'crm/forgot-password/:id',
            element: <ForgotPassword />,
        },
        {
            path: 'crm/register/:id',
            element: <CrmRegisterPassword />,
        },
        {
            path: 'crm/verify/email/:id',
            element: <CrmVerifyEmail />,
        },
        {
            path: 'crm/booking/feedback/:feedBackId/:bookingId',
            element: <CrmBookingFeedback />,
        },
        {
            path: 'sso/login',
            element: <CrmSsoLogin />,
        }
    ],
};

export default CrmConfig;
