const mutation = {
  create: `
      mutation createBookingItems($data: JSONString!) {
          createBookingItems (data: $data) {
          status,
          res,
          message
          }
      }
  `,
  edit: `
      mutation editBookingItems($data: JSONString!) {
          editBookingItems (data: $data) {
          status,
          res,
          message
          }
      }
  `,
  delete: `
      mutation deleteBookingItems($id: String!) {
          deleteBookingItems (id: $id) {
              status,
              res,
              message            
          }
      }
  `,
};

const query = {
  list: `
    query bookingItemsList {
      bookingItemsList {
        id,
        code,
        type,
        name,
        description,
        featuredImageId,
        featuredImagePath,
        publicRate,
        cmiRate,
        photoUrl,
        employeeOnly,
        inspectionRequired,
        status,
        dateCreated,
        dateUpdated
      }
    }
  `,
  listPaginate: `
    query bookingItemsListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int,
        $type: String!, 
        $order: String!, 
        $exclude: String!
    ){
        bookingItemsListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            type: $type, 
            order: $order, 
            exclude: $exclude
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {            
                id,
                code,
                type,
                name,
                description,
                featuredImageId,
                featuredImagePath,
                publicRate,
                cmiRate,
                photoUrl,
                employeeOnly,
                inspectionRequired,
                status,
                dateCreated,
                dateUpdated,
                itemTypes{
                  name
                }
            }
        }
    }
  `,
  listPaginateWTerms: `
    query bookingItemsListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int,
        $type: String!, 
        $order: String!, 
        $exclude: String!
    ){
        bookingItemsListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            type: $type, 
            order: $order, 
            exclude: $exclude
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {            
                id,
                code,
                type,
                name,
                description,
                featuredImageId,
                featuredImagePath,
                publicRate,
                cmiRate,
                photoUrl,
                employeeOnly,
                inspectionRequired,
                status,
                dateCreated,
                dateUpdated,
                itemTypes{
                  name
                  terms
                }
            }
        }
    }
  `,
  listPaginateNoPhoto: `
    query bookingItemsListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int,
        $type: String!, 
        $order: String!,
        $exclude: String
    ){
        bookingItemsListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            type: $type, 
            order: $order, 
            exclude: $exclude
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {            
                id,
                code,
                type,
                name,
                description,
                featuredImageId,
                featuredImagePath,
                publicRate,
                cmiRate,
                employeeOnly,
                inspectionRequired,
                status,
                dateCreated,
                dateUpdated
            }
        }
    }
  `,
  getItemByID: `
    query bookingItemById($itemId: String!) {
      bookingItemById(itemId: $itemId) {
        id,
        code,
        type,
        name,
        description,
        featuredImageId,
        featuredImagePath,
        publicRate,
        cmiRate,
        employeeOnly,
        inspectionRequired,
        status,
        dateCreated,
        dateUpdated,
        photoUrl,
        images {
          id,
          itemId,
          uploadId,
          fileName,
          fileType,
          fileId,
          module,
          path,
          url,
          dateCreated,
          dateUpdated
        }
      }
    }
  `,
  checkBookingItemExists: `
      query checkBookingItemExists($value: String, $model: String, $id: String) {
          checkBookingItemExists (value: $value, model: $model, id: $id) {
              id
          }
      }
  `
}

export { mutation, query };