import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "../../../config";
import { query, mutation } from "../../../graphql/crm/crm_users_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import CrmJwtService from 'app/services/crm/CrmJwtService';

const token = CrmJwtService.getAccessToken();

export const getCrmUsersById = createAsyncThunk(
    "crmApps/crmUsers/getCrmUsersById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getCrmUsersById,
            variables: {
                crmUsersId: params.crmUsersId,
            },
            publicUrl: 'crm',
        });

        if (response.data.data.getCrmUsersById) {
            const data = response.data.data.getCrmUsersById;

            return data === undefined ? null : data;
        }
    }
);

const saveImages = async (postData) => {
    return await new Promise(async (resolve, reject) => {
        if (postData.images.length) {
            var newImages = [];

            postData.images.map(async (image, index) => {
                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image,
                    });
                } else {
                    if (postData.profilePicId == image.fileId || postData.profilePicId == image.id) {
                        postData.profilePicPath = image.path;
                    }
                }
            });

            if (newImages.length) {
                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name:
                            "crm/crmUsers-" +
                            randomString() +
                            "-" +
                            (image.image.file ? image.image.file.name : image.image.fileName),
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {
                            var uploadData = result.data.uploadData;
                            uploadData["module"] = "crm";
                            uploadData["file_id"] = image.image.id;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                                publicUrl: 'crm',
                            });

                            if (response.data.data.createUpload.status == "success") {
                                var upload = response.data.data.createUpload.upload;

                                if (postData.profilePicId == image.image.id || postData.profilePicId == image.fileId) {
                                    postData.profilePicId = upload.id;
                                    postData.profilePicPath = upload.path;
                                }

                                postData.images[image.index].upload_id = upload.id;
                                postData.images[image.index].file_name = upload.fileName;
                                postData.images[image.index].file_id = upload.fileId;
                                postData.images[image.index].file_type = upload.fileType;
                                postData.images[image.index].path = upload.path;
                                postData.images[image.index].module = upload.module;
                                postData.images[image.index].url = "";
                            }
                        }

                        if (newImages.length == (index+1)) {
                            resolve(postData);
                        }
                    });
                });
            } else {
                resolve(postData);
            }
        } else {
            resolve(postData);
        }
    });
};

export const saveCrmUsers = createAsyncThunk(
    "crmApps/crmUsers/saveCrmUsers",
    async (postData, { dispatch, getState }) => {

        return await saveImages(postData).then(async (postData) => {
            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.saveCrmUsers,
                variables: {
                    data: JSON.stringify(postData),
                    id: postData.id,
                },
                publicUrl: 'crm',
            }, {
                headers: {
                    'common': {
                        'Authorization': `Bearer ${token}`
                    }

                }
            });

            if (response.data.data.saveCrmUsers) {
                CrmJwtService.setUserRefreshToken();
                dispatch(
                    showMessage({
                        message: "Crm Users has been successfully updated.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
                return postData.id;
            } else {
                CrmJwtService.setUserRefreshToken();
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });
    }
);

const crmUsersSlice = createSlice({
    name: "crmApps/crmUsers",
    initialState: null,
    reducers: {
        resetCrmUsers: () => null,
        newCrmUsers: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {
                    crmUsersId: "",
                    patronType: "",
                    status: "",
                    idNum: "",
                    name: "",
                    departmentName: "",
                    departmentId: "",
                    gender: "",
                    birthdate: null,
                    emergencyContact: "",
                    email: "",
                    phone: "",
                    ssn: "",
                    address1: "",
                    lat: "",
                    lng: "",
                    address2: "",
                    country: "United States",
                    city: "Majuro",
                    state: "Marshall Islands",
                    zipCode: "96960",
                    notes: "",
                    field1: "",
                    field2: "",
                    field3: "",
                    field4: "",
                    field5: "",
                    profilePicId: "",
                    profilePicPath: "",
                    images: [],
                },
            }),
            extraReducers: {
                [getCrmUsersById.fulfilled]: (state, action) => action.payload,
                [saveCrmUsers.fulfilled]: (state, action) => action.payload,
            }
        }
    }
});

export const { newCrmUsers, resetCrmUsers } = crmUsersSlice.actions;

export default crmUsersSlice.reducer;
