import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Patron = lazy(() => import('./patron/Patron'));
const Patrons = lazy(() => import('./patrons/Patrons'));
const Import = lazy(() => import('./import/Import'));
const PatronReports = lazy(() => import('./patron-reports/PatronReports'));
const PatronServiceReports = lazy(() => import('./patron-service-report/PatronServiceReports'));

const PatronAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/patron/:patronId/*',
            element: <Patron />,
            auth: ["pat-ap"],
        },
        {
            path: 'apps/patrons',
            element: <Patrons />,
            auth: ["pat-mp"],
        },
        {
            path: 'apps/patrons-import',
            element: <Import />,
            auth: ["pat-mp"],
        },
        {
            path: 'apps/patrons-reports',
            element: <PatronReports />,
            auth: ["pat-mp"],
        },
        {
            path: 'apps/patrons-services',
            element: <PatronServiceReports />,
            auth: ["pat-mp"],
        },
    ],
};

export default PatronAppConfig;
