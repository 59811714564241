const mutation = {
    registerPatron: `
        mutation registerPatron($barcode: String!) {
            registerPatron (barcode: $barcode) {
                status,
                res,
                message
            }
        }
    `,
    emailVerified: `
        mutation emailVerified($id: String!) {
            emailVerified (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
}

export { mutation, query };