import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
    query,
    mutation,
} from "app/main/apps/residence-hall/graphql/crmInbox_graphql";

export const getCrmInbox = createAsyncThunk(
    "residenceHallApp/crm-inbox/getCrmInbox",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.list,
            variables: params
        });
        var data = response.data.data.getTicketsAdmin;

        return data;
    }
);

const crmInboxAdapter = createEntityAdapter({});

export const {
    selectAll: selectCrmInbox,
    selectById: selectCrmInboxById,
} = crmInboxAdapter.getSelectors((state) => state.residenceHallApp.crmInbox);

const crmInboxSlice = createSlice({
    name: "residenceHallApp/crm-inbox",
    initialState: crmInboxAdapter.getInitialState({
        searchText: "",
        showModal: false,
    }),
    reducers: {
        setCrmInboxSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || "" }),
        },
    },
    extraReducers: {
        [getCrmInbox.fulfilled]: crmInboxAdapter.setAll,
    },
});

export const { setCrmInboxSearchText } = crmInboxSlice.actions;

export default crmInboxSlice.reducer;
