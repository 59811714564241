import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authCrmRoles } from 'app/crm';

import CrmUsersProfile from 'app/main/crm/user-profile/CrmUsersProfile';
import CrmBooking from 'app/main/crm/booking/CrmBooking';
import CrmBookingList from 'app/main/crm/booking-list/CrmBookingList';
import CrmBookingStatus from 'app/main/crm/status/CrmBookingStatus';
import CrmMotorpoolBooking from './motorpool/booking/CrmMotorpoolBooking';
import CrmMotorpoolBookingStatus from './motorpool/status/CrmMotorpoolBookingStatus';
import CrmMotorpoolBookingList from './motorpool/booking-list/CrmMotorpoolBookingList';

const CrmMyTickets = lazy(() => import('app/main/crm/my-tickets/CrmMyTickets'));
const CrmMyPoints = lazy(() => import('app/main/crm/my-points/CrmMyPoints'));
const CrmTenantVisitors = lazy(() => import('app/main/crm/tenant/visitors/Visitors'));
const CrmTenantVisitorStatus = lazy(() => import('app/main/crm/tenant/visitors/status/VisitorStatus'));
const CrmTenantApplication = lazy(() => import('app/main/crm/tenant/apply/Tenant'));
const CrmTenantsListView = lazy(() => import('app/main/crm/tenant/register/TenantsCardView'));
const CrmTenantStatus = lazy(() => import('app/main/crm/tenant/status/TenantStatus'));

const CrmUsersConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authCrmRoles.crm,
    routes: [
        {
            path: '/crm/my-tickets/:folderHandle',
            element: <CrmMyTickets />,
            children: [{ path: ':ticketId', element: <CrmMyTickets /> }],
        },
        {
            path: 'crm/my-tickets',
            element: <Navigate to="/crm/my-tickets/all-tickets" />,
        },
        {
            path: 'crm/user-profile/:crmUsersId/*',
            element: <CrmUsersProfile />,
        },
        {
            path: 'crm/my-points',
            element: <CrmMyPoints />,
        },
        {
            path: 'crm/booking',
            element: <CrmBooking />,
        },
        {
            path: 'crm/booking/status/:referenceNum/*',
            element: <CrmBookingStatus />,
        },
        {
            path: 'crm/booking/list',
            element: <CrmBookingList />,
        },
        {
            path: 'crm/residence-hall/visitors',
            element: <CrmTenantVisitors />,
        },
        {
            path: '/crm/residence-hall/:type/status/:referenceNum/*',
            element: <CrmTenantVisitorStatus />,
        },
        {
            path: 'crm/residence-hall/registration',
            element: <CrmTenantApplication />,
        },
        {
            path: 'crm/residence-hall/application',
            element: <CrmTenantsListView />,
        },
        {
            path: 'crm/residence-hall/status',
            element: <CrmTenantStatus />,
        },
        {
            path: 'crm/motorpool/booking',
            element: <CrmMotorpoolBooking />,
        },
        {
            path: 'crm/motorpool/booking/status/:referenceNum/*',
            element: <CrmMotorpoolBookingStatus />,
        },
        {
            path: 'crm/motorpool/booking/list',
            element: <CrmMotorpoolBookingList />,
        },
    ],
};

export default CrmUsersConfig;
