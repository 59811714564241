import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useEffect } from 'react';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import FormHelperText from '@mui/material/FormHelperText';

import { showMessage } from "app/store/fuse/messageSlice";
import { addEcommerceReviews } from "app/main/ecommerce/store/productSlice";

import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

const defaultValues = {
    name: "",
    reviews: "",
    email: "",
    rating: ""
};

function AddReviewsModal(props) {

    const token = EcommerceJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();

    const { itemId, orderId } = props;

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        // email: yup
        //     .string()
        //     .email("You must enter a valid email")
        //     .required("Email is required"),
        // name: yup.string().required("Name is required"),
        reviews: yup.string().required("Review is required"),
        rating: yup.string().required("Rating is required"),
    });

    const { openDialog, handleDialogOpen, handleDialogClose, fromOrders } = props;

    const { control, formState, handleSubmit, reset, setValue, watch, getValues, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    async function onSubmit() {

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        var postData = getValues();

        postData.itemId = itemId;
        postData.orderId = orderId;
        postData.name = token.data.displayName;
        postData.email = token.email;
        postData.userId = token.id;

        dispatch(addEcommerceReviews(postData)).then(function () {
            reset(defaultValues);
            handleDialogClose();
        });

    }

    useEffect(() => {

        reset(defaultValues);

    }, [openDialog]);

    return (
        <>
            {/* {(token && token.id && !fromOrders) && (
                <Button
                    className="bg-orange-400 text-12 py-4 px-10 truncate text-black hover:bg-orange-500 font-semibold border-1 rounded-md"
                    variant="contained"
                    onClick={() => handleDialogOpen()}
                    type="button"
                >
                    Add Review
                </Button>
            )} */}

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogContent classes={{ root: 'p-16 pb-0 sm:pt-24  sm:p-48' }}>
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            size="large"
                            className='float-right'
                        >
                            <Icon>close</Icon>
                        </IconButton>

                        <form
                            name="reviewForm"
                            noValidate
                            className="flex flex-col justify-center w-full mb-36"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Typography variant="h6" className=" font-semibold text-18 sm:text-24 text-center">
                                Add Review
                            </Typography>
                            <Typography className="mt-16 mb-36 text-center">
                                We love to hear from you how's your satisfaction for this product.
                            </Typography>

                            <Typography component="legend">Rating</Typography>

                            <Controller
                                name="rating"
                                control={control}
                                render={({ field }) => (
                                    <Rating
                                        {...field}
                                        id="rating"
                                        name="rating"
                                        size="large"
                                        precision={0.5}
                                        required
                                    />
                                )}
                            />

                            <FormHelperText error={!!errors.rating}>{errors?.rating?.message}</FormHelperText>

                            <Controller
                                name="reviews"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="review"
                                        className="mt-16"
                                        label="Your Review"
                                        type="text"
                                        required
                                        multiline
                                        rows={5}
                                        variant="outlined"
                                        error={!!errors.reviews}
                                        helperText={errors?.reviews?.message}
                                    />
                                )}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="w-full mx-auto mt-48 rounded-md h-48"
                                aria-label="LOG IN"
                                value="legacy"
                            >
                                Submit
                            </Button>
                        </form>
                    </motion.div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default AddReviewsModal;
