import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
    query,
    mutation,
} from "app/main/apps/residence-hall/graphql/visit_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

export const getVisit = createAsyncThunk(
    "residenceHallApp/visit-checkout-request/getVisit",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.list,
            variables: params
        });
        var data = response.data.data.visitList;

        return data;
    }
);

export const getVisitById = createAsyncThunk(
    "residenceHallApp/visit-checkout-request/getVisitById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getVisitByID,
            variables: {
                visitId: params.visitId,
            },
        });

        if (response.data.data.visitById) {
            var data = response.data.data.visitById;

            return data === undefined ? null : data;
        }
    }
);

export const removeVisit = createAsyncThunk(
    "residenceHallApp/visit-checkout-request/removeVisit",
    async (visitIds, { dispatch, getState }) => {

        await visitIds.map(async (id) => {
            await axios.post(CONFIG.API + "/api/", {
                query: mutation.delete,
                variables: {
                    id: id,
                },
            });
        });

        dispatch(
            showMessage({
                message: "Visit(s) has been successfully deleted.",
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: "success",
            })
        );

        return visitIds;
    }
);

export const approvedVisit = createAsyncThunk(
    "residenceHallApp/visit-checkout-request/approvedVisit",
    async (visitData, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.approvedVisit,
            variables: {
                data: JSON.stringify(visitData),
                id: visitData?.id,
            },
        });

        dispatch(
            showMessage({
                message: response.data.data.approvedVisit.message,
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.approvedVisit.status
            })
        );

        return response.data.data.saveVisit;
    }
);

const uploadImages = (uploadData, visitData, image) => {

    return new Promise(async (resolve, reject) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutationUpload.create,
            variables: {
                data: JSON.stringify(uploadData),
            },
        });

        if (response.data.data.createUpload.status == "success") {
            var upload = response.data.data.createUpload.upload;

            if (visitData.visitPicId == image.image.id) {
                visitData.visitPicPath = upload.path;
            }

            visitData.images[image.index].upload_id = upload.id;
            visitData.images[image.index].file_name = upload.fileName;
            visitData.images[image.index].file_id = upload.fileId;
            visitData.images[image.index].file_type = upload.fileType;
            visitData.images[image.index].path = upload.path;
            visitData.images[image.index].module = upload.module;
            visitData.images[image.index].url = "";

            resolve(visitData);

        }

    });

}

const saveImages = (visitData) => {

    return new Promise(async (resolve, reject) => {

        if (visitData.images.length) {

            var newImages = [];

            visitData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (visitData.visitPicId == image.fileId) {
                        visitData.visitPicPath = image.path;
                    }
                }

            });

            if (newImages.length) {

                var tempCount = 0;

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "residence-hall/visit/visit-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'residence-hall-visit';
                            uploadData['file_id'] = image.image.id;

                            await uploadImages(uploadData, visitData, image).then(async (visitData) => {

                                if (visitData) {
                                    tempCount++;
                                    if (newImages.length == tempCount) {
                                        resolve(visitData);
                                    }
                                }

                            });
                        }


                    });

                });

            } else {
                resolve(visitData)
            }


        } else {

            resolve(visitData);

        }

    });

}

export const saveVisit = createAsyncThunk(
    "residenceHallApp/visit-checkout-request/saveVisit",
    async (visitData, { dispatch, getState }) => {

        return await saveImages(visitData).then(async (visitData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.save,
                variables: {
                    data: JSON.stringify(visitData),
                    id: visitData?.id,
                },
            });

            dispatch(
                showMessage({
                    message: response.data.data.saveVisit.message,
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.saveVisit.status
                })
            );
            return response.data.data.saveVisit;

        });

    });

const visitAdapter = createEntityAdapter({});

export const {
    selectAll: selectVisit,
    selectById: selectVisitById,
} = visitAdapter.getSelectors((state) => state.residenceHallApp.visit);

const visitSlice = createSlice({
    name: "residenceHallApp/visit-checkout-request",
    initialState: visitAdapter.getInitialState({
        searchText: "",
        showModal: false,
    }),
    reducers: {
        setVisitSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || "" }),
        },
    },
    extraReducers: {
        [getVisit.fulfilled]: visitAdapter.setAll,
        [removeVisit.fulfilled]: (state, action) =>
            visitAdapter.removeMany(state, action.payload),
        [saveVisit.fulfilled]: (state, action) => action.payload,
    },
});

export const { setVisitSearchText } = visitSlice.actions;

export default visitSlice.reducer;
