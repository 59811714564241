const mutation = {
    create: `
      mutation createPatronFromBooking($data: JSONString!) {
          createPatronFromBooking (data: $data) {
            status,
            res,
            message
          }
      }
  `,
};

const query = {
}

export { mutation, query };