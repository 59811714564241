import { useFormContext, Controller } from "react-hook-form";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from "@mui/material/CardContent";
import format from 'date-fns/format';
import FormControl from "@mui/material/FormControl";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { acceptBooking, getBookingRefId, acceptMotorpoolBooking, getMotorpoolBookingRefId } from "app/main/booking/store/approveSlice";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Icon from "@mui/material/Icon";
import moment from 'moment';


const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));

function ApproveContent(props) {

    const { form, status, setForm, motorpool } = props;
    const dispatch = useDispatch();
    const routeParams = useParams();

    const [reason, setReason] = React.useState('');

    const handleClick = () => {

        form.approvalDate = moment().format('LLL');

        dispatch((motorpool ? acceptMotorpoolBooking : acceptBooking)(form)).then(function (response) {
            dispatch(
                showMessage({
                    message: response.message,
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.status,
                })
            );
            dispatch((motorpool ? getMotorpoolBookingRefId : getBookingRefId)({
                refNum: routeParams.referenceNum,
                bookingId: routeParams.bookingId
            })).then(function (response) {
                response.level = routeParams.level;
                response.approveeId = routeParams.approveeId;
                response.bookingId = routeParams.bookingId;
                response.approvedBy = (response.approvedBy ? JSON.parse(response.approvedBy) : []);
                response.bookingStatus = response.status;
                response.status = props.status;
                setForm(response);
            });
            // window.close();
        });

    }

    const handleChangeReason = (event) => {
        setReason(event.target.value);
        setForm({
            ...form,
            reason: event.target.value
        })
    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>
            <div className="w-full">
                <div className=" px-10 w-fit max-w-lg min-w-800 m-auto mb-48">


                    <Box sx={{ flexGrow: 1 }}>
                        <div className="text-right">
                            <Button
                                color="inherit" className="rounded-0 py"
                                onClick={handelClickPrint}
                            >
                                <Icon color="inherit" className="text-5xl">print</Icon>
                            </Button>
                        </div>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <DialogContent classes={{ root: 'py-0 px-10 pb-0 ' }} ref={printRef}>

                                <img className="m-auto" src="assets/images/logos/cmi-logo-blue.png" loading="lazy" />
                                <hr></hr>
                                <div className=" flex flex-col justify-center mx-16 mt-10">
                                    <Typography component="div" className=' text-2xl font-semibold text-center'>
                                        {
                                            status == 'accept' && <>
                                                Approve {form.itemType} Booking for {form.name}
                                            </>
                                        }
                                        {
                                            status == 'reject' && <>
                                                Reject {form.itemType} Booking for {form.name}
                                            </>
                                        }
                                        {
                                            status == 'download' && <>
                                                Download Invoice
                                            </>
                                        }
                                    </Typography>
                                </div>
                                <div className=" flex justify-between my-20 mx-16">
                                    <Typography className='text-14 font-semibold'>
                                        Booking Reference #: {form.referenceNum}
                                    </Typography>
                                </div>
                                <div className='flex flex-row'>
                                    <div className='flex flex-col mx-16'>
                                        <div className='flex'>
                                            <ImageList sx={{ width: 120, height: 120 }} cols={1} className="overflow-hidden rounded-md">
                                                <ImageListItem>
                                                    <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                                </ImageListItem>
                                            </ImageList>
                                        </div>
                                    </div>
                                    <div className='flex flex-col mx-16 relative'>
                                        <Typography className='text-14 font-semibold'>
                                            {form.itemCode}
                                        </Typography>
                                        <Typography className=' text-18 font-bold'>
                                            {form.itemName}
                                        </Typography>
                                    </div>
                                </div>
                                <div className='flex flex-row justify-between mt-24 mb-16'>
                                    <div className='flex flex-col mx-16 '>
                                        <Typography className=' text-14 font-semibold mt-10'>
                                            <span className=" text-14 font-medium">Name: </span>
                                            {form.name}
                                        </Typography>
                                        <Typography className=' text-14 font-semibold'>
                                            <span className=" text-14 font-medium">Email: </span>
                                            {form.email}
                                        </Typography>
                                        <Typography className=' text-14 font-semibold'>
                                            <span className=" text-14 font-medium">Contact: </span>
                                            {form.contact}
                                        </Typography>
                                        <Typography className=' text-14 font-semibold'>
                                            <span className=" text-14 font-medium">Department: </span>
                                            {form.department}
                                        </Typography>
                                    </div>
                                    <div className='flex flex-col mx-16'>
                                        <Typography className=' text-14 font-semibold mt-10'>
                                            <span className=" text-14 font-medium">Start: </span>
                                            {form.start ? format(new Date(form.start), 'EEEE, MMMM dd, yyyy') : ""} {form.start ? format(new Date(form.start), 'hh:mm a') : ""} - {form.end ? format(new Date(form.end), 'hh:mm a') : ""}
                                        </Typography>
                                        <Typography className=' text-14 font-semibold'>
                                            <span className=" text-14 font-medium">Duration: </span>
                                            {form.duration}
                                        </Typography>
                                        <Typography className=' text-14 font-semibold'>
                                            <span className=" text-14 font-medium">Time of Booking: </span>
                                            {form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}
                                        </Typography>
                                    </div>
                                </div>

                                <div className='flex flex-col mx-16 mb-24'>
                                    <Typography className=' text-14 font-semibold'>
                                        Purpose
                                    </Typography>
                                    <Typography className='text-14  '>
                                        {form.purpose}
                                    </Typography>
                                </div>

                                {
                                    (form.bookingStatus != 'Rejected' && status == 'reject' && !form.approvedBy.includes(form.level)) && <div className='flex flex-col mx-16 mb-24'>
                                        <Typography className='text-14 font-semibold'>
                                            Reason
                                        </Typography>
                                        <FormControl className="flex mx-16 mt-4 mb-9 ml-0" variant="outlined">
                                            <TextField
                                                id="reason"
                                                type="text"
                                                value={reason}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                onChange={handleChangeReason}
                                                sx={{
                                                    "& .MuiOutlinedInput-root.MuiInputBase-root ": { padding: "8px 8px" }
                                                }}
                                            />

                                        </FormControl>
                                    </div>
                                }
                                {
                                    form.bookingStatus == 'Rejected' && <div className='flex flex-col mx-16 mb-24'>
                                        <Typography className='text-14 font-semibold'>
                                            Reason
                                        </Typography>
                                        <FormControl className="flex mx-16 mt-4 mb-9 ml-0" variant="outlined">
                                            {reason || form.reason}

                                        </FormControl>
                                    </div>
                                }

                                {
                                    (form.approvees && form.approvees.length > 0) &&
                                    <div className='flex flex-col mx-16 mb-24'>
                                        <Typography className=' text-14 font-semibold'>
                                            Approved by:
                                        </Typography>

                                        {
                                            form.approvees.map(function (approvee) {
                                                return (
                                                    <div className='flex flex-row justify-between' key={approvee.id}>
                                                        <div className='flex flex-col mx-3 '>
                                                            <Typography className=' text-14 font-semibold mt-10'>
                                                                <span className=" text-14 font-medium">Name: </span> {approvee.name}
                                                            </Typography>
                                                            <Typography className=' text-14 font-semibold'>
                                                                <span className=" text-14 font-medium">Department: </span> {approvee.department}
                                                            </Typography>
                                                            <Typography className=' text-14 font-semibold'>
                                                                <span className=" text-14 font-medium">Position: </span> {approvee.position}
                                                            </Typography>
                                                            <Typography className=' text-14 font-semibold'>
                                                                <span className=" text-14 font-medium">Date of Approval: </span> {approvee.date}
                                                            </Typography>
                                                            <Typography className=' text-14 font-semibold'>
                                                                <span className=" text-14 font-medium">Status: </span> Approved
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }

                            </DialogContent>
                        </Grid>
                    </Box>
                    {
                        (form.bookingStatus != 'Rejected' && status == 'accept' && !form.approvedBy.includes(form.level)) &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <CardContent className='p-0 pb-0 text-center' sx={{ pb: '0px!important' }}>
                                <Button
                                    className="mx-4 mt-16 mb-16 ml-1 mr-1 rounded"
                                    variant="contained"
                                    onClick={handleClick}
                                >
                                    Accept Booking
                                </Button>
                            </CardContent>
                        </Box>
                    }

                    {
                        (form.bookingStatus != 'Rejected' && status == 'reject' && !form.approvedBy.includes(form.level)) &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <CardContent className='p-0 pb-0  text-center' sx={{ pb: '0px!important' }}>
                                <Button
                                    className="mx-4 mt-16 mb-16 bg-red-700 ml-1 mr-1 rounded"
                                    variant="contained"
                                    onClick={handleClick}
                                >

                                    Reject
                                </Button>
                            </CardContent>
                        </Box>
                    }
                    {
                        form.bookingStatus == 'Rejected' &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <Box sx={{
                                bgcolor: '#ffe5e5',
                                m: 1,
                                width: '100%',
                                height: '100%',
                                margin: 'unset',
                                borderRadius: 1,
                                py: 2
                            }}>
                                <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                    <Typography variant="h4 font-bold text-red-700 text-center" component="div">
                                        This booking is rejected and email has been sent to the requestor.
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Box>
                    }

                    {
                        (form.bookingStatus == 'Approved' || form.approvedBy.includes(form.level)) &&
                        <Box sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <Box sx={{
                                bgcolor: '#F1FFF7',
                                m: 1,
                                width: '100%',
                                height: '100%',
                                margin: 'unset',
                                borderRadius: 1,
                                py: 2
                            }}>
                                <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                    <Typography variant="h4 font-bold text-green-700 text-center" component="div">
                                        {form.bookingStatus}

                                    </Typography>
                                </CardContent>
                            </Box>
                        </Box>
                    }

                </div>
            </div>
        </>
    );

}

export default ApproveContent;