const mutation = {
    approveTenant: `
        mutation approveTenant($data: JSONString!) {
            approveTenant (data: $data) {
                status,
                res,
                message
            }
        }
  `,
};

const query = {
    getTenantRefId: `
        query tenantByRefId($refNum: String!, $tenantId: String!) {
            tenantByRefId (refNum: $refNum, tenantId: $tenantId){    
                id
                patron {
                    name
                    email
                    emergencyContact
                    departmentName
                }
                house {
                    houseName
                }
                houseId
                roomId
                rejectedBy
                rejectReason
                referenceNum
                roomNumber
                totalPrice
                bedNumber
                roomPrice
                mealCredit
                bookCredit
                status
                semester
                year
                userName
                userPosition
                start
                end
                timeOfBooking
                approvedById
                approvedBy
                approvees
                approvee {
                    id
                    email
                    name
                    position
                    departments
                }
            }
        }
    `
}

export { mutation, query };