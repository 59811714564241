import * as React from 'react';

import { useFormContext, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { experimentalStyled as styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from "react";

import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography';
import { FormHelperText, Input } from '@mui/material';

import { getMotorpoolDriverByLicenseNumber } from "app/main/apps/motorpool/store/driverManagementSlice";
import { format } from 'date-fns';
import DriverManagementNewModal from 'app/main/apps/motorpool/booking/driver-management/content/DriverManagementNewModal';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));


function Tab2(props) {

    const { activeTab } = props;

    if (activeTab != 1) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { control, formState, setValue, trigger, watch } = methods;
    const { errors } = formState;

    const [driverLicenseNumber, setDriverLicenseNumber] = useState("");

    const form = watch();

    useEffect(() => {
        console.log(driverLicenseNumber)
        dispatch(getMotorpoolDriverByLicenseNumber(driverLicenseNumber)).then((action) => {
            console.log("getMotorpoolDriverByLicenseNumber", action.payload)
            if (!!action.payload) {
                setValue('driverData', action.payload);
                setDriverLicenseNumber("");
            } else {
                if (!!driverLicenseNumber) {
                    setValue('driverData', {});
                }
            }
        });

    }, [driverLicenseNumber]);

    function isNotEmptyObject(value) {
        return value && typeof value === 'object' && Object.keys(value).length > 0;
    }

    function prefillData(val) {
        setDriverLicenseNumber(val);
    }

    useEffect(() => {
        console.log('errors', errors)

    }, [errors]);

    return (
        <div className="w-full">
            <>
                <div className="p-9 pb-0 w-1/2 min-w-320 m-auto mb-48 mt-32">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <Grid item xs={12} sm={12} md={12}>
                                <Item elevation={0} className="pb-0 max-w-sm m-auto">
                                    <div className="flex items-center  m-auto">
                                        <FormControl
                                            className="flex w-full mt-9 "
                                            variant="outlined"
                                        >
                                            <Typography className="text-15" color="text.primary">Driver License # <span className="text-red-500"></span></Typography>
                                            <Paper
                                                component={motion.div}
                                                initial={{ y: -20, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                                                fullWidth
                                                className="flex items-center px-8 py-4 rounded-md shadow-0 border-1 h-40px border-search-grey  mb-16"
                                            >
                                                <Input
                                                    placeholder="Enter License Number"
                                                    disableUnderline
                                                    className="flex flex-1 mx-8 min-w-200 max-w-320"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={driverLicenseNumber}
                                                    inputProps={{
                                                        'aria-label': 'Enter Invoice Number',
                                                    }}
                                                    onChange={(ev) => setDriverLicenseNumber(ev.target.value)}
                                                />
                                            </Paper>
                                        </FormControl>

                                    </div>

                                    {isNotEmptyObject(form.driverData) && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{
                                                bgcolor: form.statusBanned != 'true' ? '#F1FFF7' : '#ffe5e5',
                                                border: 2,
                                                borderColor: form.statusBanned != 'true' ? '#00843C' : '#ffc1c1',
                                                borderRadius: 1,
                                                width: "100%"
                                            }}>
                                                <CardContent>
                                                    <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                        Name:
                                                    </Typography>
                                                    <Typography className='text-black text-16 font-medium' component="div">
                                                        {form.driverData.name}
                                                    </Typography>
                                                    <br />

                                                    <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                        License Number:
                                                    </Typography>
                                                    <Typography className='text-black text-16 font-medium' component="div">
                                                        {form.driverData.licenseNumber}
                                                    </Typography>
                                                    <br />

                                                    <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                        Expiration:
                                                    </Typography>
                                                    <Typography className='text-black text-16 font-medium' component="div">
                                                        {format(new Date(form.driverData.licenseExpiration), 'MM/dd/yyyy hh:mm a')}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Box>
                                    )}

                                    {!isNotEmptyObject(form.driverData) && driverLicenseNumber != '' && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{
                                                bgcolor: '#FFECEC',
                                                m: 1,
                                                border: 2,
                                                width: '100%',
                                                height: '100%',
                                                margin: 'unset',
                                                borderColor: '#C11C20',
                                                borderRadius: 1,
                                                py: 2
                                            }}>
                                                <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                                    <Typography variant="h4 font-bold text-red-700 text-center mb-6" component="div">
                                                        {driverLicenseNumber} NOT FOUND.
                                                    </Typography>
                                                    <Typography variant="h4 font-bold text-center" component="div">
                                                        <DriverManagementNewModal module="item" prefillData={prefillData} isCrm={true} />
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Box>
                                    )}


                                    {/* {form.name && (
                                        <div className=' mt-32 items-center justify-between'>
                                            {(form.statusBanned != 'true') && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{
                                                        bgcolor: form.statusBanned != 'true' ? '#F1FFF7' : '#ffe5e5',
                                                        border: 2,
                                                        borderColor: form.statusBanned != 'true' ? '#00843C' : '#ffc1c1',
                                                        borderRadius: 1,
                                                        width: "100%"
                                                    }}>
                                                        <CardContent>
                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Name:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.name}
                                                            </Typography>
                                                            <br />

                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Contact:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.contact}
                                                            </Typography>
                                                            <br />

                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Address:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.address}
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Box>
                                            )}
                                            {form.statusBanned == 'true' && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{
                                                        bgcolor: '#FFECEC',
                                                        m: 1,
                                                        border: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: 'unset',
                                                        borderColor: '#C11C20',
                                                        borderRadius: 1,
                                                        py: 2
                                                    }}>
                                                        <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                                            <Typography variant="h4 font-bold text-red-700 text-center" component="div">
                                                                User has been banned from booking.
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Box>
                                            )}
                                        
                                        </div>
                                    )} */}
                                </Item>

                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </>
        </div>
    );
}

export default Tab2;
