import * as React from 'react';

import { useFormContext, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { experimentalStyled as styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Icon from "@mui/material/Icon";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography';

import { searchPatron } from "app/main/booking/store/bookingSlice";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));


function Tab1(props) {

    const { activeTab } = props;

    if (activeTab != 0) {
        return (<></>);
    }

    const methods = useFormContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { control, formState, setValue, trigger, watch } = methods;
    const { errors } = formState;

    const form = watch();

    async function handleSearchHandle() {
        console.log(form)

        await trigger(['empId', 'email']);

        if (form.empId && form.email) {

            dispatch(searchPatron({
                empId: form.empId,
                email: form.email
            })).then((action) => {
                if (action.payload) {
                    var patron = action.payload;
                    setValue("name", patron.name);
                    setValue('contact', patron['emergencyContact']);
                    setValue('address', patron['fullAddress']);
                    setValue('email', patron['email']);
                    setValue('department', patron['departmentName']);
                    setValue("patronId", patron["id"]);
                    setValue('statusBanned', patron['blacklist'].length > 0 ? true : false);
                    setValue('statusBooking', patron['booking'] ? true : false);
                    setValue('prevStatus', patron['booking'] ? patron['booking']['status'] : "");
                    setValue("recordNotFound", false);
                } else {
                    setValue("recordNotFound", true);
                    setValue("name", '');
                    setValue('contact', '');
                    setValue('address', '');
                    setValue('email', '');
                    setValue('department', '');
                    setValue("patronId", '');
                    setValue('statusBanned', false);
                    setValue('statusBooking', false);
                    setValue('prevStatus', "");
                }
                console.log(action.payload, form)
            });

        }

    }

    return (
        <div className="w-full">
            <>
                <div className="p-9 pb-0 w-1/2 min-w-320 m-auto mb-48 mt-32">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <Grid item xs={12} md={5} className="pt-0 ">
                                <Item elevation={0} className="p-0">
                                    <>
                                        <div className="flex items-center justify-between">
                                            <FormControl
                                                className="flex mx-16 w-full mt-9  ml-0"
                                                variant="outlined"
                                            >
                                                <Typography className="text-15" color="text.primary">Employee ID <span className="text-red-500">*</span></Typography>

                                                <Controller
                                                    name="empId"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            id="empId"
                                                            type="text"
                                                            variant="outlined"
                                                            error={!!errors.empId}
                                                            helperText={errors?.empId?.message}
                                                            required
                                                            sx={{

                                                                "& .MuiInputBase-root": {
                                                                    height: 40
                                                                },
                                                                "& .MuiOutlinedInput-root": {
                                                                    pr: 2, py: 0
                                                                },
                                                                "& .MuiAutocomplete-input": {
                                                                    pl: "0px !important"
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />

                                            </FormControl>
                                        </div>
                                    </>
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={7} className="pt-0 ">
                                <Item elevation={0} className="p-0">
                                    <div className="flex items-center justify-between">
                                        <FormControl
                                            className="flex mx-16 w-full mt-9  ml-0"
                                            variant="outlined"
                                        >
                                            <Typography className="text-15" color="text.primary">Registered Email <span className="text-red-500">*</span></Typography>

                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field }) => (
                                                    <div className="flex items-start justify-between ">
                                                        <TextField
                                                            {...field}
                                                            id="email"
                                                            type="text"
                                                            variant="outlined"
                                                            error={!!errors.email}
                                                            helperText={errors?.email?.message}
                                                            required
                                                            sx={{

                                                                "& .MuiInputBase-root": {
                                                                    height: 40
                                                                },
                                                                "& .MuiOutlinedInput-root": {
                                                                    pr: 2, py: 0
                                                                },
                                                                "& .MuiAutocomplete-input": {
                                                                    pl: "0px !important"
                                                                }
                                                            }}
                                                        />
                                                        <motion.div
                                                            className="flex mx-16 mr-0"
                                                            initial={{ opacity: 0, x: 20 }}
                                                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                                        >
                                                            <Button
                                                                className="w-full max-h-40 rounded-md py-9"
                                                                variant="contained"
                                                                onClick={handleSearchHandle}
                                                            >
                                                                <Icon className="hidden sm:flex">search</Icon> Search
                                                            </Button>
                                                        </motion.div>
                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </Item>
                            </Grid>
                            {/* <div className="flex items-center justify-between mt-9">
                                <Typography variant="h4 font-bold" component="div">
                                    Not register yet? <a className='cursor-pointer' onClick={() => {
                                        navigate("/booking/register")
                                    }}>Register here.</a>
                                </Typography>
                            </div> */}

                            <Grid item xs={12} md={12} className="pt-0 mt-32">
                                <Item elevation={0} className="p-0">
                                    {form.name && (
                                        <div className=' items-center justify-between'>
                                            {(!form.statusBanned && !(form.statusBooking && form.prevStatus != 'Done' && form.prevStatus != 'Rejected')) && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{
                                                        bgcolor: !form.statusBanned ? '#F1FFF7' : '#ffe5e5',
                                                        m: 1,
                                                        border: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: 'unset',
                                                        borderColor: !form.statusBanned ? '#00843C' : '#ffc1c1',
                                                        borderRadius: 1,
                                                    }}>
                                                        <CardContent>
                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Name:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.name}
                                                            </Typography>
                                                            <br />

                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Department:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.department}
                                                            </Typography>
                                                            <br />

                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Contact:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.contact}
                                                            </Typography>
                                                            <br />

                                                            <Typography className='text-black text-16 mb-0' color="text.secondary" gutterBottom>
                                                                Address:
                                                            </Typography>
                                                            <Typography className='text-black text-16 font-medium' component="div">
                                                                {form.address}
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Box>
                                            )}

                                            {form.statusBanned && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{
                                                        bgcolor: '#FFECEC',
                                                        m: 1,
                                                        border: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: 'unset',
                                                        borderColor: '#C11C20',
                                                        borderRadius: 1,
                                                        py: 2
                                                    }}>
                                                        <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                                            <Typography variant="h4 font-bold text-red-700 text-center" component="div">
                                                                User has been banned from booking.
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Box>
                                            )}

                                            {(form.statusBooking && form.prevStatus != 'Done' && form.prevStatus != 'Rejected') && (
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Box sx={{
                                                        bgcolor: '#FFECEC',
                                                        m: 1,
                                                        border: 2,
                                                        width: '100%',
                                                        height: '100%',
                                                        margin: 'unset',
                                                        borderColor: '#C11C20',
                                                        borderRadius: 1,
                                                        py: 2
                                                    }}>
                                                        <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                                            <Typography variant="h4 font-bold text-red-700 text-center" component="div">
                                                                You have a current status for {form.prevStatus} booking.
                                                            </Typography>
                                                        </CardContent>
                                                    </Box>
                                                </Box>
                                            )}

                                        </div>
                                    )}

                                    {form.recordNotFound && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box sx={{
                                                bgcolor: '#f1f6ff',
                                                m: 1,
                                                border: 2,
                                                width: '100%',
                                                height: '100%',
                                                margin: 'unset',
                                                borderColor: '#80a8d6',
                                                borderRadius: 1,
                                                py: 2
                                            }}>
                                                <CardContent className='p-0 pb-0 p' sx={{ pb: '0px!important' }}>
                                                    <Typography color="text.primary" variant="h4 font-bold text-center" component="div">
                                                        Record Not Found! <a className='cursor-pointer' onClick={() => {
                                                            navigate("/booking/register")
                                                        }}>Register Here.</a>
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                        </Box>
                                    )}
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </>
        </div>
    );
}

export default Tab1;
