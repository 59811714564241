/*****
* Module: User
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-41515-96
* DateUpdated: 11/14/2022
* Updated By: Johnroe
* Update Summary: Check user permission service
*/

import FuseUtils from '@fuse/utils/FuseUtils';

import { useSelector } from 'react-redux';


class UserService extends FuseUtils.EventEmitter {

    checkPermission(roles) {

        const user = useSelector(({ auth }) => auth.user);

        if (user.role == "admin") {
            return true;
        }

        if ((roles && roles.length === 0)) {
            return true;
        }

        if (user.roles === null || user.roles === undefined) {
            return true;
        }

        if (user.roles.length === 0 && user.role) {
            return ['admin', 'staff', 'user'].includes(user.role);
        }

        if (user.roles.length === 0) {
            return !user.role || user.role.length === 0;
        }
        
        if (roles && Array.isArray(roles)) {
            return user.roles.some((r) => roles.indexOf(r) >= 0);
        }

        if (user.roles.length && user.roles.length) {
            return user.roles.some((v) => user.roles.includes(v))
        }

        return user.roles.includes(roles);
    }
}

const instance = new UserService();

export default instance;