
import * as yup from 'yup';
import _ from '@lodash';
import { useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from "@mui/material/InputAdornment";
import Popover from '@mui/material/Popover';

import RegisterModal from "app/main/ecommerce/modal/RegisterModal";
import { submitLogin, logoutUser } from "app/main/ecommerce/store/loginSlice";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import ForgotPasswordModal from './ForgotPasswordModal';
import { useNavigate } from 'react-router-dom';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().required('Please enter your username.'),
    password: yup
        .string()
        .required('Please enter your password.'),
    // .min(8, 'Password is too short - should be 8 chars minimum.'),
});

const defaultValues = {
    email: '',
    password: '',
    remember: 'false',
};

function LoginModal(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = EcommerceJwtService.getDecodedAccessToken();

    const { openDialogLogin, handleDialogLoginOpen, handleDialogLoginClose, fromProduct } = props;

    const { control, formState, handleSubmit, reset, trigger } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { errors } = formState;

    async function onSubmit(data) {

        await trigger(["username", "password"]);

        dispatch(submitLogin(data)).then(function () {
        });
    }

    const [openDialogRegister, setOpenDialogRegister] = useState(false);
    const [openDialogForgotPassword, setOpenDialogForgotPassword] = useState(false);

    function handleDialogForgotPasswordOpen() {
        setOpenDialogForgotPassword(true);
    }

    function handleDialogForgotPasswordClose() {
        setOpenDialogForgotPassword(false);
    }

    function handleDialogRegisterOpen() {
        setOpenDialogRegister(true);
    }

    function handleDialogRegisterClose() {
        setOpenDialogRegister(false);
    }

    useEffect(() => {

        reset(defaultValues);
        EcommerceJwtService.setJwtLoginReload(false);

    }, [openDialogLogin]);

    const [userMenu, setUserMenu] = useState(null);

    const [showPassword, setShowPassword] = useState(false);

    const userMenuClick = (event) => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (
        <>
            {(token && token.id && !fromProduct) && (
                <>
                    <Button
                        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
                        onClick={userMenuClick}
                        color="inherit"
                    >
                        <div className="hidden md:flex flex-col mx-4 items-end">
                            <Typography component="span" className="font-semibold flex text-white">
                                {token?.username}
                            </Typography>
                        </div>
                        <Avatar className="md:mx-4">{token?.username[0]}</Avatar>
                    </Button>
                    <Popover
                        open={Boolean(userMenu)}
                        anchorEl={userMenu}
                        onClose={userMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        classes={{
                            paper: 'py-8',
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                navigate(`/e-commerce/my-account`)
                            }}
                        >
                            <ListItemIcon className="min-w-40">
                                <Icon>person_outline</Icon>
                            </ListItemIcon>
                            <ListItemText primary="My Account" />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                navigate(`/e-commerce/orders`)
                            }}
                        >
                            <ListItemIcon className="min-w-40">
                                <Icon>shopping_cart</Icon>
                            </ListItemIcon>
                            <ListItemText primary="My Orders" />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(logoutUser());
                                userMenuClose();
                            }}
                        >
                            <ListItemIcon className="min-w-40">
                                <Icon>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </MenuItem>
                    </Popover>
                </>
            )}

            {(!token.length && Array.isArray(token) && !fromProduct) && (
                <Tooltip title="Account" placement="bottom">
                    <IconButton
                        className='w-40 h-40 font-100 text-white'
                        size="large"
                        onClick={() => handleDialogLoginOpen()}
                    >
                        <Icon>person_outline</Icon>
                    </IconButton>
                </Tooltip>
            )}

            <Dialog
                open={openDialogLogin}
                onClose={handleDialogLoginClose}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth={true}
                maxWidth="xs"
            >
                <DialogContent classes={{ root: 'p-16 pb-5 sm:pt-24 sm:p-48' }}>
                    <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => handleDialogLoginClose()}
                            size="large"
                            className='float-right'
                        >
                            <Icon>close</Icon>
                        </IconButton>

                        <form
                            name="loginForm"
                            noValidate
                            className="flex flex-col justify-center w-full mb-36 max-600px:mb-0"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Typography variant="h6" className=" font-semibold text-18 sm:text-24 text-center">
                                Log in
                            </Typography>
                            <Typography className="mt-16 mb-36 text-center">
                                Welcome back to CMI One Stop Shop. Please put your login credentials below to start shopping.
                            </Typography>

                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-16"
                                        label="Username"
                                        autoFocus
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-10"
                                        label="Password"
                                        type="password"
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                        variant="outlined"
                                        InputProps={{
                                            className: "pr-2",
                                            type: showPassword ? "text" : "password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        size="large"
                                                    >
                                                        <Icon className="text-20" color="action">
                                                            {showPassword ? "visibility" : "visibility_off"}
                                                        </Icon>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        required
                                    />
                                )}
                            />

                            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between max-600px:items-start">
                                <Controller
                                    name="remember"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl>
                                            <FormControlLabel label="Remember Me" control={<Checkbox {...field} />} />
                                        </FormControl>
                                    )}
                                />

                                <ForgotPasswordModal
                                    openDialogForgotPassword={openDialogForgotPassword}
                                    handleDialogForgotPasswordOpen={handleDialogForgotPasswordOpen}
                                    handleDialogForgotPasswordClose={handleDialogForgotPasswordClose}
                                />
                            </div>

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="w-full mx-auto my-32 rounded-md h-48 max-600px:my-20"
                                aria-label="LOG IN"
                                value="legacy"
                            >
                                Login
                            </Button>
                        </form>

                        <div className="flex flex-col sm:flex-row items-center justify-center max-600px:mb-20 max-600px:items-start">
                            <span className="font-normal mr-16">Not yet a member?</span>

                            <RegisterModal
                                openDialogRegister={openDialogRegister}
                                handleDialogRegisterOpen={handleDialogRegisterOpen}
                                handleDialogRegisterClose={handleDialogRegisterClose}
                            />
                        </div>
                    </motion.div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default LoginModal;
