// * Updated By: ICO
// * Summary:  ublic Pages - Ecommerce Design Concerns
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44126-96
// * DateUpdated:  01/17/2023

// * Updated By: ICO
// * Summary:  public Pages - Ecommerce Design Concerns
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-45428-96
// * DateUpdated:  01/24/2023
import * as React from 'react';
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Typography from "@mui/material/Typography";

import { getItems, getRelatedItems, getReviews, getItemRecommendations } from "app/main/ecommerce/store/productSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import format from 'date-fns/format';

import AddReviewsModal from "app/main/ecommerce/modal/AddReviewsModal";
import LoginModal from "app/main/ecommerce/modal/LoginModal";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FuseCountdownProduct from "@fuse/core/FuseCountdown/FuseCountdownProduct";
import { fontSize } from "@mui/system";
import Carousel from 'better-react-carousel';
import styledCarousel from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TelegramShareButton, TelegramIcon, LinkedinShareButton, LinkedinIcon, } from "react-share";
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import Checkbox from '@mui/material/Checkbox';
import { showMessage } from "app/store/fuse/messageSlice";
import globalFunction from 'app/services/globalFunction';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const StyledFuseCountdownProduct = styled(FuseCountdownProduct)(({ theme }) => ({

    '& .MuiTypography-root': {
        fontSize: '1.6rem',
        marginTop: '-1px'
    }
}));
const StyledFuseCountdownProductMobile = styled(FuseCountdownProduct)(({ theme }) => ({

    '& .MuiTypography-root': {
        fontSize: '1.2rem',
        marginTop: '-1px'
    }
}));
const Root = styled('div')(({ theme }) => ({

    '& .Carousel__RailWrapper-sc-hyhecw-1': {
        margin: "0 0"
    }
}));
const BreadcrumbsStyled = styled(Breadcrumbs)(({ theme }) => ({
    flexWrap: 'nowrap',
    width: 'fit-content',
    '& .MuiBreadcrumbs-ol': { flexWrap: 'nowrap' }
}));
const StyledBtn = styledCarousel.div`
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 20px;
    color: black;
    opacity: 0.6;
    cursor: pointer;
    top: 50%;
    z-index: 10;
    transition: all 0.25s;
    transform: ${({ type }) =>
        `translateY(-50%) ${type === 'left' ? 'rotate(180deg)' : ''}`};
    left: ${({ type }) => (type === 'left' ? '-20px' : 'initial')};
    right: ${({ type }) => (type === 'right' ? '-20px' : 'initial')};

    &:hover {
        background: gray;
        color: #fff;
        opacity: 0.5;
    }
`;


const CustomDot = styledCarousel.span`
  display: inline-block;
  height: ${({ isActive }) => (isActive ? '8px' : '5px')};
  width: ${({ isActive }) => (isActive ? '8px' : '5px')};
  background: ${({ isActive }) => (isActive ? '#1890ff' : '#1890ff78')};
  transition: all 0.2s;
`
function ProductContent(props) {

    const token = EcommerceJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleClickCart, handleClickCartMultiple, item, setItem } = props;
    const [realtedProducts, setRelatedProducts] = useState([]);
    const [recommendedProducts, setRecommendedProducts] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [relatedLoading, setRelatedLoading] = useState(false);
    const [reviewsLoading, setReviewsLoading] = useState(false);
    const [recommendationsLoading, setRecommendationsLoading] = useState(false);
    const routeParams = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [reviewTab, setReviewTab] = useState("all");
    const [checkedItemsCount, setCheckedItemsCount] = useState(0);
    const [checkedItemsTotalSrp, setCheckedItemsTotalSrp] = useState(0);

    const [openDialogLogin, setOpenDialogLogin] = useState(false);

    const theme = useTheme();
    const max1300px = useMediaQuery(theme.breakpoints.up('lg'));
    function handleDialogLoginOpen() {
        setOpenDialogLogin(true);
    }

    function handleDialogLoginClose() {
        setOpenDialogLogin(false);
    }

    const { itemId } = routeParams;

    useEffect(() => {
        setLoading(true);
        dispatch(getItems(itemId)).then((action) => {


            if (action.payload) {
                setItem(action.payload);
                if (!action.payload.stock) {
                    setQuantity(0);
                } else {
                    setQuantity(1);
                }
            }
            setLoading(false);
        });
    }, [dispatch, routeParams]);

    useEffect(() => {
        if (item.id) {

            setRelatedLoading(true);
            dispatch(getRelatedItems({
                category: item.category
            })).then((action) => {

                setRelatedProducts(action.payload);

                setRelatedLoading(false);
            });

            setReviewsLoading(true);
            dispatch(getReviews({ itemId: itemId, reviewTab: reviewTab })).then((action) => {

                setReviews(action.payload);

                setReviewsLoading(false);
            });

            setRecommendationsLoading(true);
            dispatch(getItemRecommendations({ itemId: itemId })).then((action) => {

                console.log("HHHHHHHHHHHHHHHHHHHHHH", action.payload);

                setRecommendedProducts(action.payload);

                setRecommendationsLoading(false);
            });
        }
    }, [item]);

    useEffect(() => {
        setReviewsLoading(true);
        dispatch(getReviews({ itemId: itemId, reviewTab: reviewTab })).then((action) => {

            setReviews(action.payload);

            setReviewsLoading(false);
        });
    }, [reviewTab]);

    useEffect(() => {
        const checkedItems = recommendedProducts.filter(item => item.isCheck).length;
        const totalSRP = recommendedProducts
            .filter(item => item.isCheck)
            .reduce((acc, item) => acc + parseFloat(item.srp), 0)
            .toFixed(2);

        setCheckedItemsCount(checkedItems);
        setCheckedItemsTotalSrp(totalSRP);

    }, [recommendedProducts]);

    function handleQuantityChange(operation) {
        switch (operation) {
            case '+':
                var newQuantity = parseFloat(quantity) + 1;
                if (item.stock < newQuantity) {
                    return dispatch(
                        showMessage({
                            message: "Available stock is " + item.stock,
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                } else {
                    setQuantity(parseFloat(newQuantity));
                }
                break;
            case '-':
                var newQuantity = parseFloat(quantity) - 1;
                if (1 > newQuantity) {
                    return dispatch(
                        showMessage({
                            message: "Quantity must not be equal to 0.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                } else {
                    setQuantity(parseFloat(newQuantity));
                }
                break;
        }
    }

    function handleCheckItem(id) {
        const updatedItems = recommendedProducts.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    isCheck: item.isCheck ? false : true
                };
            }
            return item;
        });

        setRecommendedProducts(updatedItems);
    }

    function handleDialogOpen() {
        setOpenDialog(true);
    }

    function handleDialogClose() {
        setOpenDialog(false);
    }

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    function handleClickImage(image) {
        setItem({
            ...item,
            photoUrl: image.url
        })
    }

    function handleClickItem(item) {
        navigate(`/e-commerce/product/${item.id}`);
    }

    function handleClickTags(tag) {

        navigate(`/e-commerce/shop/${tag}`);

    }

    const handleHome = () => {
        navigate("/e-commerce/home");
    }

    if (loading) {

        return <div className="mt-25vh"><FuseLoading /></div>

    }

    if (!loading && !item.id) {
        return (
            <div>
                Item not found.
            </div>
        )
    }
    const LeftBtn = <StyledBtn type="left"><Icon>arrow_right</Icon></StyledBtn>
    const RightBtn = <StyledBtn type="right"><Icon>arrow_right</Icon></StyledBtn>

    return (

        <Root className="px-24 pb-48">
            <div role="presentation" onClick={handleClick} className="py-20 font-medium w-full overflow-hidden">
                <BreadcrumbsStyled aria-label="breadcrumb" >
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important' }} color="inherit" href="/e-commerce/home" >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" onClick={() => handleHome()} />
                    </Link>
                    <Link className="truncate ..." underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important' }} color="inherit" href="/getting-started/installation/" >
                        {item.category}
                    </Link>
                    {/* <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important' }} color="inherit" href="/getting-started/installation/" >
                        Type 1
                    </Link> */}

                    <Typography className="max-600px:w-3/4 truncate ..." color="text.primary" >
                        {item.name}
                    </Typography>

                </BreadcrumbsStyled>
            </div>
            <div className="bg-white border-1 border-1-gray-50 border-opacity-5">
                <div className="py-14 px-24">
                    <div className="body-font">
                        {/* Start Desktop View */}
                        <Stack direction={max1300px ? 'row' : 'column'} spacing={1} justifyContent="" className="max-600px:hidden w-full">
                            <Stack direction={'row'} spacing={1} justifyContent="center" className="">
                                <Box sx={{ display: 'flex', height: 495 }}>
                                    <Tabs orientation="vertical"
                                        variant="scrollable"
                                        value={0}
                                        className="cursor-pointer max-600px:mr-0 "
                                        sx={{
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                '&.Mui-disabled': { opacity: 0.3 },
                                            },

                                            '& .MuiTabs-indicator': { display: "none" },
                                            '& .MuiTabs-flexContainer button': { width: "93px", height: "93px", flexDirection: "column-reverse", border: "solid 1px rgba(0, 0, 0, 0.12)" },

                                        }}
                                    >


                                        {
                                            item.images && item.images.map((image) => {
                                                return <Tab label={<img
                                                    src={image.url}
                                                    alt="Other"
                                                    className="max-w-none w-full h-full object-contain"
                                                />} key={image.id} cols={1} className="productImageItem mb-7 flex  m-auto  relative rounded-0 p-3 overflow-hidden border-1" onClick={() => {
                                                    handleClickImage(image);
                                                }}>

                                                </Tab>
                                            })
                                        }
                                    </Tabs >
                                </Box>
                                {/* large active image */}
                                <div className="productImageItem flex  relative rounded-0 m-auto p-5 overflow-hidden border-1" style={{ width: max1300px ? '495px' : '100%', height: "495px" }}>
                                    <img
                                        src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                        alt="Acitve Image"
                                        className="max-w-none w-full h-full object-contain"
                                    />
                                </div>
                            </Stack>
                            <div className="w-full">
                                {
                                    (item.itemDiscount.discount ?
                                        <div className="flex font-semibold my-8">
                                            <div className="text-16 flex flex-row justify-between text-white font-medium bg-red px-8 py-5 max-w-fit  left-0 top-0 w-full">
                                                <span className="w-fit truncate mr-8">
                                                    {item.itemDiscount.discount}% OFF
                                                </span>
                                                {
                                                    (item.itemDiscount.expiry ? <StyledFuseCountdownProduct itemDiscount={item.itemDiscount} dateOnly={true} />
                                                        : null)
                                                }
                                            </div>
                                        </div>
                                        : null)
                                }

                                <div className="mb-10">
                                    <Typography className=" text-28 leading-tight ">
                                        {item.name}
                                    </Typography>
                                    <div className="flex font-semibold my-8">
                                        <Typography className=" text-28 leading-tight" color="primary">
                                            ${(item.itemDiscount.discount && item.itemDiscount.isActive) ? item.itemDiscount.price : item.srp}
                                        </Typography>

                                        {
                                            ((item.itemDiscount.discount && item.itemDiscount.isActive) ? <Typography className=" mx-16 text-28 leading-tight line-through text-red-200">
                                                ${item.srp}
                                            </Typography> : null)
                                        }

                                    </div>
                                    <div className="flex font-semibold my-8">
                                        <Typography className={(item.stock ? 'text-green-600' : 'text-red-600') + " font-medium text-14"}>{item.stock ? item.stock + ' in stock' : 'No Stock'} </Typography>
                                        <Typography className="ml-10 text-grey-600 font-medium text-14">{item.views < 1 ? item.views + ' view' : item.views + ' views'} </Typography>
                                    </div>
                                </div>
                                {/*end product name price discount stocks */}


                                {/* sku category tags and quantity addcart buy now wishlist */}
                                <div className="row-span-1 col-span-2 ...">
                                    <hr className=""></hr>
                                    <div className="my-10">
                                        <Typography className="my-5 text-14 font-medium">SKU: {item.barcode}</Typography>
                                        <Typography className="my-5 text-14 font-medium">Category: {item.category}</Typography>
                                        <Typography className="my-5 text-14 font-medium">Tags:
                                            {
                                                item.tags.map(function (value) {
                                                    return <StyledBreadcrumb component="div" onClick={() => {
                                                        handleClickTags(value)
                                                    }} label={value} />
                                                })
                                            }

                                        </Typography>
                                        <label className=" mt-16 -mb-10 font-semibold text-15">
                                            Quantity
                                        </label>
                                        <Stack direction={'row'} spacing={1} className="flex-wrap items-center">

                                            <div className='mt-10 rounded-md flex justify-center items-center h-auto align-middle border-1 border-grey-400 w-full max-w-160'>
                                                <Button
                                                    contained="text"
                                                    className='text-indigo-900 h-40 p-0 min-w-36 rounded-none font-medium text-20 text-center'
                                                    onClick={() => {
                                                        handleQuantityChange('-');
                                                    }}
                                                >
                                                    -
                                                </Button>
                                                <TextField
                                                    className="mb-0 text-17 font-bold appearance-none"
                                                    type="number"
                                                    placeholder='QTY'
                                                    id="item_quantity"
                                                    variant="outlined"
                                                    onKeyDown={event => globalFunction().handlePreventCharacters(event, ['e', 'E', '-', '+'])}
                                                    onPaste={globalFunction().handlePasteNumericOnly}
                                                    onChange={(ev) => {
                                                        var qaun = parseInt(ev.target.value);
                                                        if (qaun < 1) {
                                                            setQuantity(1);
                                                            return dispatch(
                                                                showMessage({
                                                                    message: "Quantity must not be equal to 0.",
                                                                    autoHideDuration: 5000, //ms
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "right",
                                                                    },
                                                                    variant: "error",
                                                                })
                                                            );
                                                        }

                                                        if (qaun > item.stock) {
                                                            setQuantity(item.stock);
                                                            return dispatch(
                                                                showMessage({
                                                                    message: "Available stock is " + item.stock,
                                                                    autoHideDuration: 5000, //ms
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "right",
                                                                    },
                                                                    variant: "error",
                                                                })
                                                            );
                                                        }

                                                        setQuantity(ev.target.value);
                                                    }}
                                                    value={quantity}
                                                    sx={{
                                                        '& #item_quantity': {
                                                            fontSize: '2rem',
                                                            fontWeight: '500',
                                                            textAlign: 'center',
                                                            width: '5rem',
                                                            padding: '0px'

                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: "unset !important"
                                                        }
                                                    }}
                                                />
                                                <Button
                                                    contained="text"
                                                    className='text-indigo-900 h-40 p-0 min-w-36 rounded-none font-medium text-20 text-center '
                                                    onClick={() => {
                                                        handleQuantityChange('+');
                                                    }}
                                                >
                                                    +
                                                </Button>
                                            </div>

                                            <Button
                                                className=" bg-orange-400 truncate text-black hover:bg-orange-500 font-semibold border-1 rounded-md"
                                                style={{ marginTop: '1rem', height: "42px", minWidth: "fit-content" }}
                                                variant="contained"
                                                onClick={() => {
                                                    if (token && token.id) {
                                                        handleClickCart(item, quantity, 'cart')
                                                    } else {
                                                        handleDialogLoginOpen();
                                                    }
                                                }}
                                                type="button">
                                                ADD TO CART
                                            </Button>
                                            <Button
                                                className=" font-semibold truncate border-1 rounded-md"
                                                style={{ marginTop: '1rem', height: "42px", minWidth: "fit-content" }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    if (token && token.id) {
                                                        handleClickCart(item, quantity, 'checkout')
                                                    } else {
                                                        handleDialogLoginOpen();
                                                    }
                                                }}
                                                type="button">
                                                BUY NOW
                                            </Button>
                                            <IconButton
                                                className=" rounded-md"
                                                style={{ marginTop: '1rem', height: "42px", fontSize: "18px", paddingLeft: "2px" }}
                                                color="inherit"
                                                size="large"
                                                onClick={() => {
                                                    if (token && token.id) {
                                                        handleClickCart(item, quantity, 'wishlist')
                                                    } else {
                                                        handleDialogLoginOpen();
                                                    }
                                                }}
                                            >
                                                <Icon className=" text-32">favorite_border</Icon> Add to Wishlist
                                            </IconButton>
                                        </Stack>
                                    </div>
                                </div>
                                {/*end of sku category tags and quantity addcart buy now wishlist */}


                                {/* Share Buttons */}
                                <div className="row-span-1 col-span-2 mt-10...">
                                    <hr className=""></hr>
                                    <div className="my-10">
                                        <label className=" mt-16 -mb-10 font-semibold text-15">
                                            Share
                                        </label>
                                        <Stack direction={'row'} spacing={1} className="flex-wrap items-center">
                                            <FacebookShareButton
                                                url={window.location.href}
                                                quote={item.name}
                                                description={item.description}
                                            >
                                                <FacebookIcon size={32} round />
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={window.location.href}
                                                quote={item.name}
                                                description={item.description}
                                            >
                                                <TwitterIcon size={32} round />
                                            </TwitterShareButton>
                                            <TelegramShareButton
                                                url={window.location.href}
                                                quote={item.name}
                                                description={item.description}
                                            >
                                                <TelegramIcon size={32} round />
                                            </TelegramShareButton>
                                            <LinkedinShareButton
                                                url={window.location.href}
                                                quote={item.name}
                                                description={item.description}
                                            >
                                                <LinkedinIcon size={32} round />
                                            </LinkedinShareButton>
                                        </Stack>
                                    </div>
                                </div>
                                {/*end of Share Buttons */}
                            </div>
                        </Stack>
                        {/* End Desktop View */}


                        {/* Start Mobile View */}
                        <div className="hidden max-600px:block max-600px:w-full max-600px:m-auto">
                            {/* Start large active image */}
                            <div style={{ minWidth: 200, height: 270 }} className='productImageItem  mb-10 flex flex-shrink-0 border-1 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden '>
                                <img
                                    src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                    alt="Acitve Image"
                                    className="max-w-none w-full h-full object-contain"
                                />
                            </div>
                            {/*End large active image */}
                            <Carousel
                                mobileBreakpoint={[{
                                    breakpoint: 600
                                }]}
                                responsiveLayout={[{
                                    breakpoint: 1279,
                                    cols: 4,
                                    rows: 1,
                                }]}
                                cols={4}
                                rows={1}
                                gap={3}
                                loop={false}
                                autoplay={3000}
                                showDots={false}
                                arrowLeft={LeftBtn}
                                arrowRight={RightBtn}
                                dot={CustomDot}
                                hideArrow={item.photoUrl ? false : true}
                            >

                                {
                                    item.images && item.images.map((image) => {
                                        return <Carousel.Item style={{ border: "solid 1px grey" }} key={image.id} onClick={() => {
                                            handleClickImage(image);
                                        }}>
                                            <div style={{ width: "100%", height: 80 }} className='productImageItem border-1 flex flex-shrink-0 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden '>
                                                <img
                                                    src={image.url}
                                                    alt="Other"
                                                    className="max-w-none w-full h-full object-contain"
                                                />
                                            </div>
                                        </Carousel.Item>
                                    })
                                }

                            </Carousel>
                            {
                                (item.itemDiscount.discount ?
                                    <div className="flex font-semibold my-8">
                                        <div className="text-12 w-full flex flex-row justify-between text-white font-medium bg-red px-8 py-5 min-w-fit  left-0 top-0">
                                            <span className="w-fit truncate mr-8">
                                                {item.itemDiscount.discount}% OFF
                                            </span>
                                            {
                                                (item.itemDiscount.expiry ? <StyledFuseCountdownProductMobile itemDiscount={item.itemDiscount} dateOnly={true} />
                                                    : null)
                                            }
                                        </div>
                                    </div>
                                    : null)
                            }
                            <div className="my-5">
                                <Typography className=" text-20 leading-tight">
                                    {item.name}
                                </Typography>
                                <div className="flex font-semibold">
                                    <Typography className=" text-20 leading-tight" color="primary">
                                        ${(item.itemDiscount.discount && item.itemDiscount.isActive) ? item.itemDiscount.price : item.srp}
                                    </Typography>

                                    {
                                        ((item.itemDiscount.discount && item.itemDiscount.isActive) ? <Typography className=" mx-16 text-20 leading-tight line-through text-red-200">
                                            ${item.srp}
                                        </Typography> : null)
                                    }

                                </div>
                                <div className="flex font-semibold">
                                    <Typography className={(item.stock ? 'text-green-600 ' : 'text-red-600') + " font-semibold text-15 "}>{item.stock ? item.stock + ' in stock' : 'No Stock'} </Typography>
                                    <Typography className="ml-10 text-grey-600 font-medium text-14">{item.views < 1 ? item.views + ' view' : item.views + ' views'} </Typography>
                                </div>
                                <Typography className="my-5 text-14 font-medium">SKU: {item.barcode}</Typography>
                                <Typography className="my-5 text-14 font-medium">Category: {item.category}</Typography>
                                <Typography className="my-5 text-14 font-medium">Tags:
                                    {
                                        item.tags.map(function (value) {
                                            return <StyledBreadcrumb component="div" onClick={() => {
                                                handleClickTags(value)
                                            }} label={value} />
                                        })
                                    }

                                </Typography>
                                <label className="mt-10 text-14 font-medium">
                                    Quantity
                                </label>
                                <Stack direction={'row'} spacing={1} alignItems='center'>
                                    <div className="w-full">
                                        <div className='rounded-md flex justify-center items-center h-auto align-middle border-1 border-grey-400'>
                                            <Button
                                                contained="text"
                                                className='text-indigo-900 h-40 p-0 min-w-36 rounded-none font-medium text-20 text-center'
                                                onClick={() => {
                                                    if (quantity > 1) {
                                                        setQuantity(quantity - 1);
                                                    }
                                                }}
                                            >
                                                -
                                            </Button>
                                            <TextField
                                                className="mb-0 text-17 font-bold appearance-none"
                                                type="number"
                                                placeholder='QTY'
                                                id="item_quantity"
                                                variant="outlined"
                                                onChange={(ev) => {
                                                    var qaun = parseInt(ev.target.value);
                                                    if (qaun > 0) {
                                                        setQuantity(ev.target.value);
                                                    }

                                                    if (qaun > item.stock) {
                                                        setQuantity(item.stock);
                                                    }

                                                    if (!qaun) {
                                                        setQuantity(ev.target.value);
                                                    }
                                                }}
                                                value={quantity}
                                                sx={{
                                                    '& #item_quantity': {
                                                        fontSize: '2rem',
                                                        fontWeight: '500',
                                                        textAlign: 'center',
                                                        width: '5rem',
                                                        padding: '0px'

                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: "unset !important"
                                                    }
                                                }}
                                            />
                                            <Button
                                                contained="text"
                                                className='text-indigo-900 h-40 p-0 min-w-36 rounded-none font-medium text-20 text-center '
                                                onClick={() => {
                                                    if (quantity < item.stock) {
                                                        setQuantity(quantity + 1);
                                                    }
                                                }}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </div>
                                    <IconButton
                                        variant="contained"
                                        onClick={() => {
                                            if (token && token.id) {
                                                handleClickCart(item, quantity, 'wishlist')
                                            } else {
                                                handleDialogLoginOpen();
                                            }
                                        }}
                                    >
                                        <Icon className=" text-32">favorite_border</Icon>
                                    </IconButton>
                                    <IconButton
                                        variant="contained"
                                        onClick={() => {
                                            if (token && token.id) {
                                                handleClickCart(item, quantity, 'cart')
                                            } else {
                                                handleDialogLoginOpen();
                                            }
                                        }}
                                        type="button">
                                        <Icon className="text-32" >shopping_cart</Icon>
                                    </IconButton>
                                </Stack>
                                <Button
                                    className=" font-semibold truncate border-1 rounded-md w-full mt-10"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        if (token && token.id) {
                                            handleClickCart(item, quantity, 'checkout')
                                        } else {
                                            handleDialogLoginOpen();
                                        }
                                    }}
                                    type="button">
                                    BUY NOW
                                </Button>
                            </div>
                            <div className="row-span-1 col-span-2 mt-10 ...">
                                <hr className=""></hr>
                                <div className="my-10">
                                    <label className=" mt-16 -mb-10 font-semibold text-15">
                                        Share
                                    </label>
                                    <Stack direction={'row'} spacing={1} className="flex-wrap items-center">
                                        <FacebookShareButton
                                            url={window.location.href}
                                            quote={item.name}
                                            description={item.description}
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={window.location.href}
                                            quote={item.name}
                                            description={item.description}
                                        >
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <TelegramShareButton
                                            url={window.location.href}
                                            quote={item.name}
                                            description={item.description}
                                        >
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                        <LinkedinShareButton
                                            url={window.location.href}
                                            quote={item.name}
                                            description={item.description}
                                        >
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                        {/* End Mobile View */}



                    </div>
                </div>
            </div >

            {/* Frequently bought together */}
            {/* related Products */}
            {
                recommendationsLoading ? <FuseLoading /> :
                    (recommendedProducts.length ?
                        < div className="bg-white border-1 border-1-gray-50 border-opacity-5 my-16" >
                            <div className="py-14 px-24">
                                <Typography className=" font-bold text-16">Frequently bought together</Typography>
                                <div className=' flex gap-20 '>
                                    <FuseScrollbars className="overflow-auto w-fit">
                                        <div className=' flex gap-10'>

                                            {
                                                recommendedProducts.map((item) => {

                                                    {/*start items */ }
                                                    return <div className=" py-20 max-600px:py-8" key={item.id} >
                                                        <div className="bg-white border-1 border-1-gray-50 border-opacity-5 p-0" style={{
                                                            minWidth: "150px",
                                                            maxWidth: "150px"
                                                        }}>
                                                            <div className='productImageItem flex flex-shrink-0 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden ' style={{ width: "150px", height: "150px" }} >
                                                                <img
                                                                    src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                                                    alt="secondary"
                                                                    className="max-w-none w-full h-full object-contain"
                                                                    onClick={() => {
                                                                        handleClickItem(item);
                                                                    }}
                                                                />
                                                                <Checkbox disabled={item.stock <= 0} onChange={(event) => handleCheckItem(item.id)} checked={item.isCheck} className=" absolute top-1 right-1 p-5" size='small' />
                                                            </div>
                                                            <div className="h-76 relative border-t-1 p-8" onClick={() => {
                                                                handleClickItem(item);
                                                            }}>
                                                                <Typography className="font-medium leading-4  line-clamp-2">{item.name}</Typography>
                                                                <Typography color="primary" className="mt-16 text-lg leading-none font-medium title-font mb-4">${item.srp}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*Eend items */ }
                                                })
                                            }

                                        </div>
                                    </FuseScrollbars>
                                    {checkedItemsCount > 0 && (

                                        <div className='w-fit text-center pr-16' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="mt-10  font-bold text-16" >
                                                <span className=' font-normal'>Total Price:</span> ${checkedItemsTotalSrp}
                                            </div>
                                            <Button
                                                className="bg-orange-400 truncate text-black hover:bg-orange-500 font-semibold border-1 rounded-md"
                                                style={{ marginTop: '1rem', height: "42px", minWidth: "fit-content" }}
                                                variant="contained"
                                                onClick={() => {
                                                    if (token && token.id) {
                                                        handleClickCartMultiple(recommendedProducts)
                                                    } else {
                                                        handleDialogLoginOpen();
                                                    }
                                                }}
                                                type="button">
                                                ADD ALL {checkedItemsCount} TO CART
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div > : null)
            }

            {/* Product Description */}
            < div className="bg-white border-1 border-1-gray-50 border-opacity-5 my-16" >
                <div className="py-14 px-24">
                    <Typography className=" font-bold text-16">Details and product description</Typography>
                    <Typography className=" mt-10">{item.description}</Typography>
                </div>
            </div >

            {/* Product Reviews */}

            {
                reviewsLoading ? <FuseLoading /> :
                    (
                        reviews.length
                            ?
                            (
                                <div className="bg-white border-1 border-1-gray-50 border-opacity-5">
                                    <div className="py-14 px-24 pb-24">
                                        <div className="flex justify-between">
                                            <Typography className="font-bold text-16">Reviews</Typography>

                                            <Tabs
                                                value={reviewTab}
                                                onChange={(event, value) => {
                                                    setReviewTab(value);
                                                }}
                                                textColor="secondary"
                                                indicatorColor="secondary"
                                            >
                                                <Tab value={"all"} label="All" />
                                                <Tab value={"5"} label="5 Star" />
                                                <Tab value={"4"} label="4 Star" />
                                                <Tab value={"3"} label="3 Star" />
                                                <Tab value={"2"} label="2 Star" />
                                                <Tab value={"1"} label="1 Star" />
                                            </Tabs>

                                            <AddReviewsModal
                                                openDialog={openDialog}
                                                handleDialogOpen={handleDialogOpen}
                                                handleDialogClose={handleDialogClose}
                                                itemId={itemId}
                                            />

                                        </div>
                                        <div className="mt-10">
                                            <Stack spacing={4} >
                                                {
                                                    reviews.map((item) => {
                                                        return (
                                                            <div key={item.id} className="flex justify-between">
                                                                <Stack direction="row" spacing={1.5} >
                                                                    <Avatar alt={item.name} src="/broken-image.jpg" />
                                                                    <Stack spacing={.5}>
                                                                        <Typography className="font-bold">{item.name}</Typography>
                                                                        <Rating name="kahit-ano-kung-saan-ka-masaya" defaultValue={parseFloat(item.rating)} precision={0.5} size="small" readOnly />
                                                                        <Typography>{item.reviews}</Typography>
                                                                    </Stack>
                                                                </Stack>
                                                                <Typography className="text-12">{format(new Date(item.dateCreated), 'MM/dd/yyyy ')}</Typography>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="bg-white border-1 border-1-gray-50 border-opacity-5">
                                    <div className="py-14 px-24 pb-24">
                                        <div className="flex justify-between">
                                            <Typography className=" font-bold text-16">Reviews</Typography>

                                            <Tabs
                                                value={reviewTab}
                                                onChange={(event, value) => {
                                                    setReviewTab(value);
                                                }}
                                                textColor="secondary"
                                                indicatorColor="secondary"
                                            >
                                                <Tab value={"all"} label="All" />
                                                <Tab value={"5"} label="5 Star" />
                                                <Tab value={"4"} label="4 Star" />
                                                <Tab value={"3"} label="3 Star" />
                                                <Tab value={"2"} label="2 Star" />
                                                <Tab value={"1"} label="1 Star" />
                                            </Tabs>
                                        </div>
                                        <div className="mt-10"></div>
                                        <div className="text-center h-92 text-grey-600 flex flex-col justify-center items-center">
                                            <Icon className="text-32" >rate_review</Icon>
                                            <Typography className="font-semibold">No reviews yet.</Typography>
                                        </div>
                                    </div>
                                </div>
                            )
                    )
            }

            {/* related Products */}
            {
                relatedLoading ? <FuseLoading /> :
                    (realtedProducts.length ? <div className="bg-white border-1 border-1-gray-50 border-opacity-5 my-16">
                        <div className="py-14">
                            <Typography className=" px-24 text-28">
                                Related Products
                            </Typography>
                            <section className="body-font">
                                <div className="container mx-auto px-10">
                                    <div className="flex flex-wrap -m-4">


                                        {
                                            realtedProducts.map((item) => {
                                                return <div className="xl:w-1/5 lg:w-1/4 md:w-1/2 sm:w-1/2 max-mobile:w-full" key={item.id} onClick={() => {
                                                    handleClickItem(item);
                                                }}>
                                                    <div className=" p-20 max-600px:p-8" key={item.id} onClick={() => { handleClickItem(item) }}>
                                                        <div className="bg-white border-1 border-1-gray-50 border-opacity-5 p-0">
                                                            <div className='productImageItem flex flex-shrink-0 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden ' style={{ width: "218px", height: "218px" }} >
                                                                <img
                                                                    src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                                                    alt="secondary"
                                                                    className="max-w-none w-full h-full object-contain"
                                                                />
                                                            </div>
                                                            <div className="h-76 relative border-t-1 p-8">
                                                                <Typography className="font-medium leading-4  line-clamp-2">{item.name}</Typography>
                                                                <Typography color="primary" className="mt-16 text-lg leading-none font-medium title-font mb-4">${item.srp}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div>
                                </div>
                            </section>
                        </div>
                    </div> : null)
            }

            <LoginModal
                fromProduct={true}
                openDialogLogin={openDialogLogin}
                handleDialogLoginOpen={handleDialogLoginOpen}
                handleDialogLoginClose={handleDialogLoginClose}
            />
        </Root >
    )

}



export default ProductContent;