

/* DateUpdated: 04/03/23
* updatedBy: ico
* Summary: Task #1208:Email verification Design Concerns
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49908-96
*/
import * as yup from 'yup';
import _ from '@lodash';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PasswordStrengthBar from "react-password-strength-bar";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';

import { showMessage } from "app/store/fuse/messageSlice";
import { saveResetPassword, checkExistingPassword, checkVerification } from "app/main/ecommerce/store/loginSlice";

const CardStyled = styled(Card)(() => ({
    // background: theme.palette.background.default
}));

const defaultValues = {
    password: "",
    passwordConfirm: ""
};

function ForgotPasswordContent() {

    const dispatch = useDispatch();

    const routeParams = useParams();

    const navigate = useNavigate();

    const [showBackButton, setShowBackButton] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(false);

    const { id } = routeParams;

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        password: yup
            .string()
            .required("Password is required")
            .test({
                message: () => "You can't enter your old password.",
                test: async (password) => {
                    if (password) {
                        return dispatch(checkExistingPassword({ password: password, id: id }));
                    }
                },
            })
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
        passwordConfirm: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

    const { control, formState, handleSubmit, watch, getValues } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { errors } = formState;

    const password = watch("password");

    const [showPassword, setShowPassword] = useState(false);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    async function onSubmit(data) {
        var data = getValues();

        data.id = id;

        dispatch(saveResetPassword(data)).then((action) => {
            if (action.payload.status != 'error') {
                setShowBackButton(true);
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });
    }

    useEffect(() => {
        setShowCancelButton(false);
        dispatch(checkVerification({ password: "", id: id })).then((action) => {
            setShowCancelButton(action);
        });
    }, [id]);

    function backToHomePage() {
        navigate("/e-commerce/home");
    }

    return (
        <div className="w-full">
            <div className="p-9 pb-0 w-1/3 min-w-320 m-auto mb-48 mt-32 ">
                <CardStyled elevation={0} className="flex flex-col shadow-0 px-10 py-5">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                            <DialogContent classes={{ root: 'py-25 px-25 pb-0 ' }}>
                                <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>

                                    <form
                                        name="forgotPassForm"
                                        noValidate
                                        className="flex flex-col justify-center w-full mb-36"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        {showBackButton && (
                                            <>
                                            
                                                <Typography variant="h6" className="text-center">
                                                    <Icon className="text-green text-6xl">check_circle_outline</Icon>
                                                </Typography>
                                                <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                                    Password Reset Successfully
                                                </Typography>
                                                
                                                <Typography variant="h8" className="font-medium text-14 sm:text-14 text-center">
                                                    You have succesfully reset your password.
                                                </Typography>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-full mx-auto mt-32 rounded-md h-48"
                                                    aria-label="LOG IN"
                                                    value="legacy"
                                                    onClick={() => backToHomePage()}
                                                >
                                                    Back to Homepage
                                                </Button>
                                            </>
                                        )}

                                        {showCancelButton && (
                                            <>
                                                 <Typography variant="h6" className="text-center mt-16">
                                                    <Icon className="text-red text-6xl">cancel</Icon>
                                                </Typography>
                                                <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                                    Email Verification
                                                </Typography>
                                                <Typography  className="w-full font-medium text-14 sm:text-14 text-center">
                                                    Email Verification is not valid
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-full mx-auto mt-32 rounded-md h-48"
                                                    aria-label="LOG IN"
                                                    value="legacy"
                                                    onClick={() => backToHomePage()}
                                                >
                                                    Back to Homepage
                                                </Button>
                                            </>
                                        )}

                                        {!showBackButton && !showCancelButton && (
                                            <>
                                                <Typography variant="h6" className="font-semibold text-18 sm:text-24 text-center">
                                                    Set your password
                                                </Typography>

                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-24"
                                                            label="Password"
                                                            type="password"
                                                            name="password"
                                                            error={!!errors.password}
                                                            helperText={errors?.password?.message}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            InputProps={{
                                                                className: "pr-2",
                                                                type: showPassword ? "text" : "password",
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() => setShowPassword(!showPassword)}
                                                                            size="large"
                                                                        >
                                                                            <Icon className="text-20" color="action">
                                                                                {showPassword ? "visibility" : "visibility_off"}
                                                                            </Icon>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="passwordConfirm"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="mt-16"
                                                            label="Password (Confirm)"
                                                            type="password"
                                                            error={!!errors.passwordConfirm}
                                                            helperText={errors?.passwordConfirm?.message}
                                                            variant="outlined"
                                                            required
                                                            fullWidth
                                                            InputProps={{
                                                                className: "pr-2",
                                                                type: showPasswordConfirm ? "text" : "password",
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                                            size="large"
                                                                        >
                                                                            <Icon className="text-20" color="action">
                                                                                {showPasswordConfirm ? "visibility" : "visibility_off"}
                                                                            </Icon>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />

                                                {password && (
                                                    <PasswordStrengthBar
                                                        password={password}
                                                        shortScoreWord="Password is short"
                                                        scoreWords={[
                                                            "Password is weak",
                                                            "Password is weak",
                                                            "Password is okay",
                                                            "Password is good",
                                                            "Password is strong",
                                                        ]}
                                                    />
                                                )}

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="w-full mx-auto mt-32 rounded-md h-48"
                                                    aria-label="LOG IN"
                                                    value="legacy"
                                                >
                                                    Update
                                                </Button>
                                            </>
                                        )}
                                    </form>
                                </motion.div>
                            </DialogContent>
                        </Grid>
                    </Box>
                </CardStyled>
            </div>
        </div>
    );
}

export default ForgotPasswordContent;
