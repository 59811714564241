import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import EcommerceHeader from 'app/main/ecommerce/header/EcommerceHeader';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import HomeContent from 'app/main/ecommerce/home/content/HomeContent';


const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));

function Home(props) {

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <EcommerceHeader />
                <Root
                    content={
                        
                       <div className="max-w-1366px m-auto">
                            <HomeContent />
                       </div>
                       
                    }

                />
            <EcommerceFooter />
        </div>
    );
}

export default Home;

