// * Updated By: Jherome
// * Summary:  actions icon fix padding left
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44294-96
// * DateUpdated:  1/05/2023

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    backgroundColor: theme.palette.background.paper,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
export const rows = [
  {
    id: 'referenceNum',
    align: 'left',
    disablePadding: false,
    label: 'Booking #',
    sort: true,
    width: "10%"
  },
  {
    id: 'name',
    align: 'left',
    disablePadding: false,
    label: 'Customer',
    sort: true,
    width: "10%"
  },
  {
    id: 'itemCode',
    align: 'left',
    disablePadding: false,
    label: 'Booking Details',
    sort: true,
    width: "15%"
  },
  {
    id: 'contact',
    align: 'left',
    disablePadding: false,
    label: 'Contact',
    sort: true,
    width: "5%"
  },
  {
    id: 'start',
    align: 'left',
    disablePadding: false,
    label: 'Date and Time',
    sort: true,
    // width: "10%"
  },
  {
    id: 'status',
    align: 'left',
    disablePadding: false,
    label: 'Status',
    sort: true,
    width: "30%"
  },
  {
    id: 'action',
    align: 'left',
    disablePadding: false,
    label: 'Actions',
    sort: false,
    width: "5%"
  },
];

function CrmBookingListTableHeader(props) {

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  return (
    <>
      {rows.map((row) => {
        return (
          <colgroup key={row.id}>
            <col style={{ width: row.width }} />
          </colgroup>
        );
      }, this)}
      <TableHead>
        <StyledTableRow className="h-48 sm:h-64">
          {rows.map((row) => {
            return (
              <TableCell
                className={row.label === "Actions" ? "p-8 pl-10 md:p-16 md:pl-28 font-bold truncate" : "p-6 md:p-10 font-bold truncate" }
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={false}
              >
                {row.sort ? (
                  <Tooltip
                    title="Sort"
                    placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={props.order.id === row.id}
                      direction={props.order.direction}
                      onClick={createSortHandler(row.id)}
                      className="font-bold truncate"
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : row.label}
              </TableCell>
            );
          }, this)}
        </StyledTableRow>
      </TableHead>
    </>
  );
}

export default CrmBookingListTableHeader;
