const mutation = {
    saveEcommerceCart: `
      mutation saveEcommerceCart($data: JSONString!) {
            saveEcommerceCart (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    addEcommerceReviews: `
      mutation addEcommerceReviews($data: JSONString!) {
            addEcommerceReviews (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    viewItem: `
        mutation viewItem($id: String!) {
            viewItem (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    itemById: `
        query itemById($itemId: String!, $dateNow: String!, $fromPage: String) {
            itemById(itemId: $itemId, fromPage: $fromPage) {
                id,
                code,
                status,
                type,
                barcode,
                category,
                department,
                views,
                name,
                description,
                cost,
                srp,
                stock,
                tags,
                supplier,
                initialSupply,
                featuredImageId,
                dateCreated,
                dateUpdated,
                itemDiscount(dateNow: $dateNow) {
                    isActive
                    price
                    discount
                    start
                    expiry
                }
                images {
                    id,
                    itemId,
                    uploadId,
                    fileName,
                    fileType,
                    fileId,
                    module,
                    path,
                    url,
                    dateCreated,
                    dateUpdated
                }
                photoUrl
            }
        }
    `,
    getRelatedItems: `
        query getRelatedItems($category: String!, $tags: String, $tagsArr: String) {
            relatedItems(category: $category, tags: $tags, tagsArr: $tagsArr) {
                id,
                code,
                status,
                type,
                barcode,
                category,
                department,
                name,
                description,
                cost,
                srp,
                stock,
                supplier,
                initialSupply,
                featuredImageId,
                dateCreated,
                dateUpdated,
                photoUrl            
            }
        }
    `,
    getItemRecommendations: `
        query getItemRecommendations($itemId: String!, $dateNow: String!) {
            getItemRecommendations(itemId: $itemId) {
                id,
                code,
                status,
                type,
                barcode,
                category,
                department,
                name,
                description,
                cost,
                srp,
                stock,
                supplier,
                initialSupply,
                featuredImageId,
                dateCreated,
                dateUpdated,
                photoUrl,
                itemDiscount(dateNow: $dateNow) {
                    isActive
                    price
                    discount
                    start
                    expiry
                }
            }
        }
    `,
    getReviews: `
        query getReviews($itemId: String!, $reviewTab: String) {
            getReviews(itemId: $itemId, reviewTab: $reviewTab) {
                id
                itemId
                name
                email
                rating
                active
                reviews
                dateCreated
                dateUpdated
            }
        }
    `,
}

export { mutation, query };