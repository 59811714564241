import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "../../../../graphql/user_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "../../../../services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "../../../../graphql/upload_graphql";
import JwtService from "app/services/jwtService";

export const getUser = createAsyncThunk(
    "userAccountApp/user/getUser",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getUserByID,
            variables: {
                userId: params.usersId,
            },
        });

        if (response.data.data.usersById) {
            const data = response.data.data.usersById;


            data.images = data.images ? [data.images] : [];

            return data === undefined ? null : data;
        }
    }
);

export const newUser = createAsyncThunk(
    "userAccountApp/user/newUser",
    async () => {

        const defaultValues = {
            firstName: "",
            lastName: "",
            username: "",
            contact: "",
            isActive: "",
            email: "",
            password: "",
            role: "user",
            group: [],
            roleitems: [],
            roles: [],
            gender: "",
            phone: "",
            mobilephone: "",
            address1: "",
            address2: "",
            country: "United States",
            countrySelect: {
                name: "United States"
            },
            stateSelect: {
                name: "Marshall Islands"
            },
            city: "Majuro",
            state: "Marshall Islands",
            zipcode: "96960",
            hiredate: null,
            terminationdate: null,
            featuredImageId: "",
            images: [],
            department: ""
        };

        const roleitems = await axios.post(CONFIG.API + "/api/", {
            query: query.getUserRoles,
        });

        if (roleitems.data.data.allRoleitem) {
            defaultValues.roleitems = roleitems.data.data.allRoleitem;
        }

        return defaultValues;
    }
);

export const removeUser = createAsyncThunk(
    "userAccountApp/user/removeUser",
    async (val, { dispatch, getState }) => {
        const { id } = getState().userAccountApp.user;
        await axios.post("/api/e-commerce-app/remove-user", { id });

        return id;
    }
);

export const checkEcommerceEmail =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkEcommerceEmail,
            variables: filter
        });

        if (response.data.data.checkEcommerceEmail.length) {
            return false;
        } else {
            return true;
        }

    };

export const checkUserExist =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkUserExist,
            variables: filter
        });

        if (response.data.data.checkUserExist.length) {
            return false;
        } else {
            return true;
        }

    };

export const saveUser = createAsyncThunk(
    "userAccountApp/user/saveUser",
    async (userData, { dispatch, getState }) => {

        saveImages(userData).then(async (userData) => {

            if (userData.id) {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.edit,
                    variables: {
                        data: JSON.stringify(userData),
                        id: parseInt(userData.id),
                    },
                });

                if (response.data.data.editUser) {
                    JwtService.setUserRefreshToken();
                    dispatch(
                        showMessage({
                            message: response.data.data.editUser["message"],
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: response.data.data.editUser["status"],
                        })
                    );
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                }
            } else {
                const response = await axios.post(CONFIG.API + "/api/", {
                    query: mutation.create,
                    variables: { data: JSON.stringify(userData) },
                });

                if (response.data.data.createUser) {
                    JwtService.setUserRefreshToken();
                    dispatch(
                        showMessage({
                            message: response.data.data.createUser["message"],
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: response.data.data.createUser["status"],
                        })
                    );
                } else {
                    dispatch(
                        showMessage({
                            message: "Something went wrong. Please try again later.",
                            autoHideDuration: 5000, //ms
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            variant: "error",
                        })
                    );
                }
            }

        });

    }
);

export const checkAccount = createAsyncThunk(
    "userAccountApp/user/checkAccount",
    async (data, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.checkAccount,
            variables: { data: JSON.stringify(data) },
        });

        return response.data.data.checkAccount;
    }
);

export const saveResetPassword = createAsyncThunk(
    "userAccountApp/user/saveResetPassword",
    async (userData, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.resetpassword,
            variables: {
                data: JSON.stringify(userData),
                id: parseInt(userData.id),
            },
        });
        dispatch(
            showMessage({
                message: response.data.data.resetPassword["message"],
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: response.data.data.resetPassword["status"],
            })
        );
    }
);

export const saveUserRole = createAsyncThunk(
    "userAccountApp/user/saveUserRole",
    async (userData, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.user_role.create,
            variables: {
                data: JSON.stringify(userData),
            },
        });

        if (response.data.data.createUsersRoles) {
            dispatch(
                showMessage({
                    message: response.data.data.createUsersRoles["message"],
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.createUsersRoles["status"],
                })
            );
        }
    }
);

const saveImages = (userData) => {

    return new Promise(async (resolve, reject) => {

        if (userData.images.length) {

            var newImages = [];

            userData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (userData.featuredImageId == image.fileId) {
                        userData.featuredImagePath = image.path;
                    }
                }

            });

            if (newImages.length) {

                newImages.map(async (image, index) => {


                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "useraccount/user/user-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'user-account';
                            uploadData['file_id'] = image.image.id;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                            });

                            if (response.data.data.createUpload.status == "success") {
                                var upload = response.data.data.createUpload.upload;

                                if (userData.featuredImageId == image.image.id) {
                                    userData.featuredImagePath = upload.path;
                                }

                                userData.images[image.index].upload_id = upload.id;
                                userData.images[image.index].file_name = upload.fileName;
                                userData.images[image.index].file_id = upload.fileId;
                                userData.images[image.index].file_type = upload.fileType;
                                userData.images[image.index].path = upload.path;
                                userData.images[image.index].module = upload.module;
                                // userData.images[image.index].url = "";

                                if (newImages.length == (index + 1)) {
                                    resolve(userData);
                                }

                            }

                        }

                    });

                });

            } else {
                resolve(userData)
            }


        } else {

            resolve(userData);

        }

    });

}

const productSlice = createSlice({
    name: "userAccountApp/user",
    initialState: null,
    reducers: {
        resetUser: () => null
    },
    extraReducers: {
        [newUser.fulfilled]: (state, action) => action.payload,
        [getUser.fulfilled]: (state, action) => action.payload,
        [saveUser.fulfilled]: (state, action) => action.payload,
        [removeUser.fulfilled]: (state, action) => null,
        [checkAccount.fulfilled]: (state, action) => action.payload,
    },
});

export const { resetUser } = productSlice.actions;

export default productSlice.reducer;
