import * as React from 'react';
import { useFormContext, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";

import { experimentalStyled as styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import CONFIG from "app/config";
import Maps from "../../../../services/maps/maps";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

function Tab2() {

    const dispatch = useDispatch();

    const routeParams = useParams();

    const methods = useFormContext();

    const { control, formState, watch, setValue } = methods;

    const { errors } = formState;

    let address = watch("address1");

    let lat = watch("lat");

    let lng = watch("lng");

    useEffect(() => {
        if (!lng && !lat) {
            handleAddressChange();
        }
    }, [dispatch, routeParams]);

    let [location, setLocation] = useState({
        address: address,
        lat: lat ? lat : 7.10262568638697,
        lng: lng ? lng : 171.3782997355861,
    });

    useEffect(() => {
        console.log("address, lat, lng", address, lat, lng);
        if (lng && lat) {
            setLocation({
                address: address,
                lat: parseFloat(lat),
                lng: parseFloat(lng),
            });
        }
    }, [dispatch, address, lat, lng]);


    function handleClickMaps(maps) {

        setValue("lat", maps.lat, { shouldDirty: true });
        setValue("lng", maps.lng, { shouldDirty: true });
        setLocation({
            address: address,
            lat: maps.lat,
            lng: maps.lng,
        });
    }

    function handleAddressChange() {
        Geocode.setApiKey(CONFIG.MAPS);
        Geocode.setLanguage("en");
        Geocode.fromAddress(address).then(
            (response) => {
                if (response.results) {
                    const { lat, lng } = response.results[0].geometry.location;
                    setValue("lat", lat, { shouldDirty: true });
                    setValue("lng", lng, { shouldDirty: true });
                    setLocation({
                        address: address,
                        lat: lat,
                        lng: lng,
                    });
                }
            },
            (error) => {
                // console.error(error);
            }
        );
    }

    return (
        <>
            
            <div className="py-24 px-32 max-400px:px-10 mx-auto w-full">
                <div className="flex-col flex">
                    <FormControl
                        className="flex flex-col mt-9  ml-0 truncate items-start"
                        variant="outlined">
                        <Typography className="text-15 mr-10" color="text.primary">Address 1 :</Typography>
                        <Controller
                            control={control}
                            name="address1"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className=""
                                    error={!!errors.address1}
                                    required
                                    // helperText={errors?.address1?.message}
                                    id="address1"
                                    variant="outlined"
                                    fullWidth
                                    onChangeCapture={handleAddressChange}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        }
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                    <small className='text-red-600'>{errors?.address1?.message}</small>
                </div>

                <FormControl
                    className="flex flex-col mt-9  ml-0 truncate items-start"
                    variant="outlined">
                    <Typography className="text-15 mr-10" color="text.primary">Address 2 :</Typography>
                    <Controller
                        control={control}
                        name="address2"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                id="address2"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />

                </FormControl>

                <FormControl
                        className="flex flex-col mt-9  ml-0 truncate items-start"
                    variant="outlined">
                    <Typography className="text-15 mr-10" color="text.primary">Country :</Typography>
                    <Controller
                        control={control}
                        name="country"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                id="country"
                                variant="outlined"
                                fullWidth
                                disabled
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                        background: "#f6f7fb"
                                    }
                                }}
                            />
                        )}
                    />

                </FormControl>

                <FormControl
                        className="flex flex-col mt-9  ml-0 truncate items-start"
                    variant="outlined">
                    <Typography className="text-15 mr-10" color="text.primary">City :</Typography>
                    <Controller
                        control={control}
                        name="city"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                id="city"
                                variant="outlined"
                                fullWidth
                                disabled
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                        background: "#f6f7fb"
                                    }
                                }}
                            />
                        )}
                    />

                </FormControl>

                <FormControl
                        className="flex flex-col mt-9  ml-0 truncate items-start"
                    variant="outlined">
                    <Typography className="text-15 mr-10" color="text.primary">State :</Typography>
                    <Controller
                        control={control}
                        name="state"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                id="state"
                                variant="outlined"
                                fullWidth
                                disabled
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                        background: "#f6f7fb"
                                    }
                                }}
                            />
                        )}
                    />

                </FormControl>

                <FormControl
                    className="flex flex-col mt-9  ml-0 truncate items-start"
                    variant="outlined">
                    <Typography className="text-15 mr-10" color="text.primary">Zip Code :</Typography>
                    <Controller
                        control={control}
                        name="zipCode"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                id="zipCode"
                                variant="outlined"
                                fullWidth
                                disabled
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                        background: "#f6f7fb"
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>
            </div>
            <div className="p-24 container relative"  >
                <Maps
                    zoomLevel={17}
                    location={location}
                    handleClickMaps={handleClickMaps}
                />
            </div>
        </>        
       
    );
}

export default Tab2;
