import clsx from 'clsx';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CrmBooking from 'app/main/crm/header/CrmBooking';
import CrmUserMenu from 'app/main/crm/header/CrmUserMenu';
import CrmMyPoints from 'app/main/crm/header/CrmMyPoints';
import CrmMyTickets from 'app/main/crm/header/CrmMyTickets';
import { useNavigate } from 'react-router-dom';
import CrmHall from './CrmHall';
import { setTicketsSearchText } from '../store/ticketsSlice';

const LogoStyled = styled('div')(({ theme }) => ({
    '& > .logo-icon': {
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& > .badge, & > .logo-text': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));


function CrmHeader(props) {

    const toolbarTheme = useSelector(selectToolbarTheme);

    const user = useSelector(({ crm }) => crm.crmuser);

    console.log("user", user);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    dispatch(setTicketsSearchText({ target: { value: '' } }));

    const handleHome = () => {
        navigate("/crm/my-tickets/all-tickets");
    }

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar
                id="fuse-toolbar"
                className={clsx('flex relative z-20 shadow-md', props.className)}
                color="default"
                sx={{ backgroundColor: toolbarTheme.palette.background.paper }}
                position="static"
            >
                <Toolbar className="p-0 min-h-48 md:min-h-64">

                    <div className="flex flex-1 px-16">
                        <div className={clsx('flex shrink items-center')}>
                            <LogoStyled className="flex items-center pb-5 pt-5">
                                <img className="logo-icon w-288" src="assets/images/logos/CMI_CRM_NAV.png" alt="logo" onClick={() => handleHome()} />
                            </LogoStyled>
                        </div>
                    </div>

                    <div className="flex items-center px-8 h-full overflow-x-auto">

                        <CrmHall />

                        {user.isBooking ? <CrmBooking /> : <></>}

                        <CrmMyTickets />

                        <CrmMyPoints />

                        <CrmUserMenu />

                    </div>
                </Toolbar>
            </AppBar>
        </ThemeProvider >
    );
}

export default memo(CrmHeader);
