import React, { useState } from 'react';
import CONFIG from "app/config";
import { Button, Grid, Icon, Stack, Typography } from '@mui/material';
import TakePhoto from './content/TakePhoto';
import FuseUtils from '@fuse/utils/FuseUtils';
import clsx from 'clsx';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


function TakeUploadService(props) {

    const { model, url, setUrl } = props;
    // Assuming model can be 'frontLicenseIdUrl' or 'backLicenseIdUrl'
    const imageUrl = model === 'orcrUrl' ? 'assets/images/id/orcr.jpg?w=248&fit=crop&auto=format' : model === 'frontLicenseIdUrl'
        ? 'assets/images/id/ID_front.png?w=248&fit=crop&auto=format'
        : 'assets/images/id/ID_back.png?w=248&fit=crop&auto=format';

    const imageSrcSet = model === 'orcrUrl' ? 'assets/images/id/orcr.jpg?w=248&fit=crop&auto=format' : model === 'frontLicenseIdUrl'
        ? 'assets/images/id/ID_front.png?w=248&fit=crop&auto=format&dpr=2 2x'
        : 'assets/images/id/ID_back.png?w=248&fit=crop&auto=format&dpr=2 2x';

    const [takePhotoModal, setTakePhotoModal] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState("");

    function setCaptureFile(file) {
        setSelectedFile(file);
        setUrl(model, file);
    }

    return (

        <Stack>
            <ImageList sx={{ width: '100%', height: '172px' }} cols={1}>
                <ImageListItem className=' border-1 border-grey-400 relative'>
                    {!url && (
                        <img
                            src={imageUrl}
                            srcSet={imageSrcSet}
                            alt='id'
                            loading="lazy"
                            style={{ objectFit: 'contain', width: '100%', height: '170px' }}
                        />
                    )}
                    {url && (
                        <>
                            <img
                                src={(typeof url === 'object') ? url.url : url}
                                alt='id'
                                loading="lazy"
                                style={{ objectFit: 'contain', width: '100%', height: '170px' }} // Add this line
                            />
                            <div className=' absolute right-0 p-6'>
                                <Icon className='text-red-600 cursor-pointer' onClick={() => { setCaptureFile("") }}>delete</Icon>
                            </div>
                        </>
                    )}
                </ImageListItem>
            </ImageList>
            <Stack direction={'row'} gap={1} className='my-8'>
                <Button variant='outlined' className='rounded-none w-full'
                    onClick={() => {
                        setTakePhotoModal(true);
                    }}
                >Take a Picture</Button>

                <label
                    // htmlFor="button-file"
                    className="productImageUpload"
                >
                    <Button variant='contained' color='secondary' className='rounded-none w-full' component="span" >
                        Upload
                    </Button>
                    <input
                        accept="image/*"
                        className="hidden"
                        id="button-file"
                        type="file"
                        onChange={async (e) => {
                            function readFileAsync() {
                                return new Promise((resolve, reject) => {
                                    const file = e.target.files[0];
                                    if (!file) {
                                        return;
                                    }
                                    const reader = new FileReader();

                                    reader.onload = () => {
                                        resolve({
                                            id: FuseUtils.generateGUID(),
                                            url: `data:${file.type};base64,${btoa(reader.result)}`,
                                            type: 'image',
                                            file: file,
                                            status: "new"
                                        });
                                    };

                                    reader.onerror = reject;

                                    reader.readAsBinaryString(file);
                                });
                            }

                            const newImage = await readFileAsync();

                            setCaptureFile(newImage);

                            // onChange([newImage, ...value]);
                            e.target.value = null;
                        }}
                    />


                </label>

            </Stack>
            <TakePhoto openDialog={takePhotoModal} setOpenDialog={setTakePhotoModal} setFile={setCaptureFile} />

        </Stack>

    );
}

export default TakeUploadService;