import { useFormContext, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDeepCompareEffect } from "@fuse/hooks";

import randomString from "app/services/randomString";
import Maps from "../../../../services/maps/maps";
import Icon from "@mui/material/Icon";
import Geocode from "react-geocode";
import CONFIG from "app/config";


import { getDepartments } from "app/main/apps/settings/store/bookingDepartmentsSlice";

import Tab1 from "../tabs/Tab1";
import Tab2 from "../tabs/Tab2";
import Tab3 from "../tabs/Tab3";

import InputMask from 'react-input-mask';

import FormHelperText from '@mui/material/FormHelperText';

//Grid Responsive
import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));
//Grid Responsive


function CrmUsersProfileContent(props) {
    const dispatch = useDispatch();
    const routeParams = useParams();

    const methods = useFormContext();
    const { control, formState, setValue, watch, trigger } = methods;
    const { errors } = formState;

    const { tabValue } = props;

    const [types, setTypes] = useState([]);
    const [department, setDepartment] = useState([]);

    let address = watch("address1");
    let lat = watch("lat");
    let lng = watch("lng");

    let [location, setLocation] = useState({
        address: address,
        lat: lat ? lat : 7.10262568638697,
        lng: lng ? lng : 171.3782997355861,
    });

    useEffect(() => {
        if (lng && lat) {
            setLocation({
                address: address,
                lat: lat,
                lng: lng,
            });
        }
    }, [dispatch, address, lat, lng]);

    async function generatePatronId() {
        var id = randomString(10);

        setValue("crmUsersId", id, { shouldDirty: true });

        await trigger(["crmUsersId"]);
    }

    async function generateIdNum() {
        var id = randomString(10);

        setValue("idNum", id, { shouldDirty: true });

        await trigger(["idNum"]);
    }
    const { crmUsersId } = routeParams;

    useEffect(() => {
        if (!lng && !lat) {
            handleAddressChange();
        }
    }, [dispatch, routeParams]);

    useDeepCompareEffect(() => {
        if (crmUsersId == "new") {
            setValue("crmUsersId", "");
            setValue("idNum", "");
            setValue("patronType", "");
        }
    }, [dispatch, routeParams]);

    useEffect(() => {
        dispatch(getDepartments()).then((action) => {
            if (action.payload) {
                setDepartment(action.payload);
            }
        });
    }, [dispatch]);

    function handleAddressChange() {
        Geocode.setApiKey(CONFIG.MAPS);
        Geocode.setLanguage("en");
        Geocode.fromAddress(address).then(
            (response) => {
                if (response.results) {
                    const { lat, lng } = response.results[0].geometry.location;
                    setValue("lat", lat, { shouldDirty: true });
                    setValue("lng", lng, { shouldDirty: true });
                    setLocation({
                        address: address,
                        lat: lat,
                        lng: lng,
                    });
                }
            },
            (error) => {
                // console.error(error);
            }
        );
    }

    function handleClickMaps(maps) {

        setValue("lat", maps.lat, { shouldDirty: true });
        setValue("lng", maps.lng, { shouldDirty: true });
        setLocation({
            address: address,
            lat: maps.lat,
            lng: maps.lng,
        });
    }

    const [typeModal, setTypeModal] = useState(false);

    function handlePrefillType(value) {
        dispatch(getTypes()).then((action) => {
            if (action.payload) {
                setTypes(action.payload);
                setValue('patronType', value);

                trigger(["patronType"]);
            }
        });
    }

    function handlePrefillModel(model, value) {
        dispatch(getDepartments()).then((action) => {
            if (action.payload) {
                setDepartment(action.payload);
                setValue('departmentName', value);

                trigger(["departmentName"]);
            }
        });
    }

    return (
        <div className="w-full">
            <div className={tabValue !== 0 ? 'hidden' : ''}>
                <Tab1 />
            </div>

            <div className={tabValue !== 1 ? 'hidden' : ''}>
                <Tab2 />
            </div>

            <div className={tabValue !== 2 ? 'hidden' : ''}>
                <Tab3 />
            </div>
        </div>
    );
}

export default CrmUsersProfileContent;
