const mutation = {
    create: `
        mutation createTenant($data: JSONString!) {
            createTenant (data: $data) {
                status,
                res {
                    id
                    referenceNum
                    houseId
                    roomId
                    roomNumber
                    house {
                        houseName
                    }
                },
                message
            }
        }
    `,
    update: `
        mutation updateTenant($data: JSONString!) {
            updateTenant (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    delete: `
        mutation deleteTenant($id: String!) {
            deleteTenant (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
    saveInspection: `
        mutation saveInspection($data: JSONString!) {
            saveInspection (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    resendTenant: `
        mutation resendTenant($data: JSONString!) {
            resendTenant (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    cancelTenant: `
        mutation cancelTenant($data: JSONString!) {
            cancelTenant (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    tenantByRefNum: `
        query tenantByRefNum($referenceNum: String!) {
            tenantByRefNum (referenceNum: $referenceNum) {
                id
                patron {
                    name
                    email
                    emergencyContact
                    departmentName
                }
                house {
                    houseName
                }
                rejectedBy
                rejectReason
                referenceNum
                roomNumber
                totalPrice
                bedNumber
                roomPrice
                mealCredit
                bookCredit
                status
                semester
                year
                userName
                userPosition
                start
                end
                timeOfBooking
                approvedById
                approvedBy
                approvees
                approvee {
                    id
                    email
                    name
                    position
                    departments
                }
            }
        } 
    `,
    getRoomsWithTenants: `
        query roomList($houseId: String, $amenities: String, $status: String) {
            roomList(houseId: $houseId, amenities: $amenities, status: $status) {
                id
                houseId
                roomNumber
                roomFloor
                roomStatus
                roomCapacity
                roomRate
                roomAmenities
                roomNotes
                roomColor
                roomPicId
                roomPicPath  
                tenants {
                    id
                    start
                    end
                    patron {
                        name
                        profilePicUrl
                    }
                }
                dateCreated
                dateUpdated
            }
        }
    `
}

export { mutation, query };