import * as React from 'react';
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/booking/store";
import BookingApproveHeader from 'app/main/booking/header/BookingApproveHeader';
import InvoiceContent from 'app/main/tenant/invoice/content/InvoiceContent';
import { useParams } from 'react-router-dom';

import { getTenantInvoice } from "app/main/tenant/store/invoiceSlice";

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-header": {
        minHeight: 72,
        height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            height: 136,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
}));

function Invoice(props) {

    const routeParams = useParams();
    const dispatch = useDispatch();

    const [form, setForm] = React.useState({
        approvedBy: []
    });

    React.useEffect(() => {

        dispatch(getTenantInvoice({
            invoiceId: routeParams.invoiceId,
            tenantId: routeParams.tenantId
        })).then(function(response) {
            setForm(response);
        });

    }, [routeParams]);

    return (
        <Root
            header={<BookingApproveHeader form={form} status={'download'}/>}
            content={
                <>
                    <InvoiceContent status={props.status} form={form} setForm={setForm}/>
                </>
            }
            innerScroll
        />
    );
}

export default withReducer("bookingApp", reducer)(Invoice);
