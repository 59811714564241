import { authRoles } from 'app/auth';
import Home from './home/Home';
import Product from './product/Product';
import Shop from './shop/Shop';
import ForgotPassword from './forgot_password/ForgotPassword';
import ActivateAccount from './activate_account/ActivateAccount';

const EcommerceConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'e-commerce/home',
            element: <Home />,
        },
        {
            path: 'e-commerce/forgot-password/:id',
            element: <ForgotPassword />,
        },
        {
            path: 'e-commerce/activate-account/:id',
            element: <ActivateAccount />,
        },
        {
            path: 'e-commerce/shop/*',
            element: <Shop />,
        },
        {
            path: 'e-commerce/product/:itemId/*',
            element: <Product />,
        },
    ],
};

export default EcommerceConfig;
