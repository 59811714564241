import * as React from 'react';
import { useFormContext, Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

function Tab3() {

    const methods = useFormContext();

    const { control, formState } = methods;

    return (
        <div className="py-24 px-32 max-400px:px-10 mx-auto w-full">

            <FormControl
                  className="flex flex-col mt-9  ml-0 truncate items-start"
                variant="outlined">
                <Typography className="text-15 mr-10" color="text.primary">Notes</Typography>
                <Controller
                    control={control}
                    name="notes"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className=""
                            id="notes"
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                        />
                    )}
                />
            </FormControl>

            <FormControl
                  className="flex flex-col mt-9  ml-0 truncate items-start"
                variant="outlined">
                <Typography className="text-15 mr-10" color="text.primary">Field 1</Typography>
                <Controller
                    control={control}
                    name="field1"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className=""
                            id="field1"
                             variant="outlined"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40
                                }
                            }}
                        />
                    )}
                />
            </FormControl>

            <FormControl
                  className="flex flex-col mt-9  ml-0 truncate items-start"
                variant="outlined">
                <Typography className="text-15 mr-10" color="text.primary">Field 2</Typography>
                <Controller
                    control={control}
                    name="field2"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className=""
                            id="field2"
                             variant="outlined"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40
                                }
                            }}
                        />
                    )}
                />
            </FormControl>

            <FormControl
                  className="flex flex-col mt-9  ml-0 truncate items-start"
                variant="outlined">
                <Typography className="text-15 mr-10" color="text.primary">Field 3</Typography>
                <Controller
                    control={control}
                    name="field3"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className=""
                            id="field3"
                             variant="outlined"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40
                                }
                            }}
                        />
                    )}
                />
            </FormControl>

            <FormControl
                  className="flex flex-col mt-9  ml-0 truncate items-start"
                variant="outlined">
                <Typography className="text-15 mr-10" color="text.primary">Field 4</Typography>
                <Controller
                    control={control}
                    name="field4"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className=""
                            id="field4"
                             variant="outlined"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40
                                }
                            }}
                        />
                    )}
                />
            </FormControl>

            <FormControl
                  className="flex flex-col mt-9  ml-0 truncate items-start"
                variant="outlined">
                <Typography className="text-15 mr-10" color="text.primary">Field 5</Typography>
                <Controller
                    control={control}
                    name="field5"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className=""
                            id="field5"
                             variant="outlined"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40
                                }
                            }}
                        />
                    )}
                />
            </FormControl>
        </div>
    );
}

export default Tab3;
