import * as React from 'react';
import { useFormContext, Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputMask from 'react-input-mask';

function Tab1() {

    const methods = useFormContext();

    const { control, formState } = methods;

    const { errors } = formState;

    return (
        <div className="w-full">
            <div className="py-24 px-32 max-400px:px-10 mx-auto w-full">
                <div className="flex max-600px:flex-col items-start justify-between">
                    <div className="flex-col flex pl-6 max-600px:w-full max-600px:pl-0">
                        <FormControl
                            className="flex flex-col mt-9  ml-0 truncate items-start"
                            variant="outlined"
                        >
                            <Typography className="text-15 mr-10" color="text.primary">First Name<span className="text-red-500">* </span></Typography>
                            <Controller
                                control={control}
                                name="firstName"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="firstName"
                                        variant="outlined"
                                        error={!!errors.firstName}
                                        required
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 40
                                            }
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <small className='text-red-600'>{errors?.firstName?.message}</small>
                    </div>
                    <div className="flex-col flex pl-6 max-600px:w-full max-600px:pl-0">
                        <FormControl
                            className="flex flex-col mt-9  ml-0 truncate items-start"
                            variant="outlined"
                        >
                            <Typography className="text-15 mr-10" color="text.primary">Middle Name</Typography>
                            <Controller
                                control={control}
                                name="middleName"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="middleName"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 40
                                            }
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </div>
                    <div className="flex-col flex pl-6 max-600px:w-full max-600px:pl-0">
                        <FormControl
                            className="flex flex-col mt-9  ml-0 truncate items-start"
                            variant="outlined"
                        >
                            <Typography className="text-15 mr-10" color="text.primary">Last Name<span className="text-red-500">* </span></Typography>
                            <Controller
                                control={control}
                                name="lastName"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="lastName"
                                        variant="outlined"
                                        error={!!errors.lastName}
                                        required
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 40
                                            }
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                        <small className='text-red-600'>{errors?.lastName?.message}</small>
                    </div>
                </div>
                <FormControl
                    className="mx-16 mt-9 ml-0 truncate"
                    variant="outlined">
                    <Controller
                        render={({ field }) => (
                            <div className="flex flex-col items-start">
                                <Typography id="demo-row-radio-buttons-group-label" className="text-15 mr-10" color="text.primary">
                                    Gender
                                </Typography>
                                <RadioGroup {...field} aria-label="gender" name="gender1" row>
                                    <FormControlLabel value="Male" control={<Radio />} label="Male"
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: 15
                                            }
                                        }} />
                                    <FormControlLabel
                                        value="Female"
                                        control={<Radio />}
                                        label="Female"
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: 15
                                            }
                                        }}
                                    />
                                </RadioGroup>
                            </div>
                        )}
                        name="gender"
                        control={control}
                    />
                </FormControl>
                <FormControl
                    className="flex flex-col items-start mt-9 ml-0 max-w-full  truncate"
                    variant="outlined">
                    <Typography className="text-15 mr-10" color="text.primary">Birthday</Typography>
                    <Controller
                        control={control}
                        name="birthdate"
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    renderInput={(field) => (
                                        <TextField {...field} className="w-full"
                                            variant='outlined'
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 40
                                                },
                                                // "& .MuiInputAdornment-root .MuiButtonBase-root":{marginRight:"unset "}
                                            }} />
                                    )}
                                    onChangeRaw={() => {
                                        setFieldTouched(field.name, true, true);
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </FormControl>
                <div className="flex-col flex">
                    <FormControl
                        className="flex flex-col items-start mt-9 ml-0 max-w-full  truncate"
                        variant="outlined">
                        <Typography className="text-15 mr-10" color="text.primary">Emergency Contact</Typography>
                        <Controller
                            control={control}
                            name="emergencyContact"
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    mask="(999) 999-9999"
                                    maskPlaceholder="(000) 000-0000"
                                >
                                    {() =>
                                        <TextField
                                            {...field}
                                            className=""
                                            id="emergencyContact"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={!!errors.emergencyContact}
                                            // helperText={errors?.emergencyContact?.message}
                                            placeholder="(000) 000-0000"
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 40
                                                }
                                            }}
                                        />}
                                </InputMask>
                            )}
                        />
                    </FormControl>
                    <small className='text-red-600'>{errors?.emergencyContact?.message}</small>
                </div>

                <div className="flex-col flex">
                    <FormControl
                        className="flex flex-col items-start mt-9 ml-0 max-w-full  truncate"
                        variant="outlined">
                        <Typography className="text-15 mr-10" color="text.primary">Phone</Typography>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field }) => (
                                <InputMask
                                    {...field}
                                    mask="(999) 999-9999"
                                    maskPlaceholder="(000) 000-0000"
                                >
                                    {() =>
                                        <TextField
                                            {...field}
                                            className=""
                                            id="phone"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="(000) 000-0000"
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 40
                                                }
                                            }}
                                        />}
                                </InputMask>
                            )}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default Tab1;
