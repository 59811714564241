import { createSlice } from '@reduxjs/toolkit';
import { query, mutation } from 'app/main/tenant/graphql/approve_graphql';
import axios from "axios";
import CONFIG from "app/config";
import CrmJwtService from 'app/services/crm/CrmJwtService';
const token = CrmJwtService.getAccessToken();

export const getTenantRefId =
    (params) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTenantRefId,
            variables: params,
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        var data = response.data.data.tenantByRefId;

        data.approvees =  data.approvees ? JSON.parse(data.approvees) : [];

        return data;
    };

export const acceptTenant =
    (form) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.approveTenant,
            variables: {
                data: JSON.stringify(form)
            },
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });;

        return response.data.data.approveTenant;
    };

const initialState = {
    success: false,
    errors: [],
};

const approveSlice = createSlice({
    name: 'tenant/approve',
    initialState,
    reducers: {
        approveSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        approveError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
});

export const { approveSuccess, approveError } = approveSlice.actions;

export default approveSlice.reducer;