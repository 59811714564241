import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import format from 'date-fns/format';

function PrintFormConfirmation({ printRef, form }) {

    return (
        <>
            <div className='hidden'>
                <div ref={printRef}>
                    <div className="header-print-posting">
                        <div className='float-left m-10 p-10'>
                            <Typography className="text-18 font-semibold">
                                CMI OSS SYSTEM
                            </Typography>
                            <Typography className="text-16 font-semibold">
                                Booking
                            </Typography>
                        </div>
                        <div className='float-right m-10 p-10'>
                            <div className="logo">
                                <img width="110" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                            </div>
                        </div>
                    </div>

                    <Card className="flex flex-col shadow">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }}>
                                    <div className='flex flex-row'>
                                        <div className='flex flex-col relative mx-16 mb-24 w-full'>
                                            <div className='flex'>
                                                <ImageList sx={{ width: 170, height: 170 }} cols={1} className="overflow-hidden rounded-md">
                                                    <ImageListItem>
                                                        <img className="max-w-none w-auto h-full" src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                                    </ImageListItem>
                                                </ImageList>
                                            </div>
                                            <Typography className='text-12 font-semibold mt-10'>
                                                {form.itemCode}
                                            </Typography>
                                            <Typography className='text-14 font-bold'>
                                                {form.itemName}
                                            </Typography>
                                            <div className="mt-10">
                                                <Typography className='text-14 font-bold'>
                                                    <span className="text-14 font-medium">CMI Rate:</span> ${form.itemRate} / (30 mins)
                                                </Typography>
                                                <Typography className='text-14 font-bold'>
                                                    <span className="text-14 font-medium">Total:</span> ${form.total}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className='flex flex-col mx-10 mb-24 w-full'>
                                            <Typography className=' text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Date:</span> {form.tempStart ? format(form.tempStart, 'EEEE, MMMM dd, yyyy') : ""}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Time:</span> {form.tempStart ? format(form.tempStart, 'hh:mm a') : ""} - {form.tempEnd ? format(form.tempEnd, 'hh:mm a') : ""}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Duration:</span>{form.duration}
                                            </Typography>

                                            <Typography className=' text-14 font-semibold mt-10'>
                                                <span className=" text-14 font-medium">Name:</span> {form.name}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Email:</span> {form.email}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Contact:</span> {form.contact}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                <span className=" text-14 font-medium">Department:</span> {form.department}
                                            </Typography>
                                            <div>
                                                <Typography className=' text-14 font-semibold'>
                                                    <span className=" text-14 font-medium">Purpose:</span> {form.purpose}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                            </Grid>
                        </Box>
                    </Card>

                </div>
            </div>
        </>
    );
}

export default PrintFormConfirmation;