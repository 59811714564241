// * Updated By: ICO
// * Summary:  Responsive issues
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-52526-96
// * DateUpdated:  05/29/2023

import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getCartList, saveCartList } from "app/main/ecommerce/store/cartSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import Button from '@mui/material/Button';
import CartTable from 'app/main/ecommerce/cart/content/CartTable';
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import Alert from '@mui/material/Alert';
import AddressModal from "../modal/AddressModal";
import { showMessage } from "app/store/fuse/messageSlice";
import { getUser } from "app/main/apps/e-commerce/store/userManagementSlice";
import FuseScrollbars from '@fuse/core/FuseScrollbars';

import Hidden from '@mui/material/Hidden';
function CartContent(props) {
    const token = EcommerceJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selected, setSelected] = useState([]);
    const [cartList, setCartList] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [address, setAddress] = useState(token.address1);
    const [user, setUser] = useState({});

    useEffect(() => {
        setLoading(true);
        dispatch(getCartList(token.id)).then((action) => {

            action.payload.map(function(value) {
                value.total = parseFloat(value.price) * parseInt(value.quantity)
                return value;
            });

            setCartList(action.payload);
            setLoading(false);
        });
    }, [dispatch, deleteClick]);

    useEffect(() => {
        dispatch(getUser({ usersId: token.id })).then((action) => {
            setAddress(action.payload.address1);
            setUser(action.payload);

        });   
        
        EcommerceJwtService.setJwtLoginReload(false);     
    }, [dispatch]);

    const [openModal, setOpenModal] = useState(false);

    function handleCloseDialog() {
        setOpenModal(false);
    }

    function handleOpenModal() {
        setOpenModal(true);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            setSelected(cartList.map((n) => n.id));
            return;
        }
        setSelected([]);
    }

    function handleDeselect() {
        setSelected([]);
    }

    function handleCheck(event, id) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }

    useEffect(() => {

        if (!selected.length) {
            // const sum = cartList.reduce((accumulator, object) => {
            //     return accumulator + parseFloat(object.total);
            // }, 0);
            setTotal(0);
        } else {
            var cartListFinal = [];

            selected.map(function(val) {
                var item = cartList.filter(function(i) {
                    return val === i.id;
                })[0];
    
                cartListFinal.push(item);
            });
            const sum = cartListFinal.reduce((accumulator, object) => {
                return accumulator + parseFloat(object.total);
            }, 0);
    
            setTotal(sum);
        }
    }, [selected, cartList]);

    useEffect(() => {
        dispatch(saveCartList({items: cartList})).then((action) => {
        });
    }, [cartList]);

    function handleClickCheckout() {

        if (!selected.length) {
            return dispatch(
                showMessage({
                    message: "Please select item(s) to checkout.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        var cartListFinal = [];

        selected.map(function(val) {
            var item = cartList.find(function(i) {
                return val === i.id;
            });

            if (item) {
                cartListFinal.push(item);
            }

        });

        if (cartListFinal.length) {

            var noStock = false;

            cartListFinal.map(function (value) {
                if (value.quantity > value.stock) {
                    noStock = value;
                }
            })

            if (noStock) {
                dispatch(
                    showMessage({
                        message: "Insufficient stock for this item. (" + noStock.name + ")",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
                return console.log("No Stock");
            }

            var checkoutData = {
                user: {
                    ...token,
                    address: address
                },
                items: cartListFinal,
                total: total
            }

            localStorage.setItem('ecommerce_checkout_data', JSON.stringify(checkoutData));
            navigate(`/e-commerce/checkout`);

        }
    }

    if (loading) {

        return <div className="mt-25vh"><FuseLoading /></div>

    }

    return (
        <div className="px-24">
            <AddressModal openDialog={openModal} handleCloseDialog={handleCloseDialog} address={address} setAddress={setAddress} user={user}></AddressModal>
            <div role="presentation" className="py-20 font-bold">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important', fontWeight: 'bold' }} color="inherit" href="/e-commerce/home" >
                        <Icon sx={{ mr: 0.5 }} fontSize="inherit" >keyboard_backspace</Icon> Go Back shopping
                    </Link>
                </Breadcrumbs>
            </div>
                <div className="w-full bg-white border-1 border-1-gray-50 border-opacity-5 px-24 py-12 relative ...">
                    <Typography className="font-semibold text-18">Shopping Cart</Typography>
                    <Alert severity="info" sx={{
                        '& .MuiAlert-icon': { display: "none" },
                        '& .MuiAlert-message': { paddingTop: "unset", paddingBottom: "unset" },
                    }} className=" rounded-md border-1 border-cyan-A700 my-16 max-600px:my-5">
                        <Typography className="font-bold text-black">Shipping Address <Link className="mr-10 cursor-pointer" onClick={handleOpenModal}>Edit</Link></Typography>
                        <Typography className="font-medium text-black">{address}</Typography>
                    </Alert>
                    <CartTable 
                        {...props}
                        cartList={cartList} 
                        setCartList={setCartList} 
                        deleteClick={deleteClick} 
                        setDeleteClick={setDeleteClick} 
                        selected={selected} 
                        handleSelectAllClick={handleSelectAllClick} 
                        handleCheck={handleCheck} 
                    />
                   
                        <div className="shadow-t-md absolute bottom-0 w-full -ml-25 px-24 py-16 bg-white ">
                            <Stack direction="row" className=" justify-between pb-16" alignItems="center">
                                 <Hidden smDown>
                                    <Typography className="font-bold text-16">Cart Totals</Typography>
                                </Hidden>
                                <Stack direction="row" spacing={8} className="mr-0 sm:mr-192" >
                                    <Stack className=" items-end">
                                        <Typography className=" text-14">Sub Total:</Typography>
                                        <Typography className=" text-14">Shipping Total:</Typography>
                                    </Stack>

                                    <Stack >
                                        <Typography className="font-bold text-14">${total.toFixed(2)}</Typography>
                                        <Typography className="font-bold text-14">FREE</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <hr></hr>
                            <Stack direction="row" alignItems="center" className="pt-16 justify-start sm:justify-end">
                                <Stack direction="row" spacing={5} alignItems="center" className="mr-0 ml-45 sm:mr-45 sm:ml-0 ">
                                    <Stack className=" items-end">
                                        <Typography className="font-bold text-16">TOTAL:</Typography>
                                    </Stack>

                                    <Stack >
                                        <Typography className="font-bold text-16 ml-16 sm:ml-0">${total.toFixed(2)}</Typography>
                                        </Stack>
                                    <Hidden smDown>
                                        <Button variant="contained" color="primary" className="rounded-md" disabled={!selected.length} onClick={handleClickCheckout}>CHECKOUT</Button>
                                    </Hidden>
                                </Stack>
                            </Stack>
                            <Hidden smUp>
                                <Button variant="contained" color="primary" className="rounded-md w-full mt-10" disabled={!selected.length} onClick={handleClickCheckout}>CHECKOUT</Button>
                            </Hidden>
                        </div>
                    
                    
                    

                </div>
        </div>
    )

}

export default CartContent;