import { createSlice } from '@reduxjs/toolkit';
import { query, mutation } from 'app/main/booking/graphql/invoice_graphql';
import axios from "axios";
import CONFIG from "app/config";

export const getBookingInvoice =
    (params) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBookingInvoice,
            variables: params,
        });

        return response.data.data.getBookingInvoice;
    };

export const getMotorpoolBookingInvoice =
    (params) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMotorpoolBookingInvoice,
            variables: params,
        });

        return response.data.data.getMotorpoolBookingInvoice;
    };

const initialState = {
    success: false,
    errors: [],
};

const invoiceSlice = createSlice({
    name: 'booking/invoice',
    initialState,
    reducers: {
        invoiceSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        invoiceError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
});

export const { invoiceSuccess, invoiceError } = invoiceSlice.actions;

export default invoiceSlice.reducer;