import * as React from 'react';
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from "framer-motion";
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import FuseLoading from '@fuse/core/FuseLoading';
import Pagination from '@mui/material/Pagination';

import { getItemCategoryList, getItemsPaginate, saveItemSearch } from "app/main/ecommerce/store/shopSlice";
import globalFunction from 'app/services/globalFunction';

function ShopContent(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dateFilter, setDateFilter } = props;
    const { searchText, setSearchText } = props;
    const { categoryText, setCategoryText } = props;
    const { typeText, setTypeText } = props;
    const { rowsPerPage, setRowsPerPage } = props;
    const { page, setPage } = props;
    const { order, setOrder } = props;

    const show = [
        { title: '10', value: 10 },
        { title: '20', value: 20 },
        { title: '30', value: 30 },
        { title: 'All', value: 999999999 },
    ];

    const routeParams = useParams();

    useEffect(() => {

        setSearchText(routeParams['*']);

        dispatch(saveItemSearch({ text: routeParams['*'] }));

    }, [routeParams]);

    const [pages, setPages] = useState(0);
    const [minSrp, setMinSrp] = useState();
    const [maxSrp, setMaxSrp] = useState();
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);
    const [expanded, setExpanded] = React.useState('productType');
    const [expanded2, setExpanded2] = React.useState('priceRange');

    function handleCheck(event, id) {
        const selectedCategoriesIndex = selectedCategories.indexOf(id);
        let newSelectedCategories = [];

        if (selectedCategoriesIndex === -1) {
            newSelectedCategories = newSelectedCategories.concat(selectedCategories, id);
        } else if (selectedCategoriesIndex === 0) {
            newSelectedCategories = newSelectedCategories.concat(selectedCategories.slice(1));
        } else if (selectedCategoriesIndex === selectedCategories.length - 1) {
            newSelectedCategories = newSelectedCategories.concat(selectedCategories.slice(0, -1));
        } else if (selectedCategoriesIndex > 0) {
            newSelectedCategories = newSelectedCategories.concat(
                selectedCategories.slice(0, selectedCategoriesIndex),
                selectedCategories.slice(selectedCategoriesIndex + 1)
            );
        }

        setSelectedCategories(newSelectedCategories);
    }

    useEffect(() => {
        setLoading(true);
        var orderItem = "-date_created";
        if (order.id) {
            orderItem = (order.direction == 'asc' ? order.id : ("-" + order.id))
        }
        dispatch(getItemsPaginate({
            keyword: routeParams['*'] ? routeParams['*'] : searchText,
            type: typeText,
            limit: rowsPerPage,
            page: page,
            order: orderItem,
            category: selectedCategories.length ? JSON.stringify(selectedCategories) : categoryText,
            catArr: selectedCategories.length ? "true" : "false",
            minSrp: parseFloat(minSrp ? minSrp : 0),
            maxSrp: parseFloat(maxSrp ? maxSrp : 9999999)
        })).then((action) => {
            if (action.payload) {
                setData(action.payload.objects);
                setPages(action.payload.pages);
                setTotalItems(action.payload.totalItems)
                setLoading(false);
            }
        });
        dispatch(getItemCategoryList()).then((action) => {
            setDataCategories(action.payload);
        });
    }, [dispatch, page, rowsPerPage, searchText, categoryText, typeText, order, dateFilter, selectedCategories, minSrp, maxSrp, routeParams]);


    function handleClickItem(item) {
        navigate(`/e-commerce/product/${item.id}`);
    }

    const handleHome = () => {
        navigate("/e-commerce/home");
    }

    const handleAllProducts = () => {
        navigate("/e-commerce/shop/");
    }

    const expandProductType = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const expandPriceRange = (panel) => (event, newExpanded) => {
        setExpanded2(newExpanded ? panel : false);
    };

    const handlePageChange = (e, item) => {
        setPage(item);
    };

    const handleShowChange = (item) => {
        setRowsPerPage(item.value);
    }

    const handleMinChangeSrp = (e, item) => {
        setMinSrp(e.target.value);
    };

    const handleMaxChangeSrp = (e, item) => {
        setMaxSrp(e.target.value);
    };

    return (
        <div className="px-24 max-600px:px-16 pb-48">
            <div role="presentation" className="py-20 font-medium">
                <Breadcrumbs aria-label="breadcrumb">
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" onClick={() => handleHome()} className='cursor-pointer' />
                    <Typography className='cursor-pointer' sx={{ display: 'flex', alignItems: 'center' }} color="text.primary" onClick={() => handleAllProducts()}>
                        All Products
                    </Typography>
                </Breadcrumbs>
            </div>
            <div className="flex flex-wrap ">
                <div className="flex flex-col xl:w-3/12 lg:3/12 md:w-3/12 sm:w-full md:pr-24 pr-0">
                    <div className="w-full bg-white border-1 border-1-gray-50 border-opacity-5 mb-16 px-24 max-600px:px-16 pb-20">
                        <Accordion elevation={0} expanded={expanded === 'productType'} onChange={expandProductType('productType')} className="">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ minHeight: "0", padding: "0", paddingTop: "2rem" }}
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        margin: " 0 !important"
                                    }
                                }}
                            >
                                <Typography className=" uppercase font-semibold tracking-wide">Categories</Typography>
                            </AccordionSummary>

                            <AccordionDetails style={{ padding: "0", paddingTop: "1rem" }}>
                                {
                                    dataCategories.map((n) => {
                                        const isSelected = selectedCategories.indexOf(n.name) !== -1;
                                        return (
                                            <FormGroup key={n.id}>
                                                <FormControlLabel
                                                    className="w-fit"
                                                    control={
                                                        <Checkbox
                                                            checked={isSelected}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => handleCheck(event, n.name)}
                                                        />
                                                    } label={n.name}
                                                />
                                            </FormGroup>
                                        );
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>

                    </div>
                    <div className="w-full bg-white border-1 border-1-gray-50 border-opacity-5 mb-16 px-24 max-600px:px-16 pb-20">
                        <Accordion elevation={0} expanded={expanded2 === 'priceRange'} onChange={expandPriceRange('priceRange')} className="">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ minHeight: "0", padding: "0", paddingTop: "2rem" }}
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        margin: " 0 !important"
                                    }
                                }}
                            >
                                <Typography className=" uppercase font-semibold tracking-wide">filter by price</Typography>
                            </AccordionSummary>

                            <AccordionDetails style={{ padding: "0", paddingTop: "1.4rem" }}>
                                <Stack
                                    direction="row" spacing={1.5}
                                    component="form"
                                    alignItems="center"
                                    noValidate
                                    autoComplete="off"
                                >
                                    <FormControl fullWidth variant="filled">
                                        <FilledInput
                                            hiddenLabel
                                            value={minSrp}
                                            type='number'
                                            size="small"
                                            placeholder="MIN"
                                            onKeyDown={event => globalFunction().handlePreventCharacters(event, ['e', 'E', '-', '+'])}
                                            onPaste={globalFunction().handlePasteNumericOnly}
                                            onChange={(e, p) => handleMinChangeSrp(e, p)}
                                            startAdornment={<InputAdornment style={{ marginTop: "0" }} position="start">$</InputAdornment>}
                                        />
                                    </FormControl>
                                    <hr className=" w-56"></hr>
                                    <FormControl fullWidth variant="filled">
                                        <FilledInput
                                            type='number'
                                            value={maxSrp}
                                            hiddenLabel
                                            size="small"
                                            placeholder="MAX"
                                            onKeyDown={event => globalFunction().handlePreventCharacters(event, ['e', 'E', '-', '+'])}
                                            onPaste={globalFunction().handlePasteNumericOnly}
                                            onChange={(e, p) => handleMaxChangeSrp(e, p)}
                                            startAdornment={<InputAdornment style={{ marginTop: "0" }} position="start">$</InputAdornment>}
                                        />
                                    </FormControl>

                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>

                <div className="xl:w-3/4 lg:w-3/4 md:w-3/4 sm:w-full flex flex-col justify-between">
                    <div className='w-full'>
                        {routeParams['*'] ? <Stack direction="row" spacing={5} className="bg-white border-1 border-1-gray-50 border-opacity-5 mb-16 px-24 max-600px:px-16 py-12 ">
                            <Typography className="font-medium text-14">
                                Tag Results: {routeParams['*']}
                            </Typography>
                            <Typography className="font-medium text-14">
                                Search Results: {routeParams['*']}
                            </Typography>
                        </Stack> : null}
                        <Stack direction="row" spacing={5} className=" bg-white border-1 border-1-gray-50 border-opacity-5 mb-16 px-24 max-600px:px-16 py-12 ">
                            <div className=" w-full flex flex-wrap justify-between items-center">
                                <Typography className="font-medium text-14">

                                    {selectedCategories.length > 0 && !loading && (
                                        <>
                                            {selectedCategories.join(", ")}  <span className=" text-grey-600 ml-10">({totalItems})</span>
                                        </>
                                    )}

                                    {!selectedCategories.length && !loading && (
                                        <>
                                            Showing {page} - {pages} of {totalItems}
                                        </>
                                    )}

                                </Typography>
                            </div>

                            <Stack direction="row" spacing={3} className="justify-between sm:w-fit w-full">
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography className="font-semibold w-fit">
                                        Show:
                                    </Typography>
                                    <TextField
                                        className=" w-80"
                                        sx={{
                                            '& .MuiSelect-select': {
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                            }
                                        }}
                                        id="outlined-select-currency"
                                        select
                                        value={rowsPerPage}
                                    >
                                        {show.map((item) => (
                                            <MenuItem key={item.title} onClick={() => handleShowChange(item)} value={item.value}>
                                                <ListItemText primary={item.title} />
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                </Stack>
                            </Stack>
                        </Stack>

                        {loading && (
                            <>
                                <FuseLoading />
                            </>
                        )}

                        {totalItems === 0 && !loading && (
                            <>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { delay: 0.1 } }}
                                    className="flex flex-1 items-center justify-center h-2/4"
                                >
                                    <Typography color="textSecondary" variant="h5">
                                        There are no items!
                                    </Typography>
                                </motion.div>
                            </>
                        )}

                        {totalItems > 0 && !loading && (
                            <div className="w-full mb-16">
                                <section className="body-font">
                                    <div className="container mx-auto">
                                        <div className="flex flex-wrap -m-4 -mx-14  ">
                                            {
                                                data.map((n) => {
                                                    return (
                                                        <div className="xl:w-1/5 lg:w-1/4 laptop:w-1/4 md:w-2/6 sm:w-1/2 w-full p-14 pt-10 cursor-pointer" key={n.id}
                                                            onClick={(event) => handleClickItem(n)}>
                                                            <div className="bg-white border-1 border-1-gray-50 border-opacity-5 p-0">
                                                                <div className='productImageItem flex flex-shrink-0 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden ' style={{ width: "218px", height: "218px" }} >
                                                                    <img className="max-w-none w-full h-full object-contain" src={n.photoUrl ? n.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                                                        alt={n.itemName} />
                                                                </div>
                                                                <div className="h-76 relative border-t-1 p-8">
                                                                    <Typography className="font-medium leading-4  line-clamp-2">
                                                                        {n.name}
                                                                    </Typography>
                                                                    <Typography color="primary" className="mt-16 text-lg leading-none font-medium title-font mb-4">${n.srp}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </section>

                            </div>
                        )}
                    </div>
                    {totalItems > 0 && !loading && (
                        <Pagination
                            className="mt-20"
                            count={pages}
                            page={page}
                            onChange={(e, p) => handlePageChange(e, p)}
                            sx={{
                                '& .MuiPagination-ul': {
                                    width: "fit-content",
                                    margin: "auto"
                                }
                            }}

                        />
                    )}
                </div>
            </div>
        </div>
    )

}

export default ShopContent;