/*
* DateUpdated: 01/03/23
* updatedBy: ico
* Summary: Masyado pong malayo yung qty ng per items. See attached: qty -Daryl
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-44126-96
*/
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import { Typography } from '@mui/material';
const Root = styled('div')`
  td,
  th {
    border: unset;
    padding-top:5px;
    padding-bottom:5px;
  }

  th {
    background-color: #FFFF;
  }
`;


export default function StickyHeadTable({ items, total }) {

    return (
        <Root>
            <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "unset", border: "unset", boxShadow: "unset",  }}>
                <TableContainer className="h-fit">
                    <Table stickyHeader aria-label="sticky table" >
                        <TableHead>
                            <TableRow>

                                <TableCell className='pb-0 px-0'>
                                    <Typography className=' truncate font-semibold   max-600px:w-160'>Product</Typography>
                                </TableCell>
                                <TableCell className='pb-0 truncate font-semibold px-0' align='left'>

                                </TableCell>
                                <TableCell className='pb-0 truncate font-semibold px-0' align='right'>
                                    Subtotal
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {
                                items.map((item) => {
                                    return (
                                        <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                                            <TableCell size='small' className='w-0 px-0'>
                                                <Typography className='font-medium w-fit truncate  max-600px:whitespace-pre-wrap'>
                                                    {item.name}
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='left' className='font-bold  px-10'>{item.quantity}x</TableCell>
                                            <TableCell align='right' className='font-medium px-0'>${parseFloat(item.total).toFixed(2)} </TableCell>
                                        </TableRow>
                                    );
                                })
                            }

                        </TableBody>
                    </Table>
                    <Stack spacing={.5} className='  pt-5'>
                        <hr></hr>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography className='font-medium'>Subtotal</Typography>
                            <Typography className='font-medium'>${total.toFixed(2)}</Typography>
                        </Stack>
                        <hr></hr>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography className='font-medium'>Shipping</Typography>
                            <Typography className='font-medium'>FREE</Typography>
                        </Stack>
                        <hr></hr>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography className='font-bold text-indigo-900 text-16'>TOTAL</Typography>
                            <Typography className='font-bold text-indigo-900 text-16'>${total.toFixed(2)}</Typography>
                        </Stack>
                    </Stack>
                </TableContainer>
            </Paper>
        </Root>
    );
}