import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from "app/config";
import { query, mutation } from "app/main/crm/graphql/tickets_graphql";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import CrmJwtService from 'app/services/crm/CrmJwtService';

const token = CrmJwtService.getAccessToken();
const dToken = CrmJwtService.getDecodedAccessToken();

export const getTickets = createAsyncThunk('crmApps/tickets/getTickets',
    async (routeParams, { getState }) => {
        routeParams = routeParams || getState().crmApps.tickets.routeParams;
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTickets,
            variables: {
                status: routeParams.folderHandle,
                userId: dToken.id
            },
        });
        const data = await response.data.data.getTickets;

        return { data, routeParams };
    }
);

export const getTicketById = createAsyncThunk('crmApps/tickets/getTicketById',
    async (ticketId, { getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTicketById,
            variables: {
                id: ticketId
            },
            publicUrl: 'crm',
        });
        return response.data.data.getTicketById;
    }
);

const saveFiles = async (postData) => {
    return await new Promise(async (resolve, reject) => {
        if (postData.attachments.length) {
            var newImages = [];

            postData.attachments.map(async (image, index) => {
                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image,
                    });
                }
            });

            if (newImages.length) {
                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name:
                            "crm/tickets-" +
                            randomString() +
                            "-" +
                            (image.image.file ? image.image.file.name : image.image.fileName),
                    }).then(async (result) => {

                        if (result.data.status === "success") {
                            var uploadData = result.data.uploadData;
                            uploadData["module"] = "crm";
                            uploadData["file_id"] = image.image.id;

                            const response = await axios.post(CONFIG.API + "/api/", {
                                query: mutationUpload.create,
                                variables: {
                                    data: JSON.stringify(uploadData),
                                },
                                publicUrl: 'crm',
                            }, {
                                headers: {
                                    'common': {
                                        'Authorization': `Bearer ${token}`
                                    }

                                }
                            });

                            if (response.data.data.createUpload.status == "success") {
                                var upload = response.data.data.createUpload.upload;

                                postData.attachments[image.index].upload_id = upload.id;
                                postData.attachments[image.index].file_name = upload.fileName;
                                postData.attachments[image.index].file_id = upload.fileId;
                                postData.attachments[image.index].file_type = upload.fileType;
                                postData.attachments[image.index].file_size = upload.fileSize;
                                postData.attachments[image.index].path = upload.path;
                                postData.attachments[image.index].module = upload.module;
                                postData.attachments[image.index].url = "";

                            }
                        }

                        if (newImages.length == (index + 1)) {
                            resolve(postData);
                        }
                    });
                });
            } else {
                resolve(postData);
            }
        } else {
            resolve(postData);
        }
    });
};

export const saveCrmDiscussion = createAsyncThunk(
    "crmApps/crmTickets/saveCrmDiscussion",
    async (postData, { dispatch, getState }) => {

        return await saveFiles(postData).then(async (postData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.saveCrmDiscussion,
                variables: {
                    data: JSON.stringify(postData)
                },
                publicUrl: 'crm',
            }, {
                headers: {
                    'common': {
                        'Authorization': `Bearer ${token}`
                    }

                }
            });

            if (response.data.data.saveCrmDiscussion) {
                dispatch(
                    showMessage({
                        message: "Message sent.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        });
    }
);

export const setFolderOnSelectedTickets = createAsyncThunk(
    'crmApps/tickets/setFolderOnSelectedTickets',
    async (id, { dispatch, getState }) => {
        const { selectedTicketIds } = getState().crmApps.tickets;

        const response = await axios.post('/api/ticket-app/set-folder', {
            selectedTicketIds,
            folderId: id,
            publicUrl: 'crm',
        });
        const data = await response.data;

        dispatch(getTickets());

        return data;
    }
);

export const toggleLabelOnSelectedTickets = createAsyncThunk(
    'crmApps/tickets/toggleLabelOnSelectedTickets',
    async (id, { dispatch, getState }) => {
        const { selectedTicketIds } = getState().crmApps.tickets;

        const response = await axios.post('/api/ticket-app/toggle-label', {
            selectedTicketIds,
            labelId: id,
            publicUrl: 'crm',
        });
        const data = await response.data;

        dispatch(getTickets());

        return data;
    }
);

const ticketsAdapter = createEntityAdapter({});

export const { selectAll: selectTickets, selectById: selectTicketById } = ticketsAdapter.getSelectors(
    (state) => state.crmApps.tickets
);

const ticketsSlice = createSlice({
    name: 'crmApps/tickets',
    initialState: ticketsAdapter.getInitialState({
        searchText: '',
        routeParams: {},
        selectedTicketIds: [],
    }),
    reducers: {
        setTicketsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        selectAllTickets: (state, action) => {
            state.selectedTicketIds = state.ids;
        },
        deselectAllTickets: (state, action) => {
            state.selectedTicketIds = [];
        },
        selectTicketsByParameter: (state, action) => {
            const [parameter, value] = action.payload;
            state.selectedTicketIds = state.ids.filter((id) => state.entities[id][parameter] === value);
        },
        toggleInSelectedTickets: (state, action) => {
            const ticketId = action.payload;
            state.selectedTicketIds = state.selectedTicketIds.includes(ticketId)
                ? state.selectedTicketIds.filter((id) => id !== ticketId)
                : [...state.selectedTicketIds, ticketId];
        },
    },
    extraReducers: {
        [getTickets.fulfilled]: (state, action) => {
            const { data, routeParams } = action.payload;
            ticketsAdapter.setAll(state, data);
            state.routeParams = routeParams;
            state.selectedTicketIds = [];
        },
    },
});

export const {
    setTicketsSearchText,
    selectAllTickets,
    deselectAllTickets,
    selectTicketsByParameter,
    toggleInSelectedTickets,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
