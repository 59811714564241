const mutation = {
    create: `
        mutation createBookingItemTypes($data: JSONString!) {
            createBookingItemTypes (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    edit: `
        mutation editBookingItemTypes($data: JSONString!) {
            editBookingItemTypes (data: $data) {
            status,
            res,
            message
            }
        }
    `,
    delete: `
        mutation deleteBookingItemTypes($id: String!) {
            deleteBookingItemTypes (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
    query bookingItemTypesList {
        bookingItemTypesList {
          id,
          name,
          terms,
          inspectionRequired,
          dateCreated,
          dateUpdated
        }
      }
  `,
    bookingItemTypesListWithoutVehicle: `
    query bookingItemTypesListWithoutVehicle {
        bookingItemTypesListWithoutVehicle {
          id,
          name,
          terms,
          inspectionRequired,
          dateCreated,
          dateUpdated
        }
      }
  `,
}

export { mutation, query };