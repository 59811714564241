const CONFIG = {
    env: "qa",
    author: "MEDISOURCE",
    API: "https://cmiapi.medisource.com",
    WEB: "https://cmi.medisource.com",
    JWT: {
        secret: 'cmi-api--tym1j05r$r!p#e#4z!x&++w7ag=!5pg1jsrs5u3p)raf(2bo(m--medisource'
    },
    MAPS: "AIzaSyDk53-rcysYR2NvNGBSRiYt4Q-Mf6hTszw",
    // paypal sandbox -- business
    // https://sandbox.paypal.com
    // sb-3y4p429722212@business.example.com
    // f}?B5<GG
    // paypal sandbox personal for testing
    // https://sandbox.paypal.com
    // sb-pr8g729722218@personal.example.com
    // }J1Pq.}.
    paypalClientID: "AQie4hpcmgsRsGv--3TC121m3RfUUqzuhVmqpkhMjtgvXdAaj14Qxd1ZgW41w_1uMqk634m17FtZcU8N"
};

export default CONFIG
