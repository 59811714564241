import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "app/graphql/crm/crm_reply_buttons";
import { showMessage } from "app/store/fuse/messageSlice";

export const getReplyButtons = createAsyncThunk('crmApp/buttons/getReplyButtons', async () => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.list
    });
    const data = response.data.data.getReplyButtons;
    return data;
});

export const removeReplyButtons = createAsyncThunk(
    'crmApp/buttons/removeReplyButtons',
    async (typeIds, { dispatch, getState }) => {


        await typeIds.map(async (id) => {

            await axios.post(CONFIG.API + "/api/", {
                query: mutation.delete,
                variables: {
                    id: id
                }
            });

        })

        dispatch(
            showMessage({
                message: "Reply Button has been successfully deleted.",
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: "success",
            })
        );

        return typeIds;
    }
);

export const checkCrmKey =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkCrmKey,
            variables: filter
        });

        if (response.data.data.checkCrmKey.length) {
            return false;
        } else {
            return true;
        }

    };

export const saveReplyButtons = createAsyncThunk(
    "crmApp/buttons/saveReplyButtons",
    async (typeData, { dispatch, getState }) => {
        if (typeData.id) {
            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.edit,
                variables: {
                    data: JSON.stringify(typeData),
                },
            });

            if (response.data.data.editCrmReplyButtons) {
                dispatch(
                    showMessage({
                        message: "Reply Button has been successfully updated.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        } else {
            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.create,
                variables: { data: JSON.stringify(typeData) },
            });

            if (response.data.data.createCrmReplyButtons) {
                dispatch(
                    showMessage({
                        message: "Reply Button has been successfully saved.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "success",
                    })
                );
            } else {
                dispatch(
                    showMessage({
                        message: "Something went wrong. Please try again later.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        }
    }
);

const itemTypeAdapter = createEntityAdapter({});

export const { selectAll: selectReplyButtons, selectById: selectReplyButtonsById } =
    itemTypeAdapter.getSelectors((state) => state.crmApp.replyButtons);

const replyButtonSlice = createSlice({
    name: 'crmApp/buttons',
    initialState: itemTypeAdapter.getInitialState({
        searchText: '',
        showModal: false
    }),
    reducers: {
        setReplyButtonsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
    },
    extraReducers: {
        [getReplyButtons.fulfilled]: itemTypeAdapter.setAll,
        [removeReplyButtons.fulfilled]: (state, action) =>
            itemTypeAdapter.removeMany(state, action.payload),
        [saveReplyButtons.fulfilled]: (state, action) => action.payload,
    },
});

export const { setReplyButtonsSearchText } = replyButtonSlice.actions;

export default replyButtonSlice.reducer;
