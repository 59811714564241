import { lazy } from "react";
import { authRoles } from 'app/auth';

const Booking = lazy(() => import("./content/Booking"));
const BookingListView = lazy(() => import("./list-view/BookingListView"));
const BookingCalendarView = lazy(() => import("./calendar/BookingCalendarView"));
const BookingItems = lazy(() => import("./items/BookingItems"));
const Blacklist = lazy(() => import("./blacklist/Blacklist"));
const BookingPerformanceReport = lazy(() => import("./reports/performance/BookingPerformanceReport"));
const BookingCounterReport = lazy(() => import("./reports/counter/BookingCounterReport"));
const BookingDepartmentReport = lazy(() => import("./reports/department/BookingDepartmentReport"));

const BookingAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: "apps/booking/:bookingId/*",
            element: <Booking />,
            auth: ["book-nb"],
        },
        {
            path: "apps/booking/list-view",
            element: <BookingListView />,
            auth: ["book-bl"],
        },
        {
            path: "apps/booking/calendar-view",
            element: <BookingCalendarView />,
            auth: ["book-bl"],
        },
        {
            path: "apps/booking/items",
            element: <BookingItems />,
            auth: ["book-bi"],
        },
        {
            path: "apps/booking/blacklist",
            element: <Blacklist />,
            auth: ["book-blk"],
        },
        {
            path: "apps/booking/reports/performance",
            element: <BookingPerformanceReport />,
            auth: ["book-rpt"],
        },
        {
            path: "apps/booking/reports/counter",
            element: <BookingCounterReport />,
            auth: ["book-rpt"],
        },
        {
            path: "apps/booking/reports/department",
            element: <BookingDepartmentReport />,
            auth: ["book-rpt"],
        },
    ],
};

export default BookingAppConfig;
