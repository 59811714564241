import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../grahpql/motorpool_vehicles_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const createUpdateMotorpoolVehicles = createAsyncThunk(
    "motorpoolApp/vehicle/createUpdateMotorpoolVehicles",
    async (params, { dispatch, getState }) => {
        params.dateNow = new Date();
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.createUpdateMotorpoolVehicles,
            variables: { data: JSON.stringify(params) },
        });

        const data = response.data.data.createUpdateMotorpoolVehicles

        if (data.status == 'success') {
            return dispatch(
                showMessage({
                    message: data.message,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            )
        } else {
            return dispatch(
                showMessage({
                    message: "Something went wrong please try again later.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            )
        }
    }
);

export const getMotorpoolVehicles = createAsyncThunk('motorpoolApp/vehicle/getMotorpoolVehicles', async (type) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.motorpoolVehicles,
        variables: {
            type: type,
        }
    });

    if (response.data.data.motorpoolVehicles) {
        let data = response.data.data.motorpoolVehicles;

        data = data.map(item => ({
            ...item,
            publicCmiRate: item['publicRate'] + ' / ' + item['cmiRate'],
            // items: !!item['accessoriesFromInventory'] ? JSON.parse(item['accessoriesFromInventory']) : [],
            accessories: !!item['items'] ? item['items'].map(i => i.itemName).join(', ') : ''
        }));

        return data === undefined ? null : data;
    }
});

export const getMotorpoolVehiclesBooking = createAsyncThunk('motorpoolApp/behicle/getMotorpoolVehicles', async (type) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.motorpoolVehicles,
        variables: {
            type: type,
            isBooking: 'true',
        }
    });

    if (response.data.data.motorpoolVehicles) {
        let data = response.data.data.motorpoolVehicles;

        data = data.map(item => ({
            ...item,
            publicCmiRate: item['publicRate'] + ' / ' + item['cmiRate'],
            items: !!item['accessoriesFromInventory'] ? JSON.parse(item['accessoriesFromInventory']) : [],
            accessories: !!item['accessoriesFromInventory'] ? JSON.parse(item['accessoriesFromInventory']).map(i => i.title).join(', ') : ''
        }));

        return data === undefined ? null : data;
    }
});

export const getTrackerCoordinates = createAsyncThunk('motorpoolApp/vehicle/getTrackerCoordinates', async (params) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getTrackerCoordinates,
        variables: {
            timezone: timeZone,
            trackerId: params.trackerId,
            start: params.start,
            end: params.end
        }
    });

    if (response.data.data.getTrackerCoordinates) {
        let data = response.data.data.getTrackerCoordinates;

        // data = data.map(item => ({
        //     ...item,
        //     publicCmiRate: item['publicRate'] + ' / ' + item['cmiRate'],
        //     items: !!item['accessoriesFromInventory'] ? JSON.parse(item['accessoriesFromInventory']) : [],
        //     accessories: !!item['accessoriesFromInventory'] ? JSON.parse(item['accessoriesFromInventory']).map(i => i.title).join(', ') : ''
        // }));

        return data === undefined ? null : data;
    }
});

export const removeMotorpoolVehicles = createAsyncThunk(
    'motorpoolApp/vehicle/removeMotorpoolVehicles',
    async (depIds, { dispatch, getState }) => {
        try {
            // Make API calls concurrently
            await Promise.all(depIds.map(async (id) => {
                await axios.post(CONFIG.API + "/api/", {
                    query: mutation.removeMotorpoolVehicles,
                    variables: {
                        id: id
                    }
                });
            }));

            // Dispatch success message
            dispatch(
                showMessage({
                    message: "Inventory(s) have been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );

            return depIds;
        } catch (error) {
            // Optionally dispatch an error message
            dispatch(
                showMessage({
                    message: "Failed to delete inventory(s). Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            // Rethrow the error to let the thunk middleware know that it has failed
            throw error;
        }
    }
);

const MotorpoolVehicleSlice = createSlice({
    name: "motorpoolApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getMotorpoolVehicles.fulfilled]: (state, action) => action.payload,
        [getTrackerCoordinates.fulfilled]: (state, action) => action.payload,
        [removeMotorpoolVehicles.fulfilled]: (state, action) => action.payload,
    },
});

export default MotorpoolVehicleSlice.reducer;
