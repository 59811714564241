const mutation = {
    save: `
        mutation saveRoom($data: JSONString!) {
            saveRoom (data: $data) {
                status
                res
                message
            }
        }
    `,
    delete: `
        mutation deleteRoom($id: String!) {
            deleteRoom (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query roomList($houseId: String, $amenities: String, $status: String) {
            roomList(houseId: $houseId, amenities: $amenities, status: $status) {
                id
                houseId
                roomNumber
                roomFloor
                roomStatus
                roomCapacity
                roomRate
                roomAmenities
                roomNotes
                roomPicId
                roomPicPath   
                roomCapacityStatus  
                tenants {
                    bedNumber
                }       
                dateCreated
                dateUpdated
            }
        }
    `,
	getRoomByID: `
		query roomById($roomId: String!) {
			roomById(roomId: $roomId) {
                id
                houseId
                roomNumber
                roomFloor
                roomStatus
                roomCapacity
                roomRate
                roomAmenities
                roomNotes
                roomPicId
                roomPicPath                  
				images {
					id
					roomId
					uploadId
					fileName
					fileType
					fileId
					module
					path
					url
					dateCreated
					dateUpdated
				}              
                dateCreated
                dateUpdated
			}
		}
		
	`
}

export { mutation, query };