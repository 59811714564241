const mutation = {
    save: `
        mutation saveRhInspection($data: JSONString!) {
            saveRhInspection (data: $data) {
                status
                res
                message
            }
        }
    `,
    delete: `
        mutation deleteRhInspection($id: String!) {
            deleteRhInspection (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query rhInspectionList($houseId: String, $type: String) {
            rhInspectionList(houseId: $houseId, type: $type) {
                id        
                houseName
                houseId
                roomNumber
                inspectionDate
                inspectionTime
                semester
                year
                inspector
                dateCreated
                dateUpdated
            }
        }
    `,
	getPostInspectionByID: `
		query rhInspectionById($rhInspectionId: String!) {
			rhInspectionById(rhInspectionId: $rhInspectionId) {
                id       
                type
                tenantId
                patronId
                houseId
                roomId
                houseName
                roomNumber
                inspectionDate
                inspectionTime
                semester
                year
                inspector
                inspectorId
                monthlyPostInspection
                pillow
                pillowCase
                bedsheet
                roomFloor
                roomWalls
                roomDoors
                roomWindows
                roomLights
                roomMattress
                roomDesk
                roomCloset
                roomFans
                roomTrashbin
                brFloor
                brWalls
                brSink
                brToilet
                brTrashbin
                repairs
                others {
                  id
                  type
                  inspectionId
                  description
                  value
                  dateCreated
                  dateUpdated
                }
                images {
                  id
                  inspectionId
                  uploadId
                  fileName
                  fileType
                  fileId
                  module
                  path
                  url
                  dateCreated
                  dateUpdated
                }              
                dateCreated
                dateUpdated
			}
		}
		
	`
}

export { mutation, query };