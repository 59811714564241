/*
* DateUpdated: 12/07/22
* updatedBy: ico
* Summary:  Check status > ISSUE: the color based on the inputted booking# didn't change -Daryl
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-41977-96
*/

/*
* DateUpdated: 03/20/23
* updatedBy: ico
* Summary: Redesign Consistency on admin Side
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49152-96
*/
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import format from 'date-fns/format';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { getBookingStatus } from "app/main/crm/store/statusSlice";
import PrintStatusContent from "app/main/crm/status/print/PrintStatusContent";

import { Divider } from "@mui/material";
const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.paper
}));
const TableUnstyled = styled('table')`
  

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

`;
function StatusContent() {
    const mainTheme = useSelector(selectMainTheme);

    const [refNum, setRefNum] = React.useState("");
    const routeParams = useParams();
    const dispatch = useDispatch();

    const [form, setForm] = React.useState(null);
    const [noRecord, setNoRecord] = React.useState(false);
    const [checkRecord, setCheckRecord] = React.useState(true);

    React.useEffect(() => {

        if (routeParams.referenceNum != 'check') {
            setNoRecord(false)
            getBookingRefNum(routeParams.referenceNum)
        }

    }, [routeParams]);

    const handleSearchHandle = () => {
        setNoRecord(false)
        getBookingRefNum(refNum);
    }

    const getBookingRefNum = (refNum) => {
        dispatch(getBookingStatus(refNum)).then((response) => {
            if (response) {

                var tempEvents = [];

                var approvee = [];

                var approvedById = response['approvedById'] ? JSON.parse(response['approvedById']) : [];

                response['approvee'].map(function (val, k) {

                    var ifExist = approvedById.filter(function (v) {
                        return v.id == val.id;
                    });

                    if (ifExist.length) {
                        var status = "Approved";
    
                        if(response['status'] == 'Expired'){
                            status = "Expired";
                        }
    
                        if(response['status'] == 'Rejected'){
                            status = "Rejected";
                        }
    
                        approvee.push({
                            ...val,
                            status: response['status'],
                            dateApproval: ifExist[0].date
                        })
                    } else {    
                        approvee.push({
                            ...val,
                            status: response['status'],
                        })
                    }
                });

                var tempEventsVal = {
                    ...response,
                    approvee: approvee
                };

                setForm(tempEventsVal);
            }

            if (tempEventsVal) {
                setNoRecord(false)

            } else {
                setNoRecord(true)
            }
            if (setForm(tempEventsVal)) {
                setCheckRecord(false)
            } else {
                setCheckRecord(false)
            }
        })
    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
             <div className="p-16 sm:p-24 m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg relative min-h-90p w-full">
                <Stack direction={'row'} spacing={1} className="px-16" justifyContent={'center'}>
                        <TextField
                            className="w-full max-w-sm"
                            onChange={(ev) => {
                                setRefNum(ev.target.value)
                                setNoRecord(false)
                                setCheckRecord(true)
                                setForm(false)
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: 40,
                                },
                                "& .MuiOutlinedInput-root": {
                                    px: 1, py: 0
                                }
                            }}
                            placeholder="Enter Reference Number"
                            variant="outlined"

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon color="action">search</Icon>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            className=" max-h-40 rounded-md py-9 truncate px-20"
                            variant="contained"
                            onClick={handleSearchHandle}
                        >
                            Check Status
                        </Button>

                </Stack>
                {(checkRecord &&
                    <div className='flex justify-center my-16'>
                        <img className="mt-14 max-600px:mt-20" src="assets/images/backgrounds/check-status.png" loading='lazy' />
                    </div>
                )}
                {(form &&
                    <div className='max-w-lg m-auto mt-10'>
                        <div className=" flex flex-col justify-center ">
                            <Typography component="div" className=' text-2xl font-semibold text-center my-10'>
                                {form.itemType} Booking for {form.name}
                            </Typography>
                            <div className='text-15 text-center font-semibold'>
                                <Typography  component="div"
                                className={"my-2 rounded-md font-bold tracking-wide text-14 p-1 py-10 text-white " +
                                (form.status == "Rejected" ? "bg-red-600 hover:bg-red-700" : "") +
                                (form.status == "Cancelled" ? "bg-pink-600 hover:bg-pink-700" : "") +
                                (form.status.includes("Approved") ?  "bg-blue-600 hover:bg-blue-700 " : "") +
                                (form.status  == "Approved - For Pre-Inspection" ? "bg-purple-600 hover:bg-purple-700" : "") +
                                (form.status == "Expired" ?"bg-gray-600 hover:bg-gray-700" : "") +
                                (form.status == "Pending" ? "bg-yellow-600 hover:bg-yellow-700" : "") +
                                (form.status.includes("In-Use") ? "bg-orange-600 hover:bg-orange-700" : "") +
                                (form.status == "Done" ? "bg-green-600 hover:bg-green-700" : "") +
                                (form.status == "For Return" ? "bg-black" : "")
                            }>
                                    Your request for booking is {form.status}
                                </Typography>
                            </div>
                        </div>
                        <Stack direction="row" className=" justify-between items-center">
                            <Typography className=" font-semibold text-16">Booking Reference Number:  {form.referenceNum}<span className="font-bold"></span></Typography>
                            <IconButton
                                color="inherit" className="rounded-0 py"
                                onClick={handelClickPrint}
                            >
                                <Icon color="inherit">print</Icon>
                            </IconButton>
                        </Stack>
                        <Stack direction="column" >
                            <Typography className="mt-5">Time of Booking: {form.timeOfBooking ? format(new Date(form.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}</Typography>
                            <Typography className="mt-5">Start: {form.start ? format(new Date(form.start), 'EEEE, MMMM dd, yyyy') : ""} {form.start ? format(new Date(form.start), 'hh:mm a') : ""} - {form.end ? format(new Date(form.end), 'hh:mm a') : ""}</Typography>
                            <Stack direction="row" className=" justify-between items-center mt-5 mb-5">
                                <Typography>Duration: {form.duration}</Typography>
                                
                            </Stack>
                            <TableUnstyled>
                                <tbody>
                                    <tr >
                                        <td width="50%">Name: <span className="font-bold"> {form.name}</span></td>
                                        <td width="50%">Email: <span className="font-bold">{form.email}</span></td>
                                    </tr>
                                    <tr >
                                        <td width="50%">Department: <span className="font-bold">{form.department}</span></td>
                                        <td width="50%">Contact: <span className="font-bold">{form.contact}</span></td>
                                    </tr>
                                </tbody>
                            </TableUnstyled>

                            <Typography className=" font-semibold uppercase mt-10 mb-10">Booking Details</Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <div style={{ width: 90, height: 90 }} className="overflow-hidden rounded-md">
                                    
                                    <img className="max-w-none w-full h-full object-contain"  src={form.itemPhotoUrl ? form.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={form.name} loading="lazy" />
                                    
                                </div>
                                <Stack >
                                    <Typography className=''>
                                    {form.itemCode} |  {form.itemName}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider className=" mt-16" />
                            <Typography className=" font-semibold uppercase mt-10 mb-10">purpose</Typography>
                            <Typography className=" leading-4"> {form.purpose}</Typography>
                            <Typography className=" font-semibold uppercase mt-20 ">approved by:</Typography>
                            
                        {form.approvee && (
                            <>
                                {
                                    form.approvee.map(function (approvee) {
                                        return (
                                            approvee.dateApproval ? <>
                                                <Typography className=" capitalize mt-10">Position: <b>{approvee.position}</b></Typography>
                                                <TableUnstyled className="mb-10" key={approvee.id}>
                                                    <tbody>
                                                        <tr >
                                                            <td width="50%">Name: <span className="font-bold">{approvee.name}</span></td>
                                                            <td width="50%">Date of Approval: <span className="font-bold">{approvee.dateApproval}</span></td>
                                                        </tr>
                                                        <tr >
                                                            <td width="50%">Department: <span className="font-bold">{approvee.department}</span></td>
                                                            <td width="50%">Status: <span className="font-bold"> {approvee.status}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </TableUnstyled>
                                            </> : null
                                        )
                                    })
                                }
                            </>
                        )}
                        </Stack>
                        <PrintStatusContent printRef={printRef} form={form} />
                    </div>
                )}
                {((noRecord) &&
                    <div className="w-full">
                        <div className="p-9 pb-0 w-fit max-w-lg min-w-800 m-auto mb-48">

                            <CardStyled className="flex flex-col shadow-0 mt-10 rounded-md bg-red-50" elevation={0}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                        <DialogContent classes={{ root: 'py-10 px-10' }}>
                                            <div className=" flex justify-center mx-16">
                                                <Typography className='text-xl font-semibold text-red-700'>
                                                    Reference Number {refNum} not found!
                                                </Typography>
                                            </div>
                                        </DialogContent>
                                    </Grid>
                                </Box>
                            </CardStyled>
                            <div className='flex justify-center my-16'>
                                <img className="mt-14" src="assets/images/backgrounds/check-status.png" loading='lazy' />
                            </div>
                        </div>
                    </div>
                )}
            </div>
                
    );

}

export default StatusContent;