// * Updated By: ICO
// * Summary: Change Address and Contact Number text
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-40269-96
// * DateUpdated:  11/15/2022

import clsx from 'clsx';
import { memo, React } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';

import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'unset',
  boxShadow:'unset',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: 'black'
}));
const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  maxWidth:"1366px !important",
  '& .MuiToolbar-root': {
  maxWidth:"1366px",
  }

}));

const LogoStyled = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge, & > .logo-text': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function EcommerceFooter(props) {
  const footerTheme = useSelector(selectFooterTheme);

  
  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md bottom-0', props.className)}
        color="default"
        // style={{ backgroundColor: footerTheme.palette.background.paper }}
        style={{ backgroundColor: "#FFFF", borderTop: "3px solid #00008a" }}
      >
        <ToolbarStyled className="container min-h-48 md:min-h-64 flex-col px-8 sm:px-12 lg:px-20 py-0 content-center overflow-x-auto">
        <Box sx={{ flexGrow: 1 }} className="w-full">
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
          
              <Grid item xs={12} sm={6} md={6}>
                <Item>
                <LogoStyled className="flex flex-col items-start pb-10 pt-0 max-w-full md:max-w-40-r">
                  <img className="logo-icon -ml-10 mb-20" src="assets/images/logos/cmi-ecommerce-logo-primary.svg" alt="logo" />
                  {/* <Typography>Hingi nalang siguro tayo sakanila kung ano ang ilalagay dito na description or pagawa sa mga QA? para meron din laman ang footer kahit papano sana.</Typography> */}
                </LogoStyled>
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <div className='flex justify-start md:justify-end '>
                  <Item>
                    <div className='-mt-16 smm:mt-20'>
                      <Typography className='font-semibold'>Address</Typography>
                      <Typography>1258 Ocean Side, Uliga Village Majuro, Republic Marshall Islands, 96960</Typography>
                    </div>
                    <div className='mt-16'>
                    <Typography className='font-semibold'>Contact</Typography>
                    <Typography>(+692) 625-1213</Typography>
                    <Typography>acs@cmi.edu</Typography>
                    </div>
                  </Item>
                </div>
              </Grid>
           
          </Grid>
        </Box>
         <div className='copyright w-full mt-10 text-center'>
            <hr className='my-16'></hr>
          <Typography className="FuseSettings-formGroupTitle mb-10" color="primary">
            © Medisource 2021. All rights reserved
          </Typography>
          </div>
        </ToolbarStyled>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(EcommerceFooter);
