const mutation = {
    saveHomePageCategory: `
      mutation saveHomePageCategory($data: JSONString!) {
            saveHomePageCategory (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deleteHomePageItem: `
      mutation deleteHomePageItem($id: String!) {
            deleteHomePageItem (id: $id) {
                status,
                res,
                message
            }
        }
    `,
    deleteHomePageCategory: `
      mutation deleteHomePageCategory($id: String!) {
            deleteHomePageCategory (id: $id) {
                status,
                res,
                message
            }
        }
    `,
    saveHomePageGroups: `
      mutation saveHomePageGroups($data: JSONString!) {
            saveHomePageGroups (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    saveHomePageItem: `
      mutation saveHomePageItem($data: JSONString!) {
            saveHomePageItem (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    homePageCategory: `
        query homePageCategory {
            homePageCategory {
                id
                code
                title
                isDefault
                hasItems
                groups {
                    id
                    categoryId
                    title
                    code
                    hasItems
                    totalItems
                }
                enableGroup
                dateCreated
                dateUpdated
            }
        }
    `,
    homePageCategoryTitle: `
        query homePageCategory {
            homePageCategory {
                id
                code
                title
            }
        }
    `,
    homePageItems: `
        query homePageItemsF($catId: String, $dateNow: String!) {
            homePageItemsF(catId: $catId) {
                id
                itemId
                itemName
                itemDescription
                categoryId
                groupId
                price
                dateCreated
                featuredImagePath
                photoUrl
                hasItem
                group {
                  id
                  categoryId
                  title
                  code
                  dateCreated
                  dateUpdated
                }
                itemDiscount(dateNow: $dateNow) {
                    isActive
                    price
                    discount
                    start
                    expiry
                }
                groupName
                dateCreated
                dateUpdated
            }
        }
    `,
    itemPromos: `
        query ecommerceSalesPromosItems($dateNow: String!) {
            ecommerceSalesPromosItems {
                id
                itemId
                itemName
                discountedPrice
                expiry
                price
                views
                sales
                sort
                totalSales
                discount
                photoUrl
                itemDiscount(dateNow: $dateNow) {
                    isActive
                    price
                    discount
                    start
                    expiry
                }
            }
        }
    `,
    homePageInventoryItems: `
        query homePageInventoryItems {
            homePageInventoryItems {
                id
                featuredImagePath
                name
                description
                srp
                photoUrl
            }
        } 
    `,
    homePageGroups: `
        query homePageGroups($catId: String) {
            homePageGroups(catId: $catId) {
                id
                title
                code
                categoryId
            }
        } 
    `,
    itemBasedOnBehaviors: `
        query itemBasedOnBehaviors($dateNow: String!) {
            itemBasedOnBehaviors{
                id,
                code,
                status,
                type,
                barcode,
                category,
                department,
                views,
                name,
                description,
                cost,
                srp,
                stock,
                tags,
                supplier,
                initialSupply,
                featuredImageId,
                dateCreated,
                dateUpdated,
                itemDiscount(dateNow: $dateNow) {
                    isActive
                    price
                    discount
                    start
                    expiry
                }
                images {
                    id,
                    itemId,
                    uploadId,
                    fileName,
                    fileType,
                    fileId,
                    module,
                    path,
                    url,
                    dateCreated,
                    dateUpdated
                }
                photoUrl
            }
        }
    `,
}

export { mutation, query };