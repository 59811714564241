import { authRoles } from 'app/auth';
import AuthorizationCheck from './bm-public/AuthorizationCheck';

const BusPublicAppConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'authorization/check',
            element: <AuthorizationCheck />,
        },
    ],
};

export default BusPublicAppConfig;
