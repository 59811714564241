// * Updated By: ICO
// * Summary:  ublic Pages - Ecommerce Design Concerns
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-44126-96
// * DateUpdated:  01/17/2023

import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import format from 'date-fns/format';

function FuseCountdownProduct(props) {

    const { onComplete, itemDiscount } = props;

    const [countdownStart, setCountdowndStart] = useState(false);

    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    var dateSet = itemDiscount.isActive ? itemDiscount.expiry : itemDiscount.start;

    if (countdownStart || (format(new Date(itemDiscount.start), 'yyyy-MM-dd') == format(new Date(), 'yyyy-MM-dd'))) {
        if (countdown.days == 0 && countdown.hours == 0 && countdown.minutes == 0 && countdown.seconds == 0) {
            dateSet = itemDiscount.expiry;
            itemDiscount.isActive = true;
        }
    }

    const [setDate] = useState(
        moment.isMoment(dateSet) ? dateSet : moment(dateSet)
    );

    const intervalRef = useRef();

    const complete = useCallback(() => {
        window.clearInterval(intervalRef.current);
        if (onComplete) {
            onComplete();
        }
    }, [onComplete]);

    const tick = useCallback(() => {
        const currDate = moment();
        const diff = setDate.diff(currDate, 'seconds');
        if (diff < 0) {
            complete();
            return;
        }
        const timeLeft = moment.duration(diff, 'seconds');
        setCountdown({
            days: timeLeft.asDays().toFixed(0) - 1,
            hours: timeLeft.hours(),
            minutes: timeLeft.minutes(),
            seconds: timeLeft.seconds(),
        });
        if (!countdownStart) {
            setCountdowndStart(true);
        }
    }, [complete, setDate]);

    useEffect(() => {
        intervalRef.current = setInterval(tick, 1000);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [tick]);

    if (props.dateOnly) {

        if (countdown.days == 0) {

            return (
                <div className={clsx('flex items-center', props.className)}>
                    <div className="flex flex-col items-center justify-center">
                        {countdownStart ? (
                            <Typography className=' text-10'>
                                {itemDiscount.isActive ? 'ENDS IN' : 'STARTS IN'} {countdown.hours} : {countdown.minutes} : {countdown.seconds}
                            </Typography>
                        ) : 'Loading...'}
                    </div>
                </div>
            );

        }

        return (
            <div className={clsx('flex items-center', props.className)}>
                <div className="flex flex-col items-center justify-center">
                    {countdownStart ? (
                        <Typography className=' text-10'>
                            {itemDiscount.isActive ? 'ENDS IN' : 'STARTS IN'} {countdown.days} day(s), {countdown.hours} : {countdown.minutes} : {countdown.seconds}
                        </Typography>
                    ) : 'Loading...'}
                </div>
            </div>
        );

    }
}

FuseCountdownProduct.propTypes = {
    onComplete: PropTypes.func,
};

export default memo(FuseCountdownProduct);
