import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation, query } from "app/main/ecommerce/graphql/product_page_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import format from 'date-fns/format';

const token = EcommerceJwtService.getAccessToken();

export const getItems = createAsyncThunk(
    "ecommerceApp/productPage/getItems",
    async (itemId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.itemById,
            variables: {
                itemId: itemId,
                dateNow: format(new Date(), 'yyyy-MM-dd'),
                fromPage: 'ecommerce'
            },
            publicUrl: 'ecommerce',
        });


        if (response.data.data.itemById) {
            const data = response.data.data.itemById;

            data.tags = (data.tags ? JSON.parse(data.tags) : []);

            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getRelatedItems = createAsyncThunk(
    "ecommerceApp/productPage/getRelatedItems",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getRelatedItems,
            variables: params
        });


        if (response.data.data.relatedItems) {
            const data = response.data.data.relatedItems;


            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const getItemRecommendations = createAsyncThunk(
    "ecommerceApp/productPage/getItemRecommendations",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getItemRecommendations,
            variables: {
                itemId: params.itemId,
                dateNow: format(new Date(), 'yyyy-MM-dd'),
            },
        });


        if (response.data.data.getItemRecommendations) {
            const data = response.data.data.getItemRecommendations;

            var tempItems = [];
            if (data) {
                data.map((item) => {
                    item.isCheck = item.stock > 0 ? true : false;
                    tempItems.push(item);
                })
            }

            return tempItems;
        } else {
            return null
        }
    }
);

export const getReviews = createAsyncThunk(
    "ecommerceApp/productPage/getReviews",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getReviews,
            variables: params
        });


        if (response.data.data.getReviews) {
            const data = response.data.data.getReviews;

            return data === undefined ? null : data;
        } else {
            return null
        }
    }
);

export const saveEcommerceCart = createAsyncThunk(
    "ecommerceApp/productPage/saveEcommerceCart",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveEcommerceCart,
            variables: { data: JSON.stringify(data) },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }
            },
            publicUrl: 'ecommerce'
        });

        return response.data.data.saveEcommerceCart
    }
);

export const addEcommerceReviews = createAsyncThunk(
    "ecommerceApp/productPage/addEcommerceReviews",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.addEcommerceReviews,
            variables: { data: JSON.stringify(data) },
            publicUrl: 'ecommerce',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        if (response.data.data.addEcommerceReviews) {
            dispatch(
                showMessage({
                    message: response.data.data.addEcommerceReviews["message"],
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.addEcommerceReviews["status"],
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const viewItem = createAsyncThunk(
    "ecommerceApp/productPage/viewItem",
    async (itemsId, { dispatch, getState }) => {

        await axios.post(CONFIG.API + "/api/", {
            query: mutation.viewItem,
            variables: {
                id: itemsId
            }
        });

        return itemsId;
    }
);

const productSlice = createSlice({
    name: "ecommerceApp/productPage",
    initialState: null,
    reducers: {
        resetProductPage: () => null,
        newProductPage: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {

                },
            }),
        },
    },
    extraReducers: {

    },
});

export const { newProductPage, resetProductPage } = productSlice.actions;

export default productSlice.reducer;
