import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { deleteCartItem } from 'app/main/ecommerce/store/cartSlice';
import { saveEcommerceCart } from 'app/main/ecommerce/store/productSlice';
import { useDispatch } from 'react-redux';
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/loginSlice';

const Root = styled('div')`
  td,
  th {
    border: unset;
    padding-top:10px;
    padding-bottom:10px;
  }

  th {
    background-color: #FFFF;
  }
`;
const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    },
];

export default function WishlistTable({ items, deleteClick, setDeleteClick, cartClick, setCarClick }) {
    const token = EcommerceJwtService.getDecodedAccessToken();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleClickDelete(item) {

        swal({
            title: "Do you want to delete this item?",
            text: item.name + ' - ' + item.quantity,
            icon: "warning",
            closeOnClickOutside: false,
            buttons: {
                confirm: {
                    text: "Yes",
                    className: "bg-blue-500",
                    value: true,
                    visible: true,
                    closeModal: true
                },
                cancel: {
                    text: "No",
                    className: "bg-red-500 text-white border-0 hover:bg-red-300",
                    value: false,
                    visible: true,
                    closeModal: true
                }
            }
        }).then((resp) => {
            if (resp) {
                dispatch(deleteCartItem(item.id)).then((action) => {
                    setDeleteClick(!deleteClick);
                    setCarClick(!cartClick);
                });
            }
        });
    }
    
    function handleClickCart(item, quantity, status) {

        var orderStatus = status == 'checkout' ? 'cart' : status;
        var price = item.price;
        var total = (parseFloat(price)*parseInt(quantity));

        var data = {
            orderStatus: orderStatus,
            total: total,
            customer: token?.data?.displayName,
            customerId: token?.id,
            shippingAddress: token?.address1,
            itemId: item.itemId,
            price: price,
            name: item.name,
            description: item.description,
            quantity: quantity,
            discount: item.discount,
            total: total,
            id: item.id
        };

        if (status == 'checkout') {
            var checkoutData = {
                user: {
                    ...token
                },
                items: [data],
                total: total
            }

            localStorage.setItem('ecommerce_checkout_data', JSON.stringify(checkoutData));
            navigate(`/e-commerce/checkout`);
        } else {

            dispatch(saveEcommerceCart(data)).then(function(action) {
                setCarClick(!cartClick);
                setDeleteClick(!deleteClick);
            
                dispatch(
                    showMessage({
                        message: action.payload["message"],
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: action.payload["status"],
                    })
                );
    
                if (action.payload.res == 'not_found') {
                    dispatch(logoutUser());                
                }
            });
    
        }
    }

    return (
        <Root>
            <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "unset", border: "unset", boxShadow: "unset", marginTop: "16px" }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" >

                        <TableBody>

                            {
                                items.map((item) => {
                                    return (
                                        <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                                            <TableCell className='font-bold w-0'>
                                                <IconButton
                                                    size="large"
                                                    onClick={() => {
                                                        handleClickDelete(item)
                                                    }}
                                                >
                                                    <Icon>close</Icon>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className='font-bold'>
                                                <Stack direction="row" spacing={1} alignItems="center" className='-ml-5'>
                                                         <div style={{ width: 80, height: 80, minWidth: 80 }} cols={1} className="overflow-hidden mr-10 border-1 rounded-md">

                                                            <img
                                                                className="max-w-none w-full h-full object-contain"
                                                                src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                                                loading="lazy"
                                                            />
                                                        </div>
                                                        <Typography className="font-bold min-w-128">
                                                        {item.name}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align='right' className='font-bold'>{item.stock ? item.stock + ' in Stock' : 'No stock'}</TableCell>
                                            <TableCell align='right' className='font-bold'>${item.total}</TableCell>
                                            <TableCell align='right' className='font-bold'>
                                                <Stack direction="row" spacing={1} alignItems="center" className='w-3/5 min-w-fit ml-auto'>
                                                    <Button
                                                        className="w-full bg-orange-400 text-12 py-4 px-10 truncate text-black hover:bg-orange-500 font-semibold border-1 rounded-md"
                                                        variant="contained"
                                                        onClick={() => {

                                                            if (item.quantity <= item.stock && item.stock != 0) {
                                                                handleClickCart(item, item.quantity, 'cart')
                                                            } else {
                                                                dispatch(showMessage({
                                                                    message: "Insufficient stock for this item. (" + item.name + ")",
                                                                    autoHideDuration: 5000, //ms
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "right",
                                                                    },
                                                                    variant: "error",
                                                                }))
                                                            }
                                                        }}
                                                        type="button">
                                                        ADD TO CART
                                                    </Button>
                                                    <Button
                                                        className="w-full truncate text-12 border-1 rounded-md py-3 px-5"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {

                                                            if (item.quantity <= item.stock && item.stock != 0) {
                                                                handleClickCart(item, item.quantity, 'checkout')
                                                            } else {                                                                
                                                                dispatch(showMessage({
                                                                    message: "Insufficient stock for this item. (" + item.name + ")",
                                                                    autoHideDuration: 5000, //ms
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "right",
                                                                    },
                                                                    variant: "error",
                                                                }))
                                                            }
                                                        }}
                                                        type="button">
                                                        BUY NOW
                                                    </Button>
                                                </Stack>
                                            </TableCell>

                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </Root>
    );
}