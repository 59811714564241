const mutation = {
    saveEcommerceOrders: `
      mutation saveEcommerceOrders($data: JSONString!) {
            saveEcommerceOrders (data: $data) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
}

export { mutation, query };