import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from "app/config";
import { query } from "app/graphql/crm/crm_report_graphql";

export const getCrmReport = createAsyncThunk('crmApp/report/getCrmReport',
    async (filter) => {

        console.log(filter, "filter");
        var fromDate = (filter['date'][0] ? filter['date'][0] : "");
        var toDate = (filter['date'][1] ? filter['date'][1] : new Date('9999-12-31'));

        if (filter.reportType == 'individual') {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: query.crmReportsIndividual,
                variables: {
                    limit: 999999,
                    toDate: toDate,
                    fromDate: fromDate,
                    page: 1
                },
            });

            return await response.data.data.crmReportsIndividual;

        }

        if (filter.reportType == 'department') {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: query.crmReportsDepartment,
                variables: {
                    limit: 999999,
                    toDate: toDate,
                    fromDate: fromDate,
                    page: 1
                },
            });

            return await response.data.data.crmReportsDepartment;

        }

        if (filter.reportType == 'topics') {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: query.crmReportsTopics,
                variables: {
                    limit: 999999,
                    toDate: toDate,
                    fromDate: fromDate,
                    page: 1
                },
            });

            return await response.data.data.crmReportsTopics;

        }

    }
);

const reportAdapter = createEntityAdapter({});

export const { selectAll: selectReport, selectById: selectReportById } = reportAdapter.getSelectors(
    (state) => state.crmApp.report
);

const reportSlice = createSlice({
    name: 'crmApp/report',
    initialState: reportAdapter.getInitialState({
        searchText: '',
    }),
    reducers: {
        setReportSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
    },
    extraReducers: {
        [getCrmReport.fulfilled]: (state, action) => {
            reportAdapter.setAll(state, action.payload);
        },
    },
});

export const {
    setReportSearchText
} = reportSlice.actions;

export default reportSlice.reducer;
