import { createSlice } from '@reduxjs/toolkit';
import { mutation } from 'app/main/booking/graphql/register_graphql';
import { query as QueryPatron } from "app/graphql/patrons_graphql";
import axios from "axios";
import { createAsyncThunk } from '@reduxjs/toolkit';
import CONFIG from "app/config";

export const submitRegister =
    (form) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.create,
            variables: { data: JSON.stringify(form) },
        });

        return response.data.data.createPatronFromBooking;
    };

export const checkPatron =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: QueryPatron.checkPatron,
            variables: filter
        });

        if(response.data.data.checkPatron.length){
            return false;
        }else{
            return true;
        }

    };

const initialState = {
    success: false,
    errors: [],
};

const registerSlice = createSlice({
    name: 'booking/register',
    initialState,
    reducers: {
        registerSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        registerError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
});

export const { registerSuccess, registerError } = registerSlice.actions;

export default registerSlice.reducer;