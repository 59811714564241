import { createEntityAdapter, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from 'app/config';
import { query, mutation } from "../graphql/notification_graphql";
import jwtService from 'app/services/jwtService';

export const getNotifications = createAsyncThunk('notificationPanel/data/getData', async (params, { dispatch, getState }) => {

  const { user } = getState().auth;
  const response = await axios.post(CONFIG.API + "/api/", {
    query: query.notificationList,
    variables: {
      userId: user.id
    }
  });
  const data = response.data.data.notificationList;
  return data;
});

export const addNotification = createAsyncThunk(
  "notificationPanel/data/savenotification",
  async (data) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.createNotifications,
      variables: {
        data: JSON.stringify(data)
      }
    });

    const createNotifications = await response.data.data.createNotifications;

    if (createNotifications) {

      return createNotifications;
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notificationPanel/data/updatenotification",
  async (data) => {
    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.updateNotifications,
      variables: {
        data: JSON.stringify(data)
      }
    });

    const updateNotifications = await response.data.data.updateNotifications;

    if (updateNotifications) {

      return updateNotifications;
    }
  }
);

const notificationsAdapter = createEntityAdapter({});

const initialState = notificationsAdapter.upsertMany(notificationsAdapter.getInitialState(), []);

export const { selectAll: selectNotifications, selectById: selectNotificationsById } =
  notificationsAdapter.getSelectors((state) => state.notificationPanel.data);

const dataSlice = createSlice({
  name: 'notificationPanel/data',
  initialState,
  reducers: {
    dismissItem: (state, action) => notificationsAdapter.removeOne(state, action.payload),
    dismissAll: (state, action) => notificationsAdapter.removeAll(state),
    addNotification: (state, action) => notificationsAdapter.addOne(state, action.payload),
    updateNotification: (state, action) => notificationsAdapter.updateOne(state, action.payload),
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, action) =>
      notificationsAdapter.addMany(state, action.payload),
  },
});

export const { dismissItem, dismissAll } = dataSlice.actions;

export default dataSlice.reducer;
