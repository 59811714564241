
// * Updated By: ICO
// * Summary:  Hover cursor the wishlist icon from Cart into Wishlist hover
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-40920-96
// * DateUpdated:  11/21/2022
import clsx from 'clsx';
import { useState, memo, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';

import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LoginModal from "app/main/ecommerce/modal/LoginModal";
import EcommerceSearch from 'app/main/ecommerce/header/EcommerceSearch';
import EcommerceSearchMobile from 'app/main/ecommerce/header/EcommerceSearchMobile';
import { getCategoryTitle } from "app/main/ecommerce/store/homeSlice";
import { getCartCount } from "app/main/ecommerce/store/cartSlice";
import { getWishlistCount } from "app/main/ecommerce/store/wishlistSlice";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import Stack from '@mui/material/Stack';
const LogoStyled = styled('div')(({ theme }) => ({
    '& > .logo-icon': {
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& > .badge, & > .logo-text': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));
const FuseSearchStyled = styled(EcommerceSearchMobile)(({ theme }) => ({
    '& .MuiButtonBase-root:first-of-type"': {
        color:'white !important'
    },
    
}));

const AppBarStyled = styled(AppBar)(({ theme }) => ({
    paddingRight: "0px !important",
    '& .MuiToolbar-root': {
        maxWidth: "1366px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    '& fieldset': { boxShadow: "unset !important", outline: "none !important", border: "unset !important" },

    '& .scroller::-webkit-scrollbar-thumb': {
        background: "white",
        border: "none"

    },
    '& .scroller::-webkit-scrollbar': {
        height: ".4em",
    },
    '& .scroller': {
        overflow: "auto !important",
        paddingBottom: "5px"
    }


}));

function EcommerceHeader(props) {

    useEffect(function() {
        EcommerceJwtService.setJwtLoginReload(true);
    }, []);

    const { cartClick, setCarClick } = props;
    const { searchText, setSearchText } = props;

    const toolbarTheme = useSelector(selectToolbarTheme);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = EcommerceJwtService.getDecodedAccessToken();

    const [openDialogLogin, setOpenDialogLogin] = useState(false);
    const [categories, setCategories] = useState([]);
    const [cartCount, setCartCount] = useState(0);
    const [wishlistCount, setWishlistCount] = useState(0);

    function handleDialogLoginOpen() {
        setOpenDialogLogin(true);
    }

    function handleDialogLoginClose() {
        setOpenDialogLogin(false);
    }

    useEffect(() => {
        dispatch(getCategoryTitle()).then((action) => {
            setCategories(action.payload);
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCartCount(token.id)).then((action) => {
            if (action.payload) {
                setCartCount(action.payload.length)
            }
        });
        dispatch(getWishlistCount(token.id)).then((action) => {
            console.log("action.payload", action.payload)
            if (action.payload) {
                setWishlistCount(action.payload.length)
            }
        });
    }, [cartClick]);

    function handleClickCart() {
        if (token && token.id) {
            navigate(`/e-commerce/cart`);
        } else {
            handleDialogLoginOpen();
        }
    }

    const handleHome = () => {
        navigate("/e-commerce/home");
    }

    const handleShop = () => {
        navigate("/e-commerce/shop");
    }

    function handleClickCategory(catCode) {
        navigate("/e-commerce/home");
        
        setTimeout(() => {            
            document.getElementById(catCode)?.scrollIntoView({behavior: 'smooth'});
        }, 2000);
    }

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBarStyled
                id="fuse-toolbar"
                className={clsx('flex relative z-20 shadow-md sticky top-0', props.className)}
                color="default"
                style={{ background: `linear-gradient(to bottom, #00007D 0%, #1414BC 100%)` }}
            >
                <Toolbar className="container justify-between p-0 lg:px-24 min-h-48 md:min-h-11-r">
                    <div className={clsx('flex shrink-0 max-600px:shrink items-center')}>
                        <LogoStyled className="flex items-center pb-10 pt-0 max-600px:pb-0">
                            <img className="logo-icon max-600px:w-3/4" src="assets/images/logos/cmi-ecommerce-logo.svg" alt="logo" onClick={() => handleHome()} />
                        </LogoStyled>
                    </div>
                    <Hidden smDown>
                        <div className="flex w-1/2 flex-col self-end">
                            <EcommerceSearch className="bg-white rounded-8 h-42 " variant="basic"
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                            <div className='flex'>
                                <div className='flex scroller text-white font-300 cursor-pointer mt-10 overflow-auto truncate ...'>
                                    <Typography className="mx-16 ml-0 pointer font-300 " onClick={() => handleHome()}>Home</Typography>
                                    <Typography className="mx-16 pointer font-300 " onClick={() => handleShop()}>Shop</Typography>
                                    {
                                        categories.map((cat) => {
                                            return <Typography className="mx-16 pointer font-300" key={cat.id} onClick={() => {
                                                handleClickCategory(cat.code);
                                            }}>{cat.title}</Typography>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Stack direction={'row'} spacing={1} className=" items-center px-8 md:px-0 h-full">

                        <FuseSearchStyled  className='hidden max-600px:block' />

                        <Tooltip title="Cart" placement="bottom">
                            <IconButton
                                className='w-40 h-40 font-100 text-white'
                                size="small"
                                onClick={handleClickCart}
                            >
                                <Icon>shopping_cart</Icon>
                                {cartCount ? <div className="absolute right-2 top-0 rounded-full bg-red-600 min-w-12 min-h-12 top  right px-4 m-0 text-white font-mono text-xs leading-tight text-center">{cartCount}</div> : null}
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Wishlist" placement="bottom">
                            <IconButton
                                className='w-40 h-40 font-100 text-white'
                                size="small"
                                onClick={() => {
                                    navigate(`/e-commerce/wishlist`);
                                }}
                            >
                                <Icon>favorite_border</Icon>
                                {wishlistCount ? <div className="absolute right-2 top-0 rounded-full bg-red-600 min-w-12 min-h-12 top  right px-4 m-0 text-white font-mono text-xs leading-tight text-center">{wishlistCount}</div> : null}
                            </IconButton>
                        </Tooltip>

                        <LoginModal
                            openDialogLogin={openDialogLogin}
                            handleDialogLoginOpen={handleDialogLoginOpen}
                            handleDialogLoginClose={handleDialogLoginClose}
                        />

                    </Stack>
                </Toolbar>
            </AppBarStyled>
        </ThemeProvider>
    );
}

export default memo(EcommerceHeader);
