const mutation = {
    create: `
        mutation createUser($data: JSONString!){
            createUser(data: $data) {
              res,
              message,
              status
            }
        }
    `,
    edit: `
      mutation editUser($data: JSONString!, $id: Int!){
        editUser(data: $data, id : $id) {
          res,
          message,
          status
        }
      }
    `,
    editSettings: `
      mutation editUserSettings($data: JSONString!, $id: Int!){
        editUserSettings(data: $data, id : $id) {
          res,
          message,
          status
        }
      }
    `,
    delete: `
      mutation deleteUser($id: Int!) {
        deleteUser(id: $id) {
          id
        }
      }
    `,
    resetpassword: `
      mutation resetPassword($data: JSONString!, $id: Int!){
        resetPassword(data: $data, id : $id) {
          res,
          message,
          status
        }
      }
    `,
    login: `
      mutation getToken($username: String!, $password: String!) {
        tokenAuth(username: $username, password: $password) {
          token
          version
          payload
          refreshToken
          refreshExpiresIn
        }
      }
    `,
    refresh_token: `
      mutation RefreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
          token
          payload
          refreshToken
          refreshExpiresIn
        }
      }
    `,
    user_role: {
        create: `
        mutation CreateUserRoleitem($data: JSONString) {
            createUsersRoles(data: $data) {
                status,
                message,
                res
            }
        }
      `
    },
    checkAccount: `
        mutation checkAccount($data: JSONString!){
          checkAccount(data: $data) {
              res,
              message,
              status
          }
      }
    `,
};

const query = {
    checkEcommerceEmail: `
      query checkEcommerceEmail($email: String, $userid: String, $ecommerce: Int, $crm: Int) {
          checkEcommerceEmail(email: $email, userid: $userid, ecommerce: $ecommerce, crm: $crm) {
              id
              email
              username
          }
      }
  `,
    checkUserExist: `
      query checkUserExist($value: String, $model: String, $userid: String, $ecommerce: Int, $crm: Int) {
          checkUserExist(value: $value, model: $model, userid: $userid, ecommerce: $ecommerce, crm: $crm) {
              id
              email
              username
          }
      }
  `,
    listPaginate: `
    query usersListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int, 
        $order: String!,
        $module: String!,
        $type: String
    ){
        usersListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            order: $order,
            module: $module,
            type: $type
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {  
              id
              username
              temporaryId
              email
              firstName
              lastName
              contact
              status
              groups {
                id
                name
                description
              }
              isActive
              gender
              phone
              mobilephone
              address1
              address2
              country
              city
              state
              zipcode
              hiredate
              terminationdate
              featuredImageId
              photoUrl
              userFullName
              department
            }
          }
      }
    `,
    listPaginateNoPhoto: `
    query usersListPaginate(
        $limit: Int, 
        $keyword: String,  
        $page: Int, 
        $order: String!,
        $module: String!,
        $type: String
    ){
        usersListPaginate(
            limit: $limit, 
            keyword: $keyword,  
            page: $page, 
            order: $order,
            module: $module,
            type: $type
        ){
            page
            pages
            hasNext
            hasPrev
            totalItems
            itemsPerPage
            objects {  
              id
              username
              temporaryId
              email
              firstName
              lastName
              contact
              status
              isActive
              gender
              phone
              mobilephone
              address1
              address2
              country
              city
              state
              zipcode
              hiredate
              terminationdate
              userFullName
              department
            }
          }
      }
    `,
    listPaginateNoPhotoIdEmail: `
      query usersListPaginate(
          $limit: Int, 
          $keyword: String,  
          $page: Int, 
          $order: String!,
          $module: String!,
          $type: String
      ){
          usersListPaginate(
              limit: $limit, 
              keyword: $keyword,  
              page: $page, 
              order: $order,
              module: $module,
              type: $type
          ){
              page
              pages
              hasNext
              hasPrev
              totalItems
              itemsPerPage
              objects {  
                id
                email
                userFullName
              }
            }
        }
    `,
    listAll: `
    query users {
      users {
        id
        username
        email
        firstName
        lastName
        contact
        status
        groups {
          id
          name
          description
        }
        isActive
        gender
        phone
        mobilephone
        address1
        address2
        country
        city
        state
        zipcode
        hiredate
        terminationdate
        featuredImageId
        photoUrl
        department
      }
    }
  `,

    getUserByID: `
    query users($userId: String!) {
      usersById(userId: $userId) {
        id
        firstName
        lastName
        username
        contact
        email
        isActive
        groups {
          id
          name
          description
        }
        roleitems{
          id
          roleCode
          roleName
          roleDesc
          title
          hidden
        }
        roles {
          roleitem{
            id
            title
            roleCode
            roleName
            roleDesc
          }
        }
        gender
        phone
        mobilephone
        address1
        address2
        country
        city
        state
        zipcode
        hiredate
        birthdate
        terminationdate
        featuredImageId
        status
        department
        images {
          id
          userId
          uploadId
          fileName
          fileType
          fileId
          module
          path
          url
          dateCreated
          dateUpdated
        }
      }
    },
  `,

    getUserRoles: `
    query users{
      allRoleitem{
        id
        roleCode
        roleName
        roleDesc
        title
        hidden
      }
    }
  `
}

export { mutation, query };