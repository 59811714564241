const mutation = {
    login: `
        mutation getToken($username: String!, $password: String!, $ecommerce: String) {
            tokenAuth(username: $username, password: $password, ecommerce: $ecommerce) {
                token
                version
                payload
                refreshToken
                refreshExpiresIn
            }
        }
    `,
    editSettings: `
        mutation editUserSettings($data: JSONString!, $id: Int!){
            editUserSettings(data: $data, id : $id) {
                res,
                message,
                status
            }
        }
    `,
    edit: `
        mutation editUser($data: JSONString!, $id: Int!){
            editUser(data: $data, id : $id) {
                res,
                message,
                status
            }
        }
    `,
    refresh_token: `
        mutation RefreshToken($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken) {
                token
                payload
                refreshToken
                refreshExpiresIn
            }
        }
    `,
    forgotPassword: `
        mutation forgotPassword($data: JSONString!){
            forgotPassword(data: $data) {
                res,
                message,
                status
            }
        }
    `,
    activateAccount: `
        mutation activateAccount($id: Int!){
            activateAccount(id: $id) {
                res,
                message,
                status
            }
        }
    `,
    saveResetPassword: `
        mutation saveResetPassword($data: JSONString!, $id: String!){
          saveResetPassword(data: $data, id : $id) {
            res,
            message,
            status
          }
        }
      `,
    resetpassword: `
        mutation resetPassword($data: JSONString!, $id: Int!){
        resetPassword(data: $data, id : $id) {
            res,
            message,
            status
        }
        }
    `,
};

const query = {
    checkExistingPassword: `
        query checkExistingPassword($password: String, $id: String) {
            checkExistingPassword(password: $password, id: $id) {
                id
                email
                username
            }
        }
    `,
    checkVerification: `
        query checkVerification($password: String, $id: String) {
            checkVerification(password: $password, id: $id) {
                id
                email
                username
            }
        }
    `,
}

export { mutation, query };