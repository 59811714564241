const mutation = {
    createUpdateMotorpoolVehiclesType: `
        mutation createUpdateMotorpoolVehiclesType($data: JSONString) {
           createUpdateMotorpoolVehiclesType(data: $data) {
                status,
                res,
                message
            }
        }
    `,
    removeMotorpoolVehiclesType: `
        mutation removeMotorpoolVehiclesType($id: String) {
           removeMotorpoolVehiclesType(id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    motorpoolVehiclesType: `
        query motorpoolVehiclesType{
            motorpoolVehiclesType{
                id
                name
                dateCreated
            }
        }
    `,
};

export { mutation, query };