import * as React from 'react';
import { styled } from "@mui/material/styles";

import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/booking/store";
import BookingHeader from 'app/main/booking/header/BookingHeader';
import StatusContent from 'app/main/booking/status/content/StatusContent';
import { useParams } from 'react-router-dom';

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-header": {
        minHeight: 72,
        height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            height: 136,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    "& .FusePageCarded-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

function Status(props) {

    const routeParams = useParams();

    console.log("routeParams", routeParams);

    return (
        <Root
            header={<BookingHeader />}
            content={
                <div className="p-16 sm:p-24 max-w-screen-md m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg">
                    <StatusContent />
                </div>
            }
            // innerScroll
        />
    );
}

export default withReducer("bookingApp", reducer)(Status);
