import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CONFIG from 'app/config';


const HelpFaqsButton = (props) => {

    function goTo(type) {

        if (type == 'help') {
            window.open((CONFIG.WEB + "/admin/help/CMIOneStopShop-AdministratorandU.html"), "Help", 'height=800,width=1280,resizable=yes,scrollbars=yes')
        }

        if (type == 'faqs') {
            window.open((CONFIG.WEB + "/admin/faqs/CMIOneStopShop-FAQs.html"), "FAQs", 'height=800,width=1280,resizable=yes,scrollbars=yes')
        }

    }

    return (
        <>
            <Tooltip title="Help" placement="bottom">
                <IconButton
                    onClick={() => {
                        goTo('help')
                    }}
                    size="large"
                >
                    <Icon>help</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title="FAQs" placement="bottom">
                <IconButton
                    onClick={() => {
                        goTo('faqs')
                    }}
                    size="large"
                >
                    <Icon>info</Icon>
                </IconButton>
            </Tooltip>
        </>
    );
};

export default HelpFaqsButton;
