import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";
import CONFIG from "app/config";

import { mutation } from "app/main/crm/graphql/user_graphql";

export const registerPatron = createAsyncThunk(
    "crm/user/registerPatron",
    async (barcode, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.registerPatron,
            variables: {
                barcode: barcode,
            }
        });

        return response.data.data.registerPatron;
    }
);

export const emailVerified = createAsyncThunk(
    "crm/user/emailVerified",
    async (params, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.emailVerified,
            variables: params,
            publicUrl: 'crm',
        });

        return response.data.data.emailVerified;
    }
);

const crmSlice = createSlice({
    name: "crm/user",
    initialState: null,
    extraReducers: {
        [registerPatron.fulfilled]: (state, action) => action.payload,
    },
});

export default crmSlice.reducer;
