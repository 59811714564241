import * as React from 'react';
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/residence-hall/store";
import ApproveContent from 'app/main/tenant/approve/content/ApproveContent';
import { useParams } from 'react-router-dom';

import { getTenantRefId } from "app/main/tenant/store/approveSlice";

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-header": {
        minHeight: 10,
        height: 10,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 32,
            height: 32,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    "& .FusePageCarded-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

function Approve(props) {

    const routeParams = useParams();
    const dispatch = useDispatch();

    const [form, setForm] = React.useState({
        approvedBy: []
    });

    React.useEffect(() => {

        console.log("routeParams", routeParams);

        dispatch(getTenantRefId({
            refNum: routeParams.referenceNum,
            tenantId: routeParams.tenantId
        })).then(function (response) {
            response.level = routeParams.level;
            response.approveeId = routeParams.approveeId;
            response.tenantId = routeParams.tenantId;
            response.approvedBy = (response.approvedBy ? JSON.parse(response.approvedBy) : []);
            response.bookingStatus = response.status;
            response.pageStatus = props.status;
            setForm(response);
        });

    }, [routeParams]);

    return (
        <Root
            content={
                <div className="p-16 sm:p-24 w-fit m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg">
                    <ApproveContent pageStatus={props.status} form={form} setForm={setForm} />
                </div>
            }
        />
    );
}

export default withReducer("tenantApp", reducer)(Approve);

