import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';


function CrmBooking(props) {

    const navigate = useNavigate();

    const handleClick = (event) => {
        navigate("/crm/booking");
    };

    const handleClickMotorpool = (event) => {
        navigate("/crm/motorpool/booking");
    };

    return (
        <>
            <Tooltip title="Booking" placement="top">
                <IconButton
                    className="w-40 h-40"
                    aria-controls="font-size-menu"
                    aria-haspopup="true"
                    size="large"
                    onClick={handleClick}
                >
                    <Icon>perm_contact_calendar</Icon>
                </IconButton>
            </Tooltip>
            <Tooltip title="Motorpool Booking" placement="top">
                <IconButton
                    className="w-40 h-40"
                    aria-controls="font-size-menu"
                    aria-haspopup="true"
                    size="large"
                    onClick={handleClickMotorpool}
                >
                    <Icon>commute</Icon>
                </IconButton>
            </Tooltip>
        </>
    );
}

export default CrmBooking;
