/*
* DateUpdated: 12/07/22
* updatedBy: ico
* Summary:  Check status > ISSUE: the color based on the inputted booking# didn't change -Daryl
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-41977-96
*/

/*
* DateUpdated: 03/20/23
* updatedBy: ico
* Summary: Redesign Consistency on admin Side
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49152-96
*/
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import format from 'date-fns/format';
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { Divider } from "@mui/material";
import CrmJwtService from 'app/services/crm/CrmJwtService';
import { tenantByRefNum } from 'app/main/tenant/store/tenantSlice';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const user = CrmJwtService.getDecodedAccessToken();
const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.paper
}));
const TableUnstyledNoBorder = styled('table')` 
  td,
  th {
    border: none;
    text-align: left;
    padding: 8px;
  }

`;
function TenantStatusContent() {
    const mainTheme = useSelector(selectMainTheme);

    const [refNum, setRefNum] = React.useState("");
    const routeParams = useParams();
    const dispatch = useDispatch();

    const [form, setForm] = React.useState(null);
    const [noRecord, setNoRecord] = React.useState(false);
    const [checkRecord, setCheckRecord] = React.useState(true);

    React.useEffect(() => {


        if (routeParams.referenceNum) {
            setNoRecord(false)
            getVisitRefNum({ referenceNum: routeParams.referenceNum })
        } else {
            setNoRecord(true)
        }

    }, [dispatch, routeParams]);

    const getVisitRefNum = (params) => {
        dispatch(tenantByRefNum(params)).then((action) => {
            if (action.payload) {
                setForm(action.payload);
                setNoRecord(false)
                setCheckRecord(false)
            } else {
                setNoRecord(true)
                setCheckRecord(false)
            }
        })
    }

    return (
        <div className="p-16 sm:p-24 m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg relative min-h-90p w-full">
            <div className='flex justify-center my-16'>
                {/* <Typography className=" font-bold text-2xl">My Residence Application Status</Typography> */}
            </div>

            {(form &&
                <div className='max-w-lg m-auto mt-10'>
                    <div className='flex mb-9'>
                        <Typography className=" font-bold text-lg">Residence Application for {form?.patron?.name}</Typography>
                    </div>

                    <Typography className="text-15 mb-9">
                        {form?.patron?.name} has booked {form?.roomNumber} Bed {form?.bedNumber} on {format(new Date(form?.timeOfBooking), 'MMMM dd, yyyy hh:mm a')} and Waiting for Approval
                    </Typography>

                    <TableUnstyledNoBorder className='w-full'>
                        <tbody>
                            <tr>
                                <td width="50%">Name: <span className="font-bold">{form?.patron?.name}</span></td>
                                <td width="50%">Start: <span className="font-bold">{format(new Date(form?.start), 'MMMM dd, yyyy')} to {format(new Date(form?.end), 'MMMM dd, yyyy')}</span></td>
                            </tr>
                            <tr>
                                <td width="50%">Email: <span className="font-bold">{form?.patron?.email}</span></td>
                                <td width="50%">Duration: <span className="font-bold">1 Semester</span></td>
                            </tr>
                            <tr>
                                <td width="50%">Department: <span className="font-bold">{form?.patron?.departmentName}</span></td>
                                <td width="50%">Time of Transaction: <span className="font-bold">{format(new Date(form?.timeOfBooking), 'MMMM dd, yyyy hh:mm a')}</span></td>
                            </tr>
                            <tr >
                                <td colSpan={2} width="50%">Contact: <span className="font-bold">{form?.patron?.emergencyContact}</span></td>
                            </tr>
                        </tbody>
                    </TableUnstyledNoBorder>

                    <div className='flex flex-col sm:flex-row justify-between mb-16 w-full'>
                        <div className='flex flex-col mx-16 w-full'>
                            <Typography className='text-14 font-semibold mt-10'>
                                <span className=" text-14 font-medium">Breakdown</span>
                            </Typography>
                            <Table className='w-full' stickyHeader aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Services
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Description
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Quantity
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Rate
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Total
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                {form?.roomNumber}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Full Payment
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                1
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                {form?.roomPrice}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                {form?.roomPrice}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                {
                                    form?.mealCredit == 'None' ? null :
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        Meal Credit
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">

                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        1
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        {form.mealCredit}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        {form.mealCredit}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                }
                                {
                                    form?.bookCredit == 'None' ? null :
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        Book Credit
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">

                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        1
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        {form.bookCredit}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className="p-10">
                                                    <Typography variant="h7">
                                                        {form.bookCredit}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                }
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                Total
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">

                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">

                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">

                                            </Typography>
                                        </TableCell>
                                        <TableCell className="p-10">
                                            <Typography variant="h7">
                                                {form?.totalPrice}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row justify-between mt-24 mb-16'>
                        <div className='flex flex-col mx-16 '>
                            <Typography className=' text-14 font-semibold mt-10'>
                                <span className=" text-14 font-medium">Processed by</span>
                            </Typography>
                            <Typography className=' text-14 font-semibold mt-10'>
                                <span className=" text-14 font-medium">Name:</span> {form?.userName}
                            </Typography>
                            <Typography className=' text-14 font-semibold'>
                                <span className=" text-14 font-medium">House:</span> {form?.house?.houseName}
                            </Typography>
                            <Typography className=' text-14 font-semibold'>
                                <span className=" text-14 font-medium">Position:</span> {form?.userPosition}
                            </Typography>
                        </div>
                    </div>

                    <Divider />

                    <div className=" flex flex-col justify-center ">
                        <Typography component="div" className=' text-2xl font-semibold text-center my-10'>
                            Application Reference No: {form?.referenceNum}
                        </Typography>
                        <div className='text-15 text-center font-semibold'>
                            <Typography component="div"
                                className={"my-2 rounded-md font-bold tracking-wide text-14 p-1 py-10 text-white " +
                                    (form?.status == "Rejected" ? "bg-red-600 hover:bg-red-700" : "") +
                                    (form?.status.includes("Approved") ? "bg-blue-600 hover:bg-blue-700 " : "") +
                                    (form?.status == "Pending" ? "bg-yellow-600 hover:bg-yellow-700" : "")
                                }>
                                {form?.status}
                            </Typography>
                            {form?.status == "Rejected" ? <Typography className=" font-bold text-15 mb-9">{form?.rejectReason}</Typography> : null}
                        </div>
                    </div>
                </div>
            )}

            {((noRecord) &&
                <div className="w-full">
                    <div className="p-9 pb-0 w-fit max-w-lg min-w-800 m-auto mb-48">

                        <CardStyled className="flex flex-col shadow-0 mt-10 rounded-md bg-red-50" elevation={0}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                    <DialogContent classes={{ root: 'py-10 px-10' }}>
                                        <div className=" flex justify-center mx-16">
                                            <Typography className='text-xl font-semibold text-red-700'>
                                                You are not a resident yet!
                                            </Typography>
                                        </div>
                                    </DialogContent>
                                </Grid>
                            </Box>
                        </CardStyled>
                    </div>
                </div>
            )}
        </div>

    );

}

export default TenantStatusContent;