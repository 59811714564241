import IdleTimer from "react-idle-timer";
import TimeoutModal from "./TimeoutModal";
import history from '@history';
import { Component } from "react";
import jwtService from "app/services/jwtService";
import EcommerceJwtService from 'app/main/ecommerce/services/ecommerceJwtService';
import CrmJwtService from 'app/services/crm/CrmJwtService';

class LockScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            username: jwtService.getTempDecodedAccessToken().username
        };

        this.idleTimer = null;
        this.logoutTimer = null;
    }

    onIdle = () => {
        console.log("I D L E")
        if (!window.location.pathname.includes("/e-commerce/")) {
            if (jwtService.getAccessToken()) {
                // jwtService.setSession(null);
                this.togglePopup();
                this.logoutTimer = setTimeout(() => {
                    this.handleLogout();
                }, 1000 * 1800 * 1); // 1800 30minutes
            }
        }
    };

    togglePopup = () => {
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    };

    handleLogout = () => {
        if (!window.location.pathname.includes("/e-commerce/")) {
            jwtService.setSession(null);

            history.push({
                pathname: "/login",
            });
        }
    };

    handleStayLoggedIn = () => {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = null;
        }
        this.idleTimer.reset();
        this.togglePopup();
    };

    hasActivity = () => {
        console.log("Presence Detected.")
        jwtService.isAuthTokenValid(jwtService.getAccessToken());
        EcommerceJwtService.isAuthTokenValid(EcommerceJwtService.getAccessToken());
        CrmJwtService.isAuthTokenValid(CrmJwtService.getAccessToken());

    }

    render() {
        const { showModal, username } = this.state;

        return (
            <div>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    stopOnIdle={true}
                    onIdle={this.onIdle}
                    timeout={1000 * 1800 * 1} // 1800 30minutes
                    onAction={this.hasActivity}
                    onActive={this.hasActivity}
                />

                <TimeoutModal
                    showModal={showModal}
                    togglePopup={this.togglePopup}
                    handleStayLoggedIn={this.handleStayLoggedIn}
                    username={username}
                />
            </div>
        );
    }
}
export default LockScreen;
