
import * as yup from 'yup';
import _ from '@lodash';
import { useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";

import { submitLogin } from "app/crm/store/crmLoginSlice";
import CrmJwtService from 'app/services/crm/CrmJwtService';
import RegisterModal from "app/main/crm/modal/RegisterModal";
import ForgotPasswordModal from 'app/main/crm/modal/ForgotPasswordModal';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup.string().required("You must enter a email"),
    password: yup
        .string()
        .required('Please enter your password.'),
});

const defaultValues = {
    email: '',
    password: '',
};

function LoginTab(props) {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const newBooking = queryParams.get('newBooking');
    const motorpool = queryParams.get('motorpool');
    const date = queryParams.get('date');

    const [searchParams] = useSearchParams({});

    const params = searchParams.getAll('returnTo');

    const crmlogin = useSelector(({ crm }) => crm.crmlogin);

    const { control, formState, handleSubmit, trigger, setError } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    useEffect(() => {
        crmlogin.errors.forEach((error) => {
            setError('password', {
                type: "manual",
                message: error.message,
            });
        });
    }, [crmlogin.errors, setError]);

    useEffect(() => {
        const token = CrmJwtService.getDecodedAccessToken();

        if (token && token.id) {
            setTimeout(() => {
                navigate(params.length == 0 ? (newBooking ? (motorpool ? "/crm/motorpool/booking" : "/crm/booking") + (date ? "?date=" + date : "") : "/crm/my-tickets") : params[0]);
            });
        }

    }, [crmlogin.success]);

    async function onSubmit(data) {

        await trigger(["username", "password"]);

        dispatch(submitLogin(data)).then(function () {
        });
    }

    const [showPassword, setShowPassword] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);

    function handleDialogOpen() {
        setOpenDialog(true);
    }

    function handleDialogClose() {
        setOpenDialog(false);
    }

    const [openDialogForgotPassword, setOpenDialogForgotPassword] = useState(false);

    function handleDialogForgotPasswordOpen() {
        setOpenDialogForgotPassword(true);
    }

    function handleDialogForgotPasswordClose() {
        setOpenDialogForgotPassword(false);
    }

    return (
        <div className="w-full">
            <form
                className="flex flex-col justify-center w-full px-10"
                onSubmit={handleSubmit(onSubmit)}
            >
                <label className="mt-14 text-15">Email</label>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-16"
                            type="text"
                            error={!!errors.email}
                            helperText={errors?.email?.message}


                            variant="outlined"
                        />
                    )}
                />
                <label className="mt-5 text-15">Password</label>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            className="mb-16"

                            type="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            variant="outlined"
                            InputProps={{
                                className: "pr-2",
                                type: showPassword ? "text" : "password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            size="large"
                                        >
                                            <Icon className="text-20" color="action">
                                                {showPassword ? "visibility" : "visibility_off"}
                                            </Icon>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                        />
                    )}
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="w-full mx-auto mt-16 rounded-md h-48"
                    aria-label="LOG IN"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    value="legacy"
                >
                    Login
                </Button>
                <Typography className="mt-24 text-14 font-500">
                    <ForgotPasswordModal
                        openDialogForgotPassword={openDialogForgotPassword}
                        handleDialogForgotPasswordOpen={handleDialogForgotPasswordOpen}
                        handleDialogForgotPasswordClose={handleDialogForgotPasswordClose}
                    />
                    <br /><br />
                    Don't have an account?
                    <br />
                    <RegisterModal
                        openDialog={openDialog}
                        handleDialogOpen={handleDialogOpen}
                        handleDialogClose={handleDialogClose}
                    />
                </Typography>
            </form>
        </div>
    );
}

export default LoginTab;
