const locale = {
  DASHBOARDS: 'Dashboards',
  INVENTORY: 'Inventory',
  STORE: 'Store',
  BOOKING: 'Booking',
  SETTINGS: 'Settings',
  PATRONS: 'Patrons',
  CARDS: 'Cards',
  USER_ACCOUNTS: 'User Accounts',
  FOOD_VENDORS: 'Food Vendors',
  RESIDENCE_HALL: 'Residence Hall',
  PATROL_MANAGEMENT: 'Patrol Management',
  BUS_MANAGEMENT: 'Bus Management',
  BUS_MAINTENANCE: 'Bus Maintenance',
};

export default locale;
