/**
 * Authorization Roles
 */
const authCrmRoles = {
  admin: ['admin'],
  staff: ['admin', 'staff'],
  user: ['admin', 'staff', 'user'],
  onlyGuest: [],
  ecommerce: ['ecommerce'],
  crm: ['crm'],
};

export default authCrmRoles;
