import { createSlice } from '@reduxjs/toolkit';
import { query, mutation } from 'app/main/tenant/graphql/invoice_graphql';
import axios from "axios";
import CONFIG from "app/config";
import CrmJwtService from 'app/services/crm/CrmJwtService';
const token = CrmJwtService.getAccessToken();

export const getTenantInvoice =
    (params) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTenantInvoice,
            variables: params,
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        return response.data.data.getTenantInvoice;
    };

const initialState = {
    success: false,
    errors: [],
};

const invoiceSlice = createSlice({
    name: 'booking/invoice',
    initialState,
    reducers: {
        invoiceSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        invoiceError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
});

export const { invoiceSuccess, invoiceError } = invoiceSlice.actions;

export default invoiceSlice.reducer;