import * as React from 'react';
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

import FusePageCarded from "@fuse/core/FusePageCarded";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/booking/store";
import BookingApproveHeader from 'app/main/booking/header/BookingApproveHeader';
import ApproveContent from 'app/main/booking/approve/content/ApproveContent';
import { useParams, useLocation } from 'react-router-dom';

import { getBookingRefId, getMotorpoolBookingRefId } from "app/main/booking/store/approveSlice";

const Root = styled(FusePageCarded)(({ theme }) => ({
    "& .FusePageCarded-header": {
        minHeight: 72,
        height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            height: 136,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    "& .FusePageCarded-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

function Approve(props) {

    const routeParams = useParams();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const motorpool = queryParams.get('motorpool');

    const [form, setForm] = React.useState({
        approvedBy: []
    });

    React.useEffect(() => {

        console.log("routeParams", routeParams);

        dispatch((motorpool ? getMotorpoolBookingRefId : getBookingRefId)({
            refNum: routeParams.referenceNum,
            bookingId: routeParams.bookingId
        })).then(function (response) {
            response.level = routeParams.level;
            response.approveeId = routeParams.approveeId;
            response.bookingId = routeParams.bookingId;
            response.approvedBy = (response.approvedBy ? JSON.parse(response.approvedBy) : []);
            response.bookingStatus = response.status;
            response.status = props.status;
            setForm(response);
        });

    }, [routeParams]);

    return (
        <Root
            // header={<BookingApproveHeader form={form} status={props.status}/>}
            content={
                <div className="p-16 sm:p-24 max-w-screen-md m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg">
                    <ApproveContent status={props.status} form={form} setForm={setForm} motorpool={motorpool} />
                </div>
            }
        // innerScroll
        />
    );
}

export default withReducer("bookingApp", reducer)(Approve);

