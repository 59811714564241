import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../grahpql/vehicles_type_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const createUpdateMotorpoolVehiclesType = createAsyncThunk(
    "settingsApp/motorpool/vehicles/createUpdateMotorpoolVehiclesType",
    async (params, { dispatch, getState }) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.createUpdateMotorpoolVehiclesType,
            variables: { data: JSON.stringify(params) },
        });

        const data = response.data.data.createUpdateMotorpoolVehiclesType

        if (data.status == 'success') {
            return dispatch(
                showMessage({
                    message: data.message,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            )
        } else {
            return dispatch(
                showMessage({
                    message: "Something went wrong please try again later.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            )
        }
    }
);

export const motorpoolVehiclesType = createAsyncThunk('settingsApp/motorpool/vehicles/motorpoolVehiclesType', async (keyword) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.motorpoolVehiclesType,
        variables: {
            keyword: keyword,
        }
    });

    if (response.data.data.motorpoolVehiclesType) {
        const data = response.data.data.motorpoolVehiclesType;

        console.log("motorpoolVehiclesType", data)

        return data === undefined ? null : data;
    }
});

export const removeMotorpoolVehiclesType = createAsyncThunk(
    'settingsApp/motorpool/inventory/removeMotorpoolVehiclesType',
    async (depIds, { dispatch, getState }) => {
        try {
            // Make API calls concurrently
            await Promise.all(depIds.map(async (id) => {
                await axios.post(CONFIG.API + "/api/", {
                    query: mutation.removeMotorpoolVehiclesType,
                    variables: {
                        id: id
                    }
                });
            }));

            // Dispatch success message
            dispatch(
                showMessage({
                    message: "Type(s) have been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );

            return depIds;
        } catch (error) {
            // Handle errors
            console.error("Error removing motorpool record:", error);
            // Optionally dispatch an error message
            dispatch(
                showMessage({
                    message: "Failed to delete record(s). Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            // Rethrow the error to let the thunk middleware know that it has failed
            throw error;
        }
    }
);

const VehiclesTypeSlice = createSlice({
    name: "settingsApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [motorpoolVehiclesType.fulfilled]: (state, action) => action.payload,
        [removeMotorpoolVehiclesType.fulfilled]: (state, action) => action.payload,
    },
});

export default VehiclesTypeSlice.reducer;
