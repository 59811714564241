import * as React from "react";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CrmBookingListTable from './CrmMotorpoolBookingListTable';

import reducer from '../../../store';
import withReducer from 'app/store/withReducer';
import { showMessage } from "app/store/fuse/messageSlice";
import { getEventsMotorpoolBookingCrm } from 'app/main/crm/store/bookingEventsSlice';
import { resendBooking, cancelBooking } from 'app/main/crm/store/motorpoolBookingSlice';
import { closeDialog, openDialog } from "app/store/fuse/dialogSlice";

import Button from "@mui/material/Button";
import FuseLoading from '@fuse/core/FuseLoading';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from '@mui/material/styles';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';

const Root = styled('div')(({ theme }) => ({

    '& .rmdp-wrapper.rmdp-shadow ': {
        width: "unset"
    },
    '& .rmdp-top-class': {
        justifyContent: "center"
    },
    '& .rmdp-shadow ': {
        boxShadow: "unset !important",
        background: "transparent"
    },
    '& .rmdp-header-values ': {
        margin: "0 1rem",
        background: theme.palette.primary.main,
        width: "100%",
        color: theme.palette.secondary.contrastText,


    },
    '& .rmdp-week-day': {
        background: theme.palette.primary.dark,
        color: theme.palette.secondary.contrastText,

    },
    '& .rmdp-day': {
        fontWeight: "500"

    },
    '& .rmdp-day-picker': {
        margin: "0 1rem",
        marginTop: "-0.15rem",
        width: "100%",
        padding: "0 !important"

    },
    '& .rmdp-day-picker div:first-of-type:not(.rmdp-week-day):not(.rmdp-week):not(.rmdp-day)': {
        marginRight: "2rem !important",
        boxShadow: "0 1.5px 5px rgb(0 0 0 / 0.23)",
    },
    '& .rmdp-day-picker div:nth-of-type(2):not(.rmdp-week-day):not(.rmdp-week):not(.rmdp-day)': {
        boxShadow: "0 1.5px 5px rgb(0 0 0 / 0.23)",
    },
    '& .rmdp-arrow-container:hover': {
        background: "none",
        boxShadow: "none"

    },
    '& .rmdp-arrow-container.rmdp-left .rmdp-arrow': {
        marginRight: "12rem",
        marginTop: "12rem",
        padding: ".5rem",
        border: `solid ${theme.palette.primary.main}`,
        borderWidth: "0 2px 2px 0",

    },
    '& .rmdp-arrow-container.rmdp-right .rmdp-arrow': {
        marginLeft: "11rem",
        marginTop: "12rem",
        padding: ".5rem",
        border: `solid ${theme.palette.primary.main}`,
        borderWidth: "0 2px 2px 0",

    },

    '& .TooltipStyled': {
        position: "absolute",
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[2],
        borderRadius: "5px",
        padding: "3px 5px",
        fontSize: "14px",
        zIndex: 9999
    },

    '& a': {
        color: `${theme.palette.text.primary}!important`,
        textDecoration: 'none!important',
    },
    '&  .fc-media-screen': {
        minHeight: '100%',
    },
    '& .fc-scrollgrid, & .fc-theme-standard td, & .fc-theme-standard th': {
        borderColor: `${theme.palette.divider}!important`,
    },
    '&  .fc-scrollgrid-section > td': {
        border: 0,
    },
    '& .fc-daygrid-day': {
        '&:last-child': {
            borderRight: 0,
        },
    },
    '& .fc-col-header-cell': {
        borderWidth: '0 0 1px 0',
        padding: '16px 0',
        '& .fc-col-header-cell-cushion': {
            color: theme.palette.text.secondary,
            fontWeight: 500,
        },
    },
    '& .fc-view ': {
        borderRadius: 20,
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        '& > .fc-scrollgrid': {
            border: 0,
        },
    },
    '& .fc-daygrid-day-number': {
        color: theme.palette.text.secondary,
        fontWeight: 500,
    },
    '& .fc-event': {
        border: 0,
        padding: '6px 12px',
        margin: '4px 2px',
    },
}));

function CrmMotorpoolBookingListContent(props) {
    const dispatch = useDispatch();
    const [listEvents, setListEvents] = useState([]);
    const [bookings, setBookings] = useState([]);
    const searchText = useSelector(({ crmApps }) => crmApps.bookingEvents.searchText);

    const [order, setOrder] = useState({
        direction: "asc",
        id: null,
    });

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [page, setPage] = useState(0);

    const user = useSelector(({ crm }) => crm.crmuser);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(getEventsMotorpoolBookingCrm(user.patron_id)).then((action) => {
            if (action.payload) {

                var tempEvents = [];

                action.payload.map(function (value) {
                    var approvee = [];

                    var approvedById = value['approvedById'] ? JSON.parse(value['approvedById']) : [];
                    if (value['approvee'] && value['approvee'].length) {
                        value['approvee'].map(function (val) {

                            var ifExist = approvedById.filter(function (v) {
                                return v.id == val.id;
                            });

                            if (ifExist.length) {
                                var status = "Approved";

                                if (value['status'] == 'Expired') {
                                    status = "Expired";
                                }

                                if (value['status'] == 'Rejected') {
                                    status = "Rejected";
                                }

                                approvee.push({
                                    ...val,
                                    status: status,
                                    dateApproval: ifExist[0].date
                                })
                            } else {
                                approvee.push({
                                    ...val,
                                    status: value['status'],
                                })
                            }
                        });
                    }

                    var tempEventsVal = {
                        ...value,
                        approvee: approvee
                    };

                    tempEvents.push(tempEventsVal);
                });

                setLoading(false);
                setBookings(tempEvents);
                setListEvents(tempEvents);
            }
        });

    }, [dispatch]);

    const handleClickResend = (data) => {
        dispatch(
            openDialog({
                children: (
                    <React.Fragment>
                        <DialogTitle id="alert-dialog-title">
                            Warning!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to resend this booking?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => dispatch(closeDialog())} color="primary">
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatch(resendBooking(data)).then((action) => {
                                        if (action.payload) {
                                            dispatch(
                                                showMessage({
                                                    message: "Booking email has been successfully resend.",
                                                    autoHideDuration: 5000, //ms
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "right",
                                                    },
                                                    variant: "success",
                                                })
                                            );
                                            dispatch(closeDialog());
                                        }
                                    });
                                }}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                ),
            })
        );
    }

    const handleClickCancel = (data) => {
        dispatch(
            openDialog({
                children: (
                    <React.Fragment>
                        <DialogTitle id="alert-dialog-title">
                            Warning!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to cancel this booking?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => dispatch(closeDialog())} color="primary">
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatch(cancelBooking(data)).then((action) => {
                                        if (action.payload) {
                                            dispatch(
                                                showMessage({
                                                    message: "Booking has been successfully cancelled.",
                                                    autoHideDuration: 5000, //ms
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "right",
                                                    },
                                                    variant: "success",
                                                })
                                            );
                                            dispatch(getEventsMotorpoolBookingCrm(user.patron_id)).then((action) => {
                                                if (action.payload) {

                                                    var tempEvents = [];

                                                    action.payload.map(function (value) {
                                                        var approvee = [];

                                                        var approvedById = value['approvedById'] ? JSON.parse(value['approvedById']) : [];
                                                        if (value['approvee'] && value['approvee'].length) {
                                                            value['approvee'].map(function (val) {

                                                                var ifExist = approvedById.filter(function (v) {
                                                                    return v.id == val.id;
                                                                });

                                                                if (ifExist.length) {
                                                                    var status = "Approved";

                                                                    if (value['status'] == 'Expired') {
                                                                        status = "Expired";
                                                                    }

                                                                    if (value['status'] == 'Rejected') {
                                                                        status = "Rejected";
                                                                    }

                                                                    approvee.push({
                                                                        ...val,
                                                                        status: status,
                                                                        dateApproval: ifExist[0].date
                                                                    })
                                                                } else {
                                                                    approvee.push({
                                                                        ...val,
                                                                        status: value['status'],
                                                                    })
                                                                }
                                                            });
                                                        }

                                                        var tempEventsVal = {
                                                            ...value,
                                                            approvee: approvee
                                                        };

                                                        tempEvents.push(tempEventsVal);
                                                    });

                                                    setLoading(false);
                                                    setBookings(tempEvents);
                                                    setListEvents(tempEvents);
                                                }
                                            });
                                            dispatch(closeDialog());
                                        }
                                    });
                                }}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                ),
            })
        );
    }

    useEffect(() => {
        if (searchText.length !== 0) {
            setPage(0);
            setListEvents(
                _.filter(bookings, (item) => {
                    return (
                        item.referenceNum.toLowerCase().includes(searchText.toLowerCase())
                        || item.name.toLowerCase().includes(searchText.toLowerCase())
                    )
                })
            );
        } else {
            setListEvents(bookings);
        }
    }, [searchText]);

    const [activeTab, setActiveTab] = React.useState(0);

    const handleClickFilter = (status) => {
        setPage(0);
        if (status == 'All') {
            setListEvents(bookings);
        } else {
            setListEvents(
                _.filter(bookings, (item) => {

                    if (status == 'In-Use') {
                        return item.status.includes(status);
                    }

                    if (status == 'Approved') {
                        return item.status.includes(status);
                    }


                    return item.status === status;
                })
            );
        }
    }

    if (loading) {
        return <FuseLoading />;
    }

    return (
        <Root className="flex flex-col flex-auto relative">
            <div className="p-0 pt-5 container">
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Tabs
                        className='min-h-0 w-full'
                        variant="scrollable"
                        scrollButtons="auto"
                        value={activeTab}
                        TabIndicatorProps={{
                            style: { display: 'none' }
                        }}
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs"
                        sx={{
                            '& .MuiTabs-scroller': { height: 'fit-content' },
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: { sm: 0.3 } },
                            },
                        }}>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 0 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("All");
                                setActiveTab(0);
                            }}
                        >
                            All
                        </Button>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 1 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("Rejected");
                                setActiveTab(1);
                            }}
                        >
                            Rejected
                        </Button>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 2 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("Approved");
                                setActiveTab(2);
                            }}
                        >
                            Approved
                        </Button>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 3 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("Pending");
                                setActiveTab(3);
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            className="mx-3 rounded truncate"
                            variant={activeTab == 4 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("In-Use");
                                setActiveTab(4);
                            }}
                        >
                            In-Use
                        </Button>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 5 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("Done");
                                setActiveTab(5);
                            }}
                        >
                            Done
                        </Button>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 6 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("Expired");
                                setActiveTab(6);
                            }}
                        >
                            Expired
                        </Button>
                        <Button
                            className="mx-3 rounded"
                            variant={activeTab == 7 ? "contained" : "outlined"}
                            onClick={() => {
                                handleClickFilter("Cancelled");
                                setActiveTab(7);
                            }}
                        >
                            Cancelled
                        </Button>
                    </Tabs>
                </Stack>
                <CrmBookingListTable
                    items={listEvents}
                    handleClickResend={handleClickResend}
                    handleClickCancel={handleClickCancel}
                    order={order}
                    setOrder={setOrder}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </div>
        </Root>
    );
}

export default withReducer('crmApps', reducer)(CrmMotorpoolBookingListContent);
