import {
	createSlice,
	createAsyncThunk,
	createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import {
	query,
	mutation,
} from "app/main/apps/residence-hall/graphql/inspection_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import { format } from "date-fns";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

export const getPostInspection = createAsyncThunk(
	"residenceHallApp/inspection/getPostInspection",
	async (params) => {
		const response = await axios.post(CONFIG.API + "/api/", {
			query: query.list,
            variables: params
		});
		var data = response.data.data.rhInspectionList;

		return data;
	}
);

export const getPostInspectionById = createAsyncThunk(
    "residenceHallApp/inspection/getPostInspectionById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getPostInspectionByID,
            variables: {
                rhInspectionId: params.rhInspectionId,
            },
        });

        if (response.data.data.rhInspectionById) {
            var data = response.data.data.rhInspectionById;
            
            data.repairs = (data.repairs ? JSON.parse(data.repairs) : []);

            if (data.inspectionTime) {

                var tempTime = data.inspectionTime.split(":");
                data.inspectionTime = new Date();

                data.inspectionTime.setHours(tempTime[0]);
                data.inspectionTime.setMinutes(tempTime[1]);
                data.inspectionTime.setSeconds(tempTime[2]);

            }

            data.pillow = (data.pillow == 'True' ? true : false);
            data.pillowCase = (data.pillowCase == 'True' ? true : false);
            data.bedsheet = (data.bedsheet == 'True' ? true : false);

            return data === undefined ? null : data;
        }
    }
);

export const removePostInspection = createAsyncThunk(
	"residenceHallApp/inspection/removePostInspection",
	async (postInspectionIds, { dispatch, getState }) => {

		await postInspectionIds.map(async (id) => {
			await axios.post(CONFIG.API + "/api/", {
				query: mutation.delete,
				variables: {
					id: id,
				},
			});
		});

		dispatch(
			showMessage({
				message: "PostInspection(s) has been successfully deleted.",
				autoHideDuration: 5000, //ms
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right",
				},
				variant: "success",
			})
		);

		return postInspectionIds;
	}
);

const uploadImages = (uploadData, postInspectionData, image) => {

    return new Promise(async (resolve, reject) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutationUpload.create,
            variables: {
                data: JSON.stringify(uploadData),
            },
        });

        if (response.data.data.createUpload.status == "success") {
            var upload = response.data.data.createUpload.upload;

            if (postInspectionData.inspectionPicId == image.image.id) {
                postInspectionData.inspectionPicPath = upload.path;
            }

            postInspectionData.images[image.index].upload_id = upload.id;
            postInspectionData.images[image.index].file_name = upload.fileName;
            postInspectionData.images[image.index].file_id = upload.fileId;
            postInspectionData.images[image.index].file_type = upload.fileType;
            postInspectionData.images[image.index].path = upload.path;
            postInspectionData.images[image.index].module = upload.module;
            postInspectionData.images[image.index].url = "";

            resolve(postInspectionData);

        }

    });

}

const saveImages = (postInspectionData) => {

    return new Promise(async (resolve, reject) => {

        if (postInspectionData.images.length) {

            var newImages = [];

            postInspectionData.images.map(async (image, index) => {

                if (image.status === "new") {
                    newImages.push({
                        index: index,
                        image: image
                    })
                } else {
                    if (postInspectionData.inspectionPicId == image.fileId) {
                        postInspectionData.inspectionPicPath = image.path;
                    }
                }

            });

            if (newImages.length) {

                var tempCount = 0;

                newImages.map(async (image, index) => {

                    await uploadSvc({
                        file: image.image.file,
                        destination_file_name: "residence-hall/postInspection/postInspection-" + randomString() + "-" + (image.image.file ? image.image.file.name : image.image.fileName)
                    }).then(async (result) => {

                        if (result.status === 200 && result.data.status === "success") {

                            var uploadData = result.data.uploadData
                            uploadData['module'] = 'residence-hall-postInspection';
                            uploadData['file_id'] = image.image.id;

                            await uploadImages(uploadData, postInspectionData, image).then(async (postInspectionData) => {

                                if (postInspectionData) {
                                    tempCount++;
                                    if (newImages.length == tempCount) {
                                        resolve(postInspectionData);
                                    }
                                }

                            });
                        }


                    });

                });

            } else {
                resolve(postInspectionData)
            }


        } else {

            resolve(postInspectionData);

        }

    });

}

export const savePostInspection = createAsyncThunk(
    "residenceHallApp/inspection/savePostInspection",
    async (postInspectionData, { dispatch, getState }) => {

        postInspectionData.repairs = (postInspectionData.repairs ? JSON.stringify(postInspectionData.repairs) : '');
        postInspectionData.inspectionDate = (postInspectionData.inspectionDate ? format(new Date(postInspectionData.inspectionDate), 'yyyy-MM-dd') : null);
        postInspectionData.inspectionTime = (postInspectionData.inspectionTime ? format(new Date(postInspectionData.inspectionTime), 'HH:mm') : null);

        console.log('postInspectionData', postInspectionData);

        return await saveImages(postInspectionData).then(async (postInspectionData) => {

            const response = await axios.post(CONFIG.API + "/api/", {
                query: mutation.save,
                variables: {
                    data: JSON.stringify(postInspectionData),
                    id: postInspectionData?.id,
                },
            });

            dispatch(
                showMessage({
                    message: response.data.data.saveRhInspection.message,
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.saveRhInspection.status
                })
            );
            return response.data.data.saveRhInspection;

        });

    });

const postInspectionAdapter = createEntityAdapter({});

export const {
	selectAll: selectPostInspection,
	selectById: selectPostInspectionById,
} = postInspectionAdapter.getSelectors((state) => state.residenceHallApp.postInspection);

const postInspectionSlice = createSlice({
	name: "residenceHallApp/inspection",
	initialState: postInspectionAdapter.getInitialState({
		searchText: "",
		showModal: false,
	}),
	reducers: {
		setPostInspectionSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		// [getPostInspection.fulfilled]: postInspectionAdapter.setAll,
		// [removePostInspection.fulfilled]: (state, action) =>
		// 	postInspectionAdapter.removeMany(state, action.payload),
		[savePostInspection.fulfilled]: (state, action) => action.payload,
	},
});

export const { setPostInspectionSearchText } = postInspectionSlice.actions;

export default postInspectionSlice.reducer;
