// * Updated By: Jherome
// * Summary:  fix layout issue
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-41130-96
// * DateUpdated:  11/23/2022

/*
* DateUpdated: 12/07/22
* updatedBy: ico
* Summary: alignment 1 column
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-41592-96
*/

/*
* DateUpdated: 01/12/23
* updatedBy: ico
* Summary: Redesign
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-44385-96
*/
import * as React from "react";
import { useState } from 'react';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import format from 'date-fns/format';
import CardContent from "@mui/material/CardContent";
import Stack from '@mui/material/Stack';
import PrintIcon from '@mui/icons-material/Print';
import reducer from "../../../store";
import withReducer from "app/store/withReducer";
import Button from "@mui/material/Button";
import PrintInformation from "app/main/apps/booking/print/PrintInformation";
import PrintInformationInvoice from "app/main/apps/booking/print/PrintInformationInvoice";
import { styled } from '@mui/material/styles';
import { Divider } from "@mui/material";
const TableUnstyled = styled('table')`
  

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

`;
function InformationModal({ item }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleOpenDialog() {
        setOpenDialog(true);
        setLoading(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    const printRefInvoice = useRef();

    const handelClickPrintInvoice = useReactToPrint({
        content: () => printRefInvoice.current,
    });

    return (
        <span>
            <Tooltip title={"Information"} arrow>
                <IconButton
                    onClick={handleOpenDialog}
                    size="large"
                >
                    <Icon>info</Icon>
                </IconButton>
            </Tooltip>

            <Dialog
                open={openDialog}
                className="z-9999"
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                fullWidth={true}
                sx={{ '& .MuiPaper-root': { maxWidth: "700px" } }}
            >
                <DialogContent classes={{ root: 'p-16' }}>
                    <div className="container">
                        <Stack direction="row" className=" justify-between items-center">
                            <Typography className=" font-semibold text-16">Booking Reference Number: <span className="font-bold">{item.referenceNum}</span></Typography>
                            <IconButton
                                color="primary" className="rounded-0 py"
                                onClick={handelClickPrint}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Stack>
                        <Stack direction="column" >
                            <Typography className="mt-5">Time of Booking: <span className="">{item.timeOfBooking ? format(new Date(item.timeOfBooking), 'MMMM dd, yyyy hh:mm a') : ""}</span></Typography>
                            <Typography className="mt-5">Start: <span className=""> {item.start ? format(new Date(item.start), 'EEEE, MMMM dd, yyyy') : ""} {item.start ? format(new Date(item.start), 'hh:mm a') : ""} - {item.end ? format(new Date(item.end), 'hh:mm a') : ""}</span></Typography>
                            <Stack direction="row" className=" justify-between items-center mt-5 mb-5">
                                <Typography>Duration: <span className="">{item.duration}</span></Typography>
                                {(item.total != '0' && (item.status.includes("In-Use") || item.status.includes("Done"))) && (
                                    <>
                                        <Button variant="text" saze="small" className=" rounded-md capitalize py-0 px-5" onClick={handelClickPrintInvoice}>
                                            View Invoice | {item.invoice?.invoiceStatus}
                                        </Button>
                                    </>
                                )}
                            </Stack>
                            <TableUnstyled>
                                <tbody>
                                    <tr >
                                        <td width="50%">Name: <span className="font-bold">{item.name}</span></td>
                                        <td width="50%">Email: <span className="font-bold">{item.email}</span></td>
                                    </tr>
                                    <tr >
                                        <td width="50%">Department: <span className="font-bold">{item.department}</span></td>
                                        <td width="50%">Contact: <span className="font-bold">{item.contact}</span></td>
                                    </tr>
                                </tbody>
                            </TableUnstyled>

                            <Typography className=" font-semibold uppercase mt-10 mb-10">Booking Details</Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <ImageList sx={{ width: 80, height: 80 }} cols={1} className="overflow-hidden rounded-md">
                                    <ImageListItem>
                                        <img className="max-w-none w-auto h-full" src={item.itemPhotoUrl ? item.itemPhotoUrl : 'assets/images/ecommerce/product-image-placeholder.png'} alt={item.name} loading="lazy" />
                                    </ImageListItem>
                                </ImageList>
                                <Stack >
                                    <Typography className=''>
                                        {item.itemCode} | {item.itemName}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider className=" mt-16" />
                            <Typography className=" font-semibold uppercase mt-10 mb-10">purpose</Typography>
                            <Typography className=" leading-4">{item.purpose}</Typography>

                            <Typography className=" font-semibold uppercase mt-20 ">approved by:</Typography>
                            {item.approvee && (
                                <>
                                    {
                                        item.approvee.map(function (approvee) {
                                            return (
                                                approvee.dateApproval ? <>
                                                    <Typography className=" capitalize mt-10">Position: <b>{approvee.position}</b></Typography>
                                                    <TableUnstyled className="mb-10" key={approvee.id}>
                                                        <tbody>
                                                            <tr >
                                                                <td width="50%">Name: <span className="font-bold">{approvee.name}</span></td>
                                                                <td width="50%">Date of Approval: <span className="font-bold">{approvee.dateApproval}</span></td>
                                                            </tr>
                                                            <tr >
                                                                <td width="50%">Department: <span className="font-bold">{approvee.department}</span></td>
                                                                <td width="50%">Status: <span className="font-bold"> {approvee.status}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </TableUnstyled>
                                                </> : null
                                            )
                                        })
                                    }
                                </>
                            )}
                        </Stack>
                        <Box className="mb-10 mt-10" sx={{ display: 'flex flex-col', justifyContent: 'center' }}>
                            <Box className={'py-10 ' +
                                (item.status == "Rejected" ? "bg-red-600 hover:bg-red-700" : "") +
                                (item.status == "Cancelled" ? "bg-pink-600 hover:bg-pink-700" : "") +
                                (item.status.includes("Approved") ? "bg-blue-600 hover:bg-blue-700" : "") +
                                (item.status == "Approved - For Pre-Inspection" ? " bg-purple-600 hover:bg-purple-700" : "") +
                                (item.status == "Expired" ? "bg-gray-600 hover:bg-gray-700" : "") +
                                (item.status == "Pending" ? "bg-yellow-600 hover:bg-yellow-700" : "") +
                                (item.status.includes("In-Use") ? "bg-orange-600 hover:bg-orange-700" : "") +
                                (item.status == "Done" ? "bg-green-600 hover:bg-green-700" : "")
                            } sx={{
                                m: 1,
                                width: '100%',
                                height: '100%',
                                margin: 'unset',
                                borderRadius: 1,
                                py: 2
                            }}>
                                <CardContent className='p-0 pb-0' sx={{ pb: '0px!important' }}>
                                    <Typography variant="h4 font-bold text-white text-center" component="div">
                                        {item.status}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Box>
                    </div>
                    <PrintInformation printRef={printRef} form={item} />
                    <PrintInformationInvoice printRefInvoice={printRefInvoice} form={item} />
                </DialogContent>
            </Dialog>
        </span >
    );
}

export default withReducer("crmApps", reducer)(InformationModal);
