import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import * as React from "react";
import { resetpassword } from "../../store/loginSlice";
import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PasswordStrengthBar from "react-password-strength-bar";
import InputAdornment from "@mui/material/InputAdornment";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { showMessage } from "app/store/fuse/messageSlice";
import FormLabel from "@mui/material/FormLabel";
import { checkAccount } from "app/main/apps/user-account/store/userSlice";

const defaultValues = {
    password: "",
    passwordConfirm: ""
};

function UserAccountResetPasswordModal({ item, module }) {
    const dispatch = useDispatch();
    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        password: yup
            .string()
            .required("Password is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )
            .test({
                message: () => 'Please dont use old password.',
                test: async (pass) => {
                    if (!!pass) {
                        const res = await checkPassword(pass);
                        if (res) {
                            return null;
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                },
            }),
        passwordConfirm: yup
            .string()
            .required("Password (Confirm) is required")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const { control, formState, handleSubmit, reset, getValues, setValue, watch, trigger } = methods;

    const [openDialog, setOpenDialog] = useState(false);

    const { isValid, dirtyFields, errors } = formState;

    const password = watch("password");

    const [showPassword, setShowPassword] = useState(false);

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    useEffect(() => {

        reset(defaultValues);

    }, [openDialog]);

    async function checkPassword(pass) {
        return new Promise((resolve, reject) => {
            dispatch(checkAccount({ id: item.id, username: item.username, password: pass })).then((action) => {
                if (action.payload.status == 'success') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSave() {

        var data = getValues();

        data.id = item.id;

        const result = await trigger(["password", "passwordConfirm"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            dispatch(resetpassword(data));
            setOpenDialog(false);
        }
    }

    return (
        <span>
            <motion.div
                className="flex"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
            >
                <Button
                    className="whitespace-nowrap rounded-md ml-10"
                    variant="contained"
                    onClick={handleOpenDialog}
                    startIcon={
                        <Icon className="hidden sm:flex">lock_open</Icon>
                    }
                >
                    Change Password
                </Button>
            </motion.div>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            {"Reset Password - " + item.firstName + " " + item.lastName}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <form noValidate className="flex flex-col">

                    <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                        <FormLabel id="" className="text-15 font-medium">
                            Password <span className="text-red-500">*</span>
                        </FormLabel>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-16"
                                    // label="Password"
                                    type="password"
                                    name="password"
                                    error={!!errors.password}
                                    helperText={errors?.password?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        className: "pr-2",
                                        type: showPassword ? "text" : "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    size="large"
                                                >
                                                    <Icon className="text-20" color="action">
                                                        {showPassword ? "visibility" : "visibility_off"}
                                                    </Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <FormLabel id="" className="text-15 font-medium">
                            Password (Confirm) <span className="text-red-500">*</span>
                        </FormLabel>
                        <Controller
                            name="passwordConfirm"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-16"
                                    // label="Password (Confirm)"
                                    type="password"
                                    error={!!errors.passwordConfirm}
                                    helperText={errors?.passwordConfirm?.message}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        className: "pr-2",
                                        type: showPasswordConfirm ? "text" : "password",
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                    size="large"
                                                >
                                                    <Icon className="text-20" color="action">
                                                        {showPasswordConfirm ? "visibility" : "visibility_off"}
                                                    </Icon>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        {(getValues('password')) &&
                            <PasswordStrengthBar
                                password={password}
                                shortScoreWord="Password is short"
                                scoreWords={[
                                    "Password is weak",
                                    "Password is weak",
                                    "Password is okay",
                                    "Password is good",
                                    "Password is strong",
                                ]}
                            />
                        }
                    </DialogContent>

                    <DialogActions className="justify-center px-8 py-16">
                        <div className="px-16">

                            <LoadingButton
                                className="rounded-md"
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={handleSave}
                            >
                                Save
                            </LoadingButton>
                            <Button
                                className="rounded-md ml-5"
                                variant="outlined"
                                type="button"
                                onClick={handleCloseDialog}
                            >
                                Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </span >
    );
}

export default UserAccountResetPasswordModal;
