import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "app/graphql/booking/booking_graphql";

export const getBookings = createAsyncThunk(
    "bookingApp/booking/getBookings",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.bookingList,
            variables: {
                id: params.id
            }
        });

        if (response.data.data.bookingList) {
            const data = response.data.data.bookingList;

            return data === undefined ? null : data;
        }
    }
);

export const getBookingById = createAsyncThunk(
    "bookingApp/booking/getBookingById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBookingById,
            variables: {
                id: params.id
            }
        });

        if (response.data.data.getBookingById) {
            const data = response.data.data.getBookingById;

            return data === undefined ? null : data;
        }
    }
);

export const saveBooking = createAsyncThunk(
    "bookingApp/booking/saveBooking",
    async (params, { dispatch, getState }) => {

        params.startUtc = (params.start ? (new Date(params.start)).toISOString() : null);

        console.log("Booking Data:::", params);

        const response = await axios.post(CONFIG.API + "/api/", {
            query: params.id ? mutation.update : mutation.create,
            variables: { data: JSON.stringify(params) }
        });

        return params.id ? response.data.data.updateBooking : response.data.data.createBooking;

    }
);

export const resendBooking = createAsyncThunk(
    'bookingApp/booking/resendBooking', 
    async (itemData) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: mutation.resendBooking,
        variables: {
            data: JSON.stringify(itemData),
        }
    });

    return response.data.data.resendBooking;

});


const bookingSlice = createSlice({
    name: "bookingApp/booking",
    initialState: null,
    reducers: {
        resetBooking: () => null,
        newBooking: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {
                    bookingId: "",
                    name: "",
                    contact: "",
                    address: "",
                    email: "",
                    department: "",
                    purpose: "",
                    featuredImageId: "",
                    start: "",
                    end: "",
                    status: "",
                    statusBanned: false
                },
            }),
            extraReducers: {
                [getBookings.fulfilled]: (state, action) => action.payload,
                [saveBooking.fulfilled]: (state, action) => action.payload,
                [resendBooking.fulfilled]: (state, action) => action.payload,
            }
        }
    }
});

export const { newBooking, resetBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
