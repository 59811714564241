// * Updated By: Jherome
// * Summary:  table name alignment
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-53499-96
// * DateUpdated:  6/23/2023

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import Pagination from '@mui/material/Pagination';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/material/styles";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import format from 'date-fns/format';
import Rating from '@mui/material/Rating';

import { Typography } from '@mui/material'; import Link from '@mui/material/Link';
import AddReviewsModal from "app/main/ecommerce/modal/AddReviewsModal";


import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FuseLoading from '@fuse/core/FuseLoading';
const Root = styled('div')`
  td,
  th {
    border: unset;
    padding-top:10px;
    padding-bottom:10px;
  }

  
`;


export default function OrdersTable({ orders, page, setPage, pages, status, loading }) {

    const [open, setOpen] = React.useState({
        0: true
    });

    const handlePageChange = (e, item) => {
        setPage(item);
    };

    const [openDialog, setOpenDialog] = React.useState(false);
    const [itemId, setItemId] = React.useState("");
    const [orderId, setOrderId] = React.useState("");

    function handleDialogOpen(itemId, orderId) {
        setItemId(itemId);
        setOrderId(orderId);
        setOpenDialog(true);
    }

    function handleDialogClose() {
        setItemId("");
        setOpenDialog(false);
    }

    if (loading) {
        return <FuseLoading />
    }

    return (
        <Root>
            <AddReviewsModal
                openDialog={openDialog}
                handleDialogOpen={handleDialogOpen}
                handleDialogClose={handleDialogClose}
                itemId={itemId}
                orderId={orderId}
                fromOrders={true}
            />
            <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: "unset", border: "unset", boxShadow: "unset" }}>
                <TableContainer className=" mt-16 w-full">
                    {
                        orders.map((order, index) => {
                            return <Table aria-label="collapsible table" key={order.id}>
                                <TableBody>
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        <TableCell className=' font-bold truncate'>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpen({
                                                    ...open,
                                                    [index]: !open[index]
                                                })}
                                            >
                                                {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                            Order ID: <span className=' text-blue-700'>{order.orderId}</span>
                                        </TableCell>
                                        <TableCell className=' font-bold truncate' align="right">
                                            { order.shippingStatus != 'To Ship' ? (order.trackingUrl ? <span> Track Package: <Link className='font-bold' target='_blank' href={order.trackingUrl}>{order.trackingNumber} <Icon style={{ fontSize: "inherit" }}>send</Icon></Link> <span className=' text-grey-300'>|</span></span> : null) : null}
                                            {order.shippingStatus}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={open[index]}>
                                                <Box sx={{ margin: 1, backgroundColor: "#F6FAFF" }}>

                                                    <Table size="small" aria-label="purchases">

                                                        <TableBody>
                                                            {
                                                                order.items.map((item) => {
                                                                    return (
                                                                        <TableRow key={item.id}>
                                                                            <TableCell component="th" scope="row" >
                                                                                <Stack direction="row" spacing={1} alignItems="center" className='-ml-5'>
                                                                                    <ImageList sx={{ width: 80, height: 80, minWidth: 80 }} cols={1} className="overflow-hidden mr-10 border-1 rounded-md">
                                                                                        <ImageListItem>
                                                                                            <img
                                                                                                className="w-full block rounded"
                                                                                                src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                                                                                                loading="lazy"
                                                                                            />
                                                                                        </ImageListItem>
                                                                                    </ImageList>
                                                                                    <Stack spacing={.5}>
                                                                                        <Typography className="font-bold text-15 text-primary-color">
                                                                                            {item.name}
                                                                                        </Typography>
                                                                                        <Typography className="font-bold text-15 text-primary-color">
                                                                                            ({item.quantity}x)
                                                                                        </Typography>

                                                                                        {(order.shippingStatus == 'Completed' && !item.rating) && <Button
                                                                                            className=" self-start my-10 bg-orange-400 max-w-136 min-w-136 text-12 py-4 px-10 truncate text-black hover:bg-orange-500 font-semibold border-1 rounded-md"
                                                                                            variant="contained"
                                                                                            type="button"
                                                                                            onClick={() => {
                                                                                                handleDialogOpen(item.itemId, order.id)
                                                                                            }}
                                                                                        >
                                                                                            RATE
                                                                                        </Button>}

                                                                                        {
                                                                                            item.rating && <Rating name="kahit-ano-kung-saan-ka-masaya" defaultValue={parseFloat(item.rating)} precision={0.5} size="small" readOnly />
                                                                                        }

                                                                                    </Stack>
                                                                                </Stack>
                                                                            </TableCell>

                                                                            <TableCell align="right" className='font-bold'>
                                                                                ${item.subTotal}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                    <Divider className='mx-10 pt-5' />

                                                    <Stack className='p-10'>
                                                        <Stack direction="row" justifyContent="space-between">
                                                            <div className=' font-medium'>Date Purchased: {format(new Date(order.dateCreated), 'MM/dd/yyyy')}</div>
                                                            <Stack alignSelf="end">
                                                                <div className=' font-medium'>Order Total: <span className='ml-10 font-bold text-16' style={{ color: "#00007D" }}>${order.total}</span> </div>
                                                            </Stack>
                                                        </Stack>
                                                    </Stack>

                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>

                            </Table>
                        })
                    }
                    <Pagination
                        className="mt-20"
                        count={pages}
                        page={page}
                        onChange={(e, p) => handlePageChange(e, p)}
                        sx={{
                            '& .MuiPagination-ul': {
                                width: "fit-content",
                                margin: "auto"
                            }
                        }}
                    />
                </TableContainer>

            </Paper>
        </Root>
    );
}