import { createSlice } from '@reduxjs/toolkit';
import { query, mutation } from 'app/main/booking/graphql/approve_graphql';
import axios from "axios";
import CONFIG from "app/config";

export const getBookingRefId =
    (params) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getBookingRefId,
            variables: params,
        });

        var data = response.data.data.bookingByRefId;

        data.approvees = data.approvees ? JSON.parse(data.approvees) : [];

        return data;
    };

export const getMotorpoolBookingRefId =
    (params) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getMotorpoolBookingRefId,
            variables: params,
        });

        var data = response.data.data.motorpoolBookingByRefId;

        data.approvees = data.approvees ? JSON.parse(data.approvees) : [];

        return data;
    };

export const acceptBooking =
    (form) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.approveBooking,
            variables: {
                data: JSON.stringify(form)
            },
        });

        return response.data.data.approveBooking;
    };

export const acceptMotorpoolBooking =
    (form) => async (dispatch) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.approveMotorpoolBooking,
            variables: {
                data: JSON.stringify(form)
            },
        });

        return response.data.data.approveMotorpoolBooking;
    };

const initialState = {
    success: false,
    errors: [],
};

const approveSlice = createSlice({
    name: 'booking/approve',
    initialState,
    reducers: {
        approveSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        approveError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
    },
});

export const { approveSuccess, approveError } = approveSlice.actions;

export default approveSlice.reducer;