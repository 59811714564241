const mutation = {
    save: `
        mutation saveAnnouncement($data: JSONString!) {
            saveAnnouncement (data: $data) {
                status
                res
                message
            }
        }
    `,
    delete: `
        mutation deleteAnnouncement($id: String!) {
            deleteAnnouncement (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query announcementList($houseId: String) {
            announcementList(houseId: $houseId) {
                id         
                houses
                title
                description
                start
                end
                announcementPicId
                announcementPicPath
                postedByUserId
                userName
                dateCreated
                dateUpdated
            }
        }
    `,
	getAnnouncementByID: `
		query announcementById($announcementId: String!) {
			announcementById(announcementId: $announcementId) {
                id
                houses
                title
                description
                start
                end
                announcementPicId
                announcementPicPath
                postedByUserId               
				images {
					id
					announcementId
					uploadId
					fileName
					fileType
					fileId
					module
					path
					url
					dateCreated
					dateUpdated
				}              
                dateCreated
                dateUpdated
			}
		}
		
	`
}

export { mutation, query };