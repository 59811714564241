const mutation = {
    approveBooking: `
        mutation approveBooking($data: JSONString!) {
            approveBooking (data: $data) {
                status,
                res,
                message
            }
        }
  `,
    approveMotorpoolBooking: `
        mutation approveMotorpoolBooking($data: JSONString!) {
            approveMotorpoolBooking (data: $data) {
                status,
                res,
                message
            }
        }
  `,
};

const query = {
    getBookingRefId: `
        query bookingByRefId($refNum: String!, $bookingId: String!) {
            bookingByRefId (refNum: $refNum, bookingId: $bookingId){    
                id
                patronId
                publicId
                itemId
                referenceNum
                itemName
                itemCode
                itemType
                itemRate
                itemPhotoUrl
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                approvedBy
                reason
                dateCreated
                dateUpdated
                createdAt
                approvees
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
            }
        }
    `,
    getMotorpoolBookingRefId: `
        query motorpoolBookingByRefId($refNum: String!, $bookingId: String!) {
            motorpoolBookingByRefId (refNum: $refNum, bookingId: $bookingId){    
                id
                patronId
                publicId
                itemId
                referenceNum
                itemName
                itemCode
                itemType
                itemRate
                itemPhotoUrl
                name
                contact
                address
                email
                department
                purpose
                timeOfBooking
                startFormatted
                endFormatted
                start
                end
                status
                title
                duration
                total
                backgroundColor
                textColor
                display
                editable
                statusBanned
                approvedBy
                reason
                dateCreated
                dateUpdated
                createdAt
                approvees
                approvee {
                    id
                    name
                    email
                    position
                    department
                    level
                    bookingTypes
                    dateCreated
                    dateUpdated
                }
            }
        }
    `
}

export { mutation, query };