// * Updated By: ICO
// * Summary: All Price Alignment by porudcts and move the % OFF top left of the Images
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-40108-96
// * DateUpdated:  11/14/2022

import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getCategory } from "app/main/ecommerce/store/homeSlice";
import FuseLoading from "@fuse/core/FuseLoading";
import Typography from "@mui/material/Typography";
import * as React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import ItemContent from "./ItemContent";
import PromoContent from "./PromoContent";
import BehaviorsContent from "./BehaviorsContent";


const StyledBtn = styled.div`
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 20px;
    color: black;
    opacity: 0.6;
    cursor: pointer;
    top: 50%;
    z-index: 10;
    transition: all 0.25s;
    transform: ${({ type }) =>
        `translateY(-50%) ${type === 'left' ? 'rotate(180deg)' : ''}`};
    left: ${({ type }) => (type === 'left' ? '-20px' : 'initial')};
    right: ${({ type }) => (type === 'right' ? '-20px' : 'initial')};

    &:hover {
        background: gray;
        color: #fff;
        opacity: 0.5;
    }
`;


function HomeContent() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});

    useEffect(() => {
        setLoading(true);
        dispatch(getCategory()).then((action) => {
            var temp = {};
            action.payload.map((cat) => {
                temp = {
                    ...temp,
                    [cat.code]: {
                        code: cat.groups?.[0]?.code,
                        id: cat.groups?.[0]?.id
                    }
                }
                setSelectedGroup(temp)

            })

            setCategories(action.payload);
            setLoading(false);
        });
    }, [dispatch]);




    if (loading) {

        return <div className="mt-25vh"><FuseLoading /></div>

    }

    if (categories.length == 0) {

        return (

            <motion.div
                className="w-full text-center m-auto"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
            >
                <Typography color="textSecondary" variant="h5">
                    There are no items!
                </Typography>
            </motion.div>

        )

    }

    return (
        <>
            <div className="py-14 px-14 max-600px:px-0">
                <BehaviorsContent />
            </div>
            {
                categories && categories.length && categories.map((cat) => {
                    return (
                        cat.hasItems == 'true' ? <div className="py-14 px-14 max-600px:px-0" key={cat.id} id={cat.code}>

                            <Typography className=" px-24 text-28">
                                {cat.title}
                            </Typography>

                            {
                                (cat.code != 'on-sale' ? <ItemContent category={cat} /> : null)
                            }

                            {
                                (cat.code == 'on-sale' ? <PromoContent category={cat} /> : null)
                            }

                        </div> : null
                    );
                })
            }

        </>
    )

}

function ProductTile(item, handleClickItem) {
    return (
        <div className=" p-20 max-600px:p-8" key={item.id} onClick={() => { handleClickItem(item) }}>
            <div className="bg-white border-1 border-1-gray-50 border-opacity-5 p-0 cursor-pointer ">
                {/* <div style={{ width: "100%", height: "218px" }} cols={1} className="overflow-hidden my-10 m-auto mt-0 border-b-1">
                    <img
                        src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                        alt={item.itemName}
                        className="h-full w-full object-cover object-center"
                    />
                </div> */}
                <div className='productImageItem flex flex-shrink-0 items-center justify-center relative rounded-0 m-auto p-5 overflow-hidden ' style={{ width: "218px", height: "218px" }} >
                    <img className="max-w-none w-full h-full object-contain" src={item.photoUrl ? item.photoUrl : 'assets/images/ecommerce/product-image-placeholder.png'}
                        alt={item.itemName} />
                </div>
                <div className="h-76 relative border-t-1 p-8">
                    <Typography className="font-medium leading-4 line-clamp-2">{item.itemName}</Typography>
                    <div className="font-semibold absolute bottom-5 my-8 flex">
                        <Typography color="primary" className="mt-16 text-lg leading-none font-medium title-font mb-4">${item.price}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeContent;