const mutation = {
    create: `
        mutation createCrmReplyButtons($data: JSONString!) {
            createCrmReplyButtons (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    edit: `
        mutation editCrmReplyButtons($data: JSONString!) {
            editCrmReplyButtons (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    delete: `
        mutation deleteCrmReplyButtons($id: String!) {
            deleteCrmReplyButtons (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query getReplyButtons {
            getReplyButtons {
                id
                key
                name
                content
                dateCreated
                dateUpdated
            }
        }
    `,
    checkCrmKey: `
      query checkCrmKey($key: String, $id: String) {
          checkCrmKey(key: $key, id: $id) {
              id
              name
              key
          }
      }
  `,
}

export { mutation, query };