import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "../grahpql/bus_fleet_graphql";
import { showMessage } from "app/store/fuse/messageSlice";
import { formatDate, getTimeOnly } from 'app/services/services';
import { format } from "date-fns";

export const createUpdateBmBusFleet = createAsyncThunk(
    "busManagementApp/busFleet/createUpdateBmBusFleet",
    async (params, { dispatch, getState }) => {
        params.dateNow = new Date();
        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.createUpdateBmBusFleet,
            variables: { data: JSON.stringify(params) },
        });

        const data = response.data.data.createUpdateBmBusFleet

        if (data.status == 'success') {
            return dispatch(
                showMessage({
                    message: data.message,
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            )
        } else {
            return dispatch(
                showMessage({
                    message: "Something went wrong please try again later.",
                    autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            )
        }
    }
);

export const getBmBusFleet = createAsyncThunk('busManagementApp/busFleet/getBmBusFleet', async (type) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.bmBusFleet,
        variables: {
            type: type,
        }
    });

    if (response.data.data.bmBusFleet) {
        let data = response.data.data.bmBusFleet;

        data = data.map(item => ({
            ...item,
            publicCmiRate: item['publicRate'] + ' / ' + item['cmiRate'],
            // items: !!item['accessoriesFromInventory'] ? JSON.parse(item['accessoriesFromInventory']) : [],
            accessories: !!item['items'] ? item['items'].map(i => i.itemName).join(', ') : ''
        }));

        return data === undefined ? null : data;
    }
});

export const bmBusReport = createAsyncThunk('busManagementApp/busFleet/bmBusReport', async (params) => {
    // params.fromDate = params.fromDate ? new Date(params.fromDate) : "1970-01-01";
    // params.toDate = params.toDate ? new Date(params.toDate) : "9999-12-31";

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.bmBusReport,
        variables: {
            type: params.type,
            fromDate: params.fromDate ? (format(new Date(params.fromDate), 'yyyy-MM-dd')) : null,
            toDate: params.toDate ? (format(new Date(params.toDate), 'yyyy-MM-dd')) : null,
        }
    });

    if (response.data.data.bmBusReport) {
        let data = response.data.data.bmBusReport;

        return data === undefined ? null : data;
    }
});

export const getBmBusFleetMonitoring = createAsyncThunk('busManagementApp/busFleet/getBmBusFleetMonitoring', async (type) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.bmBusFleetMonitoring,
        variables: {
            type: type,
        }
    });

    if (response.data.data.bmBusFleet) {
        let data = response.data.data.bmBusFleet;

        // data = data.map(item => ({
        //     ...item,
        // }));

        return data === undefined ? null : data;
    }
});

export const checkFuelAuthById = createAsyncThunk('busManagementApp/busFleet/checkFuelAuthById', async (id) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.checkFuelAuthById,
        variables: {
            id: id,
        }
    });

    if (response.data.data.checkFuelAuthById) {
        let data = response.data.data.checkFuelAuthById;

        // data = data.map(item => ({
        //     ...item,
        //     // dateFormatted: !!item['date'] ? format(new Date(item['date']), 'MM/dd/yyyy ') : '',
        // }));

        data.dateFormatted = !!data['date'] ? format(new Date(data['date']), 'MM/dd/yyyy ') : ''

        return data === undefined ? null : data;
    }
});

export const getBusSession = createAsyncThunk('busManagementApp/busFleet/getBusSession', async (params) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getBusSession,
        variables: params
    });

    if (response.data.data.getBusSession) {
        let data = response.data.data.getBusSession;

        data = data.map(item => ({
            ...item,
            depart: item.departTerminal + ' (' + (!!item.departDate ? getTimeOnly(item.departDate) : '') + ')',
            arrived: item.arrivedTerminal + ' (' + (!!item.arrivedDate ? getTimeOnly(item.arrivedDate) : 'Going') + ')',
            time2: item.time == 'Going' ? '' : item.time,
        }));

        return data === undefined ? null : data;
    }
});

export const getBusTrackerCoordinates = createAsyncThunk('busManagementApp/busFleet/getBusTrackerCoordinates', async (params) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getBusTrackerCoordinates,
        variables: {
            timezone: timeZone,
            trackerId: params.trackerId,
            start: params.start,
            end: params.end
        }
    });

    if (response.data.data.getBusTrackerCoordinates) {
        let data = response.data.data.getBusTrackerCoordinates;

        return data === undefined ? null : data;
    }
});

export const getBusTrips = createAsyncThunk('busManagementApp/busFleet/getBusTrips', async () => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getBusTrips
    });

    if (response.data.data.getBusTrips) {
        let data = response.data.data.getBusTrips;

        data = data.map(item => ({
            ...item,
            start: !!item.departDate ? getTimeOnly(item.departDate) : '',
            ended: !!item.lastArrivedDate ? getTimeOnly(item.lastArrivedDate) : '',
            dateFormatted: format(new Date(item['dateCreated']), 'MM/dd/yyyy')
        }));

        return data === undefined ? null : data;
    }
});

export const getBmBusFleetPosition = createAsyncThunk('busManagementApp/busFleet/getBmBusFleetPosition', async (type) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.bmBusFleetPosition,
        variables: {
            type: type,
        }
    });

    if (response.data.data.bmBusFleet) {
        let data = response.data.data.bmBusFleet;

        data = data.map(item => {
            let lat = '';
            let lng = '';
            if (item.trackerPosition !== 'No Tracker Found.') {
                try {
                    const parsedPosition = JSON.parse(item.trackerPosition);
                    lat = parseFloat(parsedPosition[0].latitude);
                    lng = parseFloat(parsedPosition[0].longitude);
                } catch (error) {
                    console.error('Error parsing trackerPosition:', error);
                }
                return {
                    ...item,
                    lat,
                    lng
                };
            }
        }).filter(item => item !== undefined);

        return data;
    }
});

export const bmBusIncidentReports = createAsyncThunk('busManagementApp/busFleet/bmBusIncidentReports', async (params) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.bmBusIncidentReports,
        variables: params
    });

    if (response.data.data.getTicketsAdmin) {
        let data = response.data.data.getTicketsAdmin;

        data = data.map(item => ({
            ...item,
            dateFormatted: format(new Date(item['dateCreated']), 'MM/dd/yyyy')
        }));

        return data === undefined ? null : data;
    }
});

export const busDrivers = createAsyncThunk('busManagementApp/busFleet/busDrivers', async (params) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.busDrivers,
    });

    if (response.data.data.busDrivers) {
        let data = response.data.data.busDrivers;

        // data = data.map(item => ({
        //     ...item,
        // }));

        return data === undefined ? null : data;
    }
});



export const removeBmBusFleet = createAsyncThunk(
    'busManagementApp/busFleet/removeBmBusFleet',
    async (depIds, { dispatch, getState }) => {
        try {
            // Make API calls concurrently
            await Promise.all(depIds.map(async (id) => {
                await axios.post(CONFIG.API + "/api/", {
                    query: mutation.removeBmBusFleet,
                    variables: {
                        id: id
                    }
                });
            }));

            // Dispatch success message
            dispatch(
                showMessage({
                    message: "Inventory(s) have been successfully deleted.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "success",
                })
            );

            return depIds;
        } catch (error) {
            // Optionally dispatch an error message
            dispatch(
                showMessage({
                    message: "Failed to delete inventory(s). Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
            // Rethrow the error to let the thunk middleware know that it has failed
            throw error;
        }
    }
);

const BusFleetSlice = createSlice({
    name: "busManagementApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getBmBusFleet.fulfilled]: (state, action) => action.payload,
        [bmBusReport.fulfilled]: (state, action) => action.payload,
        [busDrivers.fulfilled]: (state, action) => action.payload,
        [removeBmBusFleet.fulfilled]: (state, action) => action.payload,
        [getBusTrackerCoordinates.fulfilled]: (state, action) => action.payload,
    },
});

export default BusFleetSlice.reducer;
