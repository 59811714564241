// * Updated By: ICO
// * Summary:  CRM Booking list Hndi nag sscroll down and up pag natapat ung cursor sa table
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-52526-96
// * DateUpdated:  05/29/2023

import _ from '@lodash';
import { useState } from "react";

import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BadgeUnstyled from '@mui/base/BadgeUnstyled';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import format from 'date-fns/format';
import TablePagination from "@mui/material/TablePagination";
import FuseScrollbars from "@fuse/core/FuseScrollbars";

import { rows } from "./CrmBookingListTableHeader";
import CrmBookingListTableHeader from "./CrmBookingListTableHeader";
import InformationModal from "../modal/InformationModal";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd):not(.Mui-selected):not(:hover)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.hover,
    },
    '& td, & th': {
        border: 0,
    },
}));

const StyledBadge = styled(BadgeUnstyled)`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  position: relative;
  display: inline-block;
  line-height: 1;

  & .MuiBadge-badge {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
  }

  & .MuiBadge-dot {
    padding: 0;
    z-index: auto;
    min-width: 6px;
    width: 10px;
    height: 10px;
    background: #ff4d4f;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff;
  }

  & .MuiBadge-anchorOriginTopRight {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-38%, 8%);
    transform-origin: 100% 0;
  }
`;

function CrmBookingListTable({ items, handleClickResend, handleClickCancel, order, setOrder, page, setPage, rowsPerPage, setRowsPerPage }) {

    function handleRequestSort(event, property) {
        const id = property;
        let direction = "desc";

        if (order.id === property && order.direction === "desc") {
            direction = "asc";
        }

        setOrder({
            direction,
            id,
        });
    }

    function handleChangePage(event, value) {
        setPage(value);
    }

    function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    const notIncluded = ['Done', 'In-Use', 'In-Use - For Post-Inspection', 'Rejected', 'Approved', 'Approved - For Pre-Inspection', 'Expired', 'Cancelled'];

    return (
        <div className="w-full flex flex-col">
            <FuseScrollbars className="grow overflow-x-auto min-h-512 overscroll-auto">
                <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
                    <CrmBookingListTableHeader
                        order={order}
                        onRequestSort={handleRequestSort}
                        rowCount={items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length}
                    />

                    <TableBody>

                        {
                            items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length === 0 && (
                                <TableRow key="noitems">
                                    <TableCell colSpan={rows.length} className="p-10">
                                        <motion.div
                                            className="flex flex-1 items-center justify-center h-full"
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                        >
                                            <Typography color="textSecondary" variant="h5">
                                                There are no items!
                                            </Typography>
                                        </motion.div>
                                    </TableCell>
                                </TableRow>
                            )
                        }

                        {_.orderBy(
                            items,
                            [
                                (o) => {
                                    switch (order.id) {
                                        default: {
                                            return o[order.id];
                                        }
                                    }
                                },
                            ],
                            [order.direction]
                        )
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((n, i) => {
                                return (
                                    <StyledTableRow
                                        className="h-50 cursor-pointer"
                                        // hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={n.id}
                                    >
                                        <TableCell className="p-5 pl-10" component="th" scope="row">
                                            {n.referenceNum}
                                        </TableCell>

                                        <TableCell className="p-5 pl-10" component="th" scope="row">
                                            {n.name}
                                        </TableCell>

                                        <TableCell className="p-5 pl-10" component="th" scope="row">
                                            {n.itemCode} - {n.itemName}
                                        </TableCell>

                                        <TableCell className="p-5 pl-10" component="th" scope="row">
                                            {n.contact}
                                        </TableCell>

                                        <TableCell className="p-5 pl-10" component="th" scope="row">
                                            {n.start ? format(new Date(n.start), 'MM/dd/yyyy ') : ""}
                                            {n.start ? format(new Date(n.start), 'hh:mm a') : ""} - {new Date(n.end) ? format(new Date(n.end), 'hh:mm a') : ""}
                                        </TableCell>

                                        <TableCell className="p-5 pl-10" component="th" scope="row">
                                            <Button
                                                className={"mt-2 mb-2 rounded-md font-bold p-1 pl-5 pr-5 mr-5 " +
                                                    (n.status == "Rejected" ? "bg-red-600 hover:bg-red-700" : "") +
                                                    (n.status == "Cancelled" ? "bg-pink-600 hover:bg-pink-700" : "") +
                                                    (n.status.includes("Approved") ? "bg-blue-600 hover:bg-blue-700" : "") +
                                                    (n.status == "Approved - For Pre-Inspection" ? " bg-purple-600 hover:bg-purple-700" : "") +
                                                    (n.status == "Expired" ? "bg-gray-600 hover:bg-gray-700" : "") +
                                                    (n.status == "Pending" ? "bg-yellow-600 hover:bg-yellow-700" : "") +
                                                    (n.status.includes("In-Use") ? "bg-orange-600 hover:bg-orange-700" : "") +
                                                    (n.status == "Done" ? "bg-green-600 hover:bg-green-700" : "") +
                                                    (n.status == "For Return" ? "bg-gray-600 hover:bg-gray-700" : "")
                                                }
                                                variant="contained"
                                            >
                                                {n.status}
                                            </Button>
                                            {n.total != '0' && (
                                                <Button
                                                    className={"mt-2 mb-2  rounded-md font-bold p-1 pl-5 pr-5 capitalize"}
                                                    variant="contained"
                                                >
                                                    {n.invoice?.invoiceStatus ? n.invoice?.invoiceStatus : 'Unpaid'}
                                                </Button>
                                            )}
                                        </TableCell>

                                        <TableCell
                                            className="p-5 pl-10 truncate"
                                            component="th"
                                            scope="row"
                                            align="left"
                                        >

                                            <InformationModal item={n} />

                                            {
                                                (!notIncluded.includes(n.status)) &&
                                                (
                                                    <Tooltip title={"Resend Email"} arrow>
                                                        <IconButton
                                                            onClick={(event) => { handleClickResend(n) }}
                                                            size="medium"
                                                        >
                                                            <Icon>send</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }

                                            {
                                                n.status == "Pending" && (
                                                    <Tooltip title={"Cancel Booking"} arrow>
                                                        <IconButton
                                                            onClick={(event) => { handleClickCancel(n) }}
                                                            size="medium"
                                                        >
                                                            <Icon>cancel</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }

                                        </TableCell>

                                    </StyledTableRow>
                                );
                            })}

                    </TableBody>
                </Table>
            </FuseScrollbars>


            <TablePagination
                className="shrink-0 border-t-1"
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={"Show: "}
                page={page}
                backIconButtonProps={{
                    "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                    "aria-label": "Next Page",
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}

export default CrmBookingListTable;
