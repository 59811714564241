const mutation = {
    approvedVisit: `
        mutation approvedVisit($data: JSONString!) {
            approvedVisit (data: $data) {
                status
                res
                message
            }
        }
    `,
    delete: `
        mutation deleteVisit($id: String!) {
            deleteVisit (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    list: `
        query visitList($type: String!) {
            visitList(type: $type) {
                id
                referenceNum
                name
                contact
                relationship
                gender
                patron {
                    name
                    emergencyContact
                }
                house {
                    id
                    houseName
                }
                room {
                    roomNumber
                }
                tenant {
                    year
                    semester
                }
                checkinDate
                checkinTime
                checkoutDate
                checkoutTime
                visitDate
                visitTimeIn
                visitTimeOut
                visitPurpose
                timeIn
                timeOut
                dateIn
                dateOut
                vcType
                checkoutPurpose
                status
            }
        }
    `,
	getVisitByID: `
        query visitById($visitId: String!) {
                visitById(visitId: $visitId) {
                id
                referenceNum
                patron {
                    name
                }
                house {
                    houseName
                }
                room {
                    roomNumber
                }
                photoUrl
                houseId
                status
                roomId
                patronId
                tenantId
                name
                relationship
                contact
                gender
                visitDate
                visitTimeIn
                visitTimeOut
                visitPurpose
                visitAgreement
                visitPicId
                visitPicPath
                checkoutDate
                checkoutTime
                checkinDate
                checkinTime
                checkoutPurpose
                checkoutAgreement
                timeIn
                timeOut
                dateIn
                dateOut
                vcType
            }
        }
		
	`
}

export { mutation, query };