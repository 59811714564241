/*****
* Module: Module name
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-39723-96
* DateUpdated: 11/14/2022
* Updated By: JF
* Update Summary: update auth of roles
*/

import { lazy } from 'react';
import { authRoles } from 'app/auth';

const VendorList = lazy(() => import('./vendor-list/VendorList'));
const VendorSales = lazy(() => import('./vendor-sale/VendorSales'));
const VendorSalesOther = lazy(() => import('./vendor-sale-other/VendorSalesOther'));
const VendorSummarized = lazy(() => import('./vendor-summarized/VendorSummarized'));
const VendorInventory = lazy(() => import('./vendor-inventory/VendorInventory'));
const VendorOrderHistory = lazy(() => import('./vendor-order-history/VendorOrderHistory'));
const VendorMeal = lazy(() => import('./vendor-meal/VendorMeal'));

const VendorAppConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/meal-vendors/list',
            element: <VendorList />,
            auth: ['ven-sal', 'ven-has']
        },
        {
            path: 'apps/meal-vendors/sales',
            element: <VendorSales />,
            auth: ['ven-sal', 'ven-has']
        },
        {
            path: 'apps/meal-vendors/sales-other',
            element: <VendorSalesOther />,
            auth: ['ven-sal', 'ven-has']
        },
        {
            path: 'apps/meal-vendors/summarized',
            element: <VendorSummarized />,
            auth: ['ven-sal', 'ven-has']
        },
        {
            path: 'apps/meal-vendors/inventory',
            element: <VendorInventory />,
            auth: ['ven-sal', 'ven-has']
        },
        {
            path: 'apps/meal-vendors/order-history',
            element: <VendorOrderHistory />,
            auth: ['ven-sal', 'ven-has']
        },
        {
            path: 'apps/meal-vendors/meal/new',
            element: <VendorMeal />,
            auth: ['ven-sal', 'ven-has']
        },
    ],
};

export default VendorAppConfig;
