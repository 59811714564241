import * as React from 'react';
import _ from "@lodash";
import reducer from "app/main/apps/crm/store";
import withReducer from "app/store/withReducer";

import { styled } from "@mui/material/styles";
import FusePageSimple from "@fuse/core/FusePageSimple";
import TenantStatusContent from './content/TenantStatusContent';

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .FusePageSimple-contentWrapper": {
        maxWidth: "1366px",
        margin: "10px auto",
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));
function TenantStatus(props) {

    return (
        <Root
            content={
                <div className="max-w-1366px m-auto">
                    <TenantStatusContent />
                </div>
            }
        />
    );
}

export default withReducer("crmApps", reducer)(TenantStatus);
