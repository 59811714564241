import * as React from 'react';
import * as yup from "yup";
import { useDeepCompareEffect } from "@fuse/hooks";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { showMessage } from "app/store/fuse/messageSlice";
import moment from 'moment';

import FusePageSimple from "@fuse/core/FusePageSimple";
import Button from "@mui/material/Button";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/booking/store";
import BookingHeader from 'app/main/booking/header/BookingHeader';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import StepLabel from '@mui/material/StepLabel';
import LoadingButton from "@mui/lab/LoadingButton";

import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";
import Tab4 from "./tabs/Tab4";
import Tab5 from "./tabs/Tab5";
import Tab6 from "./tabs/Tab6";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

import { newBooking, getBookings, saveBooking } from "app/main/booking/store/bookingSlice";

const Root = styled(FusePageSimple)(({ theme }) => ({
    "& .FusePageSimple-header": {
        minHeight: 72,
        height: 72,
        alignPatrons: "center",
        [theme.breakpoints.up("sm")]: {
            minHeight: 136,
            height: 136,
        },
    },
    "& .MuiStepLabel-label, .MuiStepLabel-iconContainer": {
        fontSize: 16,
    },
    "& .FusePageSimple-header": {
        background: "unset"
    },
    "& .FusePageSimple-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

const defaultValues = {
    referenceNum: "",
    bookingType: "Employee",
    publicBooking: true,
    id: "",
    patronId: "",
    empId: "",
    name: "",
    terms: "",
    contact: "",
    address: "",
    email: "",
    department: "",
    duration: "",
    purpose: "",
    itemId: "",
    itemName: "",
    itemCode: "",
    itemRate: "",
    itemPhotoUrl: "",
    total: "",
    start: "",
    end: "",
    tempStart: "",
    tempEnd: "",
    status: "Pending",
    statusBanned: false,
    dateCreated: "",
    statusBooking: false,
    prevStatus: "",
    timeOfBooking: moment().format('yyyy-MM-DD[T]HH:mm:ss')
};

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    empId: yup
        .string()
        .required("Employee ID is required")
        .typeError("Employee ID is required"),
    terms: yup
        .string()
        .required("Terms and Condition is required"),
    email: yup
        .string()
        .required("Registered Email is required")
        .typeError("Registered Email is required"),
    itemId: yup
        .string()
        .required("Booking Details is required"),
    start: yup
        .string()
        .required("Scheduled is required"),
    end: yup
        .string()
        .required("Scheduled is required"),
    purpose: yup
        .string()
        .required("Purpose is required"),
});

function Booking(props) {

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const { reset, watch, formState, trigger, getValues } = methods;

    const { dirtyFields, errors, isValid } = formState;

    const dispatch = useDispatch();
    const routeParams = useParams();
    const form = watch();
    const { bookingId } = routeParams;

    const [bookings, setBookings] = React.useState([]);

    // usePrompt(Object.keys(dirtyFields).length);

    useDeepCompareEffect(() => {
        function updateBookingState() {
            const { bookingId } = routeParams;

            if (bookingId === "new") {
                /**
                 * Create New Booking data
                 */
                dispatch(newBooking());
                reset(defaultValues);

            } else {
                /**
                 * Get Booking data
                 */
                dispatch(getBookings(routeParams)).then((action) => {

                    reset(action.payload);
                });
            }
        }

        updateBookingState();
    }, [dispatch, routeParams]);

    const steps = ['Registration', 'Select Booking Details', 'Select Schedule', 'Terms and Condition', 'Confirmation', 'Booking Processed'];

    const [activeStep, setActiveStep] = React.useState(0);

    const [completed, setCompleted] = React.useState({});

    const [stepFailed, setStepFailed] = React.useState([]);

    const [tempData, setTempData] = React.useState([]);

    const [disabledSaveButton, setDisabledSaveButton] = React.useState(false);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };


    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;

        validation().then(function (resp) {

            if (resp.payload && resp.payload.variant == 'error') {
                return;
            }

            setActiveStep(newActiveStep);
        });
    };

    const handleBack = () => {

        validation().then(function (resp) {

            if (resp.payload && resp.payload.variant == 'error') {
                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        });
    };

    const handleStep = (step) => () => {

        validation().then(function (resp) {

            if (resp.payload && resp.payload.variant == 'error') {
                return;
            }

            setActiveStep(step);
        });

    };

    const validation = async () => {

        setStepFailed([]);

        if (form.statusBanned) {

            setStepFailed([0]);
            return;
        }

        if (activeStep === 0) {

            const result = await trigger(["name"]);

            if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

                setStepFailed([0]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        } else if (activeStep === 1) {


            const result = await trigger(["itemId"]);

            if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

                setStepFailed([1]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        } else if (activeStep === 2) {


            // const result = await trigger(["itemId"]);

            // if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            if (!form.start && !form.end) {

                setStepFailed([2]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
            // }

        }

        return {
            payload: {
                variant: 'success'
            }
        };
    }

    async function handleSave() {

        var data = getValues();

        data.fromCrm = "false";

        const result = await trigger(["purpose"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        setDisabledSaveButton(true);

        dispatch(saveBooking(data)).then((action) => {
            if (action.payload.status == 'error') {
                return dispatch(
                    showMessage({
                        message: action.payload.message,
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
            if (action.payload.res) {
                setDisabledSaveButton(false);
                setActiveStep(5)
                setTempData(action.payload.res)
            }
        });

    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <FormProvider {...methods}>
            <Root
                header={<BookingHeader />}
                content={
                    <div className="p-16 sm:p-24 max-w-screen-lg m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg relative min-h-90p">
                        <Stepper activeStep={activeStep} className="cursor-pointer">
                            {steps.map((label, index) => {

                                const labelProps = {};

                                if (stepFailed.includes(activeStep) && activeStep == index) {
                                    labelProps.error = true;
                                }

                                return (
                                    <Step key={label} className="cursor-pointer">
                                        <StepLabel className="cursor-pointer" {...labelProps} onClick={((!form.statusBanned || !(form.statusBooking && form.prevStatus != 'Done' && form.prevStatus != 'Rejected')) && activeStep != 5) ? handleStep(index) : null}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>

                        <Tab1 activeTab={activeStep} />

                        <Tab2 activeTab={activeStep} />

                        <Tab3 activeTab={activeStep} bookings={bookings} setBookings={setBookings} />

                        <Tab4 activeTab={activeStep} />

                        <Tab5 activeTab={activeStep} tempData={tempData} handelClickPrint={handelClickPrint} printRef={printRef} />

                        <Tab6 activeTab={activeStep} tempData={tempData} handelClickPrint={handelClickPrint} printRef={printRef} />

                        <React.Fragment>
                            <div className="w-1/2 min-w-320 absolute bottom-0 left-0 right-0 m-auto mb-32">
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} className=" px-28">
                                    {!isLastStep() && (
                                        <Button
                                            className="rounded-md"
                                            variant="text"
                                            color="primary"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}

                                        >
                                            Back
                                        </Button>
                                    )}
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    {(!isLastStep() && activeStep != 4) && (
                                        <Button
                                            className="rounded-md"
                                            variant="contained"
                                            color="primary"
                                            disabled={form.statusBanned || (form.statusBooking && form.prevStatus != 'Done' && form.prevStatus != 'Rejected')}
                                            onClick={handleNext}>
                                            Next
                                        </Button>
                                    )}
                                    {(!isLastStep() && activeStep == 4) && (
                                        <motion.div
                                            className="flex"
                                            initial={{ opacity: 0, x: 20 }}
                                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                        >
                                            <LoadingButton
                                                loading={disabledSaveButton}
                                                className="rounded-md mr-5"
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={handleSave}
                                            >
                                                Confirm
                                            </LoadingButton>
                                        </motion.div>
                                    )}
                                </Box>
                            </div>
                        </React.Fragment>
                    </div>
                }
            // innerScroll
            />
        </FormProvider>
    );
}

export default withReducer("bookingApp", reducer)(Booking);
