import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import CONFIG from "app/config";
import { query, mutation } from "app/graphql/booking/booking_graphql";

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const getEventsBookingCrm = createAsyncThunk('bookingApp/booking/getEventsBookingCrm', async (filter) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getEventsBookingCrm,
        variables: {
            customerId: filter
        },
        publicUrl: 'crm',
    });

    var tempEvents = [];

    response.data.data.getEventsBookingCrm.map(function (value, key) {
        var approvee = [];

        var approvedById = value['approvedById'] ? JSON.parse(value['approvedById']) : [];
        if (value['approvee'] && value['approvee'].length) {
            value['approvee'].map(function (val, k) {

                var ifExist = approvedById.filter(function (v) {
                    return v.id == val.id;
                });

                if (ifExist.length) {
                    var status = "Approved";

                    if (value['status'] == 'Expired') {
                        status = "Expired";
                    }

                    if (value['status'] == 'Rejected') {
                        status = "Rejected";
                    }

                    approvee.push({
                        ...val,
                        status: status,
                        dateApproval: ifExist[0].date
                    })
                } else {
                    approvee.push({
                        ...val,
                        status: value['status'],
                    })
                }
            });
        }

        var tempEventsVal = {
            ...value,
            approvee: approvee
        };

        tempEvents.push(tempEventsVal);
    });

    return tempEvents;

});

export const getEventsMotorpoolBookingCrm = createAsyncThunk('bookingApp/booking/getEventsMotorpoolBookingCrm', async (filter) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: query.getEventsMotorpoolBookingCrm,
        variables: {
            customerId: filter
        },
        publicUrl: 'crm',
    });

    var tempEvents = [];

    response.data.data.getEventsMotorpoolBookingCrm.map(function (value, key) {
        var approvee = [];

        var approvedById = value['approvedById'] ? JSON.parse(value['approvedById']) : [];
        if (value['approvee'] && value['approvee'].length) {
            value['approvee'].map(function (val, k) {

                var ifExist = approvedById.filter(function (v) {
                    return v.id == val.id;
                });

                if (ifExist.length) {
                    var status = "Approved";

                    if (value['status'] == 'Expired') {
                        status = "Expired";
                    }

                    if (value['status'] == 'Rejected') {
                        status = "Rejected";
                    }

                    approvee.push({
                        ...val,
                        status: status,
                        dateApproval: ifExist[0].date
                    })
                } else {
                    approvee.push({
                        ...val,
                        status: value['status'],
                    })
                }
            });
        }

        var tempEventsVal = {
            ...value,
            approvee: approvee
        };

        tempEvents.push(tempEventsVal);
    });

    return tempEvents;

});

export const removeEvents = createAsyncThunk(
    'bookingApp/booking/remove-event',
    async (item, { dispatch }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.delete,
            variables: {
                id: item.id
            },
            publicUrl: 'crm',
        });

        return item.id;

    }
);

const eventsBookingAdapter = createEntityAdapter({});

export const {
    selectAll: selectBookingEvents,
    selectIds: selectBookingEventIds,
    selectById: selectBookingEventById,
} = eventsBookingAdapter.getSelectors((state) => state.bookingApp.bookingEvents);

const bookingEventsSlice = createSlice({
    name: 'bookingApp/booking',
    initialState: eventsBookingAdapter.getInitialState({
        eventDialog: {
            type: 'new',
            props: {
                open: false,
            },
            data: null,
        },
        searchText: '',
        typeText: '',
    }),
    reducers: {
        setItemsSearchText: {
            reducer: (state, action) => {
                state.searchText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
        setItemsTypeText: {
            reducer: (state, action) => {
                state.typeText = action.payload;
            },
            prepare: (event) => ({ payload: event.target.value || '' }),
        },
    },
    extraReducers: {
        [getEventsBookingCrm.fulfilled]: eventsBookingAdapter.setAll,
        [removeEvents.fulfilled]: eventsBookingAdapter.removeOne
    },
});

export const { setItemsSearchText, setItemsTypeText } = bookingEventsSlice.actions;

export default bookingEventsSlice.reducer;
