import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mutation, query } from "app/main/ecommerce/graphql/register_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

import axios from "axios";
import CONFIG from "app/config";
import JwtService from "app/services/jwtService";

export const createEcommerceUsers = createAsyncThunk(
    "ecommerce/user/createEcommerceUsers",
    async (userData, { dispatch, getState }) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.create,
            variables: { data: JSON.stringify(userData) },
        });

        if (response.data.data.createUser) {
            JwtService.setUserRefreshToken();
            dispatch(
                showMessage({
                    message: response.data.data.createUser["message"],
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: response.data.data.createUser["status"],
                })
            );
        } else {
            dispatch(
                showMessage({
                    message: "Something went wrong. Please try again later.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }
);

export const checkEcommerceUsername =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkEcommerceUsername,
            variables: filter
        });

        if (response.data.data.checkEcommerceUsername.length) {
            return false;
        } else {
            return true;
        }

    };

export const checkEcommerceEmail =
    (filter) => async (dispatch) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.checkEcommerceEmail,
            variables: filter
        });

        if (response.data.data.checkEcommerceEmail.length) {
            return true;
        } else {
            return false;
        }

    };

const registerSlice = createSlice({
    name: "ecommerce/user",
    initialState: null,
    reducers: {
        resetUser: () => null
    },
    extraReducers: {
        [createEcommerceUsers.fulfilled]: (state, action) => action.payload,
    },
});

export const { resetUser } = registerSlice.actions;

export default registerSlice.reducer;
