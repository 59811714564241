
import { useEffect } from 'react';
import _ from '@lodash';
import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import FuseLoading from '@fuse/core/FuseLoading';

import { activateAccount } from "app/main/ecommerce/store/loginSlice";

const CardStyled = styled(Card)(() => ({
    // background: theme.palette.background.default
}));

function ActivateAccountContent() {

    const dispatch = useDispatch();

    const routeParams = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const { id } = routeParams;

    useEffect(() => {
        setLoading(true);
        var data = {
            id: id
        };
        dispatch(activateAccount(data)).then(() => {
            setLoading(false);
        });

    }, [dispatch, id]);

    function backToHomePage() {
        navigate("/e-commerce/home");
    }

    return (
        <div className="w-full">
            <div className="p-9 pb-0 w-1/3 min-w-320 m-auto mb-48 mt-32 ">
                <CardStyled elevation={0} className="flex flex-col shadow-0 px-10 py-5">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                            <DialogContent classes={{ root: 'py-25 px-25' }}>
                                <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                                    {!loading ? (
                                        <>
                                            <Typography variant="h6" className="font-medium text-18 sm:text-24 text-center">
                                                Activate Account Successfully
                                            </Typography>
                                            <Typography variant="h6" className="text-center mt-16">
                                                <Icon className="text-green font-semibold text-5xl">check_circle</Icon>
                                            </Typography>
                                            <Typography variant="h8" className="font-medium text-14 sm:text-14 text-center">
                                                You have succesfully activate your account. You can now login.
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="w-full mx-auto mt-32 rounded-md h-48"
                                                aria-label="LOG IN"
                                                value="legacy"
                                                onClick={() => backToHomePage()}
                                            >
                                                Back to Homepage
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <FuseLoading />
                                        </>
                                    )}
                                </motion.div>
                            </DialogContent>
                        </Grid>
                    </Box>
                </CardStyled>
            </div>
        </div>
    );
}

export default ActivateAccountContent;
