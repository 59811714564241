
import clsx from 'clsx';
import { useState, memo, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';

import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LoginModal from "app/main/ecommerce/modal/LoginModal";
import EcommerceSearch from 'app/main/ecommerce/header/EcommerceSearch';
import { getCategory } from "app/main/ecommerce/store/homeSlice";

const LogoStyled = styled('div')(({ theme }) => ({
    '& > .logo-icon': {
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& > .badge, & > .logo-text': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));

const AppBarStyled = styled(AppBar)(({ theme }) => ({
    paddingRight: "0px !important",
    '& .MuiToolbar-root': {
        maxWidth: "1366px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    '& fieldset': { boxShadow: "unset !important", outline: "none !important", border: "unset !important" },

    '& .scroller::-webkit-scrollbar-thumb': {
        background: "white",
        border: "none"

    },
    '& .scroller::-webkit-scrollbar': {
        height: ".4em",
    },
    '& .scroller': {
        overflow: "auto !important",
        paddingBottom: "5px"
    }


}));

function EcommerceHeader(props) {

    const { title } = props;

    const { cartClick, setCarClick } = props;
    const { searchText, setSearchText } = props;

    const toolbarTheme = useSelector(selectToolbarTheme);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openDialogLogin, setOpenDialogLogin] = useState(false);
    const [categories, setCategories] = useState([]);
    const [cartCount, setCartCount] = useState(1);

    function handleDialogLoginOpen() {
        setOpenDialogLogin(true);
    }

    function handleDialogLoginClose() {
        setOpenDialogLogin(false);
    }

    useEffect(() => {
        dispatch(getCategory()).then((action) => {
            setCategories(action.payload);
        });
    }, [dispatch]);

    useEffect(() => {

    }, [cartClick]);

    const handleHome = () => {
        navigate("/e-commerce/home");
    }

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBarStyled
                id="fuse-toolbar"
                className={clsx('flex relative z-20 shadow-md sticky top-0', props.className)}
                color="default"
                style={{ background: `linear-gradient(to bottom, #00007D 0%, #1414BC 100%)` }}
            >
                <Toolbar className="container justify-between p-0 lg:px-24 min-h-48 md:min-h-11-r">
                    <div className={clsx('flex shrink-0 items-center')}>
                        <LogoStyled className="flex items-center pb-10 pt-0">
                            <img className="logo-icon" src="assets/images/logos/cmi-ecommerce-logo.svg" alt="logo" onClick={() => handleHome()} />
                        </LogoStyled>
                    </div>

                    <Typography className="text-20 mt-20 mb-10 font-500 text-white" variant="h5">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBarStyled>
        </ThemeProvider>
    );
}

export default memo(EcommerceHeader);
