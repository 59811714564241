import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "app/main/apps/residence-hall/graphql/tenant_graphql";
import uploadSvc from "app/services/upload";
import randomString from "app/services/randomString";
import { mutation as mutationUpload } from "app/graphql/upload_graphql";

export const getTenants = createAsyncThunk(
    "tenantApp/tenant/getTenants",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.tenantList,
            variables: {
                id: params.id
            }
        });

        if (response.data.data.tenantList) {
            const data = response.data.data.tenantList;

            return data === undefined ? null : data;
        }
    }
);

export const getTenantById = createAsyncThunk(
    "tenantApp/tenant/getTenantById",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getTenantByID,
            variables: {
                tenantId: params.tenantId
            }
        });

        if (response.data.data.tenantById) {
            const data = response.data.data.tenantById;

            return data === undefined ? null : data;
        }
    }
);

export const saveTenant = createAsyncThunk(
    "tenantApp/tenant/saveTenant",
    async (params, { dispatch, getState }) => {

        params.startUtc = (params.start ? (new Date(params.start)).toISOString() : null);

        console.log("Tenant Data:::", params);

        const response = await axios.post(CONFIG.API + "/api/", {
            query: params.id ? mutation.update : mutation.create,
            variables: { data: JSON.stringify(params) }
        });

        return params.id ? response.data.data.updateTenant : response.data.data.createTenant;

    }
);

export const resendTenant = createAsyncThunk(
    'tenantApp/tenant/resendTenant', 
    async (itemData) => {

    const response = await axios.post(CONFIG.API + "/api/", {
        query: mutation.resendTenant,
        variables: {
            data: JSON.stringify(itemData),
        }
    });

    return response.data.data.resendTenant;

});


const tenantSlice = createSlice({
    name: "tenantApp/tenant",
    initialState: null,
    reducers: {
        resetTenant: () => null,
        newTenant: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {
                    tenantId: "",
                    name: "",
                    contact: "",
                    address: "",
                    email: "",
                    department: "",
                    purpose: "",
                    featuredImageId: "",
                    start: "",
                    end: "",
                    status: "",
                    statusBanned: false
                },
            }),
            extraReducers: {
                [getTenants.fulfilled]: (state, action) => action.payload,
                [saveTenant.fulfilled]: (state, action) => action.payload,
                [resendTenant.fulfilled]: (state, action) => action.payload,
            }
        }
    }
});

export const { newTenant, resetTenant } = tenantSlice.actions;

export default tenantSlice.reducer;
