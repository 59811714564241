import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { useFormContext, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import FormLabel from "@mui/material/FormLabel";

import { showMessage } from "app/store/fuse/messageSlice";
import { saveUser } from "app/main/apps/e-commerce/store/userManagementSlice";
import { useState } from 'react';

function AddressModal({ openDialog, handleCloseDialog, address, setAddress, user }) {

    const dispatch = useDispatch();
    const [hasChange, setHasChange] = useState(false);
    const [oldAddress, setOldAddress] = useState(address);

    function handleClickOkay() {
        if (!hasChange) {
            return dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );
        }
        dispatch(
            showMessage({
                message: "Shipping address has been successfully updated.",
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: "success",
            })
        );

        if (!address) {
            return dispatch(
                showMessage({
                    message: "Shipping address is required.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }


        dispatch(saveUser({
            ...user,
            address1: address,
            saveFrom: 'address-modal'
        }))

        setHasChange(false);
        handleCloseDialog();
    }

    function handleClickCancel() {
        setAddress(oldAddress);
        setHasChange(false);
        handleCloseDialog();
    }

    function addressChange(event) {
        setHasChange(true);
        setAddress(event.target.value);
    }

    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
            scroll="body"
        >
            <form noValidate className="flex flex-col">
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography className=' text-24 font-semibold' variant="subtitle1" color="inherit">
                            Address
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pt-0 sm:pb-0' }}>
                    <FormControl className="flex sm:w-400 mx-16 mt-4 mb-9 ml-0" variant="outlined">
                        {/* <FormLabel className="mt-14 text-15 font-medium">Cash</FormLabel> */}
                        <TextField
                            className="mt-16 m-0 p-0 py-0"
                            autoFocus
                            value={address}
                            onChange={addressChange}
                            variant="outlined"
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    fontSize: '2rem'
                                },
                                '& .MuiTypography-root': {
                                    fontSize: '2rem'
                                }
                            }}
                            error={!address.trim() && hasChange}
                            helperText={(!address.trim() && hasChange) ? "Shipping address is required." : ""}
                            fullWidth
                        />
                    </FormControl>
                </DialogContent>


                <DialogActions className="px-8 py-16 text-center self-center">
                    <div className="px-4">
                        <Button
                            className="rounded-md "
                            variant="contained"
                            color="primary"
                            type="button"
                            size="large"
                            onClick={handleClickOkay}
                        >
                            Save
                        </Button>
                    </div>
                    <div className="px-4">
                        <Button
                            className="rounded-md "
                            variant="outlined"
                            type="button"
                            size="large"
                            onClick={handleClickCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default AddressModal