import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';


function CrmHall(props) {

    const navigate = useNavigate();
    
    const handleClick = (event) => {
        navigate("/crm/residence-hall/visitors");
    };

    return (
        <Tooltip title="Residence Hall" placement="top">
            <IconButton
                className="w-40 h-40"
                aria-controls="font-size-menu"
                aria-haspopup="true"
                size="large"
                onClick={handleClick}
            >
                <Icon>home</Icon>
            </IconButton>
        </Tooltip>
    );
}

export default CrmHall;
