import * as React from 'react';

import { styled } from '@mui/material/styles';
import { useFormContext } from "react-hook-form";
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';

import { getBookings } from "app/main/crm/store/motorpoolBookingSlice";

import Tab4Header from './Tab4Header';

import formatISO from 'date-fns/formatISO';

const Root = styled('div')(({ theme }) => ({

    '& a': {
        color: `${theme.palette.text.primary}!important`,
        textDecoration: 'none!important',
    },

    '& .fc-daygrid-event-harness a': {
        marginTop: '3px',
        marginBottom: '0px',
    },
    '&  .fc-media-screen': {
        minHeight: '2457px',
    },
    '&  .fc-media-screen td': {
        height: '25px',
    },
    '&  .fc-view-harness-active': {
        height: '100% !important',
    },
    '& .fc-scrollgrid, .fc-theme-standard td, .fc-theme-standard th': {
        borderColor: `${theme.palette.divider}!important`,
    },
    '& .fc-timegrid-divider.fc-cell-shaded': {
        background: `${theme.palette.divider}!important`,
        padding: '0px'
    },
    '&  .fc-scrollgrid-section > td': {
        border: 0,
    },
    '& .fc-daygrid-day': {
        '&:last-child': {
            borderRight: 0,
        },
    },
    '& .fc-col-header-cell': {
        borderWidth: '0 0 1px 0',
        padding: '16px 0',
        '& .fc-col-header-cell-cushion': {
            color: theme.palette.text.secondary,
            fontWeight: 500,
        },
    },
    '& .fc-view ': {
        borderRadius: 20,
        overflow: 'hidden',
        border: `1px solid ${theme.palette.divider}`,
        '& > .fc-scrollgrid': {
            border: 0,
        },
    },
    '& .fc-daygrid-day-number': {
        color: theme.palette.text.secondary,
        fontWeight: 500,
    },
    '& .fc-event': {
        border: 0,
        padding: '1px 6px',
        margin: '4px 2px',
    },
    '& .fc-scroller.fc-scroller-liquid-absolute': {
        minHeight: '1336px'
    },
}));

function Tab4(props) {

    const { activeTab } = props;

    if (activeTab != 3) {
        return (<></>);
    }

    const { bookings, setBookings } = props;

    const methods = useFormContext();
    const dispatch = useDispatch();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const date = queryParams.get('date');

    const calendarRef = useRef();
    const [currentDate, setCurrentDate] = useState();
    const [currentDateChange, setCurrentDateChange] = useState(false);

    const { control, formState, setValue, trigger, watch } = methods;
    const { errors } = formState;

    const [loading, setLoading] = useState(false);

    const form = watch();

    useEffect(() => {

        localStorage.removeItem('tempStart')

        setLoading(true);
        dispatch(getBookings({ id: form.itemId })).then(function (action) {
            if (action.payload) {

                var tempBookings = bookings;

                action.payload.map(function (value) {
                    value['editable'] = value['editable'] === 'true' ? true : false;
                    value['backgroundColor'] = '#eeeeef';
                    value['textColor'] = 'black';

                    var isExist = bookings.filter((booking) => {
                        return (booking.id === value['id'] || booking.publicId === value['id']);
                    });

                    if (!isExist.length) {
                        tempBookings.push(value);
                    }

                });

                setBookings(tempBookings);
                localStorage.setItem('tempStart', form.start)
                setLoading(false);
            }
        });
    }, [dispatch]);

    const handleDateSelect = async (selectInfo) => {
        const { start, end } = selectInfo;

        var starts = moment(start);
        var ends = moment(end);

        var hasConflict = false;

        await bookings.map(async function (value) {

            var startBooking = moment((new Date(value['start'])));
            var endBooking = moment((new Date(value['end'])));

            // start-time in between any of the events
            if (moment(startBooking).isAfter(starts) && moment(startBooking).isBefore(ends)) {
                console.log("start-time in between any of the events")
                hasConflict = true;
                swal({
                    title: "Scheduled conflict!",
                    icon: "warning",
                    closeOnClickOutside: false,
                }).then(() => {
                });
            }
            //end-time in between any of the events
            if (moment(endBooking).isAfter(starts) && moment(endBooking).isBefore(ends)) {
                console.log("end-time in between any of the events")
                hasConflict = true;
                swal({
                    title: "Scheduled conflict!",
                    icon: "warning",
                    closeOnClickOutside: false,
                }).then(() => {
                });
            }
            //any of the events in between/on the start-time and end-time
            if (moment(startBooking).isSameOrBefore(starts) && moment(endBooking).isSameOrAfter(ends)) {
                console.log("any of the events in between/on the start-time and end-time")
                hasConflict = true;
                swal({
                    title: "Scheduled conflict!",
                    icon: "warning",
                    closeOnClickOutside: false,
                }).then(() => {
                });
            }


        });

        if (!hasConflict) {

            setValue('start', moment(start).format('yyyy-MM-DD[T]HH:mm:ss'));
            setValue('end', moment(end).format('yyyy-MM-DD[T]HH:mm:ss'));
            setValue('tempStart', start);
            setValue('tempEnd', end);

            const startTime = moment(start, "HH:mm:ss a");
            const endTime = moment(end, "HH:mm:ss a");
            const duration = moment.duration(endTime.diff(startTime));
            const hours = parseInt(duration.asHours());
            const minutes = parseInt(duration.asMinutes()) % 60;
            const durations = ((hours ? (hours != 1 ? hours + "hrs " : hours + "hr ") : "") + (minutes ? (minutes + "minutes") : ""));

            const total = (duration.asMinutes() * form?.itemRate) / 30;

            setValue('duration', durations);
            setValue('total', total);

            var tempBookings = [];

            bookings.map(function (value) {
                tempBookings.push(value);
            })

            var publicId = FuseUtils.generateGUID();

            tempBookings.push({
                id: publicId,
                backgroundColor: "#3788D8",
                display: "block",
                editable: true,
                start: formatISO(start),
                end: formatISO(end),
                textColor: "white",
                title: form.itemCode + " - " + form.itemName,
                type: "new"
            });

            setValue('publicId', publicId);

            handleEventClick({
                'event': {
                    'start': start,
                    'end': end,
                    '_def': {
                        'publicId': publicId
                    }
                }
            });

            setBookings(tempBookings);

        }

    };

    const handleDates = (rangeInfo) => {
        if (!currentDate) {
            setCurrentDate(rangeInfo);
        }

        if (currentDateChange) {
            setCurrentDate(rangeInfo);
            setCurrentDateChange(false);
        }
    };

    const handleEventDrop = (eventDropInfo) => {
        var tempBookings = [];

        var starts = moment(new Date(eventDropInfo.event.start));
        var ends = moment(new Date(eventDropInfo.event.end));

        var hasConflict = false;

        bookings.map(function (value) {

            var startBooking = moment(new Date(value['start']));
            var endBooking = moment(new Date(value['end']));

            if (eventDropInfo.event._def.publicId != value['id']) {
                // start-time in between any of the events
                if (moment(startBooking).isAfter(starts) && moment(startBooking).isBefore(ends)) {
                    console.log("start-time in between any of the events")
                    hasConflict = true;
                    swal({
                        title: "Scheduled conflict!",
                        icon: "warning",
                        closeOnClickOutside: false,
                    }).then(() => {
                    });
                }
                //end-time in between any of the events
                if (moment(endBooking).isAfter(starts) && moment(endBooking).isBefore(ends)) {
                    console.log("end-time in between any of the events")
                    hasConflict = true;
                    swal({
                        title: "Scheduled conflict!",
                        icon: "warning",
                        closeOnClickOutside: false,
                    }).then(() => {
                    });
                }
                //any of the events in between/on the start-time and end-time
                if (moment(startBooking).isSameOrBefore(starts) && moment(endBooking).isSameOrAfter(ends)) {
                    console.log("any of the events in between/on the start-time and end-time")
                    hasConflict = true;
                    swal({
                        title: "Scheduled conflict!",
                        icon: "warning",
                        closeOnClickOutside: false,
                    }).then(() => {
                    });
                }

            }

        });

        if (!hasConflict) {

            bookings.map(function (value) {

                if (value.id === eventDropInfo.event._def.publicId) {

                    value.start = formatISO(eventDropInfo.event.start);
                    value.end = formatISO(eventDropInfo.event.end);

                    setValue('start', moment(eventDropInfo.event.start).format('yyyy-MM-DD[T]HH:mm:ss'));
                    setValue('end', moment(eventDropInfo.event.end).format('yyyy-MM-DD[T]HH:mm:ss'));
                    setValue('tempStart', eventDropInfo.event.start);
                    setValue('tempEnd', eventDropInfo.event.end);

                    const startTime = moment(eventDropInfo.event.start, "HH:mm:ss a");
                    const endTime = moment(eventDropInfo.event.end, "HH:mm:ss a");
                    const duration = moment.duration(endTime.diff(startTime));
                    const hours = parseInt(duration.asHours());
                    const minutes = parseInt(duration.asMinutes()) % 60;
                    const durations = ((hours ? (hours != 1 ? hours + "hrs " : hours + "hr ") : "") + (minutes ? (minutes + "minutes") : ""));

                    const total = (duration.asMinutes() * form?.itemRate) / 30;

                    setValue('duration', durations);
                    setValue('total', total);

                    tempBookings.push(value);

                } else {

                    tempBookings.push(value);

                }
            });

            setBookings(tempBookings);

        } else {
            eventDropInfo.revert();
        }

    };

    const handleEventClick = (clickInfo) => {
        var tempBookings = [];

        bookings.map(function (value) {

            if (value.id === clickInfo.event._def.publicId) {

                value.start = formatISO(clickInfo.event.start);
                value.end = formatISO(clickInfo.event.end);

                setValue('start', moment(clickInfo.event.start).format('yyyy-MM-DD[T]HH:mm:ss'));
                setValue('end', moment(clickInfo.event.end).format('yyyy-MM-DD[T]HH:mm:ss'));
                setValue('tempStart', clickInfo.event.start);
                setValue('tempEnd', clickInfo.event.end);

                const startTime = moment(clickInfo.event.start, "HH:mm:ss a");
                const endTime = moment(clickInfo.event.end, "HH:mm:ss a");
                const duration = moment.duration(endTime.diff(startTime));
                const hours = parseInt(duration.asHours());
                const minutes = parseInt(duration.asMinutes()) % 60;
                const durations = ((hours ? (hours != 1 ? hours + "hrs " : hours + "hr ") : "") + (minutes ? (minutes + "minutes") : ""));

                const total = (duration.asMinutes() * form?.itemRate) / 30;

                setValue('duration', durations);
                setValue('total', total);

                tempBookings.push(value);


            } else {

                tempBookings.push(value);

            }

        });

        setBookings(tempBookings);

    };

    const handleEventChange = (changeInfo) => {
        var tempBookings = [];

        var starts = moment(new Date(changeInfo.event.start));
        var ends = moment(new Date(changeInfo.event.end));

        var hasConflict = false;
        // var doubleBooking = moment(starts).isSame(moment(ends).subtract(1, 'second'), 'day');

        bookings.map(function (value) {

            var startBooking = moment((new Date(value['start'])));
            var endBooking = moment((new Date(value['end'])));

            if (changeInfo.event._def.publicId != value['id']) {
                // start-time in between any of the events
                if (moment(startBooking).isAfter(starts) && moment(startBooking).isBefore(ends)) {
                    console.log("start-time in between any of the events")
                    hasConflict = true;
                    swal({
                        title: "Scheduled conflict!",
                        icon: "warning",
                        closeOnClickOutside: false,
                    }).then(() => {
                    });
                }
                //end-time in between any of the events
                if (moment(endBooking).isAfter(starts) && moment(endBooking).isBefore(ends)) {
                    console.log("end-time in between any of the events")
                    hasConflict = true;
                    swal({
                        title: "Scheduled conflict!",
                        icon: "warning",
                        closeOnClickOutside: false,
                    }).then(() => {
                    });
                }
                //any of the events in between/on the start-time and end-time
                if (moment(startBooking).isSameOrBefore(starts) && moment(endBooking).isSameOrAfter(ends)) {
                    console.log("any of the events in between/on the start-time and end-time")
                    hasConflict = true;
                    swal({
                        title: "Scheduled conflict!",
                        icon: "warning",
                        closeOnClickOutside: false,
                    }).then(() => {
                    });
                }

            }

        });

        if (!hasConflict) {
            console.log("HAYS");

            bookings.map(function (value) {
                console.log("HAYS1");


                if (value.id === changeInfo.event._def.publicId) {
                    // doubleBooking = moment(moment(new Date(changeInfo.event.start))).isSame(moment(moment(new Date(changeInfo.event.end))).subtract(1, 'second'), 'day');

                    value.start = formatISO(changeInfo.event.start);
                    value.end = formatISO(changeInfo.event.end);

                    setValue('start', moment(changeInfo.event.start).format('yyyy-MM-DD[T]HH:mm:ss'), { shouldDirty: true });
                    setValue('end', moment(changeInfo.event.end).format('yyyy-MM-DD[T]HH:mm:ss'), { shouldDirty: true });
                    setValue('tempStart', changeInfo.event.start);
                    setValue('tempEnd', changeInfo.event.end);

                    const startTime = moment(changeInfo.event.start, "HH:mm:ss a");
                    const endTime = moment(changeInfo.event.end, "HH:mm:ss a");
                    const duration = moment.duration(endTime.diff(startTime));
                    const hours = parseInt(duration.asHours());
                    const minutes = parseInt(duration.asMinutes()) % 60;
                    const durations = ((hours ? (hours != 1 ? hours + "hrs " : hours + "hr ") : "") + (minutes ? (minutes + "minutes") : ""));

                    const total = (duration.asMinutes() * form?.itemRate) / 30;

                    setValue('duration', durations);
                    setValue('total', total);

                    // if (doubleBooking) {
                    tempBookings.push(value);
                    // }

                    // } else {

                    //     if (doubleBooking) {
                    //         tempBookings.push(value);
                    //     }
                }

            });

            // if (!doubleBooking) {
            //     changeInfo.revert();
            // } else {
            setBookings(tempBookings);
            // }

        } else {
            console.log("HAYS2");

            changeInfo.revert();
        }

    };


    const handleSelectAllow = (selectInfo) => {
        var disabled = true;

        bookings.map(function (value) {

            if (value['type'] == 'new') {
                disabled = false;
            }

        });

        if (disabled) {
            return moment(new Date(selectInfo.start)).isSame(moment(new Date(selectInfo.end)).subtract(1, 'second'), 'day');
        } else {
            return false;
        }
    };

    if (loading) {
        return (
            <FuseLoading />
        )
    }

    return (
        <Root className="flex flex-col flex-auto relative ">

            <div className="flex p-24 max-600px:p-0 container h-full pb-60">
                <motion.div
                    className="w-full h-full"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                >
                    <Tab4Header calendarRef={calendarRef} currentDate={currentDate} setCurrentDateChange={setCurrentDateChange} />

                    <FullCalendar
                        className="h-full"
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={false}
                        initialView="timeGridWeek"
                        selectable
                        selectMirror
                        dayMaxEvents
                        editable
                        slotDuration={"00:15:00"}
                        allDaySlot={false}
                        weekends
                        datesSet={handleDates}
                        select={handleDateSelect}
                        events={bookings}
                        initialDate={date ? new Date(date) : (localStorage.getItem('tempStart') ? new Date(localStorage.getItem('tempStart')) : new Date())}
                        ref={calendarRef}
                        validRange={{ start: localStorage.getItem('tempStart') ? new Date(localStorage.getItem('tempStart')) : new Date() }}
                        eventClick={handleEventClick}
                        eventChange={handleEventChange}
                        eventDrop={handleEventDrop}
                        selectConstraint={{
                            start: '00:00',
                            end: '24:00',
                        }}
                        selectAllow={handleSelectAllow}
                    />
                </motion.div>
            </div>
        </Root>
    );
}

export default Tab4;
