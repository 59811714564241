
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

function CrmFooter(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className={clsx('relative z-20 shadow-md', props.className)}
        color="default"
        style={{ backgroundColor: footerTheme.palette.background.paper }}
      >
        <Toolbar className="min-h-36 md:h-36 px-8 sm:px-12 py-0 flex flex-col justify-center items-center overflow-x-auto">
          <Typography className="FuseSettings-formGroupTitle" color="textSecondary">
          © CMI One Stop Shop System 2022. All rights reserved | Developed by Medisource
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(CrmFooter);
