const mutation = {
    deleteCartItem: `
      mutation deleteCartItem($id: String!) {
            deleteCartItem (id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    orderList: `
        query ecommerceOrdersListF($customerId: String!, $status: String!, $page: Int, $limit: Int) {
            ecommerceOrdersListF(customerId: $customerId, status: $status, page: $page, limit: $limit) {
                page
                pages
                hasNext
                hasPrev
                totalItems
                itemsPerPage
                objects {            
                    id
                    customer
                    customerId
                    cardName
                    trackingNumber
                    trackingUrl
                    total
                    orderId
                    shippingStatus
                    dateCreated
                    dateUpdated
                    items {
                        id
                        itemId
                        subTotal
                        description
                        name
                        quantity
                        photoUrl
                        rating
                        dateCreated
                    }
                }
                
            }
        }
    `,
}

export { mutation, query };