import { authRoles } from 'app/auth';
import Register from './register/Register';
import Booking from './booking/Booking';
import Status from './status/Status';
import Approve from './approve/Approve';
import Invoice from './invoice/Invioce';

const BookingConfig = {
  settings: {
    theme: {
        main: "default",
        navbar: "default",
        toolbar: "default",
        footer: "default"
    },
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'booking/register',
      element: <Register />,
    },
    {
      path: 'booking',
      element: <Booking />,
    },
    {
      path: 'booking/status/:referenceNum/*',
      element: <Status />,
    },
    {
      path: 'booking/accept/:level/:referenceNum/:approveeId/:bookingId/*',
      element: <Approve status={'accept'}/>,
    },
    {
      path: 'booking/reject/:level/:referenceNum/:approveeId/:bookingId/*',
      element: <Approve status={'reject'}/>,
    },
    {
      path: 'booking/invoice/:invoiceId/:bookingId/*',
      element: <Invoice />,
    },
  ],
};

export default BookingConfig;
