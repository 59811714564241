import * as React from 'react';
import * as yup from "yup";
import { useDeepCompareEffect } from "@fuse/hooks";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { showMessage } from "app/store/fuse/messageSlice";
import moment from 'moment';
import { useState } from "react";

import FusePageSimple from "@fuse/core/FusePageSimple";
import Button from "@mui/material/Button";
import withReducer from "app/store/withReducer";
import _ from "@lodash";
import reducer from "app/main/apps/crm/store";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Box from '@mui/material/Box';
import StepLabel from '@mui/material/StepLabel';
import FuseLoading from "@fuse/core/FuseLoading";
import LoadingButton from "@mui/lab/LoadingButton";
import Tabs from '@mui/material/Tabs';

import Tab1 from "./tabs/Tab1";
import Tab2 from "./tabs/Tab2";
import Tab3 from "./tabs/Tab3";
import Tab4 from "./tabs/Tab4";
// import Tab5 from "./tabs/Tab5";
import Tab6 from "./tabs/Tab6";
import Tab7 from "./tabs/Tab7";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

import CrmHeader from 'app/main/crm/header/CrmHeader';
import CrmFooter from 'app/main/crm/footer/CrmFooter';

import { newBooking, searchPatron, saveBooking } from "app/main/crm/store/motorpoolBookingSlice";
import CrmJwtService from 'app/services/crm/CrmJwtService';
import CrmMotorpoolBookingTabs from './CrmMotorpoolBookingTabs';
import { format } from 'date-fns';

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageSimple-contentWrapper": {

    },
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .FusePageSimple-contentWrapper": {
        overscrollBehavior: "auto !important",
        maxWidth: "1366px",
        margin: "10px auto",
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    empId: yup
        .string()
        .required("Employee ID is required")
        .typeError("Employee ID is required"),
    terms: yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
    email: yup
        .string()
        .required("Registered Email is required")
        .typeError("Registered Email is required"),
    itemId: yup
        .string()
        .required("Booking Details is required"),
    start: yup
        .string()
        .required("Scheduled is required"),
    end: yup
        .string()
        .required("Scheduled is required"),
    purpose: yup
        .string()
        .required("Purpose is required"),
});

function CrmMotorpoolBooking(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routeParams = useParams();
    const user = CrmJwtService.getDecodedAccessToken();

    const defaultValues = {
        referenceNum: "",
        bookingType: "Employee",
        publicBooking: true,
        id: "",
        patronId: "",
        empId: user.id_num,
        name: "",
        terms: false,
        contact: "",
        address: "",
        email: user.email,
        department: "",
        duration: "",
        purpose: "",
        itemId: "",
        itemName: "",
        itemTerms: "",
        itemCode: "",
        itemRate: "",
        itemPhotoUrl: "",
        total: "",
        start: "",
        end: "",
        tempStart: "",
        tempEnd: "",
        status: "Pending",
        statusBanned: false,
        dateCreated: "",
        statusBooking: false,
        prevStatus: "",
        driverData: {},
        timeOfBooking: moment().format('yyyy-MM-DD[T]HH:mm:ss')
    };

    const [bookings, setBookings] = React.useState([]);

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const { reset, watch, formState, trigger, getValues, setValue } = methods;

    const { dirtyFields, errors, isValid } = formState;

    const form = watch();

    useDeepCompareEffect(() => {
        function updateBookingState() {
            dispatch(newBooking());
            reset(defaultValues);
            handleSearchHandle();
        }

        updateBookingState();
    }, [dispatch, routeParams]);

    function isNotEmptyObject(value) {
        return value && typeof value === 'object' && Object.keys(value).length > 0;
    }


    async function handleSearchHandle() {

        await trigger(['empId', 'email']);

        if (form.empId && form.email) {

            dispatch(searchPatron({
                empId: form.empId,
                email: form.email
            })).then((action) => {
                if (action.payload) {
                    var patron = action.payload;
                    setValue("name", patron.name);
                    setValue('contact', patron['emergencyContact']);
                    setValue('address', patron['fullAddress']);
                    setValue('email', patron['email']);
                    setValue('department', patron['departmentName']);
                    setValue("patronId", patron["id"]);
                    setValue('statusBanned', patron['blacklist'].length > 0 ? true : false);
                    setValue('statusBooking', patron['booking'] ? true : false);
                    setValue('prevStatus', patron['booking'] ? patron['booking']['status'] : "");
                    setValue("recordNotFound", false);
                } else {
                    setValue("recordNotFound", true);
                    setValue("name", '');
                    setValue('contact', '');
                    setValue('address', '');
                    setValue('email', '');
                    setValue('department', '');
                    setValue("patronId", '');
                    setValue('statusBanned', false);
                    setValue('statusBooking', false);
                    setValue('prevStatus', "");
                }
            });

        }

    }

    const steps = ['Registration', 'Driver', 'Select Booking Details', 'Select Schedule', 'Confirmation', 'Booking Processed'];

    const [activeStep, setActiveStep] = React.useState(1);

    const [completed, setCompleted] = React.useState({});

    const [stepFailed, setStepFailed] = React.useState([]);

    const [tempData, setTempData] = React.useState([]);

    const [disabledTab, setDisabledTab] = React.useState(false);

    const [loading, setLoading] = useState(true);

    const [disabledSaveButton, setDisabledSaveButton] = React.useState(false);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };


    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;

        validation(newActiveStep).then(function (resp) {

            if (resp.payload && resp.payload.variant == 'error') {
                return;
            }

            setActiveStep(newActiveStep);
        });
    };

    const handleBack = () => {

        validation((prevActiveStep) => prevActiveStep - 1).then(function (resp) {

            if (resp.payload && resp.payload.variant == 'error') {
                return;
            }

            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        });
    };

    const handleStep = (step) => () => {

        validation(step).then(function (resp) {

            if (resp.payload && resp.payload.variant == 'error') {
                return;
            }

            setActiveStep(step);
        });

    };

    const validation = async (step) => {
        console.log(step, activeStep, form, 'aaaaaaaaaaaaa')


        // if (step === 5 && activeStep === 4) {

        //     const result = await trigger(["terms"]);

        //     if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

        //         setStepFailed([3]);

        //         return dispatch(
        //             showMessage({
        //                 message: "Please fill in the required fields.",
        //                 autoHideDuration: 5000, //ms
        //                 anchorOrigin: {
        //                     vertical: "bottom",
        //                     horizontal: "right",
        //                 },
        //                 variant: "error",
        //             })
        //         );
        //     }
        // }

        if (step === 6) {

            return {
                payload: {
                    variant: 'error'
                }
            };
        }

        setStepFailed([]);

        if (form.statusBanned == "true") {
            // if (form.statusBanned == "true" || form.statusBooking == "true") { // https://app.activecollab.com/187150/projects/96?modal=Task-43363-96
            setStepFailed([0]);

            return {
                payload: {
                    variant: 'error'
                }
            };
        }

        if (activeStep === 1) {


            if (!isNotEmptyObject(form.driverData)) {

                setStepFailed([1]);

                console.log("VAL 2")

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            } else if (format(new Date(), 'yyyyMMdd') >= format(new Date(form.driverData.licenseExpiration), 'yyyyMMdd')) {
                setStepFailed([1]);

                return dispatch(
                    showMessage({
                        message: "License expired cannot proceed on booking.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        } else if (activeStep === 2) {


            if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

                setStepFailed([2]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        } else if (activeStep === 3) {

            if (!form.start && !form.end) {

                setStepFailed([3]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        }

        if (step === 3) {


            if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

                setActiveStep(2);

                setStepFailed([2]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

        }

        if (step === 4) {


            if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

                setActiveStep(2);

                setStepFailed([2]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }

            if (!form.start && !form.end) {

                setActiveStep(3);

                setStepFailed([3]);

                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
        }

        return {
            payload: {
                variant: 'success'
            }
        };
    }

    async function handleSave() {

        var data = getValues();

        data.fromCrm = "true";

        const result = await trigger(["purpose"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {

            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        setLoading(true);

        setDisabledSaveButton(true);

        dispatch(saveBooking(data)).then((action) => {
            if (action.payload.status == 'error') {
                return dispatch(
                    showMessage({
                        message: action.payload.message,
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            }
            if (action.payload.res) {
                reset(getValues());
                setLoading(false);
                setDisabledSaveButton(false);
                setActiveStep(5)
                setTempData(action.payload.res)
                setDisabledTab(true)
            }
        });

    }

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    function handleBookAnother() {
        reset(defaultValues);
        setActiveStep(1)
        setDisabledTab(false)
        setBookings([])
        handleSearchHandle();
    }

    if (loading) {
        <FuseLoading />
    }

    return (
        <div className="flex flex-col flex-auto min-w-0 relative z-10 h-full">
            <CrmHeader />
            <FormProvider {...methods}>
                <Root
                    contentToolbar={
                        <div className="px-12 w-full">
                            <CrmMotorpoolBookingTabs menu='booking' />
                        </div>
                    }
                    content={
                        <div className="max-w-1366px m-auto">
                            <div className="p-16 sm:p-24 m-auto mb-56 mt-24 md:mt-9 bg-white shadow-md rounded-lg relative min-h-90p w-full max-w-screen-sf min-w-sm">
                                <div className=' overflow-auto whitespace-nowrap pb-8'>
                                    <Stepper activeStep={activeStep} className="cursor-pointer">
                                        {steps.map((label, index) => {

                                            const labelProps = {};

                                            if (stepFailed.includes(activeStep) && activeStep == index) {
                                                labelProps.error = true;
                                            }

                                            return (
                                                <Step key={label} className="cursor-pointer">
                                                    <StepLabel className="cursor-pointer" {...labelProps} onClick={!disabledTab ? handleStep(index) : null}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>

                                <Tab1 activeTab={activeStep} />

                                <Tab2 activeTab={activeStep} />

                                <Tab3 activeTab={activeStep} />

                                <Tab4 activeTab={activeStep} bookings={bookings} setBookings={setBookings} />

                                {/* <Tab5 activeTab={activeStep} /> */}

                                <Tab6 activeTab={activeStep} tempData={tempData} handelClickPrint={handelClickPrint} printRef={printRef} />

                                <Tab7 activeTab={activeStep} tempData={tempData} handelClickPrint={handelClickPrint} printRef={printRef} />

                                <React.Fragment>
                                    <div className="w-1/2 min-w-320 bottom-0 left-0 right-0 m-auto mb-32">
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }} className=" px-28">
                                            {!isLastStep() && (
                                                <Button
                                                    className="rounded-md"
                                                    variant="text"
                                                    color="primary"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}

                                                >
                                                    Back
                                                </Button>
                                            )}
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            {(!isLastStep() && activeStep != 4) && (
                                                <Button
                                                    className="rounded-md"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={form.statusBanned == 'true'}
                                                    // disabled={form.statusBanned == 'true' || form.statusBooking == 'true'} https://app.activecollab.com/187150/projects/96?modal=Task-43363-96
                                                    onClick={handleNext}>
                                                    Next
                                                </Button>
                                            )}
                                            {(!isLastStep() && activeStep == 4) && (
                                                <motion.div
                                                    className="flex"
                                                    initial={{ opacity: 0, x: 20 }}
                                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                                >
                                                    <LoadingButton
                                                        loading={disabledSaveButton}
                                                        className="rounded-md mr-5"
                                                        variant="contained"
                                                        color="primary"
                                                        type="button"
                                                        onClick={handleSave}
                                                    >
                                                        Confirm
                                                    </LoadingButton>
                                                </motion.div>
                                            )}
                                            {(isLastStep()) && (
                                                <motion.div
                                                    className="flex"
                                                    initial={{ opacity: 0, x: 20 }}
                                                    animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                                >
                                                    <Button
                                                        className="whitespace-nowrap mx-4 rounded-md"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBookAnother}
                                                    >
                                                        Book Another
                                                    </Button>
                                                </motion.div>
                                            )}
                                        </Box>
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>
                    }

                />

            </FormProvider>
            <CrmFooter />
        </div>
    );
}

export default withReducer("crmApps", reducer)(CrmMotorpoolBooking);
