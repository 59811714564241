import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { borderBottom } from '@mui/system';

const StyledAppBar = styled(AppBar)(({ theme }) => ({

    backgroundColor: "#FFFFFF !important",
    borderBottom: "3px solid #00008A",
    color: "#00008A"

}));


function BookingHeader(props) {

    const { form, status } = props

    return (
        <StyledAppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters className="justify-between">
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        <img src="assets/images/logos/cmi-logo-blue.png" />
        
                    </Typography>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                    >
                        {
                            status == 'accept' && <>
                                Approve { form.itemType } Booking for { form.name }
                            </>
                        }
                        {
                            status == 'reject' && <>
                                Reject { form.itemType } Booking for { form.name }
                            </>
                        }
                        {
                            status == 'download' && <>
                                Download Invoice
                            </>
                        }

                    </Typography>
                </Toolbar>
            </Container>
        </StyledAppBar>
    );

}

export default BookingHeader;