import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { mutation as mutationUpload, query as queryUpload } from "app/graphql/upload_graphql";
import { showMessage } from "app/store/fuse/messageSlice";

export const getUploadsByFileId = createAsyncThunk('serviceApp/getUploadsByFileId', async (fileId) => {
    const response = await axios.post(CONFIG.API + "/api/", {
        query: queryUpload.getUploadsByFileId,
        variables: {
            fileId: fileId,
        }
    });

    if (response.data.data.getUploadsByFileId) {
        const data = response.data.data.getUploadsByFileId;

        console.log("getUploadsByFileId", data)

        return data === undefined ? null : data;
    }
});

export const getSignedUrlPath = createAsyncThunk(
    "serviceApp/getSignedUrl",
    async (path) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: queryUpload.getSignedUrl,
            variables: {
                path: path ? path : null,
            }
        });

        if (response.data.data.getSignedUrl) {
            const data = response.data.data.getSignedUrl;

            return data === undefined ? null : data;
        } else {
            return null;
        }
    }
);

const serviceSlice = createSlice({
    name: "serviceApp",
    initialState: null,
    reducers: null,
    extraReducers: {
        [getUploadsByFileId.fulfilled]: (state, action) => action.payload,
        [getSignedUrlPath.fulfilled]: (state, action) => action.payload,
    },
});

export default serviceSlice.reducer;
