import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CONFIG from "app/config";
import { query, mutation } from "app/main/crm/graphql/motorpool_booking_graphql";
import CrmJwtService from 'app/services/crm/CrmJwtService';

const token = CrmJwtService.getAccessToken();


export const getBookings = createAsyncThunk(
    "crmApps/crmBooking/getBookings",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.motorpoolBookingList,
            variables: {
                id: params ? params.id : ""
            }
        });

        if (response.data.data.motorpoolBookingList) {
            const data = response.data.data.motorpoolBookingList;

            return data === undefined ? null : data;
        }
    }
);

export const publicBookingList = createAsyncThunk(
    "crmApps/crmBooking/publicMotorpoolBookingList",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.publicMotorpoolBookingList,
            variables: {
                data: JSON.stringify(params),
            }
        });

        if (response.data.data.publicMotorpoolBookingList) {
            const data = response.data.data.publicMotorpoolBookingList;

            return data === undefined ? null : data;
        }
    }
);

export const searchPatron = createAsyncThunk(
    "crmApps/crmBooking/searchPatron",
    async (params) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.searchBookingPatron,
            variables: params,
            publicUrl: 'crm',
        });

        if (response.data.data.searchBookingPatron) {
            const data = response.data.data.searchBookingPatron;

            return data == undefined ? null : data;
        }
    }
);

export const saveBooking = createAsyncThunk(
    "crmApps/crmBooking/saveBooking",
    async (params, { dispatch, getState }) => {

        params.startUtc = (params.start ? (new Date(params.start)).toISOString() : null);

        console.log("Booking Data:::", params);

        const response = await axios.post(CONFIG.API + "/api/", {
            query: params.id ? mutation.update : mutation.create,
            variables: { data: JSON.stringify(params) },
            publicUrl: 'crm',
        });

        return params.id ? response.data.data.updateMotorpoolBooking : response.data.data.createMotorpoolBooking;

    }
);

export const resendBooking = createAsyncThunk(
    'bookingApp/booking/resendMotorpoolBooking',
    async (itemData) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.resendMotorpoolBooking,
            variables: {
                data: JSON.stringify(itemData),
            },
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        return response.data.data.resendMotorpoolBooking;

    });

export const cancelBooking = createAsyncThunk(
    'bookingApp/booking/cancelMotorpoolBooking',
    async (itemData) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.cancelMotorpoolBooking,
            variables: {
                data: JSON.stringify(itemData),
            },
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        return response.data.data.cancelMotorpoolBooking;

    });

export const bookingFeedback = createAsyncThunk(
    "crmApps/crmBooking/bookingFeedback",
    async (feedbackId) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.bookingFeedback,
            variables: {
                id: feedbackId
            }
        });

        if (response.data.data.bookingFeedback) {
            const data = response.data.data.bookingFeedback;

            return data === undefined ? null : data;
        }
    }
);

export const saveBookingFeedback = createAsyncThunk(
    'bookingApp/booking/saveBookingFeedback',
    async (itemData) => {

        const response = await axios.post(CONFIG.API + "/api/", {
            query: mutation.saveBookingFeedback,
            variables: {
                data: JSON.stringify(itemData),
            },
            publicUrl: 'crm',
        }, {
            headers: {
                'common': {
                    'Authorization': `Bearer ${token}`
                }

            }
        });

        return response.data.data.saveBookingFeedback;

    });

export const getItemTypes = createAsyncThunk(
    'bookingApp/booking/motorpoolVehiclesType',
    async () => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.motorpoolVehiclesType
        });
        const data = response.data.data.motorpoolVehiclesType;
        return data;
    });

export const getCrmUser = createAsyncThunk(
    'bookingApp/booking/getCrmUser',
    async (id) => {
        const response = await axios.post(CONFIG.API + "/api/", {
            query: query.getCrmUser,
            variables: {
                usersId: id,
            },
        });
        const data = response.data.data.getCrmUser;

        data.bookingConfigFilter = data.bookingConfigFilter ? JSON.parse(data.bookingConfigFilter) : null;

        return data;
    });


const bookingSlice = createSlice({
    name: "crmApps/crmBooking",
    initialState: null,
    reducers: {
        resetBooking: () => null,
        newBooking: {
            reducer: (state, action) => action.payload,
            prepare: (event) => ({
                payload: {
                    bookingId: "",
                    name: "",
                    contact: "",
                    address: "",
                    email: "",
                    department: "",
                    purpose: "",
                    featuredImageId: "",
                    start: "",
                    end: "",
                    status: "",
                    statusBanned: false
                },
            }),
            extraReducers: {
                [searchPatron.fulfilled]: (state, action) => action.payload,
                [getBookings.fulfilled]: (state, action) => action.payload,
                [saveBooking.fulfilled]: (state, action) => action.payload,
                [getItemTypes.fulfilled]: (state, action) => action.payload,
            }
        }
    }
});

export const { newBooking, resetBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
