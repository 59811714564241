import { useFormContext, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from '@mui/material/FormHelperText';
import Typography from "@mui/material/Typography";

import Icon from "@mui/material/Icon";

import InputMask from 'react-input-mask';

import { getDepartments } from "app/main/apps/settings/store/bookingDepartmentsSlice";


function RegisterContent() {

    const dispatch = useDispatch();

    const methods = useFormContext();
    const { control, formState, setValue, watch, getValues } = methods;
    const { errors } = formState;

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        dispatch(getDepartments()).then((action) => {
            setDepartments(action.payload)
        });
    }, [dispatch]);

    return (
        <>
            <div className="">
                <div className="flex flex-1 w-full items-center justify-between">
                    <FormControl
                        className="flex mx-16 w-full mt-9  ml-0"
                        variant="outlined"
                    >
                        <Typography color="text.primary" className="text-15">Barcode / ID NO <span className="text-red-500">*</span></Typography>
                        <Controller
                            name="idNum"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className=" mb-16"
                                    error={!!errors.idNum}
                                    required
                                    helperText={errors?.idNum?.message}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        }
                                    }}
                                    autoFocus
                                    id="idNum"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                </div>

                <div className="flex flex-1 w-full items-center justify-between">
                    <FormControl
                        className="flex mx-16 w-full mt-9  ml-0"
                        variant="outlined"
                    >
                        <Typography color="text.primary" className="text-15">Email <span className="text-red-500">*</span></Typography>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="mb-16"
                                    error={!!errors.email}
                                    required
                                    helperText={errors?.email?.message}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        }
                                    }}
                                    autoFocus
                                    id="email"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                </div>

                <Typography color="text.primary" className="text-15">Department <span className="text-red-500">*</span></Typography>
                <div className="flex -mx-4 ml-2 ">
                    <div className="flex items-center justify-between">
                        <FormControl className="flex sm:w-200 md:w-400 mx-16 mt-4 mb-9 ml-0 w-340px" variant="outlined">
                            <Controller
                                name="departmentName"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        className="h-40px"
                                        id="departmentName-select"
                                        required
                                        error={!!errors.departmentName}
                                    >
                                        {departments.map((departmentName) => (
                                            <MenuItem value={departmentName.name} key={departmentName.id} onClick={() => {
                                                setValue("departmentId", departmentName.id);
                                            }}>
                                                {departmentName.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <FormHelperText error={!!errors.departmentName}>{errors?.departmentName?.message}</FormHelperText>
                        </FormControl>
                    </div>
                </div>

                <div className="flex flex-1 w-full items-center justify-between">
                    <strong className="text-18 w-320">Primary Details</strong>
                    <hr className="w-full" />
                </div>

                <div className="flex flex-1 w-full items-center justify-between">
                    <FormControl
                        className="flex mx-16 w-full mt-9  ml-0"
                        variant="outlined"
                    >
                        <Typography color="text.primary" className="text-15">First Name <span className="text-red-500">*</span></Typography>
                        <Controller
                            name="firstName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className=" mb-16"
                                    error={!!errors.firstName}
                                    required
                                    helperText={errors?.firstName?.message}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        }
                                    }}
                                    autoFocus
                                    id="firstName"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl
                        className="flex mx-16 w-full mt-9  ml-0"
                        variant="outlined"
                    >
                        <Typography color="text.primary" className="text-15">Middle Name</Typography>
                        <Controller
                            name="middleName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className=" mb-16"
                                    error={!!errors.middleName}
                                    required
                                    helperText={errors?.middleName?.message}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        }
                                    }}
                                    autoFocus
                                    id="middleName"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl
                        className="flex mx-16 w-full mt-9  ml-0"
                        variant="outlined"
                    >
                        <Typography color="text.primary" className="text-15">Last Name <span className="text-red-500">*</span></Typography>
                        <Controller
                            name="lastName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className=" mb-16"
                                    error={!!errors.lastName}
                                    required
                                    helperText={errors?.lastName?.message}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        }
                                    }}
                                    autoFocus
                                    id="lastName"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </FormControl>
                </div>

                <FormControl
                    className="flex w-full mx-16   mt-9  ml-0"
                    variant="outlined">
                    <Controller
                        render={({ field }) => (
                            <div className="">
                                <Typography color="text.primary" id="demo-row-radio-buttons-group-label" className="text-15">
                                    Gender
                                </Typography>
                                <RadioGroup {...field} aria-label="gender" name="gender1" row>
                                    <FormControlLabel value="Male" control={<Radio />} label="Male"
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: 15
                                            }
                                        }} />
                                    <FormControlLabel
                                        value="Female"
                                        control={<Radio />}
                                        label="Female"
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontSize: 15
                                            }
                                        }}
                                    />
                                </RadioGroup>
                            </div>
                        )}
                        name="gender"
                        control={control}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0 max-w-fit"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Birthday</Typography>
                    <Controller
                        control={control}
                        name="birthdate"
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    // label="Birthday"
                                    value={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    renderInput={(field) => (
                                        <TextField {...field} className=""
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 40
                                                }
                                            }} />
                                    )}
                                    onChangeRaw={(e) => {
                                        setFieldTouched(field.name, true, true);
                                    }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Emergency Contact</Typography>
                    <Controller
                        control={control}
                        name="emergencyContact"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Emergency Contact"
                                id="emergencyContact"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Phone <span className="text-red-500">*</span></Typography>
                    <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                            <InputMask
                                {...field}
                                mask="(999) 999-9999"
                                maskPlaceholder="(000) 000-0000"
                            >
                                {() =>
                                    <TextField
                                        {...field}
                                        className=""
                                        // label="Phone"
                                        id="phone"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.phone}
                                        helperText={errors?.phone?.message}
                                        placeholder="(000) 000-0000"
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 40
                                            }
                                        }}
                                    />}
                            </InputMask>
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Notes</Typography>
                    <Controller
                        control={control}
                        name="notes"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Notes"
                                id="notes"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Field 1</Typography>
                    <Controller
                        control={control}
                        name="field1"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Field1"
                                id="field1"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Field 2</Typography>
                    <Controller
                        control={control}
                        name="field2"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Field2"
                                id="field2"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Field 3</Typography>
                    <Controller
                        control={control}
                        name="field3"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Field3"
                                id="field3"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Field 4</Typography>
                    <Controller
                        control={control}
                        name="field4"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Field4"
                                id="field4"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    className="flex w-full mx-16 mt-9 ml-0"
                    variant="outlined">
                    <Typography color="text.primary" className="text-15">Field 5</Typography>
                    <Controller
                        control={control}
                        name="field5"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                // label="Field5"
                                id="field5"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>


            </div>

        </>
    )

}

export default RegisterContent;