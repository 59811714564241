const mutation = {
	save: `
		mutation saveHouse($data: JSONString!) {
			saveHouse (data: $data) {
				status
				res
				message
			}
		}
	`,
	checkHouse: `
		mutation checkHouse($data: JSONString!) {
			checkHouse (data: $data) {
				status
				res
				message
			}
		}
	`,
};

const query = {
	getHouseByID: `
		query houseById($houseId: String!) {
			houseById(houseId: $houseId) {
				id
				houseName
				houseMother
				houseAdmins
				houseDetails
				houseAddress
				houseContact
				houseRoomNumber
				houseStatus
				housePicId
				housePicPath
				dateCreated
				dateUpdated
				images {
					id
					houseId
					uploadId
					fileName
					fileType
					fileId
					module
					path
					url
					dateCreated
					dateUpdated
				}
			}
		}
		
	`
}

export { mutation, query };