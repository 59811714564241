// * Updated By: Jherome
// * Summary: print form format, igaya sa ibang print form- ARA
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-43517-96
// * DateUpdated:  12/20/2022

// * Updated By: ico
// * Summary: fix price and remvie sentence
// * Task: https://app.activecollab.com/187150/projects/96?modal=Task-43692-96
// * DateUpdated:  12/27/2022

import { useFormContext, Controller } from "react-hook-form";

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardContent from "@mui/material/CardContent";
import format from 'date-fns/format';
import FormControl from "@mui/material/FormControl";
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { acceptBooking, getBookingRefId } from "app/main/booking/store/approveSlice";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

const CardStyled = styled(Card)(({ theme }) => ({
    background: theme.palette.background.default
}));

function InvoiceContent(props) {

    const { form, status, setForm } = props;
    const dispatch = useDispatch();
    const routeParams = useParams();

    console.log(form, "form");

    const printRef = useRef();

    const handelClickPrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <>
            <div className="w-full">
                <div className="p-9 pb-0 w-fit max-w-lg min-w-800 m-auto mb-48">

                    <CardStyled className="flex flex-col shadow-0 mt-10" elevation={0}>
                        <Box sx={{ flexGrow: 1 }}>
                            <div className="text-right">
                                <Button
                                    color="inherit" className="rounded-0 py"
                                    onClick={handelClickPrint}
                                >
                                    <Icon color="inherit">print</Icon>
                                </Button>
                            </div>
                            <Grid container spacing={{ xs: 0, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                                <DialogContent classes={{ root: 'py-10 px-10 pb-0 ' }} ref={printRef}>
                                    <div className='flex flex-row justify-center'>
                                        <div className='flex flex-col mx-16'>
                                            <div className='flex justify-center'>
                                                <div className="logo">
                                                    <img width="200" src="assets/images/logos/cmi-logo-blue.png" alt="logo" />
                                                </div>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    College of Marshall Islands PO Box 1258 Majuro,
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    Republic of the Marshall Islands 96960
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    TELEPHONE #: +692 625 1213
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    EMAIL ADDRESS: acs@cmi.edu
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-2'>
                                                    WEBSITE: oss.cmi.edu
                                                </Typography>
                                            </div>
                                            <div className='flex justify-center'>
                                                <Typography className=' text-14 font-semibold mt-32'>
                                                    Commercial Services
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-bold mt-10'>
                                                INVOICE #: {form?.invoice?.invoiceNum}
                                            </Typography>
                                            <Typography className=' text-14 font-semibold'>
                                                TIME OF BOOKING: {form?.booking?.dateCreated ? format(new Date(form?.booking?.dateCreated), 'MMMM dd, yyyy hh:mm a') : null}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                BILL TO: {form?.booking?.name}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                EMAIL: {form?.booking?.email}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                ADDRESS: {form?.booking?.address}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                CONTACT: {form?.booking?.contact}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                DEPARTMENT: {form?.booking?.department}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-bold mt-10'>
                                                START: {form?.booking?.start ? format(new Date(form?.booking?.start), 'EEEE, MMMM dd, yyyy') : ""} {form?.booking?.start ? format(new Date(form?.booking?.start), 'hh:mm a') : ""} - {form?.booking?.end ? format(new Date(form?.booking?.end), 'hh:mm a') : ""}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                BOOKING DETAILS:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                {form?.booking?.itemCode} {form?.booking?.itemType} {form?.booking?.itemName}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                PURPOSE:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                {form?.booking?.purpose}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                DURATION:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                {form?.booking?.duration}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-medium mt-10'>
                                                UNIT PRICE:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                SUBTOTAL:
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                DISCOUNT:
                                            </Typography>
                                        </div>
                                        <div className='flex flex-col mx-16 text-right'>
                                            <Typography className=' text-14 font-medium  mt-10'>
                                                ${parseFloat(form?.booking?.itemRate).toFixed(2)}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                ${parseFloat(form?.invoice?.subTotal).toFixed(2)}
                                            </Typography>
                                            <Typography className=' text-14 font-medium'>
                                                ${parseFloat(form?.invoice?.discount).toFixed(2)}
                                            </Typography>
                                        </div>
                                    </div>

                                    <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            <Typography className=' text-14 font-bold mt-10'>
                                                TOTAL:
                                            </Typography>
                                        </div>
                                        <div className='flex flex-col mx-16 text-right'>
                                            <Typography className=' text-14 font-bold mt-10 '>
                                                ${parseFloat(form?.invoice?.grandTotal).toFixed(2)}
                                            </Typography>
                                        </div>
                                    </div>

                                    {/* <div className='flex flex-row justify-between mt-16 mb-16'>
                                        <div className='flex flex-col mx-16 '>
                                            Please make all checks/remittance payable to: "College of the Marshall Islands" and mail payment to the address above attention "FABS Office"
                                            <br />
                                            <br />
                                            Thank you very much
                                        </div>
                                    </div> */}

                                </DialogContent>
                            </Grid>
                        </Box>

                    </CardStyled>

                </div>
            </div>
        </>
    );

}

export default InvoiceContent;