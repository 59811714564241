import "@fake-db";
import BrowserRouter from "@fuse/core/BrowserRouter";
import LockScreen from "@fuse/core/LockScreen";
import InternetDetector from "@fuse/core/InternetDetector";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { selectCurrLangDir } from "app/store/i18nSlice";
import withAppProviders from "./withAppProviders";
import { Auth } from "./auth";
import { AuthCrm } from "./crm";
import * as React from "react";
import { MessengerChat } from "react-messenger-chat-plugin";
import AppAutoLogout from "./services/AppAutoLogout";
import axios from './services/axiosConfig'; // this is for handle request

const emotionCacheOptions = {
    rtl: {
        key: "muirtl",
        stylisPlugins: [rtlPlugin],
        insertionPoint: document.getElementById("emotion-insertion-point"),
    },
    ltr: {
        key: "muiltr",
        stylisPlugins: [],
        insertionPoint: document.getElementById("emotion-insertion-point"),
    },
};

const App = () => {
    const langDirection = useSelector(selectCurrLangDir);

    return (
        <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
            <MessengerChat
                pageId="109872448125295"
                // bottomSpacing={{ xs: "24px", sm: "125px" }}
                version="v15.0"
            />
            <AppAutoLogout>
                <Auth>
                    <AuthCrm>
                        <BrowserRouter>
                            <FuseAuthorization>
                                <FuseTheme>
                                    <SnackbarProvider
                                        maxSnack={5}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        classes={{
                                            containerRoot:
                                                "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
                                        }}
                                    >
                                        <InternetDetector />
                                        <LockScreen />
                                        <FuseLayout />
                                    </SnackbarProvider>
                                </FuseTheme>
                            </FuseAuthorization>
                        </BrowserRouter>
                    </AuthCrm>
                </Auth>
            </AppAutoLogout>
        </CacheProvider>
    );
};

export default withAppProviders(App)();
