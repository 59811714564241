import { useFormContext, Controller } from "react-hook-form";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";

import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from 'react-input-mask';

import { showMessage } from "app/store/fuse/messageSlice";

import { saveUser } from "app/main/apps/e-commerce/store/userManagementSlice";
import UserAccountResetPasswordModal from "../modal/UserAccountResetPasswordModal";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { format } from "date-fns";

function MyAccountContent(props) {

    const dispatch = useDispatch();
    const methods = useFormContext();
    const { control, formState, getValues, setValue, trigger, reset } = methods;
    const { errors, dirtyFields, isValid } = formState;

    async function generateUsername() {
        var rug = require("random-username-generator");
        rug.setSeperator("");

        setValue("username", rug.generate());

        await trigger(["username"]);
    }

    async function handlesaveUser() {

        const result = await trigger(["email", "password", "passwordConfirm", "firstName", "lastName", "username", "birthdate", "address1", "mobilephone", "phone", "isActive"]);

        console.log(errors)

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            var data = getValues();

            data.birthdate = data.birthdate ? format(new Date(data.birthdate), 'yyyy-MM-dd') : null;

            dispatch(saveUser(data)).then(function () {
                reset(getValues());
            });
        }
    }

    async function handleChange(e, model) {
        const regex = /^[a-zA-Z ]*$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setValue(model, e.target.value, { shouldDirty: true });
            const result = await trigger(["firstName", "lastName"]);
        }
    };

    function handleClickCancel() {
        reset();
    }

    return (
        <div className="px-24 max-600px:px-10 pb-48 max-w-lg m-auto">
            <div role="presentation" className="py-20 font-medium">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none !important' }} color="inherit" href="/e-commerce/home" >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    </Link>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary" >
                        My Account
                    </Typography>
                </Breadcrumbs>
            </div>
            <div className="bg-white border-1 border-1-gray-50 border-opacity-5">
                <div className="py-14 px-24 max-600px:px-10">
                    <Typography className="font-semibold text-18">My Account</Typography>
                    {/* <Alert severity="info" sx={{
              '& .MuiAlert-icon': { display: "none" },
              '& .MuiAlert-message': { paddingTop: "unset", paddingBottom: "unset" },
          }} className=" rounded-md border-1 border-cyan-A700 my-16">
              <Typography className="font-bold text-black">Shipping Method <Link className="mr-10 cursor-pointer" >Edit</Link></Typography>
              <Typography className="font-medium text-black">Last lost test</Typography>
          </Alert> */}

                    {/* <Stack 
              direction="row"
              className=""
              // divider={<Divider orientation="vertical" flexItem />}
              spacing={2}> */}

                    <div className=" mt-10">
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <div className="">
                                    <Typography className="text-15" color="text.primary">Status <span className="text-red-500">*</span> </Typography>
                                    <RadioGroup {...field} aria-label="status" name="status1" row>
                                        <FormControlLabel
                                            value={"Enable"}
                                            control={<Radio required />}
                                            label="Enable"
                                        />
                                        <FormControlLabel
                                            value={"Disable"}
                                            control={<Radio required />}
                                            label="Disable"
                                        />
                                        <FormControlLabel
                                            value={"Pending"}
                                            control={<Radio required />}
                                            label="Pending"
                                        />
                                    </RadioGroup>
                                    <FormHelperText className="ml-14" error={!!errors.status}>{errors?.status?.message}</FormHelperText>
                                </div>
                            )}
                        />
                        <div className="flex items-center justify-between">
                            <FormControl
                                className="flex w-full  ml-0"
                                variant="outlined"
                            >
                                <Typography className="text-15">Username <span className="text-red-500">*</span> </Typography>
                                <Controller
                                    name="username"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex">
                                            <TextField
                                                {...field}
                                                className=""
                                                error={!!errors.username}
                                                required
                                                helperText={errors?.username?.message}
                                                id="username"
                                                variant="outlined"
                                                fullWidth
                                                sx={{
                                                    "& .MuiInputBase-root": {
                                                        height: 40
                                                    }
                                                }}
                                            />
                                            <motion.div
                                                className="flex ml-16"
                                                initial={{ opacity: 0, x: 20 }}
                                                animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                                            >
                                                <Button
                                                    className="whitespace-nowrap mx-4 max-h-40 rounded-md"
                                                    variant="contained"
                                                    onClick={generateUsername}
                                                >
                                                    <Icon className="">settings_backup_restore</Icon>
                                                </Button>
                                            </motion.div>
                                        </div>


                                    )}
                                />

                            </FormControl>

                        </div>

                        <FormControl
                            className="flex mt-9  w-full"
                            variant="outlined">
                            <Typography className="text-15" color="text.primary">Email <span className="text-red-500">*</span> </Typography>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-16"
                                        error={!!errors.email}
                                        required
                                        helperText={errors?.email?.message}
                                        id="email"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                height: 40
                                            },
                                            "& .MuiOutlinedInput-input": {
                                                py: "1rem"
                                            }
                                        }}
                                    />
                                )}
                            />
                        </FormControl>


                        <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: "column", sm: 'row' }}>
                            <div className="w-full">
                                <Typography className="text-15" color="text.primary">First Name <span className="text-red-500">*</span></Typography>
                                <Controller
                                    name="firstName"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className=""
                                            error={!!errors.firstName}
                                            required
                                            helperText={errors?.firstName?.message}
                                            // label="First Name"
                                            autoFocus
                                            id="firstName"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => { handleChange(e, 'firstName') }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 40
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="w-full">
                                <Typography className="text-15" color="text.primary">Last Name <span className="text-red-500">*</span></Typography>
                                <Controller
                                    name="lastName"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-16"
                                            error={!!errors.lastName}
                                            required
                                            helperText={errors?.lastName?.message}
                                            // label="Last Name"
                                            id="lastName"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => { handleChange(e, 'lastName') }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 40
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </Stack>
                        <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: "column", sm: 'row' }}>
                            <div className="w-full mb-16">
                                <Controller
                                    render={({ field }) => (
                                        <div className="">
                                            <Typography id="demo-row-radio-buttons-group-label" color="text.primary">
                                                Gender
                                            </Typography>
                                            <RadioGroup {...field} aria-label="gender" name="gender1" row>
                                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                <FormControlLabel
                                                    value="Female"
                                                    control={<Radio />}
                                                    label="Female"
                                                />
                                            </RadioGroup>
                                        </div>
                                    )}
                                    name="gender"
                                    control={control}
                                />
                            </div>
                            <div className="w-full mb-16">
                                <Controller
                                    control={control}
                                    name="birthdate"
                                    render={({ field }) => (
                                        <div className="">
                                            <Typography id="demo-row-radio-buttons-group-label" color="text.primary">
                                                Birthdate
                                            </Typography>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    renderInput={(field) => (
                                                        <TextField {...field} className="w-full"
                                                            variant='outlined'
                                                            sx={{
                                                                "& .MuiInputBase-root": {
                                                                    height: 40
                                                                },
                                                                // "& .MuiInputAdornment-root .MuiButtonBase-root":{marginRight:"unset "}
                                                            }} />
                                                    )}
                                                    onChangeRaw={() => {
                                                        setFieldTouched(field.name, true, true);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    )}
                                />
                            </div>
                        </Stack>
                        <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: "column", sm: 'row' }}>
                            <div className="w-full">
                                <Typography className="text-15" color="text.primary">Phone <span className="text-red-500">*</span></Typography>
                                <Controller
                                    control={control}
                                    name="phone"
                                    render={({ field }) => (
                                        <InputMask
                                            {...field}
                                            mask="(999) 999-9999"
                                            maskPlaceholder="(000) 000-0000"
                                        >
                                            {() =>
                                                <TextField
                                                    {...field}
                                                    className=""
                                                    // label="Phone"
                                                    id="phone"
                                                    variant="outlined"
                                                    error={!!errors.phone}
                                                    helperText={errors?.phone?.message}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 40
                                                        }
                                                    }}
                                                />
                                            }
                                        </InputMask>
                                    )}
                                />
                            </div>

                            <div className="w-full">
                                <Typography className="text-15" color="text.primary">Mobile Phone <span className="text-red-500">*</span></Typography>
                                <Controller
                                    control={control}
                                    name="mobilephone"
                                    render={({ field }) => (
                                        <InputMask
                                            {...field}
                                            mask="(999) 999-9999"
                                            maskPlaceholder="(000) 000-0000"
                                        >
                                            {() =>
                                                <TextField
                                                    {...field}
                                                    className="mb-16"
                                                    // label="Mobile Phone"
                                                    id="mobilephone"
                                                    variant="outlined"
                                                    error={!!errors.mobilephone}
                                                    helperText={errors?.mobilephone?.message}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 40
                                                        }
                                                    }}
                                                />
                                            }
                                        </InputMask>
                                    )}
                                />
                            </div>
                        </Stack>

                        <motion.div
                            className="flex"
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
                        >
                            <Button
                                className="whitespace-nowrap rounded-md"
                                variant="contained"
                                color="primary"
                                onClick={handlesaveUser}
                            >
                                SAVE
                            </Button>

                            <Button
                                className="whitespace-nowrap rounded-md ml-3"
                                variant="contained"
                                color="primary"
                                onClick={handleClickCancel}
                            >
                                Cancel
                            </Button>

                            <UserAccountResetPasswordModal item={getValues()} module={"user"} />

                        </motion.div>
                    </div>
                    <div>
                    </div>
                    {/* </Stack> */}
                </div>
            </div>
        </div>
    );
}

export default MyAccountContent;
