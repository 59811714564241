const mutation = {
    deleteCartItem: `
      mutation deleteCartItem($id: String!) {
            deleteCartItem (id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    wishlistCount: `
        query ecommerceWishlistList($customerId: String!) {
            ecommerceWishlistList(customerId: $customerId) {
            id
            }
        }
    `,
    wishlistList: `
        query ecommerceWishlistList($customerId: String!) {
            ecommerceWishlistList(customerId: $customerId) {
                id
                itemId
                name
                stock
                description
                price
                quantity
                total
                discount
                photoUrl
            }
        }
    `,
    cartInventoryItems: `
        query cartInventoryItems {
            cartInventoryItems {
                id
                featuredImagePath
                name
                description
                srp
                photoUrl
            }
        } 
    `,
    cartGroups: `
        query cartGroups($catId: String) {
            cartGroups(catId: $catId) {
                id
                title
                code
                categoryId
            }
        } 
    `
}

export { mutation, query };