import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from 'app/store/fuse/messageSlice';
import { setUserData } from './crmUserSlice';
import { mutation, query } from "app/graphql/login_graphql";
import CrmJwtService from 'app/services/crm/CrmJwtService';
import axios from "axios";
import CONFIG from "app/config";

export const submitLogin =
  ({ email, password }) =>
    async (dispatch) => {
      return await CrmJwtService
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          window.location.reload();

          dispatch(setUserData(user));

          return dispatch(crmloginSuccess());
        })
        .catch((errors) => {
          errors.forEach((error) => {
            return dispatch(
              showMessage({
                message: error.message,
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "warning",
              })
            );
          });
        });
    };

export const forgotPassword = createAsyncThunk(
  "login/user/forgotpassword",
  async (postdata, { dispatch, getState }) => {

    const response = await axios.post(CONFIG.API + "/api/", {
      query: mutation.forgotPassword,
      variables: { data: JSON.stringify(postdata) },
    });


    return response.data.data.forgotPassword;
  }

);

export const checkLoginEmail =
  (filter) => async (dispatch) => {

    const response = await axios.post(CONFIG.API + "/api/", {
      query: query.checkLoginEmail,
      variables: filter
    });

    console.log(response.data.data.checkLoginEmail)

    if (response.data.data.checkLoginEmail.length) {
      return true;
    } else {
      return false;
    }

  };


const initialState = {
  success: false,
  errors: [],
};

const crmLoginSlice = createSlice({
  name: 'crm/crmlogin',
  initialState,
  reducers: {
    crmloginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    crmloginError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { crmloginSuccess, crmloginError } = crmLoginSlice.actions;

export default crmLoginSlice.reducer;
