/* DateUpdated: 03/20/23
* updatedBy: ico
* Summary: Redesign
* Task: https://app.activecollab.com/187150/projects/96?modal=Task-49292-96
*/
import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import CrmHeaderStatic from 'app/main/crm/header/CrmHeaderStatic';
import CrmFooter from 'app/main/crm/footer/CrmFooter';
import VerifyEmailContent from 'app/main/crm/verify/content/VerifyEmailContent';

const Root = styled(FusePageSimple)(() => ({
    "& .FusePageCarded-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard, .FusePageSimple-header": {
        backgroundColor: "unset",
        background: "unset",
        boxShadow: "unset"
    },
    "& .ps__rail-y": {
        display: "none !important"
    }
}));

function CrmRegisterPassword(props) {

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <CrmHeaderStatic />
            <Root
                content={
                    <div className="max-w-1366px m-auto my-20 h-full flex justify-center items-center max-h-screen-50 ">
                        <VerifyEmailContent />
                    </div>
                }
            />
            <CrmFooter />
        </div>
    );
}

export default CrmRegisterPassword;

