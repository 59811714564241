import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";

import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import EcommerceHeader from 'app/main/ecommerce/header/EcommerceHeader';
import EcommerceFooter from 'app/main/ecommerce/footer/EcommerceFooter';
import ShopContent from 'app/main/ecommerce/shop/content/ShopContent';

const Root = styled(FusePageSimple)(({ theme }) => ({
    "& .FusePageSimple-topBg": {
        background: "unset"
    },
    minHeight: "unset",
    "& .FusePageSimple-contentCard": {
        backgroundColor: "unset",
        boxShadow: "unset"
    }
}));

function Shop(props) {

    const dispatch = useDispatch();
    
    const routeParams = useParams();

    useEffect(() => {

        if(routeParams['*']){
            setSearchText(routeParams['*']);
            
        }

    }, [routeParams, dispatch]);

    const [dateFilter, setDateFilter] = useState([null, null]);
    const [searchText, setSearchText] = useState(routeParams['*'] ? routeParams['*'] : "");
    const [categoryText, setCategoryText] = useState("");
    const [typeText, setTypeText] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState({
        direction: "asc",
        id: null,
    });

    return (
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
            <EcommerceHeader
                searchText={searchText}
                setSearchText={setSearchText}
            />
            <Root
                content={
                    <div className="max-w-1366px m-auto">
                        <ShopContent
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            categoryText={categoryText}
                            setCategoryText={setCategoryText}
                            typeText={typeText}
                            setTypeText={setTypeText}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            page={page}
                            setPage={setPage}
                            order={order}
                            setOrder={setOrder}
                        />
                    </div>
                }

            />

            <EcommerceFooter />
        </div>
    );
}

export default Shop;

