const mutation = {
    createUpdateMotorpoolVehicles: `
        mutation createUpdateMotorpoolVehicles($data: JSONString) {
           createUpdateMotorpoolVehicles(data: $data) {
                status,
                res,
                message
            }
        }
    `,
    removeMotorpoolVehicles: `
        mutation removeMotorpoolVehicles($id: String) {
           removeMotorpoolVehicles(id: $id) {
                status,
                res,
                message
            }
        }
    `,
};

const query = {
    motorpoolVehicles: `
        query motorpoolVehicles($type: String, $isBooking: String){
            motorpoolVehicles(type: $type, isBooking: $isBooking){
                id
                code
                type
                name
                description
                featuredImagePath
                featuredImageId
                featuredImageUrl
                fileId
                publicRate
                cmiRate
                employeeOnly
                inspectionRequired
                status
                notes
                vehicleType
                plate
                brand
                model
                color
                year
                transmission
                capacity
                orcr
                orcrUrl
                orcrRegistration
                orcrExpiration
                fuelConsumptionLiters
                fuelConsumptionKm
                fullTankLiters
                fuelType
                accessoriesFromInventory
                trackerId
                items {
                    id
                    itemId
                    itemCode
                    itemName
                    quantity
                }
                dateCreated
                title
            }
        }
    `,
    getTrackerCoordinates: `
        query getTrackerCoordinates($timezone: String, $trackerId: String, $start: String, $end: String){
            getTrackerCoordinates(timezone: $timezone, trackerId: $trackerId, start: $start, end: $end){
                deviceId
                routes{
                    lat
                    lng
                }
            }
        }
    `,
};

export { mutation, query };