import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const LogoStyled = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge, & > .logo-text': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function CrmHeaderStatic(props) {
  
  const navigate = useNavigate();

  const toolbarTheme = useSelector(selectToolbarTheme);

  const handleHome = () => {
      navigate("/crmlogin");
  }

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{ backgroundColor: toolbarTheme.palette.background.paper }}
        position="static"
      >
        <Toolbar className="container justify-between p-0 lg:px-24 min-h-48 md:min-h-11-r">
          <div className={clsx('flex shrink-0 items-center')}>
            <LogoStyled className="flex items-center pb-10 pt-0">
              <img className="logo-icon w-288" src="assets/images/logos/CMI_CRM_NAV.png" alt="logo" onClick={() => handleHome()} />
            </LogoStyled>
          </div>

          <Typography className="text-20 mt-20 mb-10 font-500" variant="h5">
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider >
  );
}

export default memo(CrmHeaderStatic);
