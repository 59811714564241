const mutation = {
    saveCrmUsers: `
      mutation saveCrmUsers($data: JSONString!) {
          saveCrmUsers (data: $data) {
            status,
            res,
            message
          }
      }
  `,
};

const query = {
    crmReportsIndividual: `
        query crmReportsIndividual($limit: Int, $toDate: String, $fromDate: String, $page: Int) {
            crmReportsIndividual(limit: $limit, toDate: $toDate, fromDate: $fromDate, page: $page) {    
                page
                pages
                hasNext
                hasPrev
                totalItems
                itemsPerPage
                objects { 
                    name
                    averageTicket
                    averageTicketRaw
                }  
            }
        }
    `,
    crmReportsDepartment: `
        query crmReportsDepartment($limit: Int, $toDate: String, $fromDate: String, $page: Int) {
            crmReportsDepartment(limit: $limit, toDate: $toDate, fromDate: $fromDate, page: $page) {    
                page
                pages
                hasNext
                hasPrev
                totalItems
                itemsPerPage
                objects { 
                    name
                    averageTicket
                    averageTicketRaw
                }  
            }
        }
    `,
    crmReportsTopics: `
        query crmReportsTopics($limit: Int, $toDate: String, $fromDate: String, $page: Int) {
            crmReportsTopics(limit: $limit, toDate: $toDate, fromDate: $fromDate, page: $page) {    
                page
                pages
                hasNext
                hasPrev
                totalItems
                itemsPerPage
                objects { 
                    name
                    averageTicket
                    averageTicketRaw
                }  
            }
        }
    `,
}

export { mutation, query };