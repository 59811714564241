import Card from '@mui/material/Card';
import { styled, lighten } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import JWTLoginTab from './tabs/JWTLoginTab';
import JwtService from "app/services/jwtService";

const Root = styled('div')(({ theme }) => ({
  // background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
  //   theme.palette.primary.dark,
  //   0.5
  // )} 100%)`,
  background: theme.palette.primary.white,
  color: theme.palette.primary.contrastText,

  '& .Login-leftSection': {},

  '& .Login-rightSection': {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
      theme.palette.primary.dark,
      0.1
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function Login() {
  useEffect(function() {
    JwtService.setJwtLoginReload(false);
  }, []);
  return (
    <Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 lg-1180:p-24">
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 lg-1180:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className="Login-leftSection flex flex-col w-full max-w-md items-center justify-center shadow-0 pt-28"
          square
        >
          <CardContent className="flex flex-col pt-28 items-center justify-center w-full py-5 max-w-320" sx={{ p: 0, '&:last-child': { pb: '6rem' } }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <div className="flex items-center mb-10">
                <img className="logo-icon" src="assets/images/logos/cmi-logo-college.png" alt="logo" />
                {/* <div className="border-l-1 mr-20  ml-10 w-2 h-60" /> */}

              </div>
              <div>
                {/* <Typography
                    className="text-16 font-normal mb-20"
                    color="textSecondary"
                  >
                    Enter your email address and password to get access to your account
                  </Typography> */}
              </div>
            </motion.div>
            <JWTLoginTab />

          </CardContent>
        </Card>

        <div className="Login-rightSection hidden lg-1180:flex flex-1">
          <div className="hidden lg-1180:flex flex-1 p-64 bg-[url('img/bg-login-transparent.png')]  max-1245px:p-52">
            <div className="max-w-fit">
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
              >
                <Typography variant="h2" color="inherit" className="font-semibold leading-tight -mt-12">
                  Welcome to
                </Typography>
                <Typography variant="h4" color="inherit" className="font-semibold leading-tight text-orange-300">
                    CMI One Stop Shop!
                </Typography>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.3 } }}
              >
                <Typography variant="subtitle1" color="inherit" className="text-20 mt-24 max-1245px:text-18">
                  Powerful and professional admin template for <br></br> Web Applications, CRM, CMS,<br></br>  Admin
                  Panels and more.
                </Typography>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </Root>
  );
}

export default Login;
